import * as React from 'react';

import ButtonTertiaryHoverStyled from './ButtonTertiaryHoverStyled';

type ButtonTertiaryHoverProps = any

const ButtonTertiaryHover: React.FC<ButtonTertiaryHoverProps> = (props) => (
  <ButtonTertiaryHoverStyled  {...props}>{props.children ? props.children : ""}</ButtonTertiaryHoverStyled>
);

export default ButtonTertiaryHover;