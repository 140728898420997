import styled from '@emotion/styled'
import React, { CSSProperties, SVGProps } from 'react'
import MediaQueries from '../../figma/tokens/MediaQueries'
import Spacings from '../../figma/tokens/Spacings'
import { useLanguage } from '../../hooks/useLanguage'
import { AntiloopTextType } from '../../tiger/interfaces/Antiloop'
import { BUTTON_HEIGHT_MOBILE, BUTTON_HEIGHT_PX } from '../StrongHardCodedSizes'
import FigmaBox from './FigmaBox'
import FigmaTextContainer from './FigmaTextContainer'
import SVGContainer from './SVGContainer'
import SubmitButton from './SubmitButton'

type Variant = 'primary' | 'secondary' | 'tertiary'

export type FigmaButtonProps = {
  onClick?: (event?: any) => void
  textKey: AntiloopTextType
  text?: string
  leftSvg?: React.FC<React.PropsWithChildren<React.PropsWithChildren<SVGProps<SVGSVGElement>>>>
  rightSvg?: React.FC<React.PropsWithChildren<React.PropsWithChildren<SVGProps<SVGSVGElement>>>>
  variant?: Variant
  disabled?: boolean
  style?: CSSProperties
  className?: any
  pressed?: boolean
  loading?: boolean
  focus?: boolean
  svgColor?: string
  fullWidth?: boolean
}

const FigmaButton: React.FC<React.PropsWithChildren<React.PropsWithChildren<FigmaButtonProps>>> = (props: FigmaButtonProps) => {
  const { textKey, leftSvg, rightSvg, onClick, variant, svgColor, text, fullWidth = true, loading } = props

  const language = useLanguage()

  return (
    <FigmaBox fullWidth={fullWidth} align="center">
      <Wrapper onClick={onClick} variant={variant} loading={loading} {...props} fullWidth={fullWidth}>
        {leftSvg && (
          <SVGWrapper left>
            <SVGContainer SVG={leftSvg} svgColor={svgColor} />
          </SVGWrapper>
        )}
        <FigmaTextContainer textKey={textKey} text={text} language={language} />
        {rightSvg && (
          <SVGWrapper>
            <SVGContainer SVG={rightSvg} svgColor={svgColor} />
          </SVGWrapper>
        )}
      </Wrapper>
    </FigmaBox>
  )
}

const SVGWrapper = styled.div<{ left?: boolean }>`
  display: flex;
  svg {
    display: flex;
  }

  ${(props) => (props.left ? `padding-right: ${Spacings.tiny};` : `padding-left: ${Spacings.tiny};`)};
`
const Wrapper = styled(SubmitButton)<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => props.fullWidth && 'width: 100%;'}

  > div {
    padding: 0 ${Spacings.small};
    height: ${BUTTON_HEIGHT_PX};
  }

  @media (max-width: ${MediaQueries.sm}px) {
    > div {
      height: ${BUTTON_HEIGHT_MOBILE};
    }
  }
`

export default FigmaButton
