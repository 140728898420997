import MaterialTable, { MTableToolbar } from '@material-table/core'
import FigmaBox from 'mynt-components/components/FigmaBox'
import React, { useRef, useState } from 'react'
import { AccountXYear } from '../api/swagger/definitions/backoffice'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import useLanguage from '../flamingo/hooks/useLanguage'
import { getYearsTableActions } from '../helpers/AccountingTablesHelper'
import { useAccountingYears, useDeleteAccountingYear } from '../api/react-query'
import { GetTableOptions } from '../helpers/getTableOptions'
import { captureException } from '../helpers/sentryHelpers'
import FigmaButton from '../mynt-components/components/FigmaButton'
import MyntPopper from '../mynt-components/components/MyntPopper'
import { TextKeys, getText } from '../tiger/libs/TextRepository'
import { YearFormTypes } from './ModalAccountingYear'
import { ModalAccountingYear, ModalConfirmDialog } from './Modals'
import TableWrapper from './TableWrapper'

enum YearDefaultState {
  TRUE = 'True',
  FALSE = 'False'
}

const YearsTable: React.FC = () => {
  const language = useLanguage()
  const [yearValues, setYearValues] = useState<AccountXYear>()
  const [isYearModalOpen, setIsYearModalOpen] = useState<boolean>(false)
  const [isYearDeleteModalOpen, setIsYearDeleteModalOpen] = useState<boolean>(false)
  const divRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(divRef.current)

  const { data: years = [], isLoading: yearsLoading } = useAccountingYears({
    refetchOnWindowFocus: false
  })
  const deleteMutation = useDeleteAccountingYear()

  const handleDeleteClick = (year: AccountXYear) => {
    setIsYearDeleteModalOpen(true)
    setYearValues(year)
  }

  const handleEditClick = (year: AccountXYear) => {
    setIsYearModalOpen(true)
    setYearValues(year)
  }

  const handleDeleteAccept = async () => {
    handleClearModal()
    return deleteMutation
      .mutateAsync(yearValues?.id as string)
      .then((res) => res)
      .catch((err) => captureException(err))
  }

  const handleClearModal = () => {
    setIsYearDeleteModalOpen(false)
    setYearValues(undefined)
  }

  return (
    <>
      <ModalAccountingYear
        isOpen={isYearModalOpen}
        type={yearValues ? YearFormTypes.EDIT : YearFormTypes.CREATE}
        values={yearValues}
        onModalCancel={() => {
          setIsYearModalOpen(false)
          setYearValues(undefined)
        }}
      />
      <MyntPopper
        visible={isYearDeleteModalOpen}
        noPadding
        marginBottom={Spacings.large}
        position="top"
        anchorRef={anchorEl}
        content={<ModalConfirmDialog onCancel={handleClearModal} onConfirm={handleDeleteAccept} />}
      />
      <TableWrapper>
        <MaterialTable
          components={{
            Toolbar: (props) => (
              <FigmaBox fullWidth direction={'row'} align={'center'} justify={'stretch'}>
                <FigmaBox fullWidth>
                  <MTableToolbar {...props} />
                </FigmaBox>
                <FigmaBox>
                  <FigmaButton textKey={TextKeys.accountingYearsCreateYears} onClick={() => setIsYearModalOpen(true)} />
                </FigmaBox>
              </FigmaBox>
            )
          }}
          columns={[
            {
              title: getText(TextKeys.createYearNameLabel, language),
              field: 'name'
            },
            {
              title: getText(TextKeys.createYearFromDateLabel, language),
              field: 'fromDate'
            },
            {
              title: getText(TextKeys.createYearToDateLabel, language),
              field: 'toDate'
            },
            {
              title: getText(TextKeys.yearsDefaultLabel, language),
              field: 'default',
              render: (year: AccountXYear) => (year.default ? YearDefaultState.TRUE : YearDefaultState.FALSE)
            }
          ]}
          data={years}
          isLoading={yearsLoading}
          title={getText(TextKeys.yearsHeading, language)}
          actions={getYearsTableActions(handleEditClick, handleDeleteClick, setAnchorEl) as any}
          options={GetTableOptions({
            headerTextKey: TextKeys.accountingPaymentsOCR,
            extraOptions: { filtering: false, headerStyle: { backgroundColor: ColorsDeprecated.surfaceOnWhite } }
          })}
        />
      </TableWrapper>
    </>
  )
}

export default YearsTable
