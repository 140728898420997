// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const spacing = {
 "large": "2rem",
 "medium": "1.5rem",
 "tiny": "0.75rem",
 "small": "1rem",
 "min": "0.5rem",
 "minimum": "0.25rem",
 "smallest": "0.125rem",
 "max": "6rem",
 "huge": "4rem",
 "big": "3rem"
}

export default spacing;