/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BackOfficePayoutCreateDtoV2 } from '../models';
// @ts-ignore
import { BackOfficePayoutPatchDtoV2 } from '../models';
// @ts-ignore
import { EnfucePayoutState } from '../models';
// @ts-ignore
import { ErrorMessage } from '../models';
// @ts-ignore
import { GetAllPayoutsV2200ResponseInner } from '../models';
/**
 * PayoutsApi - axios parameter creator
 * @export
 */
export const PayoutsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * hasBackOfficeFullRole()
         * @param {BackOfficePayoutCreateDtoV2} backOfficePayoutCreateDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayoutV2: async (backOfficePayoutCreateDtoV2: BackOfficePayoutCreateDtoV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'backOfficePayoutCreateDtoV2' is not null or undefined
            assertParamExists('createPayoutV2', 'backOfficePayoutCreateDtoV2', backOfficePayoutCreateDtoV2)
            const localVarPath = `/bo/v2/payouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficePayoutCreateDtoV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {Array<EnfucePayoutState>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPayoutsV2: async (customerId?: string, states?: Array<EnfucePayoutState>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/v2/payouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customer-id'] = customerId;
            }

            if (states) {
                localVarQueryParameter['states'] = Array.from(states);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} payoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutV2: async (payoutId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payoutId' is not null or undefined
            assertParamExists('getPayoutV2', 'payoutId', payoutId)
            const localVarPath = `/bo/v2/payouts/{payout-id}`
                .replace(`{${"payout-id"}}`, encodeURIComponent(String(payoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} payoutId 
         * @param {BackOfficePayoutPatchDtoV2} backOfficePayoutPatchDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPayoutV2: async (payoutId: string, backOfficePayoutPatchDtoV2: BackOfficePayoutPatchDtoV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payoutId' is not null or undefined
            assertParamExists('patchPayoutV2', 'payoutId', payoutId)
            // verify required parameter 'backOfficePayoutPatchDtoV2' is not null or undefined
            assertParamExists('patchPayoutV2', 'backOfficePayoutPatchDtoV2', backOfficePayoutPatchDtoV2)
            const localVarPath = `/bo/v2/payouts/{payout-id}`
                .replace(`{${"payout-id"}}`, encodeURIComponent(String(payoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backOfficePayoutPatchDtoV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayoutsApi - functional programming interface
 * @export
 */
export const PayoutsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayoutsApiAxiosParamCreator(configuration)
    return {
        /**
         * hasBackOfficeFullRole()
         * @param {BackOfficePayoutCreateDtoV2} backOfficePayoutCreateDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPayoutV2(backOfficePayoutCreateDtoV2: BackOfficePayoutCreateDtoV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllPayoutsV2200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPayoutV2(backOfficePayoutCreateDtoV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {Array<EnfucePayoutState>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPayoutsV2(customerId?: string, states?: Array<EnfucePayoutState>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAllPayoutsV2200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPayoutsV2(customerId, states, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} payoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayoutV2(payoutId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllPayoutsV2200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayoutV2(payoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} payoutId 
         * @param {BackOfficePayoutPatchDtoV2} backOfficePayoutPatchDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPayoutV2(payoutId: string, backOfficePayoutPatchDtoV2: BackOfficePayoutPatchDtoV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllPayoutsV2200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPayoutV2(payoutId, backOfficePayoutPatchDtoV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PayoutsApi - factory interface
 * @export
 */
export const PayoutsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayoutsApiFp(configuration)
    return {
        /**
         * hasBackOfficeFullRole()
         * @param {BackOfficePayoutCreateDtoV2} backOfficePayoutCreateDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayoutV2(backOfficePayoutCreateDtoV2: BackOfficePayoutCreateDtoV2, options?: any): AxiosPromise<GetAllPayoutsV2200ResponseInner> {
            return localVarFp.createPayoutV2(backOfficePayoutCreateDtoV2, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} [customerId] 
         * @param {Array<EnfucePayoutState>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPayoutsV2(customerId?: string, states?: Array<EnfucePayoutState>, options?: any): AxiosPromise<Array<GetAllPayoutsV2200ResponseInner>> {
            return localVarFp.getAllPayoutsV2(customerId, states, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} payoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutV2(payoutId: string, options?: any): AxiosPromise<GetAllPayoutsV2200ResponseInner> {
            return localVarFp.getPayoutV2(payoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} payoutId 
         * @param {BackOfficePayoutPatchDtoV2} backOfficePayoutPatchDtoV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPayoutV2(payoutId: string, backOfficePayoutPatchDtoV2: BackOfficePayoutPatchDtoV2, options?: any): AxiosPromise<GetAllPayoutsV2200ResponseInner> {
            return localVarFp.patchPayoutV2(payoutId, backOfficePayoutPatchDtoV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayoutsApi - object-oriented interface
 * @export
 * @class PayoutsApi
 * @extends {BaseAPI}
 */
export class PayoutsApi extends BaseAPI {
    /**
     * hasBackOfficeFullRole()
     * @param {BackOfficePayoutCreateDtoV2} backOfficePayoutCreateDtoV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutsApi
     */
    public createPayoutV2(backOfficePayoutCreateDtoV2: BackOfficePayoutCreateDtoV2, options?: AxiosRequestConfig) {
        return PayoutsApiFp(this.configuration).createPayoutV2(backOfficePayoutCreateDtoV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} [customerId] 
     * @param {Array<EnfucePayoutState>} [states] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutsApi
     */
    public getAllPayoutsV2(customerId?: string, states?: Array<EnfucePayoutState>, options?: AxiosRequestConfig) {
        return PayoutsApiFp(this.configuration).getAllPayoutsV2(customerId, states, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} payoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutsApi
     */
    public getPayoutV2(payoutId: string, options?: AxiosRequestConfig) {
        return PayoutsApiFp(this.configuration).getPayoutV2(payoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} payoutId 
     * @param {BackOfficePayoutPatchDtoV2} backOfficePayoutPatchDtoV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutsApi
     */
    public patchPayoutV2(payoutId: string, backOfficePayoutPatchDtoV2: BackOfficePayoutPatchDtoV2, options?: AxiosRequestConfig) {
        return PayoutsApiFp(this.configuration).patchPayoutV2(payoutId, backOfficePayoutPatchDtoV2, options).then((request) => request(this.axios, this.basePath));
    }
}
