import * as React from 'react';

import ButtonSecondaryHoverStyled from './ButtonSecondaryHoverStyled';

type ButtonSecondaryHoverProps = any

const ButtonSecondaryHover: React.FC<ButtonSecondaryHoverProps> = (props) => (
  <ButtonSecondaryHoverStyled  {...props}>{props.children ? props.children : "Kom igång"}</ButtonSecondaryHoverStyled>
);

export default ButtonSecondaryHover;