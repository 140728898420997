import styled from '@emotion/styled'
import React from 'react'
import { AntiloopTextType, Language } from '../../tiger/interfaces/Antiloop'
import FigmaText from './FigmaText'

export type FigmaTextContainerProps = {
  textKey: AntiloopTextType
  className?: string
  text?: string
  language: Language
  element?: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ style: any }>>>
  textStyles?: React.CSSProperties
}

const FigmaTextContainer: React.FC<React.PropsWithChildren<React.PropsWithChildren<FigmaTextContainerProps>>> = ({
  textKey,
  className,
  text,
  element = Span,
  language,
  textStyles,
  ...props
}) => (
  <div className={className}>
    <FigmaText {...props} style={textStyles} textKey={textKey} text={text} element={element} language={language} />
  </div>
)

const Span = styled.span`
  display: flex;
  flex-direction: column;
`

export default FigmaTextContainer
