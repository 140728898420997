import Spacings from '../figma/tokens/Spacings'
import { Divider, LinkButton } from '../helpers/customerGeneralFormHelper'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaButton from '../mynt-components/components/FigmaButton'
import TextContainer from './TextContainer'

type Props = {
  onConfirm: () => void
  onCancel: () => void
}

const SendWelcomeEmailPopperContent = ({ onConfirm, onCancel }: Props) => (
  <FigmaBox fullPadding spacing={Spacings.small} style={{ maxWidth: '235px', position: 'relative' }}>
    <FigmaBox bottom spacing={Spacings.small} gap={Spacings.min}>
      <TextContainer textKey={TextKeys.toolTipSendWelcomeEmailHeading} />
      <TextContainer textKey={TextKeys.toolTipSendWelcomeEmailText} />
    </FigmaBox>
    <Divider onlyBottom spacing={Spacings.min} />
    <FigmaBox fullWidth direction="row" gap={Spacings.min}>
      <FigmaBox justify="center" align="center" fullWidth>
        <LinkButton onClick={onCancel} textKey={TextKeys.toolTipSendWelcomeEmailButtonNo} />
      </FigmaBox>
      <FigmaBox justify="center" align="center" fullWidth>
        <FigmaButton onClick={onConfirm} textKey={TextKeys.toolTipSendWelcomeEmailButtonYes} variant="primary" />
      </FigmaBox>
    </FigmaBox>
  </FigmaBox>
)

export default SendWelcomeEmailPopperContent
