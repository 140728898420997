import { CreditTypes } from 'helpers/utils'
import { ICredit } from 'interfaces/credit'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { payoutCompanyCredit } from '../api'
import { CoreMyntModal } from './modals/CoreMyntModal'
import { NumberFormatController } from './react-hook-components'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { Amount } from 'types'

const getPayOutCreditAPI = (creditType: CreditTypes) => {
  switch (creditType) {
    case CreditTypes.COMPANYCREDIT: {
      return payoutCompanyCredit
    }

    default: {
      throw new Error(`${creditType} api does not exist`)
    }
  }
}

interface Props {
  isOpen: boolean
  onCancel: () => void
  onSuccess: () => Promise<void>
  creditType: CreditTypes
  credit?: ICredit
}

const CreditPayoutModal: React.FC<Props> = ({ credit, isOpen, onCancel, onSuccess, creditType }) => {
  const form = useForm<Amount>({
    defaultValues: credit?.creditAmount ?? { value: 0, currency: 'SEK' }
  })

  useEffect(() => {
    if (!credit?.creditAmount) return

    form.reset(credit.creditAmount)
  }, [credit])

  const [submitting, setSubmitting] = useState(false)

  const creditId = get(credit, 'id', '')

  const handleSubmit = async (payload: Amount) => {
    setSubmitting(true)

    getPayOutCreditAPI(creditType)(creditId, payload)
      .then(() => {
        onSuccess()
        onCancel()
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (!credit) return null

  return (
    <CoreMyntModal
      open={isOpen}
      title="Payout"
      onClose={onCancel}
      actions={(Wrapper) => (
        <Wrapper>
          <Button onClick={onCancel}>Cancel</Button>
          <LoadingButton variant="primary" onClick={form.handleSubmit(handleSubmit)} loading={submitting}>
            Submit
          </LoadingButton>
        </Wrapper>
      )}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '16px', gap: '24px' }}>
        {/* <SelectController control={form.control} name="currency" options={EnumToOptions(Currencies)} labelText="Currency" /> */}
        <NumberFormatController control={form.control} name="value" labelText="Amount" adornment={<>{form.watch('currency')}</>} />
      </Box>
    </CoreMyntModal>
  )
}

export default CreditPayoutModal
