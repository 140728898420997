import styled from '@emotion/styled'
import React from 'react'
import { BackOfficeCustomerDtoV2, CustomerProduct } from '../api/swagger/definitions/backoffice'
import TextContainer from '../components/TextContainer'
import BorderRadius from '../figma/tokens/BorderRadius'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Shadows from '../figma/tokens/Shadows'
import Spacings from '../figma/tokens/Spacings'
import FigmaBox, { FigmaBoxProps } from '../mynt-components/components/FigmaBox'
import FigmaButton from '../mynt-components/components/FigmaButton'
import MaterialFieldFormik, { MaterialFieldProps } from '../mynt-components/components/MaterialFieldFormik'
import { AntiloopTextType } from '../tiger/interfaces/Antiloop'
import { TextKeys } from '../tiger/libs/TextRepository'

// https://css-tricks.com/triangle-with-shadow/
export const Triangle = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  transform: rotate(180deg) translate(50%, -100%);

  &:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 75px;
    background: ${ColorsDeprecated.baseWhite};
    transform: rotate(45deg);
    top: 40px;
    left: -10px;
    box-shadow: ${Shadows.baseHeavy};
  }
`

export const BorderedContainer = styled(FigmaBox)<{
  noMargin?: boolean
  green?: boolean
}>`
  padding: ${Spacings.medium};
  border: 1px solid ${ColorsDeprecated.baseStone};
  border-radius: ${BorderRadius.soft};
  margin: ${({ noMargin }) => (!noMargin ? Spacings.tiny : 0)};
  margin-left: 0;
  ${({ green }) => (green ? `background-color: ${ColorsDeprecated.surfaceOnHover}` : '')};
`

export const DeleteCustomerPopper = ({ onCancel, onConfirm }) => (
  <FigmaBox style={{ width: '235px', position: 'relative' }} spacing={Spacings.small} fullPadding>
    <FigmaBox spacing={Spacings.min} bottom>
      <TextContainer textKey={TextKeys.toolTipAreYouSureHeading} />
    </FigmaBox>
    <Divider spacing={Spacings.min} />
    <FigmaBox fullWidth direction="row">
      <FigmaBox justify="center" align="center" fullWidth>
        <LinkButton onClick={onCancel} textKey={TextKeys.toolTipAreYouSureCancel} />
      </FigmaBox>
      <FigmaBox justify="center" align="center" fullWidth>
        <FigmaButton onClick={onConfirm} textKey={TextKeys.toolTipAreYouSureYes} variant="primary" />
      </FigmaBox>
    </FigmaBox>
    <Triangle />
  </FigmaBox>
)

export const LinkButton: React.FC<
  FigmaBoxProps & {
    textKey: AntiloopTextType
    text?: string
    disabled?: boolean
  }
> = ({ textKey, text, disabled, ...props }) => (
  <HoverFigmaBox disabled={disabled} {...props}>
    <TextContainer textKey={textKey} text={text} />
  </HoverFigmaBox>
)

export const HoverFigmaBox = styled(FigmaBox)<{ disabled?: boolean }>`
  cursor: pointer;
  user-select: none;

  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')}

  span {
    ${({ disabled }) => (disabled ? `color: ${ColorsDeprecated.textDisabled} !important` : '')}
  }

  &:hover span {
    color: ${ColorsDeprecated.textLinkHover} !important;
  }
`

export const Divider = ({ spacing = Spacings.large, onlyBottom = false }) => (
  <div
    style={{
      height: 2,
      backgroundColor: `${ColorsDeprecated.baseStone}`,
      width: '100%',
      margin: onlyBottom ? `0 0 ${spacing} 0` : `${spacing} 0`
    }}
  />
)

export const StyledMaterialFieldFormik: React.FC<MaterialFieldProps & { noSpacing?: boolean }> = ({ noSpacing, ...props }) => (
  <FigmaBox spacing={Spacings.large} bottom={!noSpacing} fullWidth={props.fullWidth}>
    <MaterialFieldFormik {...props} />
  </FigmaBox>
)

/**
 * Replace values in an object with the return value of the given replacer function
 * @param obj object to replace
 * @param replacer method used to replace value
 */
export const objectValueReplace = <T, X>(obj: Record<string, T>, replacer: (value: T) => X): Record<string, T> =>
  Object.entries(obj).reduce((prev, [key, value]) => ({ ...prev, [key]: replacer(value) }), {})

export const customerHasProduct = (customer: BackOfficeCustomerDtoV2) => Array.from(customer.products).includes(CustomerProduct.CARD)
