import { useEffect, useState } from 'react'
import { BackOfficeInvoiceResponseDtoV2, InvoiceState, InvoiceType } from '../../api/swagger/definitions/backoffice'
import {
  useCreateExternalPayment,
  useCreateInvoice,
  useCustomer,
  useCustomerCardAccounts,
  useInvoices,
  usePatchInvoice
} from '../../api/react-query'
import { YYYY_MM_DD } from '../../tiger/Constants'
import { InvoiceTable, InvoiceTableProps } from './InvoiceTable'
import useMaterialNotification from '../../hooks/useMaterialNotification'
import { useQueryClient } from '@tanstack/react-query'
import { prettifyEnumLabel } from '../../helpers/CreditOnCardHelpers'
import dayjs from 'dayjs'
import { INVOICES } from '../../api/react-query/keys'
import { AllOnSubmits, InvoiceModals, TogglableModals } from './InvoiceModals'
import { When } from '../../mynt-components/components/When'
import { TooltipCustomerInvoices, TooltipCustomerSubscriptionFee } from '../../components/Tooltips'
import ButtonIconRegisterExternalPayment from '../../figma/images/buttonIconRegisterExternalPayment'
import ButtonIconRegisterInvoicePayment from '../../figma/images/buttonIconRegisterInvoicePayment'
import Spacings from '../../figma/tokens/Spacings'
import FigmaBox from '../../mynt-components/components/FigmaBox'
import { UnstyledButton } from '../../mynt-components/components/StyledComponents'
import { Currencies } from '../../flamingo/config/ConfigCurrencies'
import useConfigByCountry from '../../flamingo/hooks/useConfigByCountry'
import { get } from 'lodash'
import Typography from '@mui/material/Typography'
import { BookAction, CancelAction, ChangePaymentMethod, CreditAction, DebtCollectionAction, ReminderAction } from './actions'
import Box from '@mui/material/Box'
import { useNotesContext } from 'contexts/Notes'

const formatAccountOCR = (accounts: { name: string; ocr: string }[]) => {
  if (accounts.length === 0) return 'No accounts found'
  if (accounts.length === 1) return accounts[0].ocr

  return accounts.map((account) => `${account.name} (${account.ocr})`).join(', ')
}

type InvoicesProps = {
  customerId?: string
  defaultFilters?: Partial<InvoiceTableProps<BackOfficeInvoiceResponseDtoV2[]>['filters']>
}

const filterByString = <T,>(data: T[], field: string, search: string) => {
  if (search === '') return null

  return data.filter((item) => {
    const columnValue = get(item, field)?.toString() ?? ''

    if (!columnValue) return false

    return columnValue.toLowerCase().includes(search.toLowerCase())
  })
}

export const Invoices = ({ customerId, defaultFilters }: InvoicesProps) => {
  const [filters, setFilters] = useState<InvoiceTableProps<BackOfficeInvoiceResponseDtoV2[]>['filters']>({
    state: [],
    type: [],
    invoiceDate: { from: undefined, to: undefined },
    settleDate: { from: undefined, to: undefined },
    ...defaultFilters
  })

  const { data: invoicesData = [], isLoading } = useInvoices(customerId, {
    type: filters.type,
    state: filters.state,
    from: filters.invoiceDate.from?.format(YYYY_MM_DD),
    to: filters.invoiceDate.to?.format(YYYY_MM_DD),
    settleFrom: filters.settleDate.from?.format(YYYY_MM_DD),
    settleTo: filters.settleDate.to?.format(YYYY_MM_DD)
  })

  const { data: customer } = useCustomer(customerId as string, { enabled: Boolean(customerId) })
  const { data: accounts = [] } = useCustomerCardAccounts(customerId as string, { enabled: Boolean(customerId) })

  const patchInvoice = usePatchInvoice()
  const createInvoice = useCreateInvoice()
  const externalPayment = useCreateExternalPayment(customerId ?? '')

  const notify = useMaterialNotification()
  const queryClient = useQueryClient()
  const { formatAmount } = useConfigByCountry()
  const [notesContext] = useNotesContext()

  const [modals, setModals] = useState<TogglableModals>({})

  const handleSubmit = async ([modal, data]: AllOnSubmits) => {
    switch (modal) {
      case 'invoicePayment': {
        return onSubmitInvoice(data)
      }

      case 'externalPayment': {
        return onSubmitExternalInvoice(data)
      }

      case 'invoicePenalty': {
        return onSubmitReminderFee(data)
      }

      default: {
        throw new Error(`missing submit handler for modal '${modal}'`)
      }
    }
  }

  const handleClose = () => setModals({})

  const onSubmitReminderFee = async (invoice: Parameters<typeof patchInvoice.mutateAsync>[0]) =>
    patchInvoice.mutateAsync(invoice).then(() => {
      notify(`Sent reminder`)
      setModals({})
    })

  const onSubmitInvoice = async (invoice: Parameters<typeof createInvoice.mutateAsync>[0]) =>
    createInvoice.mutateAsync(invoice).then(() => {
      notify(`Created ${prettifyEnumLabel(invoice.type).toLowerCase()} invoice`)

      // Adding invalidation on the hook runs too fast, must wait atleast 1 second
      // Some of the invoices get updated shortly after reply with a different state. The query hook does not reflect this update
      setTimeout(async () => queryClient.invalidateQueries([INVOICES]), 1000)

      setModals({})
    })

  const onSubmitExternalInvoice = async (values: {
    customerId: string
    ocr: string | undefined
    amount: number
    paymentDate: string
    description: string
    currency: Currencies
  }) =>
    externalPayment
      .mutateAsync({
        info: values.description,
        paymentDate: dayjs(values.paymentDate).format(YYYY_MM_DD),
        amount: { value: values.amount, currency: values.currency },
        ocr: values.ocr || values.customerId
      })
      .then(() => {
        notify(`Created external payment`)
      })

  const handleActionClick = (invoice: BackOfficeInvoiceResponseDtoV2) => async (updatedInvoice: Partial<BackOfficeInvoiceResponseDtoV2>) =>
    patchInvoice.mutateAsync({ id: invoice.id, ...updatedInvoice })

  const tableActions = (invoice, onClose) => [
    <BookAction key={1} invoice={invoice} onClick={handleActionClick(invoice)} />,
    <CancelAction key={2} invoice={invoice} onClick={handleActionClick(invoice)} />,
    <CreditAction key={3} invoice={invoice} onClick={handleActionClick(invoice)} />,
    <ReminderAction
      key={4}
      invoice={invoice}
      onClick={async () => {
        onClose()
        return Promise.resolve(setModals({ invoicePenalty: invoice }))
      }}
    />,
    <DebtCollectionAction key={5} invoice={invoice} onClick={handleActionClick(invoice)} />,
    <ChangePaymentMethod key={6} invoice={invoice} onClick={handleActionClick(invoice)} />
  ]

  return (
    <>
      {customer && <InvoiceModals customer={customer} modals={modals} onClose={handleClose} onSubmit={handleSubmit} />}
      <FigmaBox align="flex-end" spacing={Spacings.tiny} bottom top fullWidth direction="row" justify="space-between">
        <FigmaBox>
          <h2>Customer invoices</h2>
          <Typography variant="subtitle1">
            <Typography style={{ display: 'inline' }} fontWeight="bold" variant="subtitle1">
              {'OCR: '}
            </Typography>
            {formatAccountOCR(accounts.map(({ name, ocr }) => ({ name, ocr })))}
          </Typography>
        </FigmaBox>
        <FigmaBox direction="row" gap={Spacings.min}>
          <TooltipCustomerInvoices
            content={
              <UnstyledButton onClick={() => setModals({ externalPayment: true })}>
                <ButtonIconRegisterExternalPayment />
              </UnstyledButton>
            }
          />
          <When is={Boolean(customerId)}>
            <TooltipCustomerSubscriptionFee
              content={
                <UnstyledButton onClick={() => setModals({ invoicePayment: true })}>
                  <ButtonIconRegisterInvoicePayment />
                </UnstyledButton>
              }
            />
          </When>
        </FigmaBox>
      </FigmaBox>
      <Box sx={{ maxWidth: notesContext.open ? '75vw' : '93vw' }}>
        <InvoiceTable
          invoices={invoicesData}
          filters={filters}
          onFilterApiChange={setFilters}
          isLoading={isLoading || patchInvoice.isLoading}
          actions={tableActions}
          formatAmount={formatAmount}
        />
      </Box>
    </>
  )
}
