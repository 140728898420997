import { Button as ButtonDeprecated } from '../components/Button'
import LoaderRenderProp from '../components/LoaderRenderProp'
import { CreditTypes } from 'helpers/utils'
import { useCreditDetails } from 'hooks/credits'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { CompanyCreditDto, CreditState } from '../api/swagger/definitions/backoffice'
import Spacings from '../figma/tokens/Spacings'
import { useCustomer, useRepaymentModels } from '../api/react-query'
import {
  approveCredit,
  closeCredit,
  defaultCredit,
  netCredit,
  regenerateAgreementDocument,
  regenerateRepaymentPlan,
  repayCredit,
  setStandardTerms,
  updateCompanyCredits
} from '../api'
import { formatSubmitValues, isAccountingTab } from '../helpers/CreditsDetailsHelpers'
import { TabSets } from '../helpers/TabsHelpers'
import useMaterialNotification from '../hooks/useMaterialNotification'
import { BO_MAX_CONTENT_WIDTH } from '../mynt-components/WeakHardCodedSizes'
import Tabs from '../mynt-components/components/Tabs'
import { TextKeys } from '../tiger/libs/TextRepository'
import CreditAccounting from './CreditAccounting'
import CreditAgreementsContainer from './CreditAgreementsContainer'
import { CreditFormGeneralInformation, CreditFormGeneralInformationValidationSchema } from './CreditFormGeneral'
import { CreditFormRequest, CreditFormRequestValidationSchema } from './CreditFormRequest'
import CustomerLink from './CustomerLink'
import CreditPayoutModal from './PayoutModal'
import TextContainer from './TextContainer'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import { SelectController } from './react-hook-components'
import { EnumToOptions } from 'helpers/CreditOnCardHelpers'
import LoadingButton from '@mui/lab/LoadingButton'
import { CustomerNotes, useCustomerNotesDataProvider } from './CustomerNotes'

const getCreditUpdateAPI = async (creditType: CreditTypes, id: string, credit: CompanyCreditDto) => {
  switch (creditType) {
    case CreditTypes.COMPANYCREDIT: {
      return updateCompanyCredits(id, credit as CompanyCreditDto)
    }

    default: {
      throw new Error(`${creditType} api does not exist`)
    }
  }
}

const CreditDetailsContainer: React.FC = () => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [showMenu, setShowMenu] = useState(false)

  const { creditId = '', creditType: _creditType = '' } = useParams<'creditId' | 'creditType'>()
  const { data: repaymentModels = [] } = useRepaymentModels()

  const creditType = _creditType as CreditTypes

  const [submitting, setSubmitting] = useState('')
  const [changeTabExternally, setChangeTabExternally] = useState('')
  const [isPayoutModalOpen, setIsPayoutModalOpen] = useState(false)

  const {
    details: data = {},
    loading,
    fetchDetails
  } = useCreditDetails(creditId, creditType, (data: CompanyCreditDto) => {
    if (data.state && isAccountingTab(data.state)) {
      setChangeTabExternally('3')
    } else {
      setChangeTabExternally('0')
    }
  })
  const details = data as CompanyCreditDto

  const form = useForm<Partial<CompanyCreditDto>>({
    defaultValues: details,
    resolver: yupResolver(yup.object().concat(CreditFormRequestValidationSchema).concat(CreditFormGeneralInformationValidationSchema)),
    mode: 'onChange'
  })

  // If the cache updates, reset the form with the latest data
  useEffect(() => {
    form.reset(details)
  }, [details])

  const isCompanyCredit = CreditTypes.COMPANYCREDIT === creditType
  const orgno = get(details, 'orgNo', '')
  const customerId = get(details, 'customerId', '')
  const name = get(details, 'companyName', 'NON-EXISTING CUSTOMER')
  const no = get(details, 'no', '')

  const { data: customers } = useCustomer(details.customerId as string, { enabled: Boolean(details.customerId) })
  const notify = useMaterialNotification()

  const fetchedCustomerId = customers?.id

  const customerNotesProps = useCustomerNotesDataProvider(fetchedCustomerId as string, { enabled: Boolean(fetchedCustomerId) })

  const handleFinish = async (values: CompanyCreditDto) => {
    setSubmitting('form')

    const updatedValues = formatSubmitValues(details, values, isCompanyCredit)

    try {
      if (!updatedValues.id) return

      await getCreditUpdateAPI(creditType, updatedValues.id, updatedValues)
      await fetchDetails()

      notify('Save was successful')
    } catch (e) {
    } finally {
      setSubmitting('')
    }
  }

  const handleAction =
    (action: 'standard' | 'document' | 'repaymentPlan' | 'approve' | 'repaid' | 'default' | 'close' | 'net') => async () => {
      const actions = {
        standard: setStandardTerms,
        document: regenerateAgreementDocument,
        repaymentPlan: regenerateRepaymentPlan,
        approve: approveCredit,
        repaid: repayCredit,
        default: defaultCredit,
        close: closeCredit,
        net: netCredit
      }

      setSubmitting(action)

      try {
        await actions[action](creditId)
        await fetchDetails()

        notify('Action was successful')
      } catch (e) {
      } finally {
        setSubmitting('')
      }
    }

  const handlePayoutSuccess = async () => {
    await fetchDetails()

    notify('Action was successful')
  }

  // NOTE there was a view here when isCompanyCredit is false which had some non-existent apis in it

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%', maxWidth: BO_MAX_CONTENT_WIDTH, padding: `0 ${Spacings.small}`, margin: '0 auto' }}>
        <LoaderRenderProp
          showLoader={loading || isEmpty(details)}
          render={() => (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '32px' }}>
                <Box>
                  <CustomerLink id={customerId} orgNo={orgno} name={name}>
                    , Credit number: {no}
                  </CustomerLink>
                </Box>
                {isCompanyCredit && (
                  <Box style={{ textAlign: 'right' }}>
                    <ButtonDeprecated
                      style={{ margin: 5, fontSize: 15 }}
                      onClick={handleAction('standard')}
                      loading={submitting === 'standard'}
                    >
                      Set standard terms
                    </ButtonDeprecated>
                    <ButtonDeprecated
                      style={{ margin: 5, fontSize: 15 }}
                      onClick={handleAction('repaymentPlan')}
                      loading={submitting === 'repaymentPlan'}
                    >
                      Regenerate repayment plan
                    </ButtonDeprecated>
                    <ButtonDeprecated
                      style={{ margin: 5, fontSize: 15 }}
                      onClick={handleAction('document')}
                      loading={submitting === 'document'}
                    >
                      Regenerate agreement
                    </ButtonDeprecated>
                    <ButtonDeprecated
                      style={{ margin: 5, fontSize: 15 }}
                      onClick={handleAction('approve')}
                      loading={submitting === 'approve'}
                    >
                      Approve
                    </ButtonDeprecated>
                    <ButtonDeprecated style={{ margin: 5, fontSize: 15 }} onClick={() => setIsPayoutModalOpen(true)}>
                      Payout
                    </ButtonDeprecated>
                    <Menu
                      onClose={() => setShowMenu(false)}
                      MenuListProps={{ onMouseLeave: () => setShowMenu(false) }}
                      open={showMenu}
                      anchorEl={buttonRef.current}
                    >
                      <MenuItem onClick={handleAction('repaid')}>Repaid</MenuItem>
                      <MenuItem onClick={handleAction('net')}>Netted</MenuItem>
                      <MenuItem onClick={handleAction('default')}>Default</MenuItem>
                      <MenuItem onClick={handleAction('close')}>Just close</MenuItem>
                    </Menu>
                    <ButtonDeprecated
                      onMouseOver={() => setShowMenu(true)}
                      ref={buttonRef}
                      loading={submitting === 'close' || submitting === 'default' || submitting === 'repaid'}
                    >
                      Close
                    </ButtonDeprecated>
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px 0' }}>
                <Box>
                  <SelectController control={form.control} name="state" options={EnumToOptions(CreditState)} labelText="State" />
                </Box>
                <Box>
                  <LoadingButton onClick={form.handleSubmit(handleFinish)} variant="primary" loading={submitting === 'form'}>
                    Save
                  </LoadingButton>
                </Box>
              </Box>
              <FormProvider {...form}>
                <Tabs
                  fullPage
                  enableNestedTabs
                  alwaysShowNavHeadings
                  tabKeyNameSpace={TabSets.CREDITS}
                  changeTabExternally={changeTabExternally}
                  tabs={[
                    {
                      labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Request'} />,
                      contentElement: <CreditFormRequest control={form.control} />,
                      enable: isCompanyCredit
                    },
                    {
                      labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Credit info'} />,
                      contentElement: (
                        <CreditFormGeneralInformation
                          control={form.control}
                          personalGuaranteesOptions={(details.personalGuarantees || [])?.map((name) => ({
                            label: name,
                            value: name
                          }))}
                          repaymentModelOptions={repaymentModels.map((name) => ({
                            label: name,
                            value: name
                          }))}
                        />
                      )
                    },
                    {
                      labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Documents'} />,
                      contentElement: (
                        <CreditAgreementsContainer
                          orgno={orgno}
                          agreementDocumentId={get(details, 'agreementDocumentId', '')}
                          creditNo={get(details, 'no', '')}
                          creditId={get(details, 'id', '')}
                          customerId={customerId}
                          showQuotes={isCompanyCredit}
                        />
                      )
                    },
                    {
                      labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Accounting'} />,
                      contentElement: (
                        <CreditAccounting creditId={creditId} creditType={creditType} credit={details} refetchCredit={fetchDetails} />
                      )
                    }
                  ]}
                />
              </FormProvider>
            </>
          )}
        />
        <CreditPayoutModal
          isOpen={isPayoutModalOpen}
          onCancel={() => setIsPayoutModalOpen(false)}
          credit={details as any}
          creditType={creditType}
          onSuccess={handlePayoutSuccess}
        />
      </div>
      {fetchedCustomerId && <CustomerNotes {...customerNotesProps} />}
    </div>
  )
}

export default CreditDetailsContainer
