import styled from '@emotion/styled'
import React from 'react'

const SVGContainer: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      customStylesDeprecated?: React.CSSProperties
      SVG?: React.FC<React.PropsWithChildren<React.PropsWithChildren<any>>> //In order to be compliant with React native this needs to be set to any
      svgColor?: string
      svgStyles?: React.CSSProperties
      className?: string
    }>
  >
> = ({ SVG, customStylesDeprecated = {}, svgColor, svgStyles, className }) => {
  className = !!className ? `centered ${className}` : `centered`

  customStylesDeprecated = { ...customStylesDeprecated, lineHeight: 0 }

  return (
    <Span style={customStylesDeprecated} className={className}>
      {!!SVG && <SVG color={svgColor} style={{ ...svgStyles }} />}
    </Span>
  )
}

const Span = styled.span`
  display: flex;
`

export default SVGContainer
