import classNames from 'classnames'
import PropTypes from 'prop-types'
import { ReactComponent as Spinner } from '../assets/icons/loader.svg'

const setClassName = ({ className, size }: { className?: string; size?: string }) => classNames(className, { [size || '']: size })

const Loader = ({ className, size, ...props }: { className?: string; size?: string; style?: any }) => (
  <Spinner className={setClassName({ className, size })} {...props} />
)

Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string
}

Loader.defaultProps = {
  size: 'medium'
}

export default Loader
