/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CardStatusPatch = {
    CARD_OK: 'CARD_OK',
    CARD_BLOCKED: 'CARD_BLOCKED',
    SUSPECTED_FRAUD: 'SUSPECTED_FRAUD',
    CARD_CLOSED_DUE_TO_FRAUD: 'CARD_CLOSED_DUE_TO_FRAUD',
    CARD_NO_RENEWAL: 'CARD_NO_RENEWAL',
    CARD_LOST: 'CARD_LOST',
    CARD_STOLEN: 'CARD_STOLEN',
    CARD_CLOSED: 'CARD_CLOSED'
} as const;

export type CardStatusPatch = typeof CardStatusPatch[keyof typeof CardStatusPatch];



