/* eslint-disable no-restricted-imports */
/* eslint-disable no-named-mui-exports/no-named-mui-exports */
import { colors } from './colors'
import { base } from './base'
import deepmerge from '@mui/utils/deepmerge'
import { ThemeOptions, createTheme } from '@mui/material'

const getVariantStyle = (variantName: string, theme: ThemeOptions) => {
  const variant = theme?.components?.MuiButton?.variants?.find((v) => v.props.variant === variantName)

  return variant ? variant.style : {}
}

export const light = createTheme(base, {
  typography: {
    color: colors.product.black[100]
  },
  palette: {
    mode: 'light',
    mynt: colors
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: deepmerge(
            {
              color: colors.product.white,
              backgroundColor: colors.product.blue[600],
              ':hover': {
                backgroundColor: colors.product.blue[700]
              },
              ':active': {
                backgroundColor: colors.product.blue[500]
              },
              ':disabled': {
                backgroundColor: colors.product.grey[400],
                color: colors.product.grey[500]
              }
            },
            getVariantStyle('primary', base)
          )
        },
        {
          props: { variant: 'secondary' },
          style: deepmerge(
            {
              color: colors.product.black[100],
              borderColor: colors.product.black[50],
              ':hover': {
                color: colors.brand.gray[400],
                borderColor: colors.product.grey[400],
                backgroundColor: colors.product.white
              },
              ':active': {
                borderColor: colors.product.black[100],
                color: colors.product.black[100],
                backgroundColor: colors.product.grey[100]
              },
              ':disabled': {
                borderColor: colors.product.grey[400],
                color: colors.product.grey[400],
                backgroundColor: colors.brand.gray[100]
              }
            },
            getVariantStyle('secondary', base)
          )
        },
        {
          props: { variant: 'tertiary' },
          style: deepmerge(
            {
              color: colors.product.white,
              backgroundColor: colors.product.black[100],
              ':hover': {
                color: colors.product.white,
                backgroundColor: colors.brand.gray[400]
              },
              ':active': {
                color: colors.product.white,
                backgroundColor: colors.product.black[100]
              },
              ':disabled': {
                color: colors.product.grey[500],
                backgroundColor: colors.product.grey[400]
              }
            },
            getVariantStyle('tertiary', base)
          )
        }
      ]
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              color: colors.product.white,
              '& + .MuiSwitch-track': {
                backgroundColor: colors.product.success
              }
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: colors.product.grey[300]
            }
          },
          '& .MuiSwitch-track': {
            backgroundColor: colors.product.grey[200]
          }
        }
      }
    }
  }
})
