/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const BackOfficeAgreementTypeV6 = {
    CARD_CREDIT_AGREEMENT: 'CARD_CREDIT_AGREEMENT',
    DIRECT_DEBIT_AGREEMENT: 'DIRECT_DEBIT_AGREEMENT'
} as const;

export type BackOfficeAgreementTypeV6 = typeof BackOfficeAgreementTypeV6[keyof typeof BackOfficeAgreementTypeV6];



