import styled from '@emotion/styled'
import { Checkbox } from '../components/Checkbox'
import React, { useEffect } from 'react'
import { InboxItemType } from '../api/swagger/definitions/backoffice'
import { InboxFilterActionTypes, useInboxFilterContext } from '../context/InboxFilterContext'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { LocalStorageKeys, useLocalStorageItem } from '../mynt-components/hooks/useLocalStorageItem'
import { prettifyEnumLabel } from 'helpers/CreditOnCardHelpers'
import FormGroup from '@mui/material/FormGroup'

interface InboxFiltersProps {
  filters: InboxItemType[]
  children: React.ReactElement
}

const InboxFilters: React.FC<InboxFiltersProps> = ({ filters, children }) => {
  const { item: storageFilters, setItem: setStorageFilters } = useLocalStorageItem<InboxItemType[]>(LocalStorageKeys.INBOX_FILTERS, [])

  const { item: checkAllStorageFilters, setItem: setCheckAllStorageFilters } = useLocalStorageItem<boolean>(
    LocalStorageKeys.INBOX_FILTERS_CHECK_ALL,
    true
  )

  const { inboxFilterState, inboxFilterDispatch } = useInboxFilterContext()

  const allInboxFilters = Object.values(InboxItemType)

  const handleCheckAllClick = () => {
    if (inboxFilterState.checkAllFilters) {
      inboxFilterDispatch({ type: InboxFilterActionTypes.SET_FILTERS, filters: [] })
      setStorageFilters([])
      inboxFilterDispatch({ type: InboxFilterActionTypes.SET_CHECK_ALL_FILTERS, checkAllFilters: false })
      setCheckAllStorageFilters(false)
    } else {
      inboxFilterDispatch({ type: InboxFilterActionTypes.SET_FILTERS, filters: allInboxFilters })
      setStorageFilters(allInboxFilters)
      inboxFilterDispatch({ type: InboxFilterActionTypes.SET_CHECK_ALL_FILTERS, checkAllFilters: true })
      setCheckAllStorageFilters(true)
    }
  }

  const handleFilterChange = (value: InboxItemType, checked) => {
    const newFilters = checked ? [...inboxFilterState.filters, value] : inboxFilterState.filters.filter((filter) => filter !== value)

    inboxFilterDispatch({ type: InboxFilterActionTypes.SET_FILTERS, filters: newFilters })
    setStorageFilters(newFilters)
    if (allInboxFilters.length !== value.length) {
      inboxFilterDispatch({ type: InboxFilterActionTypes.SET_CHECK_ALL_FILTERS, checkAllFilters: false })
      setCheckAllStorageFilters(false)
    }
  }

  useEffect(() => {
    !!storageFilters && inboxFilterDispatch({ type: InboxFilterActionTypes.SET_FILTERS, filters: storageFilters })
    !!checkAllStorageFilters &&
      inboxFilterDispatch({ type: InboxFilterActionTypes.SET_CHECK_ALL_FILTERS, checkAllFilters: checkAllStorageFilters })
  }, [])

  return (
    <FigmaBox fullWidth direction="row" bottom>
      <FigmaBox style={{ width: '20%' }} direction="column">
        <FigmaBox style={{ color: 'black' }} bottom fullWidth>
          <FormGroup onChange={handleCheckAllClick}>
            <Checkbox checked={inboxFilterState.checkAllFilters}>Check/Uncheck All</Checkbox>
          </FormGroup>
        </FigmaBox>
        {children}
      </FigmaBox>
      <SectionContainer fullWidth direction="row">
        {allInboxFilters.map((filterType, index) => (
          <FilterBox key={`${filterType}${index}`} right>
            <FormGroup onChange={(event: any) => handleFilterChange(filterType, event.target.checked)}>
              <Checkbox checked={inboxFilterState.filters.includes(filterType)}>
                <FigmaBox direction="row" fullWidth>
                  {prettifyEnumLabel(filterType)}
                </FigmaBox>
              </Checkbox>
            </FormGroup>
          </FilterBox>
        ))}
      </SectionContainer>
    </FigmaBox>
  )
}

const SectionContainer = styled(FigmaBox)`
  flex-wrap: wrap;
  color: black;
`
const FilterBox = styled(FigmaBox)`
  width: 33%;
`
export default InboxFilters
