import Close from '@mui/icons-material/Close'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider/LocalizationProvider'
import IconButton from '@mui/material/IconButton'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import React, { Profiler } from 'react'
import Axios from './api/axios'
import { AppStateContextProvider } from './contexts/AppStateContext'
import { MaterialNotificationAxios, SnackbarUtilsConfigurator } from './hooks/useMaterialNotification'
import './index.css'
import Router from './routes/Router'
import { SessionContextProvider } from 'contexts/session'
import { RolePermissionsProvider } from 'contexts/permissions'
import { permissions } from 'appPermissions'
import { NotesContextProvider } from 'contexts/Notes'

Axios.interceptors.response.use((response) => response, MaterialNotificationAxios)

type DismissProps = {
  _key: SnackbarKey
  onDismiss: (key: SnackbarKey) => any
}

const Dismiss: React.FC<DismissProps> = ({ _key, onDismiss }) => (
  <IconButton onClick={onDismiss(_key)}>
    <Close />
  </IconButton>
)

export default function App() {
  const notistackRef = React.createRef<any>()

  const dismissMaterialNotification = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key)
  }

  return (
    <Profiler id="profiler" onRender={() => null}>
      <SnackbarProvider ref={notistackRef} action={(key) => <Dismiss _key={key} onDismiss={dismissMaterialNotification} />}>
        <AppStateContextProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarUtilsConfigurator />
            <SessionContextProvider>
              <RolePermissionsProvider value={permissions}>
                <NotesContextProvider>
                  <Router />
                </NotesContextProvider>
              </RolePermissionsProvider>
            </SessionContextProvider>
          </LocalizationProvider>
        </AppStateContextProvider>
      </SnackbarProvider>
    </Profiler>
  )
}
