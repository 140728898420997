import React from 'react'
import MaterialCheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

type OldLibCheckboxProps = {
  prefixCls?: string
  className?: string
  defaultChecked?: boolean
  checked?: boolean
  style?: React.CSSProperties
  disabled?: boolean
  onChange?: (e: any) => void
  onClick?: React.MouseEventHandler<HTMLElement>
  onMouseEnter?: React.MouseEventHandler<HTMLElement>
  onMouseLeave?: React.MouseEventHandler<HTMLElement>
  onKeyPress?: React.KeyboardEventHandler<HTMLElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLElement>
  value?: any
  tabIndex?: number
  name?: string
  children?: React.ReactNode
  id?: string
  autoFocus?: boolean
  type?: string
}

/** @deprecated use checkbox from material ui instead */
export const Checkbox = React.forwardRef<OldLibCheckboxProps, OldLibCheckboxProps>((props, ref) => {
  const { children } = props

  return (
    <FormControlLabel
      {...props}
      ref={ref}
      sx={{ margin: '0' }}
      control={<MaterialCheckbox sx={{ padding: '2px 4px 2px 0' }} size="small" />}
      label={<span style={{ fontSize: '14px' }}>{children}</span>}
    />
  )
})

Checkbox.displayName = 'Checkbox'
