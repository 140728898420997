import Accounting from '../components/Accounting'
import React from 'react'

const AccountingPage = () => (
  <div style={{ marginTop: 40 }}>
    <Accounting />
  </div>
)

export default AccountingPage
