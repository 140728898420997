import { CreditTypes } from 'helpers/utils'
import routes from 'routes/constants'
import { FastSearchResultItem } from '../api/swagger/definitions/backoffice'

export const createRoutePath = (row: FastSearchResultItem) => {
  /* Change everything to 'row.customerId' but COMPANY_CREDIT
     when backend releases the new search path */

  const defaultPath = `${routes.CUSTOMERS_PATH}/${row.customerId}`

  const paths = {
    INVOICE_CREDIT: `${routes.CREDITS_PATH}/${CreditTypes.INVOICECREDIT}/${row.customerId}`,
    COMPANY_CREDIT: `${routes.CREDITS_PATH}/${CreditTypes.COMPANYCREDIT}/${row.id}`,
    CUSTOMER: `${routes.CUSTOMERS_PATH}/${row.customerId}`,
    PERSON: `${routes.CUSTOMERS_PATH}/${row.customerId}`,
    REPAYMENT_INVOICE: `${routes.REPAYMENT_INVOICE_PATH}/${row.customerId}`
  }

  return paths[row.type] ?? defaultPath
}
