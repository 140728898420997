import React from 'react'
import { CompanyCreditDto } from '../api/swagger/definitions/backoffice'
import { Control } from 'react-hook-form'
import Box from '@mui/material/Box'
import { DatePickerControllerV2, TextFieldController } from './react-hook-components'
import * as Yup from 'yup'

interface IProps {
  control: Control<CompanyCreditDto>
}

export const CreditFormRequestValidationSchema = Yup.object().shape({
  requestAmount: Yup.object().shape({
    value: Yup.number().required('Requested amount is required').typeError('Requested amount must be a number')
  }),
  requestPeriodMonths: Yup.number().required('Requested period is required').typeError('Requested amount must be a number'),
  requestDate: Yup.date().required('Requested date is required'),
  purpose: Yup.string().required('Purpose is required')
})

export const CreditFormRequest: React.FC<IProps> = ({ control }) => (
  <Box sx={{ width: '100%', display: 'flex', gap: '16px', flexDirection: 'row' }}>
    <Box sx={{ width: '100%', display: 'flex', gap: '24px', flexDirection: 'column' }}>
      <TextFieldController control={control} name="requestAmount.value" labelText="Requested amount" />
      <TextFieldController control={control} name="requestPeriodMonths" labelText="Requested period (months)" />
    </Box>
    <Box sx={{ width: '100%', display: 'flex', gap: '24px', flexDirection: 'column' }}>
      <DatePickerControllerV2 control={control} name="requestDate" labelText="Requested date" />
      <TextFieldController control={control} name="purpose" labelText="Purpose" />
    </Box>
  </Box>
)
