import Button from '@mui/material/Button'
import React from 'react'
import FigmaBox from '../mynt-components/components/FigmaBox'
import Google from 'figma/svgs/google'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff'
import Box from '@mui/material/Box'
import { colors } from 'themes'

export type VPNStatus = 'connected' | 'disconnected' | 'connecting'

export type LoginFormProps = {
  handleGoogleLogin: () => unknown
  vpnStatus: VPNStatus
  loading?: boolean
  errorMessage?: string
}

const getVpnIcon = (vpnStatus: VPNStatus) => {
  switch (vpnStatus) {
    case 'connected':
      return <VpnKeyIcon sx={{ color: colors.product.success[600] }} fontSize="small" />

    case 'disconnected':
      return <VpnKeyOffIcon sx={{ color: colors.product.error[600] }} fontSize="small" />

    default:
      return <VpnKeyOffIcon fontSize="small" />
  }
}

const LoginForm: React.FC<LoginFormProps> = ({ handleGoogleLogin, vpnStatus = 'connecting', loading, errorMessage }) => {
  const vpnIconColor = loading ? '' : colors.product.success[600]
  const buttonDisabled = vpnStatus !== 'connected'

  return (
    <FigmaBox fullWidth align="center">
      <FigmaBox>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}>
          <Box>VPN {vpnStatus}</Box>
          <Box sx={{ marginTop: 0.5, color: vpnIconColor }}>{getVpnIcon(vpnStatus)}</Box>
        </Box>
        {errorMessage && <Box sx={{ color: colors.product.error[600], paddingBottom: '0.5em', textAlign: 'center' }}>{errorMessage}</Box>}
        <Button onClick={handleGoogleLogin} fullWidth variant="secondary" disabled={buttonDisabled}>
          <FigmaBox direction="row" align="center" gap="0.5em">
            <FigmaBox>
              <Google width="1em" height="1em" />
            </FigmaBox>
            <FigmaBox>Sign in with Google</FigmaBox>
          </FigmaBox>
        </Button>
      </FigmaBox>
    </FigmaBox>
  )
}

export default LoginForm
