import MaterialTable from '@material-table/core'
import { formatCurrency } from 'helpers/utils'
import React, { useMemo } from 'react'
import { AccountResultDto } from '../api/swagger/definitions/backoffice'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import useLanguage from '../flamingo/hooks/useLanguage'
import { GetTableOptions } from '../helpers/getTableOptions'
import TextKeys from '../libs/TextKeys'
import { getText } from '../tiger/libs/TextRepository'
import TableWrapper from './TableWrapper'

interface AccountingSummaryProps {
  summary: AccountResultDto[]
  loading: boolean
  currencies: string[]
}

const VouchersSummaryTable: React.FC<AccountingSummaryProps> = ({ summary, loading, currencies }) => {
  const language = useLanguage()

  const currencyColumns = useMemo(
    () =>
      currencies.map((currency) => ({
        title: currency,
        field: currency,
        render: ({ periods }: AccountResultDto) => {
          const period = periods?.find((period) => period.currency === currency)

          if (period) {
            return formatCurrency({ value: period.value, currency })
          }

          return '-'
        }
      })),
    [currencies]
  )

  return (
    <TableWrapper>
      <MaterialTable
        columns={[
          {
            title: getText(TextKeys.voucherListNameLabel, language),
            field: 'name'
          },
          {
            title: getText(TextKeys.voucherListNumberLabel, language),
            field: 'no'
          },
          ...currencyColumns
        ]}
        title="Summary"
        data={summary}
        isLoading={loading}
        options={GetTableOptions({
          headerTextKey: TextKeys.accountingPaymentsOCR,
          extraOptions: { filtering: false, headerStyle: { backgroundColor: ColorsDeprecated.surfaceOnWhite } }
        })}
      />
    </TableWrapper>
  )
}

export default VouchersSummaryTable
