import * as React from 'react';

import ButtonTertiaryPressedStyled from './ButtonTertiaryPressedStyled';

type ButtonTertiaryPressedProps = any

const ButtonTertiaryPressed: React.FC<ButtonTertiaryPressedProps> = (props) => (
  <ButtonTertiaryPressedStyled  {...props}>{props.children ? props.children : ""}</ButtonTertiaryPressedStyled>
);

export default ButtonTertiaryPressed;