import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import qs from 'query-string'

export enum CreditTypes {
  COMPANYCREDIT = 'company-credits',
  INVOICECREDIT = 'invoice-credits'
}

export const setEmptyPropsToNull = (object: any) => {
  for (const key in object) {
    if (!object.hasOwnProperty(key)) return

    if (isObject(object[key])) {
      if (isArray(object[key])) {
        object[key].forEach((item: unknown) => {
          // We're making an assumption if the item is not an object
          // null is a valid value
          isObject(item) && setEmptyPropsToNull(item)
        })
      } else {
        setEmptyPropsToNull(object[key])
      }
    }

    // Check whether primitive type is set to '' or undefined
    if (object[key] === '' || object[key] === undefined) {
      object[key] = null
    }
  }
}

export const getCreditType = (type: keyof typeof CreditTypes) => CreditTypes[type]

export const formatCurrency = (item: { value: number; currency: string } | null | undefined, fractions = 0) => {
  const fractionOptions = {
    minimumFractionDigits: fractions,
    maximumFractionDigits: fractions
  }

  if (!item || !item.value) {
    return '-'
  }

  if (fractions !== 0 && Number.isInteger(item.value)) {
    fractionOptions.maximumFractionDigits = 0
    fractionOptions.minimumFractionDigits = 0
  }

  return item.value.toLocaleString('SWE', {
    style: 'currency',
    currency: item.currency,
    ...fractionOptions
  })
}

export const dateDiffInDays = (a: Date, b: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export function dateDiffInMonths(dateFrom: Date, dateTo: Date) {
  return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear())
}

export function formatDate(date: Date) {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (0 === bytes) return '0 Bytes'

  const c = 1024
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const unitMagnitude = Math.floor(Math.log(bytes) / Math.log(c))

  return `${parseFloat((bytes / Math.pow(c, unitMagnitude)).toFixed(decimals))} ${units[unitMagnitude]}`
}

export const getFeatureFlagsFromUrl = () => {
  if (window.location.search) {
    return String(qs.parse(window.location.search)?.features)
      .split(',')
      .reduce((a, flag) => {
        a[flag] = true
        return a
      }, {})
  }

  return {}
}
