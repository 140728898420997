import { useMemo } from 'react'
import { CreditDto } from '../api/swagger/definitions/backoffice'
import useConfigByCountry from 'flamingo/hooks/useConfigByCountry'
import { DataGridPro, DataGridProProps, GridColDef } from '@mui/x-data-grid-pro'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

type Amount = {
  value: number
  currency: string
}

type Sums = {
  amount: Amount
  remaining: Amount
}

const sumAmountAndRemainingCredits = (credits: CreditDto[], initialValue: Sums) =>
  credits.reduce((acc, credit) => {
    acc.amount.value += credit.creditAmount?.value ?? 0
    acc.remaining.value += credit.remainingAmount?.value ?? 0

    acc.amount.currency = credit.creditAmount?.currency ?? 'unknown currency'
    acc.remaining.currency = credit.remainingAmount?.currency ?? 'unknown currency'

    return acc
  }, initialValue)

type CreditsTableProps = {
  credits: CreditDto[]
  view?: 'customer'
} & Omit<DataGridProProps, 'rows' | 'columns'>

export const CreditsTable = ({ credits, view, ...props }: CreditsTableProps) => {
  const { formatAmount } = useConfigByCountry()

  const isCustomerView = view === 'customer'

  const sums = useMemo<Sums>(() => {
    const initialValue: Sums = { amount: { value: 0, currency: 'SEK' }, remaining: { value: 0, currency: 'SEK' } }

    if (view !== 'customer') return initialValue

    return sumAmountAndRemainingCredits(credits, initialValue)
  }, [credits, view])

  const companyColumns: GridColDef<CreditDto>[] = [
    {
      field: 'no',
      headerName: 'No',
      flex: 1
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1
    },
    {
      field: 'state',
      headerName: 'State',
      flex: 1.25
    },
    {
      field: 'partner',
      headerName: 'Partner',
      flex: 1.25
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 2
    },
    {
      field: 'orgNo',
      headerName: 'Organisation No',
      flex: 1
    },
    {
      field: 'creditAmount',
      headerName: 'Credit Amount',
      renderCell: ({ value }) => formatAmount(value),
      flex: 1
    },
    {
      field: 'remainingAmount',
      headerName: 'Remaining Amount',
      renderCell: ({ value }) => formatAmount(value),
      flex: 1
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 1
    }
  ]

  const customerColumns: GridColDef<CreditDto>[] = [
    {
      field: 'no',
      headerName: 'No',
      flex: 0.5
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1.25
    },
    {
      field: 'state',
      headerName: 'State',
      flex: 1
    },
    {
      field: 'creditAmount',
      headerName: 'Credit Amount',
      renderCell: ({ value }) => formatAmount(value),
      renderHeader: () => (isCustomerView ? `Credit Amount (${formatAmount(sums.amount)})` : `Credit Amount`),
      flex: 1.75
    },
    {
      field: 'remainingAmount',
      headerName: 'Remaining Amount',
      renderCell: ({ value }) => formatAmount(value),
      renderHeader: () => (isCustomerView ? `Remaining Amount (${formatAmount(sums.remaining)})` : `Remaining Amount`),
      flex: 1.75
    },
    {
      field: 'creditDate',
      headerName: 'Credit Date',
      flex: 1
    },
    {
      field: 'creditPeriodMonths',
      headerName: 'Credit Period (months)',
      flex: 1.25
    }
  ]

  const showEmptyOrLoadingMessage = !credits.length || props.loading

  return (
    <DataGridPro
      sx={{ height: showEmptyOrLoadingMessage ? 200 : 'auto', width: '100%' }}
      {...props}
      rows={credits}
      disableRowSelectionOnClick
      columns={isCustomerView ? customerColumns : companyColumns}
      pagination
      pageSizeOptions={[10, 25, 50]}
      initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
      slots={{
        noRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No credits found 😔
          </Stack>
        ),
        loadingOverlay: () => {
          if (Math.random() < 0.9) return <LinearProgress />

          return (
            <Box>
              <LinearProgress />
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}>
                <img src="https://emoji.slack-edge.com/TBEGNHMN0/funkyjohan/238c33dc475cea33.gif" />
              </Box>
            </Box>
          )
        }
      }}
    />
  )
}

export default CreditsTable
