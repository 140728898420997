import { UseMutationResult } from '@tanstack/react-query'
import { BackOfficePatchPaymentRequestDto, BackOfficePaymentResponseDto, PaymentState } from '../api/swagger/definitions/backoffice'
import { TextKeys, getText } from '../tiger/libs/TextRepository'

type MutationType = UseMutationResult<
  BackOfficePatchPaymentRequestDto,
  Error,
  {
    paymentId: string
    payout: BackOfficePatchPaymentRequestDto
  }
>

type GetPaymentsActionsProps = {
  mutation: MutationType
  onVerifySender: (payment: BackOfficePaymentResponseDto) => void
}

export const getPaymentsActions = ({ mutation, onVerifySender }: GetPaymentsActionsProps) => [
  (payment: BackOfficePaymentResponseDto) => ({
    icon: 'book',
    tooltip: getText(TextKeys.accountingInvoicesToolTipBook, 'en'),
    disabled: mutation.isLoading || [PaymentState.BOOKED, PaymentState.CANCELLED, PaymentState.MANUAL_HANDLED].includes(payment.state),
    onClick: (event: any, payment: BackOfficePaymentResponseDto) =>
      mutation.mutate({
        paymentId: payment.id,
        payout: { state: PaymentState.BOOKED }
      })
  }),
  (payment: BackOfficePaymentResponseDto) => ({
    icon: 'checkbox',
    tooltip: 'Mark as manual handled',
    disabled: mutation.isLoading || [PaymentState.BOOKED, PaymentState.CANCELLED, PaymentState.MANUAL_HANDLED].includes(payment.state),
    onClick: (event: any, payment: BackOfficePaymentResponseDto) =>
      window.confirm('Mark as manual handled?') &&
      mutation.mutate({
        paymentId: payment.id,
        payout: { state: PaymentState.MANUAL_HANDLED }
      })
  }),
  (payment: BackOfficePaymentResponseDto) => {
    if (payment.state !== PaymentState.TO_BE_RETURNED) return

    return {
      icon: 'arrow_back',
      tooltip: 'Mark as returned',
      onClick: () => {
        if (window.confirm('Are you sure you want to mark the payment as returned?')) {
          mutation.mutate({
            paymentId: payment.id,
            payout: { state: PaymentState.RETURNED }
          })
        }
      }
    }
  },
  (payment: BackOfficePaymentResponseDto) =>
    // if (payment.state !== PaymentState.SENDER_UNKNOWN) return

    ({
      icon: 'receipt_long',
      tooltip: 'Verify Sender',
      // disabled: row.state !== EnfucePayoutState.TO_BE_RETURNED,
      onClick: (event: any, payment: BackOfficePaymentResponseDto) => onVerifySender(payment)
    })
]
