import React from 'react'
import { AntiloopTextType } from '../../tiger/interfaces/Antiloop'
import AntiloopVariant from '../../tiger/interfaces/AntiloopVariant'
import useIsMobile from './useIsMobile'

export default function useTextVariant(textKey: AntiloopTextType) {
  const isMobile = useIsMobile()

  let variantStyle: { style: React.CSSProperties } = { style: {} }

  try {
    variantStyle = isMobile ? textKey.variants[AntiloopVariant.MOBILE] : textKey.variants[AntiloopVariant.DESKTOP]
  } catch (error: any) {
    variantStyle = error.message.includes(AntiloopVariant.MOBILE)
      ? textKey.variants[AntiloopVariant.DESKTOP]
      : textKey.variants[AntiloopVariant.MOBILE]
  }

  if (!variantStyle) {
    variantStyle = textKey.variants[AntiloopVariant.DESKTOP] || textKey.variants[AntiloopVariant.MOBILE]
  }

  return variantStyle
}
