import styled from '@emotion/styled';
import ButtonTertiaryDisabledCss from './ButtonTertiaryDisabledCss';


interface ButtonTertiaryDisabledProps {
  children: any;
  [propName: string]: {};
}

// Extend the below as needed
const ButtonTertiaryDisabledStyled = styled.div<ButtonTertiaryDisabledProps>`
  ${ButtonTertiaryDisabledCss};
`;

export default ButtonTertiaryDisabledStyled;