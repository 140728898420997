import { CustomerProduct } from '../api/swagger/definitions/backoffice'
import Spacings from '../figma/tokens/Spacings'
import { useCustomer } from '../api/react-query'
import { CustomerIdContext, MinWidthFigmaBox } from '../helpers/CreditOnCardHelpers'
import TextKeys from '../libs/TextKeys'
import { CREDIT_REQUEST_WIDTH } from '../mynt-components/StrongHardCodedSizes'
import FigmaBox from '../mynt-components/components/FigmaBox'
import MyntLoader from '../mynt-components/components/MyntLoader'
import CreditOnCardCreditRequest from './CreditOnCardCreditRequest'
import CreditOnCardCreditSettings from './CreditOnCardCreditSettings'
import TextContainer from './TextContainer'

type CreditOnCardV2Props = {
  customerId: string
}

const CustomerCardTabCreditOnCard = ({ customerId }: CreditOnCardV2Props) => {
  const { data: customer, isLoading: customerIsLoading } = useCustomer(customerId)
  const customerHasCard = Array.from(customer?.products ?? []).includes(CustomerProduct.CARD)

  if (customerIsLoading) return <MyntLoader />

  // Don't render stuff without the card product, it will cause a lot of errors
  if (!customerHasCard)
    return <TextContainer textKey={TextKeys.creditOnCardCreditSettingsHeading} text="The customer does not have the card product enabled" />

  return (
    <CustomerIdContext.Provider value={customerId}>
      <FigmaBox fullWidth gap={Spacings.medium}>
        <FigmaBox direction="row" justify="space-between" gap={Spacings.medium} fullWidth>
          <CreditOnCardCreditSettings />
          <MinWidthFigmaBox width={CREDIT_REQUEST_WIDTH}>
            <CreditOnCardCreditRequest />
          </MinWidthFigmaBox>
        </FigmaBox>
      </FigmaBox>
    </CustomerIdContext.Provider>
  )
}

export default CustomerCardTabCreditOnCard
