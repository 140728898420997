import { usePatchCard, usePrivateCustomerCard, usePrivateCustomerCardPlastics } from 'api/react-query'
import React from 'react'
import { TabSets } from '../helpers/TabsHelpers'
import { RegionAndEcommBlockingInterface } from '../interfaces/CardInterfaces'
import TextKeys from '../libs/TextKeys'
import Tabs from '../mynt-components/components/Tabs'
import CustomerCardTabOverviewAccountUsageLimits from './CustomerCardTabOverviewAccountUsageLimits'
import CustomerCardTabOverviewSingleCardDetailsPrivateCard from './CustomerCardTabOverviewSingleCardDetailsPrivateCard'
import CustomerCardTabOverviewSingleCardDetailsRegionAndEcommBlocking from './CustomerCardTabOverviewSingleCardDetailsRegionAndEcommBlocking'
import TextContainer from './TextContainer'

interface CustomerCardTabOverviewSingleCardDetailsProps {
  customerId: string
  card?: { id: string; internalId: string; singleAmountLimit?: number }
}

const CustomerCardTabOverviewSingleCardDetails: React.FC<CustomerCardTabOverviewSingleCardDetailsProps> = ({
  customerId,
  card: selectedCard
}) => {
  const cardId = selectedCard?.id || ''

  const { data: card, isLoading: cardLoading } = usePrivateCustomerCard(customerId, cardId)
  const { data: cardPlastics = [], isLoading: cardPlasticsLoading } = usePrivateCustomerCardPlastics(customerId, cardId)

  const cardMutation = usePatchCard(customerId, cardId)

  const isLoading = cardLoading || cardPlasticsLoading

  const handleOnRegionalBlock = async (property: keyof RegionAndEcommBlockingInterface) => {
    if (!card?.regionAndEcommBlocking) return

    const value = card.regionAndEcommBlocking[property]

    await cardMutation.mutateAsync({
      regionAndEcommBlocking: {
        ...card.regionAndEcommBlocking,
        [property]: !value
      }
    })
  }

  return (
    <Tabs
      fullPage
      enableNestedTabs
      alwaysShowNavHeadings
      tabKeyNameSpace={TabSets.CUSTOMER_SCORING_BANKACCOUNT}
      tabs={[
        {
          labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Overview'} />,
          contentElement: (
            <CustomerCardTabOverviewSingleCardDetailsPrivateCard
              customerId={customerId}
              card={card}
              plastics={cardPlastics}
              isLoading={isLoading}
            />
          ),
          enable: true
        },
        {
          labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Usage limits'} />,
          contentElement: (
            <div style={{ marginTop: -20 }}>
              <CustomerCardTabOverviewAccountUsageLimits
                isLoading={isLoading}
                customerId={customerId}
                internalCardId={selectedCard?.internalId as string}
                singleAmountLimit={selectedCard?.singleAmountLimit}
                usageLimits={card?.usageLimits || []}
              />
            </div>
          ),
          enable: true
        },
        {
          labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Regional blocks'} />,
          contentElement: (
            <div style={{ marginTop: -30 }}>
              <CustomerCardTabOverviewSingleCardDetailsRegionAndEcommBlocking
                data={card?.regionAndEcommBlocking as any}
                onCheckboxClick={handleOnRegionalBlock as any}
                isCardLoading={cardLoading}
              />
            </div>
          ),
          enable: true
        }
      ]}
    />
  )
}

export default CustomerCardTabOverviewSingleCardDetails
