import { ConfigCountry, ConfigCountries, Countries } from '../config/ConfigCountries'
import useCountry from './useCountry'

const useConfigByCountry = (preferredCountry?: Countries): ConfigCountry => {
  const country = useCountry()

  return ConfigCountries[preferredCountry || country || Countries.SE]
}

export default useConfigByCountry
