// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const radii = {
 "circle": "100px",
 "soft": "8px",
 "verySoft": "20px",
 "rounded": "4px",
 "hard": "0px"
}

export default radii;