import styled from '@emotion/styled'
import { useCardDeliveryAdress, usePersons } from 'api/react-query'
import MaterialFieldFormik from 'mynt-components/components/MaterialFieldFormik'
import React, { useEffect, useMemo } from 'react'
import * as Yup from 'yup'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'

import { CoreMyntModal } from './modals/CoreMyntModal'
import Button from '@mui/material/Button'
import { AutoCompleteController, TextFieldController } from './react-hook-components'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import { Line } from 'mynt-components/components/StyledComponents'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js'

export type FormValues = {
  personId: string
  address1: string
  address2?: string
  areaCode: string
  city: string
  companyName: string
  country: string
  fullName: string
}

type ManuallyOrderCardProps = {
  visible: boolean
  onClose: () => unknown
  customerId: string
  loading?: boolean
  onSubmit: (values: FormValues, initialValues: FormValues) => void | Promise<any>
}

const validationSchema = Yup.object().shape({
  personId: Yup.string().typeError('Please choose a person').required('Please choose a person'),
  fullName: Yup.string().typeError('Please enter full name').required('Please enter full name'),
  address1: Yup.string().typeError('Please enter the address').required('Please enter the address'),
  address2: Yup.string().typeError('Please enter the address'),
  areaCode: Yup.string()
    .typeError('Please enter the postal code')
    .required('Please enter the postal code')
    .matches(/^\d+$/, 'Please enter the postal code'),
  city: Yup.string().typeError('Please enter the city').required('Please enter the city'),
  companyName: Yup.string().typeError('Please enter the company name').required('Please enter the company name')
})

export const _ModalManuallyOrderCard: React.FC<ManuallyOrderCardProps> = (props) => {
  const { visible, onClose, onSubmit, customerId, loading } = props

  const defaultValues: FormValues = {
    personId: '',
    address1: '',
    address2: '',
    areaCode: '',
    city: '',
    companyName: '',
    country: '', // not shown as a field in the form
    fullName: ''
  }

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  const selectedPersonId = form.watch('personId')

  const { data: persons = [] } = usePersons(customerId)
  const { data: delivery } = useCardDeliveryAdress(customerId, selectedPersonId)

  const personOptions = useMemo(() => persons.map(({ id, fullName }) => ({ value: id, label: fullName })), [persons])

  const handleClose = () => {
    form.reset(defaultValues)
    onClose?.()
  }

  useEffect(() => {
    if (!delivery?.address) return

    const { address, personName, companyName } = delivery

    form.setValue('address1', address.address1)
    form.setValue('address2', address.address2 || '')
    form.setValue('fullName', personName)
    form.setValue('companyName', companyName)
    form.setValue('areaCode', address.areaCode)
    form.setValue('city', address.city)
    form.setValue('country', address.country || '')

    form.trigger()
  }, [delivery, selectedPersonId])

  if (!visible) return null

  return (
    <CoreMyntModal
      wrapperProps={{ sx: { width: '450px' } }}
      open={visible}
      onClose={handleClose}
      title="Order card"
      actions={(Wrapper) => (
        <Wrapper>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="primary"
            onClick={form.handleSubmit((formValues) => onSubmit(formValues, defaultValues))}
          >
            Order card
          </LoadingButton>
        </Wrapper>
      )}
    >
      <Box sx={{ display: 'flex', gap: '24px', flexDirection: 'column', paddingTop: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant="body2" fontWeight="medium">
            Choose for which person
          </Typography>
          <AutoCompleteController control={form.control} name="personId" options={personOptions} label="Person" />
        </Box>
        <Box sx={{ height: '2px' }}>
          <Line fullWidth fullHeight />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant="body2" fontWeight="medium">
            Confirm or change the cards delivery info
          </Typography>
          <TextFieldController disabled control={form.control} name="fullName" labelText="Full name" />
        </Box>
        <TextFieldController control={form.control} name="companyName" labelText="Company name" />
        <TextFieldController control={form.control} name="address1" labelText="Address" />
        <TextFieldController control={form.control} name="address2" labelText="Apt, etc. (optional)" />
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <TextFieldController control={form.control} name="areaCode" labelText="Postal code" />
          <TextFieldController control={form.control} name="city" labelText="City" />
        </Box>
      </Box>
    </CoreMyntModal>
  )
}

// return (
//   <Modal
//     onClose={handleClose}
//     heading={() => (
//       <FigmaBox direction="row" fullWidth align="center" justify="center">
//         <TextContainer textKey={TextKeys.orderNewCardModalHeading} />
//       </FigmaBox>
//     )}
//   >
//     <Formik
//       innerRef={formRef}
//       initialValues={initialValues}
//       validationSchema={validationSchema}
//       onSubmit={async (values, formikHelpers) => onSubmit(values, initialValues, formikHelpers)}
//     >
//       {(formProps) => (
//         <FigmaBox spacing={Spacings.large} fullPadding>
//           <FigmaBox fullWidth direction="column" align="center" justify="space-between" spacing={Spacings.medium} bottom>
//             <FigmaBox fullWidth bottom spacing={Spacings.small}>
//               <TextContainer textKey={TextKeys.orderNewCardModalPersonHeading} />
//             </FigmaBox>
//             <MaterialSelectFieldFormik
//               labelTextKey={TextKeys.mFOrderCardChoosePersonLabel}
//               fullWidth
//               name="personId"
//               formProps={formProps}
//               options={personSelect || []}
//               onChange={handleSelectChange}
//             />
//           </FigmaBox>
//           <FigmaBox fullWidth direction="column" align="center" justify="space-between">
//             <FigmaBox fullWidth bottom spacing={Spacings.small}>
//               <TextContainer textKey={TextKeys.orderNewCardModalAddressHeading} />
//             </FigmaBox>
//             <FigmaBox fullWidth spacing={Spacings.small} bottom>
//               <GreyWhenDisabledInput
//                 disabled
//                 fullWidth
//                 name="fullName"
//                 formProps={formProps}
//                 labelTextKey={TextKeys.mFOrderCardFullNameLabel}
//               />
//             </FigmaBox>
//             <FigmaBox fullWidth spacing={Spacings.small} bottom>
//               <MaterialFieldFormik fullWidth name="companyName" formProps={formProps} labelTextKey={TextKeys.mFOrderCardCompanyLabel} />
//             </FigmaBox>
//             <FigmaBox fullWidth spacing={Spacings.small} bottom>
//               <MaterialFieldFormik fullWidth name="address1" formProps={formProps} labelTextKey={TextKeys.mFOrderCardAddressLabel} />
//             </FigmaBox>
//             <FigmaBox fullWidth spacing={Spacings.small} bottom>
//               <MaterialFieldFormik fullWidth name="address2" formProps={formProps} labelTextKey={TextKeys.mFInvoiceRecipientOrgLabel} />
//             </FigmaBox>
//             <FigmaBox direction="row" fullWidth spacing={Spacings.large} bottom>
//               <FigmaBox fullWidth spacing={Spacings.small} right>
//                 <MaterialFieldFormik fullWidth name="areaCode" formProps={formProps} labelTextKey={TextKeys.mFOrderCardZipLabel} />
//               </FigmaBox>
//               <FigmaBox fullWidth>
//                 <MaterialFieldFormik fullWidth name="city" formProps={formProps} labelTextKey={TextKeys.mFOrderCardCityLabel} />
//               </FigmaBox>
//             </FigmaBox>
//           </FigmaBox>
//           <FigmaBox align="center" fullWidth>
//             <FigmaButton loading={loading} textKey={TextKeys.orderCardButton} onClick={formProps.handleSubmit} />
//           </FigmaBox>
//         </FigmaBox>
//       )}
//     </Formik>
//   </Modal>
// )

const GreyWhenDisabledInput = styled(MaterialFieldFormik)`
  input:disabled {
    background-color: ${ColorsDeprecated.surfaceOnWhite};
  }
`
