import { CreditTypes } from 'helpers/utils'
import React from 'react'
import { CompanyCreditDto } from '../api/swagger/definitions/backoffice'
import CreditAccountingActions from './CreditAccountingActions'
import CreditAccountingDebt from './CreditAccountingDebt'
import CreditAccountingGeneral from './CreditAccountingGeneral'
import CreditRepayment from './Repayment'
import Box from '@mui/material/Box'

interface IProps {
  creditId: string
  creditType: CreditTypes
  credit: CompanyCreditDto
  refetchCredit: () => void
}

const CreditAccounting: React.FC<IProps> = ({ creditId, creditType, credit, refetchCredit }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
      <Box sx={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
        <h2>General</h2>
        <CreditAccountingGeneral credit={credit} />
      </Box>
      <Box sx={{ flex: 3, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <h2>Debt</h2>
        <CreditAccountingDebt credit={credit} />
      </Box>
      <Box sx={{ flex: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <CreditAccountingActions id={creditId} type={creditType} onSubmit={refetchCredit} />
      </Box>
    </Box>
    <div>
      <CreditRepayment creditId={creditId} repaymentModel={credit.repaymentModel} creditAmount={credit?.creditAmount?.value ?? 0} />
    </div>
  </Box>
)

export default CreditAccounting
