import styled from '@emotion/styled'
import React from 'react'
import Spacings from '../figma/tokens/Spacings'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaButton from '../mynt-components/components/FigmaButton'
import ModalExperimental, { ModalProps } from '../mynt-components/components/ModalExperimental'
import { TextKeys } from '../tiger/libs/TextRepository'
import TextContainer from './TextContainer'
import Button from '@mui/material/Button'

export type ModalAreYouSureProps = ModalProps & {
  onContinue: () => void
  anchor: any
  headingText?: string
}

export const _ModalAreYouSure: React.FC<ModalAreYouSureProps> = (props) => {
  const { onContinue } = props

  return (
    <ModalExperimental hideCloseButton onClose={props.onClose}>
      <Container fullWidth fullPadding>
        <Heading fullWidth bottom align="center">
          <TextContainer textKey={TextKeys.toolTipAreYouSureHeading} text={props.headingText} />
        </Heading>
        <FigmaBox fullWidth direction="row" justify="center" gap={Spacings.tiny}>
          <FigmaBox style={{ width: '50%' }} fullWidth>
            <Button size="medium" fullWidth onClick={props.onClose} variant="secondary">
              Cancel
            </Button>
          </FigmaBox>
          <FigmaBox style={{ width: '50%' }} fullWidth align="center">
            <Button size="medium" variant="primary" fullWidth onClick={onContinue}>
              Yes
            </Button>
          </FigmaBox>
        </FigmaBox>
      </Container>
    </ModalExperimental>
  )
}

const Container = styled(FigmaBox)`
  width: max-content;
  padding: ${Spacings.medium};
`
const Heading = styled(FigmaBox)`
  margin-top: -${Spacings.medium};
`
