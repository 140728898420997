import styled from '@emotion/styled'
import { Filters, RollingCreditFilters, RollingCreditFiltersProps } from '../RollingCreditFilters'
import { RollingCreditTable, RollingCreditType } from '../RollingCreditTable'
import { useMemo, useState } from 'react'
import { ModalInvoicePenaltyFee } from '../../../components/Modals'
import { Invoice, Payload } from '../../../components/ModalInvoicePenaltyFee'
import dayjs from 'dayjs'
import { CardCreditInterestFee, PayloadValues } from '../Modals/CardCreditInterestFee'

type RollingCreditProps = {
  credits: RollingCreditType[]
  onApiFilterChange?: (filters: Partial<Filters>) => void
  onMarkAsClosed?: (id: string) => Promise<unknown>
  onMarkAsPaidEnough?: (row: RollingCreditType) => Promise<unknown>
  options?: RollingCreditFiltersProps['options']
  onNotification?: (message: string) => void
  onCreateLatePaymentInvoice?: (payload: Payload) => unknown | Promise<unknown>
  isMarkAsClosedDisabled?: (row: RollingCreditType) => boolean
  isMarkAsPaidEnoughDisabled?: (row: RollingCreditType) => boolean
  isLoading?: boolean
  onRecalculateTransactions?: (creditId: string) => unknown | Promise<unknown>
  onCreateCardCreditIntrestFee?: (payload: PayloadValues) => unknown | Promise<unknown>
  onModalOpen?: (creditId: string) => void
  onModalClose?: () => void
}

export const unique = <T,>(data: T[]) => Array.from(new Set(data))

export const extractOptions = (credits: RollingCreditType[]) => {
  const firstPass = credits.reduce<{ currency: string[]; status: string[] }>(
    (acc, credit) => ({ currency: [...acc.currency, credit.currency], status: [...acc.status, credit.status] }),
    { currency: [], status: [] }
  )

  const currencies = unique(firstPass.currency)
  const statuses = unique(firstPass.status)

  return {
    currency: currencies.map((currency) => ({ label: currency, value: currency })),
    status: statuses.map((states) => ({ label: states, value: states }))
  }
}

const mapRollingCreditToInvoice = (credit: RollingCreditType): Invoice => ({
  dueDate: credit.dueDate,
  amount: {
    currency: credit.currency,
    value: 0
  },
  id: credit.creditId.toString(),
  latePaymentInterestRate: {
    currency: credit.currency,
    value: 0
  },
  no: Number(credit.creditNo)
})

const formatDates = (field: string, date: string) => {
  if (field === 'period') return dayjs(date).format('MM-DD')

  return dayjs(date).format('YYYY-MM-DD')
}

type SelectedInvoice = {
  rollingCredit: RollingCreditType
  invoice: Invoice
}

type ModalNames = 'invoicePenaltyFee' | 'cardCreditInterestFee'

export const RollingCredit = ({
  credits,
  onApiFilterChange,
  onMarkAsClosed,
  onMarkAsPaidEnough,
  options,
  onNotification,
  onCreateLatePaymentInvoice,
  onCreateCardCreditIntrestFee,
  onModalOpen,
  onModalClose,
  ...props
}: RollingCreditProps) => {
  const _options: RollingCreditFiltersProps['options'] = useMemo(() => options ?? extractOptions(credits), [credits, options])

  const [filters, setFilters] = useState<Partial<Filters>>({
    hasPaid: false,
    state: 'OPEN'
  })
  const [selectedInvoice, setSelectedInvoice] = useState<Partial<Record<ModalNames, SelectedInvoice>>>()

  const handleFilterChange = (filters: Partial<Filters>) => {
    setFilters(filters)
    onApiFilterChange?.(filters)
  }

  const handleOpenInvoice = (modal: ModalNames, rollingCredit: RollingCreditType) => {
    onModalOpen?.(rollingCredit.creditId)

    setSelectedInvoice({
      [modal]: {
        invoice: mapRollingCreditToInvoice(rollingCredit),
        rollingCredit
      }
    })
  }

  const handleCreateLatePaymentInvoice = async (payload: Payload) => {
    const promiseOrVoid = onCreateLatePaymentInvoice?.(payload)

    if (promiseOrVoid instanceof Promise) {
      promiseOrVoid.then(() => {
        setSelectedInvoice(undefined)
        onModalClose?.()
      })
    }

    return promiseOrVoid
  }

  const handleCreateCardCreditIntrestFee = async (payload: PayloadValues) => {
    const promiseOrVoid = onCreateCardCreditIntrestFee?.(payload)

    if (promiseOrVoid instanceof Promise) {
      promiseOrVoid.then(() => {
        setSelectedInvoice(undefined)
        onModalClose?.()
      })
    }

    return promiseOrVoid
  }

  const handleMarkAsClosed = async (id: string) => onMarkAsClosed?.(id)

  const handleMarkAsPaidEnough = async (row: RollingCreditType) => onMarkAsPaidEnough?.(row)

  const _format = (value: number, currency: string) =>
    Intl.NumberFormat('sv', {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0
    }).format(value)

  return (
    <Container>
      {selectedInvoice?.cardCreditInterestFee && (
        <CardCreditInterestFee
          isOpen
          onModalCancel={() => {
            setSelectedInvoice(undefined)
            onModalClose?.()
          }}
          onSubmit={handleCreateCardCreditIntrestFee}
          companyName={selectedInvoice.cardCreditInterestFee.rollingCredit.customer}
          defaultValues={{
            interest: {
              currency: selectedInvoice.cardCreditInterestFee.invoice.amount.currency,
              value: 0
            },
            vat: {
              currency: selectedInvoice.cardCreditInterestFee.invoice.amount.currency,
              value: 0
            }
          }}
        />
      )}
      {selectedInvoice?.invoicePenaltyFee && (
        <ModalInvoicePenaltyFee
          isOpen
          onModalCancel={() => {
            setSelectedInvoice(undefined)
            onModalClose?.()
          }}
          onSubmit={handleCreateLatePaymentInvoice}
          companyName={selectedInvoice?.invoicePenaltyFee.rollingCredit.customer}
          formatAmount={_format}
          defaultPenaltyFee={{
            currency: selectedInvoice?.invoicePenaltyFee?.invoice.amount.currency,
            latePaymentFee: {
              currency: selectedInvoice?.invoicePenaltyFee?.invoice.amount.currency,
              value: 0
            },
            vat: {
              currency: selectedInvoice?.invoicePenaltyFee?.invoice.amount.currency,
              value: 0
            }
          }}
          invoice={selectedInvoice?.invoicePenaltyFee?.invoice}
        />
      )}
      <RollingCreditFilters dateFormat="YYYY-MM-DD" onFilterChange={handleFilterChange} options={_options} filterValues={filters} />
      <RollingCreditTable
        {...props}
        formatAmount={_format}
        formatDate={formatDates}
        textSearch={filters.text}
        rollingCredits={credits}
        onCreateLatePaymentFee={(row) => handleOpenInvoice('invoicePenaltyFee', row)}
        onCreateCreditInterestFee={(row) => handleOpenInvoice('cardCreditInterestFee', row)}
        onMarkAsClosed={handleMarkAsClosed}
        onMarkAsPaidEnough={handleMarkAsPaidEnough}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
