// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

import borderWidths from 'figma/tokens-elements/borderWidthsDeprecated';
import colors from 'figma/tokens-elements/colorsDeprecated';
import radii from 'figma/tokens-elements/radiiDeprecated';
import spacing from 'figma/tokens-elements/spacingDeprecated';

const ButtonTertiaryDefaultCss = `
  width: 100%;
  box-sizing: border-box;
  border: 0;
  border-style: solid;
  height: ${spacing.big};
  background-color: ${colors.white};
  border-width: ${borderWidths.hairline};
  border-color: ${colors.stone};
  border-radius: ${radii.soft};
`;

export default ButtonTertiaryDefaultCss;