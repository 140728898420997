import ColorsDeprecated from '../../figma/tokens/ColorsDeprecated'
import Shadows from '../../figma/tokens/Shadows'
import { TooltipThemeVariants } from '../components/MyntTooltip'

export const getTooltipThemeStyles = (variant: TooltipThemeVariants) => {
  const isLight = variant === TooltipThemeVariants.LIGHT
  return {
    backgroundColor: isLight ? ColorsDeprecated.baseCloud : ColorsDeprecated.gray,
    ' > div > div span': {
      color: `${isLight ? ColorsDeprecated.gray : ColorsDeprecated.white} !important`
    }
  }
}

export const getTooltipArrowThemeStyles = (variant) => {
  const isLight = variant === TooltipThemeVariants.LIGHT
  return {
    color: isLight ? ColorsDeprecated.white : ColorsDeprecated.gray,
    borderColor: ColorsDeprecated.baseCloud,
    '&::before': {
      boxShadow: Shadows.baseHeavy
    }
  }
}
