import React from 'react'
import { FileContext } from '../api/swagger/definitions/backoffice'
import CustomerFiles from './CustomerFiles'

interface Props {
  customerId: string
}

const CustomerScoringTabsWithContext: React.FC<Props> = ({ customerId }) => (
  <CustomerFiles customerId={customerId} contexts={[FileContext.SCORING]} fileUploadContext={FileContext.SCORING} />
)

export default CustomerScoringTabsWithContext
