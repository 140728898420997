import styled from '@emotion/styled'
import React from 'react'
import { AutogiroApprovalState } from '../api/swagger/definitions/backoffice'
import BorderRadius from '../figma/tokens/BorderRadius'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { AntiloopTextType } from '../tiger/interfaces/Antiloop'
import TextContainer from './TextContainer'

type Props = {
  textKey?: AntiloopTextType
  text?: string
  status: AutogiroApprovalState
  fullWidth?: boolean
  left?: boolean
  right?: boolean
  align?: 'center' | 'flex-start' | 'flex-end' | 'normal'
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly'
  spacing?: string
}

const DirectDebitTabStatusBox: React.FC<Props> = ({
  textKey,
  status,
  fullWidth,
  left,
  right,
  align = 'flex-end',
  justify = 'flex-end',
  spacing: _spacing = Spacings.minimum,
  text
}) => {
  const statusFormated = getStatusColors(status)

  return (
    <StyledRow left={left} right={right} direction="row" align={align} justify={justify} fullWidth alignText="center">
      <FlexWrapper backgroundColor={statusFormated.backgroundColor} fullWidth={fullWidth} direction="row">
        <FigmaBox fullPadding fullWidth spacing={_spacing || Spacings.minimum} direction="row">
          <TextContainer textKey={textKey || statusFormated.textKey} text={text} />
        </FigmaBox>
      </FlexWrapper>
    </StyledRow>
  )
}

const FlexWrapper = styled(FigmaBox)<{ backgroundColor?: string }>`
  background: ${(props) => (!!props.backgroundColor ? props.backgroundColor : ColorsDeprecated.baseGreen)};

  border-radius: ${BorderRadius.rounded};
`
const StyledRow = styled(FigmaBox)<{ left?: boolean; right?: boolean }>`
  ${(props) => !!props.left && `margin-left: ${Spacings.tiny};`}
  ${(props) => !!props.right && `margin-right: ${Spacings.tiny};`}
`

export default DirectDebitTabStatusBox

export const getStatusColors = (status: AutogiroApprovalState): { backgroundColor: string; textKey: AntiloopTextType } => {
  switch (status) {
    case AutogiroApprovalState.APPROVED:
      return { backgroundColor: ColorsDeprecated.baseGarden, textKey: TextKeys.directDebitTagApproved }

    case AutogiroApprovalState.FAILED:
      return { backgroundColor: ColorsDeprecated.baseCritical, textKey: TextKeys.directDebitTagFail }

    case AutogiroApprovalState.PENDING:
      return { backgroundColor: ColorsDeprecated.baseWarning, textKey: TextKeys.directDebitTagPending }

    case AutogiroApprovalState.REQUESTED:
      return { backgroundColor: ColorsDeprecated.baseStone, textKey: TextKeys.directDebitTagRequested }

    case AutogiroApprovalState.CANCELLED:
      return { backgroundColor: ColorsDeprecated.decorativeTiger, textKey: TextKeys.directDebitTagCancelled }

    case AutogiroApprovalState.PENDING_CANCELLATION:
      return { backgroundColor: ColorsDeprecated.categoryYellow, textKey: TextKeys.directDebitTagPendingCancellation }
    case AutogiroApprovalState.READY:
      return { backgroundColor: ColorsDeprecated.baseGarden, textKey: TextKeys.directDebitTagReady }

    default:
      return { backgroundColor: ColorsDeprecated.baseStone, textKey: TextKeys.statusBadgeInactive }
  }
}
