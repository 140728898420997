export enum TabSets {
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_SCORING = 'CUSTOMER_SCORING',
  CUSTOMER_SCORING_BANKACCOUNT = 'CUSTOMER_SCORING_BANKACCOUNT',
  CUSTOMER_CARD = 'CUSTOMER_CARD',
  CUSTOMER_CREDITS = 'CUSTOMER_CREDITS',
  CREDITS = 'CREDITS',
  BIDDING = 'BIDDING',
  BIDDING_DETAILS = 'BIDDING_DETAILS',
  ACCOUNTING = 'ACCOUNTING',
  ACCOUNTING_VOUCHERS = 'ACCOUNTING_VOUCHERS'
}

export enum AccountingTabs {
  VOUCHERS = 'VOUCHERS',
  ACCOUNTS = 'ACCOUNTS',
  YEARS = 'YEARS',
  PAYOUTS = 'PAYOUTS',
  PAYMENTS = 'PAYMENTS',
  INVOICES = 'INVOICES'
}

export enum VoucherTabs {
  LIST = 'List',
  SUMMARY = 'Summary'
}
