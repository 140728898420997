/* eslint-disable no-console */
import styled from '@emotion/styled'
import React, { LegacyRef, useEffect, useRef, useState } from 'react'
import TextContainer from '../../components/TextContainer'
import Shadows from '../../figma/tokens/Shadows'
import { AntiloopTextType } from '../../tiger/interfaces/Antiloop'
import FigmaBox from './FigmaBox'

const StyledContainer = styled(FigmaBox)`
  width: 100%;
  position: relative;
  cursor: pointer;
`

const ValueContainer = styled.span<{ active?: boolean; activeTextColor: string; inactiveTextColor: string }>`
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  user-select: none;

  span {
    transition: color 200ms ease;
    color: ${(props) => (props.active ? props.activeTextColor : props.inactiveTextColor)} !important;
  }
`

type RateSelectorItemProps = {
  value: any
  labelTextKey: AntiloopTextType
  labelText?: string
  onClick?: (value: any) => void
}

type InternalRateSelectorItemProps = {
  _onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  _active: boolean
  _activeTextColor?: string
  _inactiveTextColor?: string
}

const Item = React.forwardRef<HTMLDivElement, RateSelectorItemProps>(({ labelTextKey, labelText, ...props }, ref) => {
  const internalProps = props as unknown as InternalRateSelectorItemProps

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    props.onClick?.(props.value)
    internalProps._onClick?.(event)
  }

  useEffect(() => {
    if (props.value === undefined)
      console.warn(`[RateSelector] ${props.value} was passed to a RateSelector.Item which is not a valid value`)
  }, [props.value])

  return (
    <StyledContainer align="center" justify="center" onClick={onClick} ref={ref} {...props}>
      <ValueContainer
        activeTextColor={internalProps._activeTextColor || '#000000'}
        inactiveTextColor={internalProps._inactiveTextColor || '#78787A'}
        active={internalProps._active}
      >
        <TextContainer textKey={labelTextKey} text={labelText} />
      </ValueContainer>
    </StyledContainer>
  )
})

Item.displayName = 'RateSelectorItem'

const Cursor = styled.div`
  height: 100%;
  width: unset;
  padding: 0;
  top: 50%;
  left: 0;
  position: absolute;
  background-color: ${(props) => props.color || 'white'};
  z-index: 15;
  transform: translateY(-50%) scale(0.9, 0.75);
  transition: left ease;
  transform-origin: left;
  border-radius: 8px;
  box-shadow: ${Shadows.baseSoft};
`

type RateSelectorProps = {
  onValueChange?: (value: any) => void
  children: JSX.Element[]
  value?: any
  disableControlledValueChangedEvent?: boolean
  activeTextColor?: string
  inactiveTextColor?: string
  cursorColor?: string
  optionColor?: string
  defaultValue?: any
}

const RateSelector = ({
  onValueChange,
  value,
  disableControlledValueChangedEvent,
  defaultValue,
  children,
  ...props
}: RateSelectorProps) => {
  const refs = useRef<HTMLDivElement[]>([])
  const cursorRef = useRef<HTMLDivElement>()

  const [activeIndex, setActiveIndex] = useState<number>()

  const updateCursor = (cursor: HTMLDivElement, target: HTMLDivElement, index: number) => {
    const targetRect = target.getBoundingClientRect()

    cursor.style.width = `${targetRect.width}px`

    const cursorRect = cursor.getBoundingClientRect()

    const targetCenter = targetRect.width / 2
    const cursorCenter = cursorRect.width / 2

    const centerToTarget = targetRect.width * index + targetCenter - cursorCenter

    setActiveIndex(index)

    cursor.style.left = `${centerToTarget}px`
    setTimeout(() => (cursor.style.transitionDuration = '200ms'), 100)
  }

  const onClick = (index: number, el: JSX.Element, synthetic?: boolean) => (e: React.MouseEvent<HTMLDivElement>) => {
    if (!cursorRef.current) {
      console.warn('[RateSelector] Cursor ref was not attached, unable to render cursor properly')
      return onValueChange?.(el.props.value)
    }

    // If a custom value is passed, dont rerender the cursor unless its controlled change event (as in its synthetic)
    if (value !== undefined && !synthetic) {
      return onValueChange?.(el.props.value)
    }

    updateCursor(cursorRef.current, e.currentTarget, index)

    if (synthetic) {
      return !disableControlledValueChangedEvent && onValueChange?.(el.props.value)
    }

    onValueChange?.(el.props.value)
  }

  const addRef = (index) => (ref) => (refs.current[index] = ref)

  useEffect(() => {
    if (value === undefined) return

    let index

    const element = children.find((el, i) => {
      index = i
      return el.props.value === value
    })

    if (!element) {
      const possibleValues = children.map((c) => c.props.value).join(', ')

      throw new Error(
        `Invalid value ${value} passed to RateSelector, the RateSelector.Item children has the following values [${possibleValues}]`
      )
    }

    onClick(index, element, true)({ currentTarget: refs.current[index] } as React.MouseEvent<HTMLDivElement>)
  }, [value])

  useEffect(() => {
    if (defaultValue !== undefined) {
      const index = children.findIndex((child) => child.props.value === defaultValue)
      const target = refs.current[index]

      onClick(index, children[index], true)({ currentTarget: target } as React.MouseEvent<HTMLDivElement>)
    }
  }, [defaultValue])

  return (
    <StyledGrid optionColor={props.optionColor || '#f8f8f7'} columns={children.length}>
      {children.map((el, index) =>
        React.cloneElement(el, {
          ref: addRef(index),
          _onClick: onClick(index, el),
          _active: index === activeIndex,
          _activeTextColor: props.activeTextColor,
          _inactiveTextColor: props.inactiveTextColor
        })
      )}
      <Cursor color={props.cursorColor} ref={cursorRef as LegacyRef<HTMLDivElement>} />
    </StyledGrid>
  )
}

const StyledGrid = styled.div<{ columns: number; optionColor: string }>`
  border-radius: 8px;
  background-color: ${(props) => props.optionColor};
  height: 40px;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: ${(props) =>
    Array(props.columns)
      .fill(0)
      .map(() => '1fr')
      .join(' ')};
  grid-template-rows: 1fr;
`

RateSelector.Item = Item

export default RateSelector
