/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CreditState = {
    REQUEST: 'REQUEST',
    APPROVED: 'APPROVED',
    CUSTOMER_ACCEPTED: 'CUSTOMER_ACCEPTED',
    PAID_OUT: 'PAID_OUT',
    REPAID: 'REPAID',
    NETTED: 'NETTED',
    DEFAULT: 'DEFAULT',
    DENIED: 'DENIED',
    CLOSED: 'CLOSED'
} as const;

export type CreditState = typeof CreditState[keyof typeof CreditState];



