import React from 'react'
import {
  _TooltipAccountStatusCompanyCredit,
  _TooltipAccountStatusGenericCircle,
  _TooltipCardPETabGenerateAndSend,
  _TooltipChangeAccountStatus,
  _TooltipCustomerInvoices,
  _TooltipCustomerSubscriptionFee,
  _TooltipInvoiceInformationPDF,
  _TooltipKYCBeneficialOwnersControlTypes,
  _TooltipRefreshAddress,
  _TooltipResetAllOverrides,
  _TooltipResetOverride,
  _TooltipValueOverridden
} from './TooltipsComponents'

export const TooltipAccountStatusGenericCircle = React.memo(_TooltipAccountStatusGenericCircle)
export const TooltipAccountStatusCompanyCredit = React.memo(_TooltipAccountStatusCompanyCredit)
export const TooltipCardPETabGenerateAndSend = React.memo(_TooltipCardPETabGenerateAndSend)
export const TooltipInvoiceInformationPDF = React.memo(_TooltipInvoiceInformationPDF)
export const TooltipKYCBeneficialOwnersControlTypes = React.memo(_TooltipKYCBeneficialOwnersControlTypes)
export const TooltipChangeAccountStatus = React.memo(_TooltipChangeAccountStatus)
export const TooltipResetAllOverrides = React.memo(_TooltipResetAllOverrides)
export const TooltipResetOverride = React.memo(_TooltipResetOverride)
export const TooltipValueOverridden = React.memo(_TooltipValueOverridden)
export const TooltipCustomerInvoices = React.memo(_TooltipCustomerInvoices)
export const TooltipCustomerSubscriptionFee = React.memo(_TooltipCustomerSubscriptionFee)
export const TooltipRefreshAddress = React.memo(_TooltipRefreshAddress)
