import TextContainer from '../components/TextContainer'
import dayjs from 'dayjs'
import useLanguage from 'flamingo/hooks/useLanguage'
import { Formik, FormikHelpers } from 'formik'
import { YYYY_MM_DD } from 'mynt-components/Constants'
import FigmaBox from 'mynt-components/components/FigmaBox'
import FigmaButton from 'mynt-components/components/FigmaButton'
import MaterialDatePickerFormik from 'mynt-components/components/MaterialDatePickerFormik'
import MaterialFieldFormik from 'mynt-components/components/MaterialFieldFormik'
import ModalExperimental from 'mynt-components/components/ModalExperimental'
import React from 'react'
import { TextKeys, getText } from 'tiger/libs/TextRepository'
import * as Yup from 'yup'
import { AccountXYear } from '../api/swagger/definitions/backoffice'
import Spacings from '../figma/tokens/Spacings'
import { useCreateAccountingYear, useUpdateAccountingYear } from '../api/react-query'
import { captureException } from '../helpers/sentryHelpers'
import MaterialSwitchFormik from '../mynt-components/components/MaterialSwitchFormik'
import ModalExperimentalFooter from '../mynt-components/components/ModalExperimentalFooter'
import ModalExperimentalHeader from '../mynt-components/components/ModalExperimentalHeader'

export enum YearFormTypes {
  CREATE = 'create',
  EDIT = 'edit'
}

interface YearFormProps {
  isOpen: boolean
  type: YearFormTypes
  values?: AccountXYear
  onModalCancel: () => void
}

export const _ModalAccountingYear: React.FC<YearFormProps> = ({ isOpen, type, values, onModalCancel }) => {
  const createAccountingYearMutation = useCreateAccountingYear()
  const updateAccountingYearMutation = useUpdateAccountingYear(values?.id as string)
  const language = useLanguage()

  const initialValues: AccountXYear = {
    name: values?.name || '',
    fromDate: values?.fromDate || dayjs().format(YYYY_MM_DD),
    toDate: values?.toDate || dayjs().format(YYYY_MM_DD),
    default: values?.default || false
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().typeError(getText(TextKeys.createYearNameError, language)).required(getText(TextKeys.createYearNameError, language)),
    fromDate: Yup.string()
      .typeError(getText(TextKeys.createYearFromDateError, language))
      .required(getText(TextKeys.createYearFromDateError, language)),
    toDate: Yup.string()
      .typeError(getText(TextKeys.createYearToDateError, language))
      .required(getText(TextKeys.createYearToDateError, language)),
    default: Yup.boolean()
      .typeError(getText(TextKeys.mFRegisterPaymentDecriptionError, language))
      .required(getText(TextKeys.mFRegisterPaymentDecriptionError, language))
  })

  const onSubmit = async (values: AccountXYear, initialValues: AccountXYear, formikHelpers: FormikHelpers<AccountXYear>) => {
    if (type === YearFormTypes.EDIT && !!initialValues) {
      return updateAccountingYearMutation
        .mutateAsync(values)
        .then((res) => {
          onModalCancel()
          return res
        })
        .catch((err) => captureException(err))
    }

    if (type === YearFormTypes.CREATE) {
      return createAccountingYearMutation
        .mutateAsync(values)
        .then((res) => {
          onModalCancel()
          return res
        })
        .catch((err) => captureException(err))
    }
  }
  return (
    <>
      {isOpen && (
        <ModalExperimental
          onClose={onModalCancel}
          backOfficeStyling
          heading={() => (
            <ModalExperimentalHeader>
              <TextContainer textKey={type === YearFormTypes.EDIT ? TextKeys.editYearHeading : TextKeys.createYearHeading} />
            </ModalExperimentalHeader>
          )}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values: AccountXYear, formikHelpers: FormikHelpers<AccountXYear>) =>
              onSubmit(values, initialValues, formikHelpers)
            }
          >
            {(formProps) => (
              <form name="modal_years" onSubmit={formProps.handleSubmit}>
                <FigmaBox
                  fullWidth
                  direction="column"
                  justify="space-between"
                  align="flex-start"
                  gap={Spacings.large}
                  top={Spacings.large}
                  bottom={Spacings.large}
                  left={Spacings.medium}
                  right={Spacings.medium}
                >
                  <FigmaBox fullWidth direction="row" align="flex-start" justify="space-between" gap={Spacings.medium}>
                    <FigmaBox fullWidth>
                      <MaterialDatePickerFormik
                        fullWidth
                        name={'fromDate'}
                        inputFormat={YYYY_MM_DD}
                        formProps={formProps}
                        placeHolderTextKey={TextKeys.createYearFromDateLabelPlaceholder}
                        labelTextKey={TextKeys.createYearFromDateLabel}
                        value={formProps.values['fromDate'] as unknown as dayjs.Dayjs}
                        onChange={(date) => formProps.setFieldValue('fromDate', dayjs(date).format(YYYY_MM_DD))}
                      />
                    </FigmaBox>
                    <FigmaBox fullWidth>
                      <MaterialDatePickerFormik
                        fullWidth
                        name={'toDate'}
                        inputFormat={YYYY_MM_DD}
                        labelTextKey={TextKeys.createYearToDateLabel}
                        placeHolderTextKey={TextKeys.createYearToDatePlaceholder}
                        formProps={formProps}
                        value={formProps.values['toDate'] as unknown as dayjs.Dayjs}
                        onChange={(date) => formProps.setFieldValue('toDate', dayjs(date).format(YYYY_MM_DD))}
                      />
                    </FigmaBox>
                  </FigmaBox>

                  <FigmaBox fullWidth direction="row" align="flex-start" justify="space-between" gap={Spacings.medium}>
                    <FigmaBox flex="0.5">
                      <MaterialFieldFormik
                        fullWidth
                        name="name"
                        formProps={formProps}
                        labelTextKey={TextKeys.createYearNameLabel}
                        placeHolderTextKey={TextKeys.createYearNamePlaceholder}
                      />
                    </FigmaBox>
                    <FigmaBox />
                  </FigmaBox>

                  <FigmaBox fullWidth direction="row" align="flex-start" justify="space-between" gap={Spacings.medium}>
                    <FigmaBox flex="0.5" justify="space-between" direction="row">
                      <FigmaBox direction="column">
                        <TextContainer textKey={TextKeys.createYearNameDeafaultHeading} />
                        <TextContainer textKey={TextKeys.createYearNameDeafaultBody} />
                      </FigmaBox>
                      <MaterialSwitchFormik name="default" formProps={formProps} />
                    </FigmaBox>
                    <FigmaBox />
                  </FigmaBox>
                </FigmaBox>

                <ModalExperimentalFooter>
                  <FigmaBox direction="row" fullWidth align="flex-start" justify="flex-end" gap={Spacings.tiny}>
                    <FigmaButton
                      fullWidth={false}
                      variant="tertiary"
                      textKey={TextKeys.createYearNameButtonCancel}
                      onClick={onModalCancel}
                    />
                    <FigmaButton fullWidth={false} textKey={TextKeys.editYearButtonSave} />
                  </FigmaBox>
                </ModalExperimentalFooter>
              </form>
            )}
          </Formik>
        </ModalExperimental>
      )}
    </>
  )
}
