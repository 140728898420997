const EtaskuLogo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        version="1.1"
        id="svg2"
        width="753.94666"
        height="200.50667"
        viewBox="0 0 753.94666 200.50667"
        {...props}
    >
        <defs id="defs6" />
        <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,200.50667)">
            <g id="g10" transform="scale(0.1)">
                <path d="M 249.258,640.629 H 671.543 V 821.91 c -32.969,18.539 -76.211,26.778 -129.785,26.778 -135.938,0 -253.36,-88.575 -292.5,-208.059 z m 661.23,-492.32 C 910.488,148.309 776.602,0 541.758,0 243.066,0 0,245.121 0,545.875 c 0,302.813 243.066,545.885 541.758,545.885 142.148,0 267.793,-53.56 364.609,-142.135 V 444.941 H 251.309 C 292.52,327.52 407.871,243.07 541.758,243.07 c 131.836,0 187.461,70.032 187.461,70.032 L 910.488,148.309" fill="#040606" fillOpacity={1} fillRule="nonzero" stroke={"none"} id="path12" />
                <path d="M 1996.11,1225.66 H 1575.88 V 30.8906 H 1303.96 V 1225.66 H 883.75 v 247.19 h 1112.36 v -247.19" fill="#040606" fillOpacity={1} fillRule="nonzero" stroke={"none"} id="path14" />
                <path d="m 2447.25,418.16 v 84.461 c -96.82,-57.68 -257.48,-41.199 -346.06,-94.75 V 273.969 c 47.36,-32.957 100.94,-51.5 160.67,-51.5 98.88,0 185.39,51.5 185.39,195.691 z m 370.8,-150.371 V 30.8906 h -61.8 c -96.82,0 -181.29,43.2696 -238.96,111.2384 C 2453.44,57.6719 2340.14,0 2220.66,0 2066.17,0 1967.29,65.9102 1868.42,170.969 v 341.945 c 61.79,82.402 224.53,111.242 352.24,131.836 218.36,35.02 226.59,84.465 226.59,127.723 0,61.797 -70.04,96.812 -181.27,96.812 -175.1,0 -218.36,-131.828 -218.36,-131.828 l -201.86,115.352 c 70.02,140.078 189.51,238.951 420.22,238.951 292.52,0 438.77,-129.772 438.77,-356.365 V 370.789 c 0,-57.687 45.31,-103 102.98,-103 h 10.32" fill="#040606" fillOpacity={1} fillRule="nonzero" stroke={"none"} id="path16" />
                <path d="m 3246.56,869.285 c -63.86,0 -105.06,-32.957 -105.06,-80.336 0,-47.383 28.85,-80.34 80.34,-100.937 l 189.51,-76.219 C 3526.72,564.418 3637.95,490.262 3637.95,306.93 3637.95,133.891 3489.63,0 3263.05,0 2939.63,0 2857.23,238.949 2857.23,238.949 l 224.53,113.289 c 0,0 35.02,-125.648 162.73,-125.648 94.77,0 129.79,41.199 129.79,86.512 0,41.199 -22.66,82.398 -100.94,113.296 l -156.56,61.801 C 2947.87,554.117 2884,663.297 2884,809.551 c 0,144.195 142.15,282.209 341.96,282.209 263.67,0 370.8,-222.475 370.8,-222.475 L 3394.88,741.566 c 0,0 -47.38,127.719 -148.32,127.719" fill="#040606" fillOpacity={1} fillRule="nonzero" stroke={"none"} id="path18" />
                <path d="m 4173.54,440.828 c -45.34,-20.609 -94.77,-37.078 -144.2,-49.449 V 30.8906 H 3775.96 V 1503.75 h 253.38 V 642.691 c 100.93,35.02 189.51,92.704 265.72,166.86 v 251.309 h 253.38 V 710.668 C 4501.05,657.113 4449.55,609.73 4393.95,566.48 L 4684.39,30.8906 H 4396 L 4173.54,440.828" fill="#040606" fillOpacity={1} fillRule="nonzero" stroke={"none"} id="path20" />
                <path d="M 5654.65,1060.86 V 30.8906 h -236.9 l -20.6,84.4614 C 5339.47,45.3203 5250.9,0 5131.43,0 c -201.88,0 -370.8,183.328 -370.8,370.789 v 690.071 h 257.5 V 432.582 c 0,-98.871 72.08,-185.402 175.09,-185.402 90.63,0 158.62,16.492 203.93,100.941 v 712.739 h 257.5" fill="#040606" fillOpacity={1} fillRule="nonzero" stroke={"none"} id="path22" />
            </g>
        </g>
    </svg>
)

export default EtaskuLogo;