import { useContext } from 'react'
import Spacings from '../figma/tokens/Spacings'
import { useCardCredits } from '../api/react-query'
import { CustomerIdContext } from '../helpers/CreditOnCardHelpers'
import FigmaBox from '../mynt-components/components/FigmaBox'
import MyntLoader from '../mynt-components/components/MyntLoader'
import CreditOnCardCreditRequestView from './CreditOnCardCreditRequestView'

const CreditOnCardCreditRequest = () => {
  const customerId = useContext(CustomerIdContext)
  const { data: credits, isLoading } = useCardCredits(customerId)

  if (!credits && isLoading) {
    return <MyntLoader />
  }

  if (!credits) {
    return null
  }

  return (
    <FigmaBox fullWidth gap={Spacings.small}>
      {credits.map((credit) => (
        <CreditOnCardCreditRequestView key={credit.id} credit={credit} />
      ))}
    </FigmaBox>
  )
}

export default CreditOnCardCreditRequest
