import { FilterContextProvider } from '../context/InboxFilterContext'
import InboxContainer from './InboxContainer'

const InboxPage = () => (
  <div style={{ marginTop: 40 }}>
    <FilterContextProvider>
      <InboxContainer />
    </FilterContextProvider>
  </div>
)

export default InboxPage
