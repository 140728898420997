import styled from '@emotion/styled'
import { captureException } from 'helpers/sentryHelpers'
import React from 'react'
import { colors } from 'themes'
import Button from '@mui/material/Button'
import RefreshIcon from '@mui/icons-material/Refresh'

const iconSrc = 'https://media.tenor.com/qiP7_BYUpo0AAAAi/this-is-fine.gif'

class ErrorBoundary extends React.Component {
  state = { error: null }

  componentDidCatch(error: any, info) {
    captureException(error, info?.componentStack)
    this.setState({ error })
  }

  render() {
    if (this.state.error) {
      const ErrorOverlay = customElements.get('vite-error-overlay')

      if (ErrorOverlay) {
        document.body.appendChild(new ErrorOverlay(this.state.error))
      }

      return (
        <Container>
          <Flex>
            <Title>{'Unfortunately an error happened 😔'}</Title>
          </Flex>
          <Flex>
            <Button onClick={() => window.location.reload()} startIcon={<RefreshIcon />} variant="primary">
              Reload the page
            </Button>
          </Flex>
        </Container>
      )
    }

    return this.props.children
  }
}

const Flex = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  gap: 2rem;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
`

const Title = styled.h1`
  margin: 0;
  font-family: Inter;
  color: ${colors.product.black['100']};
`

export default ErrorBoundary
