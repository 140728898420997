import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

interface IProps {
  render(): React.ReactNode
  showLoader: boolean
}

const LoaderRenderProp = ({ render, showLoader }: IProps) => {
  if (showLoader) {
    return <CircularProgress sx={{ position: 'absolute', left: '50%', top: '25%' }} />
  }

  return <>{render()}</>
}

export default LoaderRenderProp
