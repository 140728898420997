import styled from '@emotion/styled'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import TextField from '@mui/material/TextField'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import React from 'react'
import { PEReport } from '../api/react-query/interfaces/mynt'
import { PE_REPORTS } from '../api/react-query/keys'
import TextContainer from '../components/TextContainer'
import Spacings from '../figma/tokens/Spacings'
import { usePEReports } from '../api/react-query'
import { postPEReports } from '../api'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { TooltipCardPETabGenerateAndSend } from './Tooltips'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

type Props = { customerId: string }
export default function CardPETab({ customerId }: Props) {
  const [date, setDate] = React.useState(dayjs())
  const { data = [] } = usePEReports(customerId)
  const queryClient = useQueryClient()

  const onCreatePEReport = async () =>
    postPEReports(customerId, {
      month: date.month() + 1, // dayjs is 0 indexed [0-11], server is [1-12]
      year: date.year()
    }).then(async () => queryClient.invalidateQueries([PE_REPORTS]))

  const columns: GridColDef[] = [
    {
      headerName: TextKeys.generatedAt.characters,
      field: 'createdAt',
      flex: 1,
      renderCell: ({ value }) => dayjs(value * 1000).format()
    },
    {
      headerName: TextKeys.peReconYear.characters,
      field: 'year',
      flex: 1
    },
    {
      headerName: TextKeys.peReconMonth.characters,
      field: 'month',
      flex: 1
    },
    {
      headerName: TextKeys.peReconCreateId.characters,
      field: 'peMonthlySummaryCreateId',
      flex: 1
    },
    {
      headerName: 'File',
      field: '',
      flex: 1,
      renderCell: ({ row }) => {
        const { year, month, id, data } = row as PEReport

        const fileName = `${year}_${month}_${id}.xml`
        return <InsertDriveFileIcon onClick={() => downloadTxtFile(data, fileName)} />
      }
    }
  ]

  return (
    <FigmaBox fullWidth spacing={Spacings.medium} fullPadding>
      <FigmaBox direction="row" align="center" gap={Spacings.small}>
        <FigmaBox>
          <DesktopDatePicker
            inputFormat="YYYY MM"
            views={['year', 'month']}
            value={date}
            onChange={setDate as any}
            renderInput={(props) => <FixedDesktopDatePickerInput {...props} label="Year and Month" />}
          />
        </FigmaBox>
        <FigmaBox>
          <TooltipCardPETabGenerateAndSend onCreatePEReport={onCreatePEReport} />
        </FigmaBox>
      </FigmaBox>
      <FigmaBox fullWidth top bottom spacing={Spacings.medium}>
        <Box sx={{ height: data.length === 0 ? '200px' : '' }}>
          <DataGridPro
            disableColumnFilter
            disableColumnMenu
            slots={{
              noRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  No PE reports found
                </Stack>
              )
            }}
            columns={columns}
            rows={data}
          />
        </Box>
        {/* <Table columns={columns} dataSource={data} rowKey="id" className="small" pagination={false} /> */}
      </FigmaBox>
      <InfoContainer top={Spacings.max}>
        <FigmaBox gap={Spacings.min} top={Spacings.max}>
          <TextContainer textKey={TextKeys.peReconInfoHeader} />
          <TextContainer textKey={TextKeys.peReconInfoText} />
        </FigmaBox>
      </InfoContainer>
    </FigmaBox>
  )
}

const downloadTxtFile = (data: string, fileName: string) => {
  const element = document.createElement('a')
  //@ts-exepct-error
  const file = new Blob([data], {
    type: 'text/xml'
  })

  element.href = URL.createObjectURL(file)
  element.download = fileName
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

const InfoContainer = styled(FigmaBox)`
  width: 50%;
`

const FixedDesktopDatePickerInput = styled(TextField)`
  button {
    margin-right: ${Spacings.minimum};
  }
`
