import OpenInNew from '@mui/icons-material/OpenInNew'
import {
  DataGridPro,
  GridActionsCellItem,
  GridApiPro,
  GridCellParams,
  GridFilterItem,
  GridFilterOperator,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro'
import { useMemo, useRef } from 'react'
import FigmaBox from '../../mynt-components/components/FigmaBox'
import Spacings from '../../figma/tokens/Spacings'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Access, usePermissions } from 'contexts/permissions'

const createSelectOperator = (label: string, options: string[]): GridFilterOperator => ({
  label: label,
  value: 'customArrayContains',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    const filter = Array.from(filterItem.value)

    if (filter.length === 0) return null

    return (params: GridCellParams) => filter.includes(params.value)
  },
  InputComponent: ({ applyValue, ...params }) => (
    <Box sx={{ width: '100%', paddingTop: 1 }}>
      <Autocomplete
        onChange={(event, value) => {
          applyValue({ ...params.item, value })
        }}
        value={params.item.value}
        multiple
        fullWidth
        options={options}
        renderTags={(values) => <span>{values.length} selected</span>}
        renderInput={(props) => <TextField variant="standard" {...props} label={params.label} />}
      />
    </Box>
  ),
  headerLabel: label
})

export type Card = {
  owner: string
  number: string
  status: string
  type: string
  id: string
  externalId: string
  personId?: string
  address?: string
  singleAmountLimit?: number
}

export type OverviewTableProps = {
  cards: Card[]
  onRowOpen?: (card: Card) => void
  onRowSelect?: (cards: string[]) => void
  onDelete?: (cards: string[]) => void
  onUserInfoCopy?: (rows: Card[]) => void
  selectedRows?: string[]
  initialFilters?: {
    field: string
    value: string | string[]
  }[]
}

const getUniqueOptions = (cards: Card[], field: keyof Card) => Array.from(new Set(cards.map((card) => card[field]))) as string[]

export const CardOverviewTable = ({
  cards,
  onRowOpen,
  onRowSelect,
  onDelete,
  onUserInfoCopy,
  selectedRows,
  initialFilters
}: OverviewTableProps) => {
  const apiRef = useRef<GridApiPro>({} as GridApiPro)

  const statusOptions = useMemo(() => getUniqueOptions(cards, 'status'), [cards])
  const typeOptions = useMemo(() => getUniqueOptions(cards, 'type'), [cards])

  const permissions = {
    details: usePermissions('customers.cards.overview.details')
  }

  return (
    <DataGridPro
      unstable_headerFilters
      columnVisibilityModel={{ actions: permissions.details }}
      pagination
      checkboxSelection
      disableRowSelectionOnClick
      onRowSelectionModelChange={(cards) => {
        onRowSelect?.(cards.map((card) => card.toString()))
      }}
      onRowDoubleClick={({ row }) => onRowOpen?.(row)}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
        filter: {
          filterModel: {
            items: initialFilters?.map(({ field, value }, index) => ({ id: index, field, value, operator: 'customArrayContains' })) ?? []
          }
        }
      }}
      rowSelectionModel={selectedRows}
      pageSizeOptions={[10, 25, 50]}
      apiRef={apiRef}
      rows={cards}
      slots={{
        headerFilterMenu: null,
        toolbar: () => (
          <FigmaBox
            fullPadding
            align="baseline"
            spacing={Spacings.tiny}
            fullWidth
            justify={onDelete ? 'space-between' : 'flex-end'}
            direction="row"
          >
            <FigmaBox align="center" direction="row" gap="2rem">
              <Access permissions="customers.cards.overview.terminate">
                {onDelete && selectedRows && (
                  <FigmaBox>
                    <Button
                      data-testid="delete-cards-button"
                      size="small"
                      disabled={selectedRows.length === 0}
                      onClick={() => onDelete(selectedRows)}
                      variant="contained"
                      color={'error'}
                    >
                      Terminate {selectedRows.length} cards
                    </Button>
                  </FigmaBox>
                )}
              </Access>
              <FigmaBox>
                {onUserInfoCopy && selectedRows && (
                  <Button
                    onClick={() => onUserInfoCopy?.(cards.filter((card) => selectedRows?.includes(card.id)))}
                    variant="tertiary"
                    disabled={selectedRows.length === 0}
                    data-testid="copy-cards-button"
                  >
                    Copy {selectedRows?.length} names and card numbers
                  </Button>
                )}
              </FigmaBox>
            </FigmaBox>
            <FigmaBox>
              <GridToolbarQuickFilter />
            </FigmaBox>
          </FigmaBox>
        )
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true
        }
      }}
      columns={[
        {
          field: 'owner',
          headerName: 'Owner',
          flex: 1.5
        },
        {
          field: 'number',
          headerName: 'Card number',
          flex: 0.75
        },
        {
          field: 'status',
          headerName: 'Status',
          flex: 1.5,
          filterOperators: [createSelectOperator('Status', statusOptions)]
        },
        {
          field: 'type',
          headerName: 'Type',
          flex: 1.5,
          filterOperators: [createSelectOperator('Type', typeOptions)]
        },
        {
          field: 'externalId',
          headerName: 'Enfuce ID',
          flex: 1
        },
        {
          field: 'address',
          headerName: 'Delivery address',
          flex: 1.25
        },
        {
          field: 'actions',
          type: 'actions',
          getActions: ({ row }) => [<GridActionsCellItem key={1} icon={<OpenInNew />} label="Delete" onClick={() => onRowOpen?.(row)} />]
        }
      ]}
    />
  )
}
