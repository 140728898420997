import styled from '@emotion/styled'
import isEqual from 'fast-deep-equal'
import React from 'react'
import { BackOfficeCustomerDtoV2, KycAccessLevel } from '../api/swagger/definitions/backoffice'
import TextContainer from '../components/TextContainer'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import { AccountStatuses, accountStatusToColor, accountStatusTooltipsComponents, AccountStatusTypes } from '../helpers/AccountStatusHelper'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { AntiloopTextType } from '../tiger/interfaces/Antiloop'
import { TextKeys } from '../tiger/libs/TextRepository'

export type SectionProps = {
  textKey: AntiloopTextType
  statuses: KycAccessLevel[]
  hideBorder?: boolean
  type: string
  customer: BackOfficeCustomerDtoV2
  hideRightPadding?: boolean
}

const Section: React.FC<SectionProps> = ({ textKey, statuses, hideBorder, type, customer, hideRightPadding }) => (
  <SectionContainer hideBorder={hideBorder} align="center" justify="center" spacing={Spacings.tiny} left right={!hideRightPadding}>
    {/* textWrap does not exist in Reaxt.CSSProperties ?? */}
    <FigmaBox style={{ textWrap: 'nowrap' } as React.CSSProperties} spacing={Spacings.min} bottom>
      <TextContainer textKey={textKey} />
    </FigmaBox>
    <FigmaBox justify="space-between" direction="row" gap={Spacings.min}>
      {!!statuses &&
        statuses.map((status, index) => {
          const ToolTip = accountStatusTooltipsComponents[type]?.[index]

          if (!ToolTip) return null

          return <ToolTip key={`tooltip-${index}`} customer={customer} color={accountStatusToColor[status]} />
        })}
    </FigmaBox>
  </SectionContainer>
)

const AccountStatus: React.FC<{
  customer: BackOfficeCustomerDtoV2
  statuses: AccountStatuses
}> = ({ statuses, customer }) => (
  <FigmaBox direction="row" spacing={Spacings.tiny} top left bottom>
    <Section customer={customer} type={AccountStatusTypes.PRODUCTS} statuses={statuses.products} textKey={TextKeys.infoStatusProducts} />
    <Section customer={customer} type={AccountStatusTypes.KYC} statuses={statuses.kyc} textKey={TextKeys.KYC} />
    <Section
      customer={customer}
      type={AccountStatusTypes.CARD_FEATURES}
      statuses={statuses.cardFeatures}
      textKey={TextKeys.infoStatusCardFeatures}
    />
    <Section
      customer={customer}
      type={AccountStatusTypes.CARD_ACTIVATIO_STATUS}
      statuses={statuses.cardActivationStatus}
      textKey={TextKeys.infoStatusCardActivationStatus}
      hideBorder
    />
  </FigmaBox>
)

export default React.memo(AccountStatus, isEqual)

const SectionContainer = styled(FigmaBox)<{ hideBorder?: boolean }>`
  border-right: 2px solid ${({ hideBorder }) => (hideBorder ? 'transparent' : ColorsDeprecated.baseStone)};
`
