import styled from '@emotion/styled'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import { Field, FormikProps } from 'formik'
import React, { useMemo } from 'react'
import NumberFormat from 'react-number-format'
import Colors from '../../figma/panda/Colors'
import BorderRadius from '../../figma/tokens/BorderRadius'
import Spacings from '../../figma/tokens/Spacings'
import { ConfigCurrency, Currencies } from '../../flamingo/config/ConfigCurrencies'
import useConfigByCountry from '../../flamingo/hooks/useConfigByCountry'
import useConfigByCurrency from '../../flamingo/hooks/useConfigByCurrency'
import { getText } from '../../helpers/getText'
import { useLanguage } from '../../hooks/useLanguage'
import { AntiloopTextType } from '../../tiger/interfaces/Antiloop'
import useTextVariant from '../hooks/useTextVariant'
import FigmaBox from './FigmaBox'
import MyntLoader from './MyntLoader'
import placeholderGenericV2 from './placeholderGenericV2'

export type MaterialNumberFormatProps = {
  name: string
  error?: string
  adornment?: string
  className?: string
  disabled?: boolean
  labelText?: string
  fieldWidth?: number
  fullWidth?: boolean
  loading?: boolean
  value?: string | number
  formProps: FormikProps<any>
  defaultValue?: string | number
  labelTextKey?: AntiloopTextType
  placeHolderTextKey?: AntiloopTextType
  onBlur?: (event: React.ChangeEvent<any>) => void
  onChange?: (event: React.ChangeEvent<any>) => void
  validate?: (value: string | number, formProps?: FormikProps<any>) => string
}

const getFieldAdornment = (currencyConfig: ConfigCurrency, adornment?: string) => {
  const fieldAdornment: {
    startAdornment: string | undefined
    endAdornment: string | undefined
  } = {
    startAdornment: undefined,
    endAdornment: undefined
  }

  if (adornment) {
    if (adornment in Currencies) {
      if (currencyConfig?.startAdornment) {
        fieldAdornment.startAdornment = currencyConfig.startAdornment
      }
      if (currencyConfig?.endAdornment) {
        fieldAdornment.endAdornment = currencyConfig.endAdornment
      }
    } else {
      fieldAdornment.endAdornment = adornment
    }
  }
  return fieldAdornment
}

const MaterialNumberFormatFormik: React.FC<React.PropsWithChildren<React.PropsWithChildren<MaterialNumberFormatProps>>> = ({
  name,
  value,
  onBlur,
  children,
  disabled,
  onChange,
  validate,
  loading,
  adornment,
  className,
  formProps,
  fullWidth,
  labelText,
  fieldWidth,
  defaultValue,
  labelTextKey,
  placeHolderTextKey,
  error: errorMessage,
  ...rest
}) => {
  const language = useLanguage()
  const countryConfig = useConfigByCountry()
  const currencyConfig = useConfigByCurrency()

  const textVariant = useTextVariant(placeholderGenericV2)
  const placeholder = !!placeHolderTextKey && getText(placeHolderTextKey, language)
  const label = labelText ? labelText : !!labelTextKey && getText(labelTextKey, language)
  const fieldAdornment = useMemo(() => getFieldAdornment(currencyConfig, adornment), [currencyConfig, adornment])

  return (
    <FigmaBox fullWidth={fullWidth} {...rest}>
      <StyledOutlinedInput
        name={name}
        variant="outlined"
        disabled={disabled}
        allowNegative={true}
        className={className}
        fullWidth={fullWidth}
        isNumericString={true}
        customInput={TextField}
        component={NumberFormat}
        fixedDecimalScale={true}
        defaultValue={defaultValue}
        allowEmptyFormatting={false}
        decimalSeparator={countryConfig?.separators?.decimals}
        thousandSeparator={countryConfig?.separators?.thousands}
        label={label ? label : undefined}
        value={value ?? formProps.values[name]}
        style={{ ...textVariant.style, width: fieldWidth }}
        sx={{ '.MuiOutlinedInput-root': { paddingLeft: 0 } }}
        placeholder={placeholder && !value ? placeholder : undefined}
        validate={(value: string | number) => !!validate && validate(value, formProps)}
        InputProps={{
          startAdornment: fieldAdornment.startAdornment && <Adornment>{fieldAdornment.startAdornment}</Adornment>,
          endAdornment: loading ? (
            <MyntLoader variant="small" style={{ paddingRight: Spacings.tiny }} />
          ) : (
            fieldAdornment.endAdornment && <Adornment>{fieldAdornment.endAdornment}</Adornment>
          )
        }}
        error={!!errorMessage || (!!formProps.errors && formProps.errors[name] && formProps.touched[name])}
        onBlur={({ target: { value } }) => {
          const event = { target: { value: value.split(countryConfig?.separators?.thousands).join('') || '' } } as any
          onBlur ? onBlur(event) : formProps.handleBlur(name)(event)
        }}
        onValueChange={({ value }) => {
          const event = { target: { value: value.split(countryConfig?.separators?.thousands).join('') || '' } } as any
          onChange ? onChange(event) : formProps.handleChange(name)(event)
        }}
      >
        {children}
      </StyledOutlinedInput>
      {!errorMessage ? (
        !!formProps.errors &&
        formProps.errors[name] &&
        formProps.touched[name] && <FormHelperText error>{<>{errorMessage ?? formProps.errors[name]}</>}</FormHelperText>
      ) : (
        <FormHelperText error>{errorMessage}</FormHelperText>
      )}
    </FigmaBox>
  )
}

const StyledOutlinedInput = styled(Field)<{ select?: boolean }>`
  border-radius: ${BorderRadius.soft};
  border-color: ${Colors.base300};
  background-color: ${Colors.baseWhite};

  & .MuiInputBase-input.MuiOutlinedInput-input {
    color: ${({ select }) => select && Colors.baseBlack};

    border-radius: ${BorderRadius.soft};
  }

  .MuiSelect-select:focus {
    background-color: ${Colors.baseWhite};
  }
`

// It's motivated to have some hardcoded styles here, as we don't want all styles from a textKey
// This allows for full control over the fragile input field adornment
const Adornment = styled(FigmaBox)`
  padding: 0 ${Spacings.tiny};
  font-weight: 500;
  font-size: 16px;
  color: ${Colors.baseBlack};
`

export default MaterialNumberFormatFormik
