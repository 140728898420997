import React from 'react'
import { AntiloopTextType, Language } from 'tiger/interfaces/Antiloop'
import useLanguage from '../flamingo/hooks/useLanguage'
import FigmaTextContainer from '../mynt-components/components/FigmaTextContainer'
import Loader from './Loader'

export type TextContainerProps = {
  textKey: AntiloopTextType
  className?: string
  text?: string
  language?: Language
  element?: React.FC<{ style: any }>
}

type Props = Omit<TextContainerProps, 'language'> & { loading?: boolean }

export default function TextContainer(props: Props) {
  const language = 'en'
  if (props.loading) return <Loader size="small" />
  return <FigmaTextContainer {...props} language={language} />
}
