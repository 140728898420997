import { Dispatch, SetStateAction } from 'react'
import { AccountDto, AccountXYear, VoucherDto } from '../api/swagger/definitions/backoffice'
import ImageContainer from '../mynt-components/components/ImageContainer'
import { ImageKeys } from '../tiger/libs/ImageRepository'

export const getAccountsTableActions = (
  handleEditClick: (account: AccountDto) => void,
  handleDeleteClick: (account: AccountDto) => void,
  setAnchorEl?: Dispatch<SetStateAction<HTMLElement | null>>
) => [
  {
    icon: ImageContainer,
    iconProps: {
      imageKey: ImageKeys.editIconDeletePerson
    },
    onClick: (event, account: AccountDto) => {
      handleEditClick(account)
    }
  },
  {
    icon: ImageContainer,
    iconProps: {
      imageKey: ImageKeys.removeIconDeletePerson
    },
    onClick: (event, account: AccountDto) => {
      if (setAnchorEl) {
        setAnchorEl(event.currentTarget)
      }

      handleDeleteClick(account)
    }
  }
]

export const getVouchersTableActions = (handleEditClick: (voucher: VoucherDto) => void) => [
  {
    icon: ImageContainer,
    iconProps: {
      imageKey: ImageKeys.editIconDeletePerson
    },
    onClick: (event, voucher: VoucherDto) => {
      handleEditClick(voucher)
    }
  }
]

export const getYearsTableActions = (
  handleEditClick: (year: AccountXYear) => void,
  handleDeleteClick: (year: AccountXYear) => void,
  setAnchorEl?: Dispatch<SetStateAction<HTMLElement | null>>
) => [
  {
    icon: ImageContainer,
    iconProps: {
      imageKey: ImageKeys.editIconDeletePerson
    },
    onClick: (event, year: AccountXYear) => {
      handleEditClick(year)
    }
  },
  {
    icon: ImageContainer,
    iconProps: {
      imageKey: ImageKeys.removeIconDeletePerson
    },
    onClick: (event, year: AccountXYear) => {
      if (setAnchorEl) {
        setAnchorEl(event.currentTarget)
      }
      handleDeleteClick(year)
    }
  }
]
