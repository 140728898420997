import { Global } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { Control, Path, useForm } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import ModalForked from '../../mynt-components/components/ModalForked'
import Spacings from '../../figma/tokens/Spacings'
import FigmaBox from '../../mynt-components/components/FigmaBox'
import { AutoCompleteController, TextFieldController } from '../../components/react-hook-components'
import { When } from '../../mynt-components/components/When'
import LoadingButton from '@mui/lab/LoadingButton'

export type CreateCustomerFormData<Country = string> = {
  companyName: string
  orgNo: string
}

type CreateCustomerModalProps = {
  onClose?: () => void
  onSubmit: (payload: CreateCustomerFormData) => void | Promise<void>
  isOpen: boolean | undefined
  countryOptions: { value: string; label: string }[]
}

export const CreateCustomerModal = ({ onClose, onSubmit, isOpen, countryOptions }: CreateCustomerModalProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm<CreateCustomerFormData>({
    mode: 'onChange'
  })

  const onFormSubmit = async (data: CreateCustomerFormData) => {
    setIsLoading(true)

    const promiseOrVoid = onSubmit(data)

    if (promiseOrVoid instanceof Promise) {
      return promiseOrVoid.finally(() => setIsLoading(false))
    }

    setIsLoading(false)
  }

  const handleClose = () => {
    onClose?.()
  }

  if (!isOpen) return null

  return (
    <ModalForked
      fullWidth
      alignHeadingWithCloseButton
      onClose={handleClose}
      heading={() => (
        <FigmaBox spacing={Spacings.large} left right>
          <Typography variant="h6">Create new customer</Typography>
        </FigmaBox>
      )}
    >
      <MuiSelectFontFix />
      <FigmaBox fullWidth spacing={Spacings.large} fullPadding gap={Spacings.min}>
        <Row data-testid="company-name" control={form.control} name="companyName" labelText="Company name" type="text" />
        <Row data-testid="org-no" control={form.control} name="orgNo" labelText="Org. no" type="text" />
        <FigmaBox top={Spacings.tiny} fullWidth>
          <LoadingButton
            loading={isLoading}
            onClick={form.handleSubmit(onFormSubmit)}
            variant="primary"
            disabled={!form.formState.isValid || isLoading}
          >
            Create customer
          </LoadingButton>
        </FigmaBox>
      </FigmaBox>
    </ModalForked>
  )
}

type Options = { value: string; label: string; disabled?: boolean }[]

type RowProps<T extends Record<string, any>> = {
  control: Control<T>
  labelText?: string
  name: Path<T>
  type?: 'text' | 'select' | 'number' | 'date'
  options?: Options
  adornmentText?: string
  onChange?: (event: any) => void
  disabled?: boolean
}

const Row = <T extends Record<string, any>>({
  control,
  labelText,
  name,
  type = 'number',
  options,
  adornmentText,
  disabled,
  ...props
}: RowProps<T>) => (
  <FigmaBox data-testid={props['data-testid']} fullWidth justify="space-between" direction="row">
    <FigmaBox fullWidth flex={2} style={{ whiteSpace: 'pre' }} justify="center">
      <Typography variant="subtitle1">{labelText}</Typography>
    </FigmaBox>
    <StyledTextFieldControllerContainer fullWidth flex={3}>
      <When is={['text', 'number'].includes(type)}>
        <TextFieldController
          disabled={disabled}
          type={type}
          noLabel
          name={name}
          control={control}
          adornment={adornmentText ? <Typography>{adornmentText}</Typography> : undefined}
        />
      </When>
      <When is={type === 'select'}>
        <AutoCompleteController control={control} name={name} options={options as Options} />
      </When>
    </StyledTextFieldControllerContainer>
  </FigmaBox>
)

// These fixes some issues that should not be applied everywhere that comes from UX. Would be improved once panda components are created
const MuiSelectFontFix = () => <Global styles={{ '.MuiPaper-root li': { fontSize: '14px !important' } }} />

const StyledTextFieldControllerContainer = styled(FigmaBox)`
  *:not(svg) {
    font-family: Inter !important;
    font-size: 14px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
