import styled from '@emotion/styled';
import ButtonTertiaryClickedCss from './ButtonTertiaryClickedCss';


interface ButtonTertiaryClickedProps {
  children: any;
  [propName: string]: {};
}

// Extend the below as needed
const ButtonTertiaryClickedStyled = styled.div<ButtonTertiaryClickedProps>`
  ${ButtonTertiaryClickedCss};
`;

export default ButtonTertiaryClickedStyled;