import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import logoBo from '../figma/images/logoBo'
import Spacings from '../figma/tokens/Spacings'
import SVGContainer from '../mynt-components/components/SVGContainer'

const HeaderLogo: React.FC = () => (
  <Logo to={'/start'}>
    <SVGContainer SVG={logoBo} />
  </Logo>
)

const Logo = styled(Link)`
  padding: ${Spacings.medium} 0;

  &,
  * {
    user-select: none;
  }
`

export default HeaderLogo
