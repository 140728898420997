import React from 'react'
import { SessionRole, useSessionRole } from './session'
import { Permissions } from 'appPermissions'

// See AppPermissions.ts for more details

export type RolePermissions<P = Permissions[]> = {
  [key in SessionRole]: P
}

export const hasPermission = (
  rolePermissions: RolePermissions,
  role: SessionRole,
  permission: string | string[],
  mode: 'some' | 'every' = 'every'
) => {
  if (rolePermissions[role].includes('*')) return true

  const permissionsArray = Array.isArray(permission) ? permission : [permission]

  if (mode === 'some') {
    return permissionsArray.some((_permission) => rolePermissions[role].some((rolePermission) => _permission.startsWith(rolePermission)))
  }

  return permissionsArray.every((_permission) => rolePermissions[role].some((rolePermission) => _permission.startsWith(rolePermission)))
}

export const usePermissions = (permission: Permissions | Permissions[], mode?: 'some' | 'every') => {
  const rolePermissions = useRolePermissionsContext()
  const { role } = useSessionRole()

  return hasPermission(rolePermissions, role, permission, mode)
}

export const RolePermissionsContext = React.createContext<RolePermissions>({} as RolePermissions)

export const useRolePermissionsContext = () => React.useContext(RolePermissionsContext)

export const RolePermissionsProvider = RolePermissionsContext.Provider

type AccessProps = {
  permissions: Permissions | Permissions[]
}

export const Access = ({ permissions, children }: React.PropsWithChildren<AccessProps>) => {
  const hasPermission = usePermissions(permissions)

  if (!hasPermission) return null

  return <>{children}</>
}

export const useIsLoadingWithPermission = (isLoading: boolean, permissions: Permissions | Permissions[]) => {
  if (!usePermissions(permissions)) return false

  return isLoading
}
