/* eslint-disable */
// prettier-ignore
// THIS FILE IS AUTO-GENERATED BY EMU. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.
export default {
  "top": 100,
  "heaven": 3000,
  "overTheTop": 2000,
  "focus": 10,
  "higher": 2,
  "high": 1,
  "regular": 0,
  "mobileStepper": 1000,
  "betweenMobileStepperAndSpeedDial": 1025,
  "betweenSpeedDialAndAppBar": 1075,
  "betweenAppBArAndDrawer": 1150,
  "betweenDrawerAndModal": 1250,
  "betweenModalAndSnackBar": 1350,
  "betweenSnackBarAndTooltip": 1450,
  "speedDial": 1050,
  "appBar": 1100,
  "drawer": 1200,
  "modal": 1300,
  "snackbar": 1400,
  "tooltip": 1500
}