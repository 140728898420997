import { createContext, Dispatch, useContext, useMemo, useReducer } from 'react'
import { InboxItemType } from '../api/swagger/definitions/backoffice'

enum InboxFilterSteps {
  UPDATE_FILTERS = 'UPDATE_FILTERS'
}

export enum InboxFilterActionTypes {
  SET_STEP = 'SET_STEP',
  INITIAL_STATE = 'INITIAL_STATE',
  SET_FILTERS = 'SET_FILTERS',
  SET_STORAGE_FILTERS = 'SET_STORAGE_FILTERS',
  SET_CHECK_ALL_FILTERS = 'SET_CHECK_ALL_FILTERS'
}

type InboxFilterSetStepAction = {
  type: InboxFilterActionTypes.SET_STEP
  step: InboxFilterSteps
}

type InboxFilterState = {
  step: InboxFilterSteps
  filters: InboxItemType[]
  checkAllFilters: boolean
}

type InboxFilterInitialState = {
  type: InboxFilterActionTypes.INITIAL_STATE
}

type InboxFilterSetFiltersAction = {
  type: InboxFilterActionTypes.SET_FILTERS
  filters: InboxItemType[]
}

type InboxFilterSetStorageFilters = {
  type: InboxFilterActionTypes.SET_STORAGE_FILTERS
}

type InboxFilterSetCheckAllFilters = {
  type: InboxFilterActionTypes.SET_CHECK_ALL_FILTERS
  checkAllFilters: boolean
}

type InboxFilterAction =
  | InboxFilterSetStepAction
  | InboxFilterSetFiltersAction
  | InboxFilterInitialState
  | InboxFilterSetStorageFilters
  | InboxFilterSetCheckAllFilters

const initialState: InboxFilterState = {
  step: InboxFilterSteps.UPDATE_FILTERS,
  filters: [],
  checkAllFilters: false
}

const reducer = (state: InboxFilterState, action: InboxFilterAction): InboxFilterState => {
  switch (action.type) {
    case InboxFilterActionTypes.SET_FILTERS: {
      return {
        ...state,
        filters: action.filters
      }
    }
    case InboxFilterActionTypes.SET_CHECK_ALL_FILTERS: {
      return {
        ...state,
        checkAllFilters: action.checkAllFilters
      }
    }
    default: {
      throw new Error('Unhandled action type')
    }
  }
}

const InboxFilterContext = createContext<{
  inboxFilterState: InboxFilterState
  inboxFilterDispatch: Dispatch<InboxFilterAction>
}>({ inboxFilterState: initialState, inboxFilterDispatch: () => ({}) })

export const FilterContextProvider: React.FC = ({ children }) => {
  const [inboxFilterState, inboxFilterDispatch] = useReducer(reducer, initialState)

  const value = useMemo(() => ({ inboxFilterState, inboxFilterDispatch }), [inboxFilterState, inboxFilterDispatch])
  return <InboxFilterContext.Provider value={value}>{children}</InboxFilterContext.Provider>
}

export const useInboxFilterContext = () => useContext(InboxFilterContext)
