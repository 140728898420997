import { useAppState } from '../../contexts/AppStateContext'
import { Countries } from '../config/ConfigCountries'

export function getInitialCountry(defaultCountry: Countries) {
  // Allow setting of default country for every app
  const initialCountry: Countries = defaultCountry

  return initialCountry
}

export default function useCountry() {
  return useAppState().state.country
}
