import { yupResolver } from '@hookform/resolvers/yup'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import {
  AutogiroApprovalType,
  BackOfficeAgreementTypeV6,
  CreateAgreementV6Request,
  CreateDirectDebitMandateV4Request
} from '../api/swagger/definitions/backoffice'
import Spacings from '../figma/tokens/Spacings'
import { Countries } from '../flamingo/config/ConfigCountries'
import useLanguage from '../flamingo/hooks/useLanguage'
import { useCreateAgreementV6, useCreateDirectDebitMandateV4, useCustomer } from '../api/react-query'
import { BorderContainer } from '../helpers/CreditOnCardHelpers'
import useMaterialNotification from '../hooks/useMaterialNotification'
import TextKeys from '../libs/TextKeys'
import { createBankAccountRegex, createIbanAccountValidation } from '../libs/ValidationHelper'
import Button from '../mynt-components/components/Button'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { LightTooltip } from '../mynt-components/components/MyntTooltip'
import { When } from '../mynt-components/components/When'
import { Language } from '../tiger/interfaces/Antiloop'
import { getText } from '../tiger/libs/TextRepository'
import { Checkbox } from './Checkbox'
import { ModalDirectDebitWithoutAgreement } from './Modals'
import TextContainer from './TextContainer'
import { TextFieldController } from './react-hook-components'

const countryMetaData = {
  [Countries.SE]: {
    labels: {
      title: TextKeys.enterBankAccountHeading, // Add bank account number
      subtitle: TextKeys.DirectDebitDetailsText, // Set the customers bank account number for direct debit
      label: TextKeys.mFenterBankAccountLabel, // Bank account number
      button: TextKeys.enterBankAccountButton, // Set bank account
      error: TextKeys.mFenterBankAccountError // Add bank account number
    },
    bankAccountValidator: createBankAccountRegex,
    accountName: 'bankAccount',
    initialValues: {
      bankAccount: '',
      agreement: {
        agreementType: BackOfficeAgreementTypeV6.DIRECT_DEBIT_AGREEMENT
      },
      mandate: {
        type: AutogiroApprovalType.AUTOGIRO
      }
    }
  },
  [Countries.FI]: {
    labels: {
      title: TextKeys.enterBankAccountHeadingIBAN, // Add IBAN account number
      subtitle: TextKeys.DirectDebitDetailsTextIBAN, // Set the customers IBAN account number for direct debit
      label: TextKeys.mFenterBankAccountLabelIBAN, // IBAN account number
      button: TextKeys.enterBankAccountButtonIBAN, // Set IBAN account number
      error: TextKeys.mFenterIBANAccountError // Add IBAN account number
    },
    bankAccountValidator: createIbanAccountValidation,
    accountName: 'iban',
    initialValues: {
      iban: '',
      agreement: {
        agreementType: BackOfficeAgreementTypeV6.DIRECT_DEBIT_AGREEMENT
      },
      mandate: {
        type: AutogiroApprovalType.SEPA_DIRECT_DEBIT
      }
    }
  },
  [Countries.DK]: {
    labels: {
      title: TextKeys.enterBankAccountHeadingIBAN, // Add IBAN account number
      subtitle: TextKeys.DirectDebitDetailsTextIBAN, // Set the customers IBAN account number for direct debit
      label: TextKeys.mFenterBankAccountLabelIBAN, // IBAN account number
      button: TextKeys.enterBankAccountButtonIBAN, // Set IBAN account number
      error: TextKeys.mFenterIBANAccountError // Add IBAN account number
    },
    bankAccountValidator: createIbanAccountValidation,
    accountName: 'iban',
    initialValues: {
      iban: '',
      agreement: {
        agreementType: BackOfficeAgreementTypeV6.DIRECT_DEBIT_AGREEMENT
      },
      mandate: {
        type: AutogiroApprovalType.SEPA_DIRECT_DEBIT
      }
    }
  }
} as const

type Props = {
  customerId: string
}

type FormValues = {
  bankAccount?: string
  iban?: string
  mandate: CreateDirectDebitMandateV4Request
  agreement: CreateAgreementV6Request
}

const createValidationSchema = (meta: (typeof countryMetaData)[keyof typeof countryMetaData], language: Language) => {
  if (meta.accountName === 'iban') {
    return Yup.object().shape({
      iban: meta.bankAccountValidator(meta.accountName, getText(meta.labels.error, language))
    })
  }

  if (meta.accountName === 'bankAccount') {
    return Yup.object().shape({
      bankAccount: meta.bankAccountValidator(meta.accountName, getText(meta.labels.error, language))
    })
  }
}

const DirectDebitTabNewConnection = ({ customerId }: Props) => {
  const language = useLanguage()
  const customer = useCustomer(customerId)
  const createAgreement = useCreateAgreementV6(customerId)
  const createDirectDebitMandateV4 = useCreateDirectDebitMandateV4(customerId)
  const country = customer.data?.country as Countries
  const notify = useMaterialNotification()

  const [sendMandateAgreement, setSendMandateAgreement] = useState<boolean>(true)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

  const meta = countryMetaData[country]

  const onSubmit = (values: FormValues) => {
    if (sendMandateAgreement || showConfirmModal) {
      submitAgreement(values)
    } else {
      setShowConfirmModal(true)
    }
  }

  const submitAgreement = async (values: FormValues) => {
    if (sendMandateAgreement) {
      await createAgreement.mutateAsync({
        ...values.agreement,
        [meta.accountName]: values[meta.accountName]
      })

      notify('Agreement created')
    } else {
      await createDirectDebitMandateV4.mutateAsync({
        ...values.mandate,
        [meta.accountName]: values[meta.accountName]
      })

      notify('Mandate created')
    }

    setShowConfirmModal(false)
  }

  const validationSchema = useMemo(() => createValidationSchema(meta, language), [language, meta])

  const form = useForm<FormValues>({
    defaultValues: meta.initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  })

  const isOverrideDisabled = meta.initialValues.mandate.type === AutogiroApprovalType.SEPA_DIRECT_DEBIT

  return (
    <BorderContainer fullWidth smallSpacing>
      <FigmaBox gap={Spacings.min} bottom={Spacings.small}>
        <TextContainer textKey={TextKeys.earlierCreditAgreementHeadline} text={meta.labels.title.characters} />
        <TextContainer textKey={meta.labels.subtitle} />
      </FigmaBox>
      <FigmaBox fullWidth direction="column" gap={Spacings.small}>
        <TextFieldController labelTextKey={meta.labels.label} name={meta.accountName} control={form.control} />
        <When is={isOverrideDisabled}>
          <LightTooltip
            title="Not allowed to override, SEPA mandates requires a signed agreement from the customer"
            content={
              <Checkbox
                disabled
                className="checkbox"
                checked={sendMandateAgreement}
                onChange={() => setSendMandateAgreement(!sendMandateAgreement)}
              >
                <TextContainer textKey={TextKeys.customersDirectDebitNewDirectDebitCheckSendMandateAgreement} />
              </Checkbox>
            }
          />
        </When>
        <When is={!isOverrideDisabled}>
          <Checkbox className="checkbox" checked={sendMandateAgreement} onChange={() => setSendMandateAgreement(!sendMandateAgreement)}>
            <TextContainer textKey={TextKeys.customersDirectDebitNewDirectDebitCheckSendMandateAgreement} />
          </Checkbox>
        </When>
        <FigmaBox>
          <Button
            type="submit"
            textKey={meta.labels.button}
            loading={createDirectDebitMandateV4.isLoading}
            disabled={createDirectDebitMandateV4.isLoading}
            onClick={form.handleSubmit(onSubmit)}
          />
        </FigmaBox>
        <ModalDirectDebitWithoutAgreement
          visible={showConfirmModal}
          loading={false}
          onSubmit={form.handleSubmit(submitAgreement)}
          onClose={() => setShowConfirmModal(false)}
        />
      </FigmaBox>
    </BorderContainer>
  )
}

export default DirectDebitTabNewConnection
