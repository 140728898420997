/* eslint-disable no-restricted-imports */
/* eslint-disable no-named-mui-exports/no-named-mui-exports */
import { createTheme } from '@mui/material'

export const base = createTheme({
  typography: {
    fontFamily: 'Inter',
    fontWeightMedium: 500,
    fontSize: 18
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      defaultProps: {
        size: 'medium',
        variant: 'secondary'
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            ':hover': {
              boxShadow: 'none'
            }
          }
        },
        {
          props: { variant: 'tertiary' },
          style: {
            padding: '8px 12px',
            lineHeight: '1rem',
            '& .MuiCircularProgress-root': {
              // These styles are placed inline by mui LoadingButton and cannot be overridden without !important
              height: '1rem !important',
              width: '1rem !important'
            },
            // startIcon and endIcon sizes
            '&.MuiButton-text > * > *:first-child': {
              height: 16,
              width: 16
            }
          }
        }
      ],
      styleOverrides: {
        sizeSmall: {
          padding: '6px 12px',
          fontSize: 14
        },
        sizeMedium: {
          padding: '8px 16px',
          fontSize: 16
        },
        sizeLarge: {
          padding: '12px 24px',
          fontSize: 18
        },
        iconSizeMedium: {
          '& > *:first-child': {
            height: 22,
            width: 22
          }
        },
        root: {
          boxShadow: 'none',
          textTransform: 'none',
          lineHeight: '1.375rem', // 100% doenst quite match, equivilent to 1.375rem or 22px
          letterSpacing: '-1%',
          padding: '12px 24px',
          border: '1px solid transparent',
          borderRadius: 8,
          '& .MuiCircularProgress-root': {
            // These styles are placed inline by mui LoadingButton and cannot be overridden without !important
            height: '1.375rem !important',
            width: '1.375rem !important'
          },
          ':active': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              '& + .MuiSwitch-track': {
                opacity: 1,
                border: 0
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
              }
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.7
            }
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            opacity: 1,
            transition: 'background-color 500ms'
          }
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Inter',
        color: 'black'
      },
      variants: [
        {
          props: { variant: 'h1' },
          style: { fontSize: '32px', fontStyle: 'normal', fontWeight: 500, lineHeight: '110%' }
        },
        {
          props: { variant: 'h2' },
          style: { fontSize: '24px', fontStyle: 'normal', fontWeight: 500, lineHeight: '120%' }
        },
        {
          props: { variant: 'h3' },
          style: { fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '110%' }
        },
        {
          props: { variant: 'body1', fontWeight: 'bold' },
          style: { fontSize: '18px', fontStyle: 'normal', fontWeight: 600, lineHeight: '140%', letterSpacing: '-0.36px' }
        },
        {
          props: { variant: 'body1', fontWeight: 'medium' },
          style: { fontSize: '18px', fontStyle: 'normal', fontWeight: 500, lineHeight: '140%', letterSpacing: '-0.36px' }
        },
        {
          props: { variant: 'body1' },
          style: { fontSize: '18px', fontStyle: 'normal', fontWeight: 400, lineHeight: '140%', letterSpacing: '-0.36px' }
        },
        {
          props: { variant: 'body2', fontWeight: 'bold' },
          style: { fontSize: '16px', fontStyle: 'normal', fontWeight: 600, lineHeight: '140%', letterSpacing: '-0.36px' }
        },
        {
          props: { variant: 'body2', fontWeight: 'medium' },
          style: { fontSize: '16px', fontStyle: 'normal', fontWeight: 500, lineHeight: '140%', letterSpacing: '-0.36px' }
        },
        {
          props: { variant: 'body2' },
          style: { fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '140%', letterSpacing: '-0.36px' }
        },
        {
          props: { variant: 'subtitle1', fontWeight: 'bold' },
          style: { fontSize: '14px', fontStyle: 'normal', fontWeight: 600, lineHeight: '140%', letterSpacing: '-0.36px' }
        },
        {
          props: { variant: 'subtitle1', fontWeight: 'medium' },
          style: { fontSize: '14px', fontStyle: 'normal', fontWeight: 500, lineHeight: '140%', letterSpacing: '-0.36px' }
        },
        {
          props: { variant: 'subtitle1' },
          style: { fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: '140%', letterSpacing: '-0.36px' }
        },
        {
          props: { variant: 'subtitle2', fontWeight: 'bold' },
          style: { fontSize: '12px', fontStyle: 'normal', fontWeight: 600, lineHeight: '140%', letterSpacing: '-0.36px' }
        },
        {
          props: { variant: 'subtitle2', fontWeight: 'medium' },
          style: { fontSize: '12px', fontStyle: 'normal', fontWeight: 500, lineHeight: '140%', letterSpacing: '-0.36px' }
        },
        {
          props: { variant: 'subtitle2' },
          style: { fontSize: '12px', fontStyle: 'normal', fontWeight: 400, lineHeight: '140%', letterSpacing: '-0.36px' }
        }
      ]
    }
  }
})
