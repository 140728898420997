//THIS FILE IS AUTOMATICALLY GENERATED BY EMU
import gazelleEn from './gazelle.en.json'
import gazelleFi from './gazelle.fi.json'
import gazelleSv from './gazelle.sv.json'
import eagleEn from './eagle.en.json'
import eagleFi from './eagle.fi.json'
import eagleSv from './eagle.sv.json'

export const gazelle = {
  en: gazelleEn,
  fi: gazelleFi,
  sv: gazelleSv
}

export const eagle = {
  en: eagleEn,
  fi: eagleFi,
  sv: eagleSv
}
