import styled from '@emotion/styled';
import ButtonSecondaryDisabledCss from './ButtonSecondaryDisabledCss';


interface ButtonSecondaryDisabledProps {
  children: any;
  [propName: string]: {};
}

// Extend the below as needed
const ButtonSecondaryDisabledStyled = styled.div<ButtonSecondaryDisabledProps>`
  ${ButtonSecondaryDisabledCss};
`;

export default ButtonSecondaryDisabledStyled;