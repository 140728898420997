import styled from '@emotion/styled';
import ButtonSecondaryDefaultCss from './ButtonSecondaryDefaultCss';


interface ButtonSecondaryDefaultProps {
  children: any;
  [propName: string]: {};
}

// Extend the below as needed
const ButtonSecondaryDefaultStyled = styled.div<ButtonSecondaryDefaultProps>`
  ${ButtonSecondaryDefaultCss};
`;

export default ButtonSecondaryDefaultStyled;