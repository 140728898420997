// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

import borderWidths from 'figma/tokens-elements/borderWidthsDeprecated';
import colors from 'figma/tokens-elements/colorsDeprecated';
import fontFamilies from 'figma/tokens-elements/fontFamiliesDeprecated';
import fontSizes from 'figma/tokens-elements/fontSizesDeprecated';
import fontWeights from 'figma/tokens-elements/fontWeightsDeprecated';
import letterSpacings from 'figma/tokens-elements/letterSpacingsDeprecated';
import lineHeights from 'figma/tokens-elements/lineHeightsDeprecated';
import radii from 'figma/tokens-elements/radiiDeprecated';
import spacing from 'figma/tokens-elements/spacingDeprecated';

const ButtonSecondaryHoverCss = `
  color: ${colors.black};
  font-size: ${fontSizes.button};
  font-family: ${fontFamilies.Inter};
  font-weight: ${fontWeights.bold};
  line-height: ${lineHeights.xs};
  letter-spacing: ${letterSpacings.wide};
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  border-style: solid;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: ${spacing.tiny};
  padding-right: ${spacing.tiny};
  height: 58;
  border-width: ${borderWidths.regular};
  border-color: ${colors.green};
  border-radius: ${radii.soft};
`;

export default ButtonSecondaryHoverCss;