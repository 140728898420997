import Box from '@mui/material/Box'
import React from 'react'

export const SearchResultRow: React.FC<{ style?: React.CSSProperties }> = ({ children, style = {} }) => (
  <Box sx={{ paddingBottom: '25px', display: 'flex', ...style }} alignItems="middle" justifyContent="center">
    {children}
  </Box>
)

export const SearchResultHeader: React.FC<{
  title: string
  subTitle: string
}> = ({ title, subTitle }) => (
  <>
    <h3 style={{ margin: 0, fontSize: 20, color: 'black', fontWeight: 500 }}>{title}</h3>
    <p
      style={{
        margin: 0,
        fontSize: 16,
        color: '#87888b'
      }}
    >
      {subTitle}
    </p>
  </>
)

export const SearchResultContent: React.FC = ({ children }) => (
  <p
    style={{
      fontFamily: 'Inter',
      marginBottom: 0,
      color: '#87888b'
    }}
  >
    {children}
  </p>
)
