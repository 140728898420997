import qs from 'query-string'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { findCustomerUsingGET2 } from '../api'
import { CUSTOMERS_PATH } from '../routes/constants'

/* THIS PAGE SHOULD in future support these endpoints:
/external/customerLead?product-id=productId"
"/external/Kyc?customer-id=customerId"
"/external/AutogiroStatus?customer-id=customerId"
"/external/CardOrdered?customer-id=customerId"
"/external/CardActivated?customer-id=customerId"
"/external/TeamInvitationLinkCompleted?customer-id=customerId"
"/external/LoanContractPartiallySigned?customer-id=customerId"
"/external/CustomerAppliedForCardCreditLimit?customer-id=customerId"
"/external/CreditCustomerOnboardedAutoApproved?customer-id=customerId"
"/external/CreditCustomerOnboardedAutoDenied?customer-id=customerId"
"/external/CustomerLoggedIn?customer-id=customerId"
"/external/CustomerAppliedForCreditLimit?customer-id=ustomerId"
"/external/LimitWarning?customer-id=ustomerId"
"/external/ApprovedFakturaLoan?customer-id=customerId

also see this file for up to date EventTypes:

*/

const ExternalPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = qs.parse(location.search) as Record<string, string>

  useEffect(() => {
    if (!params.customerId) return

    findCustomerUsingGET2(params.customerId)
      .then((c) => {
        if (!c) throw new Error(`Cant find customer with id: ${params.customerId}`)

        navigate(`${CUSTOMERS_PATH}/${c.orgNo}`)
      })
      .catch((e) => navigate(CUSTOMERS_PATH))
  }, [])

  return <div></div>
}

export default ExternalPage
