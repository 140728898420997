import TextContainer from '../components/TextContainer'
import useLanguage from 'flamingo/hooks/useLanguage'
import FigmaBox from 'mynt-components/components/FigmaBox'
import FigmaButton from 'mynt-components/components/FigmaButton'
import ModalExperimental from 'mynt-components/components/ModalExperimental'
import React, { useMemo } from 'react'
import { TextKeys, getText } from 'tiger/libs/TextRepository'
import * as Yup from 'yup'
import { AccountDto, AccountXYear } from '../api/swagger/definitions/backoffice'
import Spacings from '../figma/tokens/Spacings'
import ZIndices from '../figma/tokens/ZIndices'
import { useCreateAccountingAccount, useUpdateAccountingAccount } from '../api/react-query'
import { captureException } from '../helpers/sentryHelpers'
import ModalExperimentalFooter from '../mynt-components/components/ModalExperimentalFooter'
import ModalExperimentalHeader from '../mynt-components/components/ModalExperimentalHeader'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextFieldController } from './react-hook-components'
import Button from '../mynt-components/components/Button'

export enum AccountFormTypes {
  CREATE = 'create',
  EDIT = 'edit'
}

interface AccountFormProps {
  isOpen: boolean
  type: AccountFormTypes
  initialValues?: AccountDto
  years: AccountXYear[]
  onModalCancel: () => void
}

export const _ModalAccountingAccount: React.FC<AccountFormProps> = ({
  isOpen,
  type,
  initialValues: _initialValues,
  years,
  onModalCancel
}) => {
  const createAccountingAccountMutatation = useCreateAccountingAccount()
  const updateAccountingAccountMutatation = useUpdateAccountingAccount(_initialValues?.id as string)
  const language = useLanguage()

  const getInitialAccountingYearValue = (values?: AccountDto) => {
    if (values) {
      return values.accountingYearId
    }
    const defaultYear = years.find((year) => !!year.default)
    return defaultYear ? defaultYear.id : undefined
  }

  const initialValues: AccountDto = {
    no: _initialValues?.no || 0,
    name: _initialValues?.name || '',
    accountingYearId: getInitialAccountingYearValue(_initialValues)
  }

  const validationSchema = Yup.object().shape({
    no: Yup.number()
      .typeError(getText(TextKeys.editAccountNumberError, language))
      .required(getText(TextKeys.editAccountNumberError, language)),
    name: Yup.string()
      .typeError(getText(TextKeys.editAccountNameError, language))
      .required(getText(TextKeys.editAccountNameError, language))
  })

  const onSubmit = async (values: AccountDto) => {
    if (type === AccountFormTypes.EDIT) {
      return updateAccountingAccountMutatation
        .mutateAsync(values)
        .then((res) => {
          onModalCancel()
          return res
        })
        .catch((err) => captureException(err))
    }

    if (type === AccountFormTypes.CREATE) {
      return createAccountingAccountMutatation
        .mutateAsync(values)
        .then((res) => {
          onModalCancel()
          return res
        })
        .catch((err) => captureException(err))
    }
  }

  const form = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <>
      {isOpen && (
        <ModalExperimental
          zIndexModal={ZIndices.focus}
          onClose={onModalCancel}
          backOfficeStyling
          heading={() => (
            <ModalExperimentalHeader>
              {type === AccountFormTypes.EDIT ? (
                <TextContainer textKey={TextKeys.editAccountHeading} />
              ) : (
                <TextContainer textKey={TextKeys.editAccountHeading} text={getText(TextKeys.accountingAccountingCreateAccount, language)} />
              )}
            </ModalExperimentalHeader>
          )}
        >
          <form name="modal_accounts" onSubmit={form.handleSubmit(onSubmit)}>
            <FigmaBox
              fullWidth
              direction="column"
              justify="space-between"
              align="flex-start"
              gap={Spacings.large}
              top={Spacings.large}
              bottom={Spacings.large}
              left={Spacings.medium}
              right={Spacings.medium}
            >
              <FigmaBox fullWidth direction="row" align="flex-start" justify="space-between" gap={Spacings.medium}>
                <FigmaBox fullWidth>
                  <TextFieldController control={form.control} name={'no'} disabled labelTextKey={TextKeys.editAccountNumberLabel} />
                </FigmaBox>
              </FigmaBox>
              <FigmaBox fullWidth direction="row" align="flex-start" justify="space-between" gap={Spacings.medium}>
                <FigmaBox fullWidth>
                  <TextFieldController name="name" control={form.control} labelTextKey={TextKeys.editAccountNameLabel} />
                </FigmaBox>
              </FigmaBox>
            </FigmaBox>
            <ModalExperimentalFooter>
              <FigmaBox direction="row" fullWidth align="flex-start" justify="flex-end" top={Spacings.tiny} gap={Spacings.tiny}>
                <FigmaBox fullWidth>
                  <FigmaButton variant="tertiary" textKey={TextKeys.editAccountButtonCancel} onClick={onModalCancel} />
                </FigmaBox>
                <FigmaBox fullWidth>
                  <Button onClick={form.handleSubmit(onSubmit)} textKey={TextKeys.editAccountButtonSave} />
                </FigmaBox>
              </FigmaBox>
            </ModalExperimentalFooter>
          </form>
        </ModalExperimental>
      )}
    </>
  )
}
