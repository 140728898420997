import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PersonDto, PersonPatchDto } from '../api/swagger/definitions/backoffice'
import Spacings from '../figma/tokens/Spacings'
import { usePatchPerson } from '../api/react-query'
import { getObjectDifference, isDev } from '../helpers/common'
import useMaterialNotification from '../hooks/useMaterialNotification'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { TextFieldController } from './react-hook-components'
import { CoreMyntModal } from './modals/CoreMyntModal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'

interface Props {
  customerId: string
  isOpen: boolean
  onCancel: () => void
  onSuccess: () => void
  person: PersonDto | null | undefined
}

const defaultValues = (person?: PersonDto | null): PersonPatchDto => ({
  blPersonExternalId: (person?.blPersonExternalId ?? '') as any,
  email: person?.email ?? '',
  findityEmployeeId: person?.findityEmployeeId ?? '',
  fullName: person?.fullName ?? '',
  inviteSentAt: person?.inviteSentAt ?? '',
  peEmployeeId: person?.peEmployeeId,
  roles: person?.roles ?? [],
  ssn: person?.ssn ?? '',
  phoneNumber: (person?.phoneNumber ?? '') as any,
  partnerExternalId: person?.partnerExternalId ?? ''
})

const UpdatePersonModal: React.FC<Props> = ({ customerId, isOpen, onCancel, onSuccess, person }) => {
  const personId = person?.id as string
  const personMutation = usePatchPerson(customerId)
  const [isLoading, setIsLoading] = useState(false)
  const notify = useMaterialNotification()

  const form = useForm<PersonPatchDto>({
    defaultValues: defaultValues(person)
  })

  const handleSubmit = async (values: PersonPatchDto) => {
    const initialValues = defaultValues(person)
    const changedValues = getObjectDifference(values, initialValues)

    if (Object.keys(changedValues).length === 0) {
      return
    }

    setIsLoading(true)

    personMutation
      .mutateAsync({ personId, ...changedValues })
      .then(() => {
        onSuccess()
        notify('Updated person')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    form.reset(defaultValues(person))
  }, [person])

  if (!isOpen) return null

  return (
    <CoreMyntModal
      open
      title="Update person"
      actions={(Wrapper) => (
        <Box sx={{ color: 'white' }}>
          <Wrapper>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <LoadingButton variant="primary" onClick={form.handleSubmit(handleSubmit)} loading={isLoading}>
              Update
            </LoadingButton>
          </Wrapper>
        </Box>
      )}
      onClose={onCancel}
    >
      <Box sx={{ display: 'flex', gap: '16px', paddingTop: '16px' }}>
        <FigmaBox gap={Spacings.small} fullWidth>
          <TextFieldController disabled={!isDev()} labelText="Full name" control={form.control} name="fullName" />
          <TextFieldController disabled={!isDev()} labelText="Email" control={form.control} name="email" />
          <TextFieldController labelText="PE employee ID" control={form.control} name="peEmployeeId" />
          <TextFieldController labelText="Björn Lundén external ID" control={form.control} name="blPersonExternalId" />
        </FigmaBox>
        <FigmaBox gap={Spacings.small} fullWidth>
          <TextFieldController disabled={!isDev()} labelText="SSN" control={form.control} name="ssn" />
          <TextFieldController disabled={!isDev()} labelText="Phone number" control={form.control} name="phoneNumber" />
          <TextFieldController labelText="Findity employee ID" control={form.control} name="findityEmployeeId" />
          <TextFieldController labelText="External partner person ID" control={form.control} name="partnerExternalId" />
        </FigmaBox>
      </Box>
    </CoreMyntModal>
  )
}

export default UpdatePersonModal
