import createTheme from '@mui/material/styles/createTheme'
import BorderRadius from '../figma/tokens/BorderRadius'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Shadows from '../figma/tokens/Shadows'
import Spacings from '../figma/tokens/Spacings'
import { light } from 'themes'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'

const colorPrimary = {
  light: ColorsDeprecated.baseGreen,
  main: ColorsDeprecated.baseGarden,
  dark: ColorsDeprecated.textLink,
  contrastText: '#fff'
}

const DefaultMaterialTheme = () =>
  // const tabStyles = useTextVariant(TextKeys.accountMenuOverview)

  createTheme(
    {
      components: {
        MuiDataGrid: {
          styleOverrides: {
            root: {
              fontSize: 14
            }
          }
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              fontSize: 16
            }
          }
        },
        MuiFormControlLabel: {
          styleOverrides: {
            root: {
              fontSize: 16
            }
          }
        },
        MuiList: {
          styleOverrides: {
            root: {
              paddingTop: 0,
              paddingBottom: 0
            }
          }
        },
        MuiCollapse: {
          styleOverrides: {
            root: {
              '& .SnackbarContent-root.SnackbarContent-root': {
                fontFamily: 'Inter',
                fontWeight: 600,
                '.MuiButton-root .MuiSvgIcon-root': {
                  color: ColorsDeprecated.baseStone
                }
              },
              '& .SnackbarContent-root.SnackbarItem-variantInfo': {
                backgroundColor: 'white',
                color: 'black',
                '& .MuiSvgIcon-root': {
                  color: ColorsDeprecated.textLink
                }
              }
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              '.MuiInputBase-adornedStart': {
                padding: 0
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
                borderBottom: `1px solid ${ColorsDeprecated.baseStone}`,
                borderRadius: 0
              },
              fontSize: 16
            }
          }
        },
        MuiButtonBase: { defaultProps: { disableRipple: true } },
        MuiButton: {
          defaultProps: {
            disableRipple: true
          }
        },
        MuiInput: {
          styleOverrides: {
            sizeSmall: {
              fontSize: 10
            }
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            sizeSmall: {
              padding: 0,
              height: 32,
              fontSize: 14
            },
            root: {
              // height: INPUT_HEIGHT,
              borderRadius: BorderRadius.soft,
              borderColor: ColorsDeprecated.baseStone,
              '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                borderColor: ColorsDeprecated.baseStone
              }
            },
            input: {
              borderRadius: BorderRadius.soft,
              padding: '12.5px'
            },
            inputMultiline: {
              borderRadius: 'initial'
            },
            adornedEnd: {
              paddingRight: 0
            }
          }
        },
        MuiInputLabel: {
          styleOverrides: {
            sizeSmall: {
              fontSize: 14
            },
            root: {
              fontSize: 16
            },
            outlined: {
              top: '-3px',
              '&$focused': {
                top: 0
              }
            }
          }
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              color: ColorsDeprecated.baseGrey,
              backgroundColor: ColorsDeprecated.baseWhite,
              borderRadius: BorderRadius.soft,
              boxShadow: Shadows.baseHeavy,
              padding: Spacings.small,
              fontFamily: 'Inter'
            }
          }
        },
        MuiTabs: {
          styleOverrides: {
            indicator: {
              backgroundColor: ColorsDeprecated.textLink,
              height: 3,
              minWidth: 120
            },
            flexContainer: {
              borderBottom: `1px solid ${ColorsDeprecated.baseStone}`
            }
          }
        },
        MuiTab: {
          styleOverrides: {
            root: {
              width: 120,
              '@media (min-width: 0px)': {
                minWidth: 120
              },
              textTransform: 'none',
              '&:hover': {
                color: ColorsDeprecated.textLinkHover
              }
            }
          }
        },
        MuiCheckbox: {
          styleOverrides: {
            colorSecondary: {
              color: ColorsDeprecated.baseGreen,
              '&$checked': {
                color: ColorsDeprecated.baseGreen
              },
              '&$hover': {
                color: ColorsDeprecated.baseGreen
              }
            }
          }
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              '&.MuiInputAdornment-positionEnd': {}
            }
          }
        },
        MuiSelect: {
          styleOverrides: {
            multiple: {
              padding: 8
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              fontSize: 16
            }
          }
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              fontSize: 16
            }
          }
        },
        MuiAutocomplete: {
          styleOverrides: {
            paper: {
              fontSize: 16
            }
          }
        },
        MuiSwitch: {
          styleOverrides: {
            switchBase: {
              margin: '2px !important'
            }
          }
        }
      }
    },
    light
  )

export default DefaultMaterialTheme()
