import { attempt, isError } from 'lodash'
import React, { useContext, useEffect, useMemo } from 'react'
import { Countries } from '../flamingo/config/ConfigCountries'
import { Currencies } from '../flamingo/config/ConfigCurrencies'
import { getInitialCountry } from '../flamingo/hooks/useCountry'
import { getInitialCurrency } from '../flamingo/hooks/useCurrency'
import { getInitialLanguage } from '../flamingo/hooks/useLanguage'
import { LocalStorageKeys, useLocalStorageItem } from '../mynt-components/hooks/useLocalStorageItem'
import { Language } from '../tiger/interfaces/Antiloop'

const STATE = 'STATE'

type Context = {
  state: AppState
  setState: React.Dispatch<React.SetStateAction<AppState>>
}

type AppState = {
  isHeaderSearchOpen: boolean
  language: Language
  country: Countries
  currency: Currencies
}

const initialLanguage = 'en'
const initialCountry = getInitialCountry(Countries.SE)
const initialCurrency = getInitialCurrency(Currencies.SEK)

const DEFAULT_APP_STATE: AppState = {
  isHeaderSearchOpen: false,
  language: initialLanguage,
  country: initialCountry,
  currency: initialCurrency
}

const AppStateContext = React.createContext<Context>({
  state: DEFAULT_APP_STATE,
  setState: () => ({})
})

export const useAppState = () => useContext(AppStateContext)

const getStateFromStorage = (): AppState => {
  if (!sessionStorage) return DEFAULT_APP_STATE

  const storageState = sessionStorage.getItem(STATE)
  if (!storageState) return DEFAULT_APP_STATE

  let state = attempt(JSON.parse, storageState) as AppState
  if (isError(state)) state = DEFAULT_APP_STATE

  return state
}

const useAppStateWithStorage = (): Context => {
  const [state, setState] = React.useState<AppState>(getStateFromStorage())
  const { setItem: setLocalStorageCountry } = useLocalStorageItem(LocalStorageKeys.COUNTRY, state.country)
  const { setItem: setLocalStorageCurrency } = useLocalStorageItem(LocalStorageKeys.CURRENCY, state.currency)
  const { setItem: setLocalStorageLanguage } = useLocalStorageItem(LocalStorageKeys.LANGUAGE, state.language)

  // Store app state in local and session storage on change
  useEffect(() => {
    sessionStorage.setItem(STATE, JSON.stringify({ ...state }))
    setLocalStorageCountry(state.country)
    setLocalStorageCurrency(state.currency)
    setLocalStorageLanguage(state.language)
  }, [state])

  return { state, setState }
}

type AppStateContextProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

export const AppStateContextProvider = ({ children }: AppStateContextProviderProps) => {
  const { state, setState } = useAppStateWithStorage()
  const context = useMemo(() => ({ state, setState }), [state])

  return <AppStateContext.Provider value={context}>{children}</AppStateContext.Provider>
}
