// BACKOFFICE LAYOUT CONTAINER MAX WIDTH
export const BO_CONTENT_SIZE = 1200

export const BO_NOTES_MAX_WIDTH = 400
export const BO_MAX_CONTENT_WIDTH = 1920
export const BO_LAYOUT_SIZE = BO_MAX_CONTENT_WIDTH

export const BO_SUBSCRIPTION_FEE_MODAL_WIDTH = 433

// BACKOFFICE HEADER
export const BO_HEADER_PHONE_DRAWER_WIDTH = '220px'
export const BO_HEADER_PHONE_SEARCH_WIDTH = '200px'
export const BO_HEADER_TABLET_WIDTH = 600
export const BO_HEADER_DESKTOP_WIDTH = 1000
export const BO_HEADER_LARGE_DESKTOP_WIDTH = 1200

// BACKOFFICE CUSTOMERS > CARD TAB
export const BO_CUSTOMER_CARD_FORM_MIN_WIDTH = '300px'
export const BO_TWO_COLUMN_SIZE_LEFT = '60%'
export const BO_TWO_COLUMN_SIZE_RIGHT = '40%'

// BO CARD OVERVIEW ACCOUNT TYPE DROPDOWN SWITCH SIZE
export const CUSTOMER_ACCOUNT_TYPE_SELECTOR_WIDTH = '280px'

// USED IN BO ROLE SELECT
export const ROLES_SELECT_WIDTH = '352px'

// USED IN BO VOUCHERS
export const VOUCHERS_FILTERS_SELECT_INPUT_MIN_HEIGHT = '32px'

// USED IN CHART COMPONENTS
export const DEFAULT_CHART_Y_TICK_HORIZONTAL_POSITION = -16
export const DEFAULT_CHART_Y_TICK_CONTENT_WRAPPER_Y_ALIGNMENT = 4
export const DEFAULT_CHART_Y_TICK_TICK_LINE_STROKE_WIDTH = 2
