import React, { useState } from 'react'
import { RepaymentInvoiceDto } from '../api/swagger/definitions/backoffice'
import { createRepaymentInvoiceReminder } from '../api'
import useMaterialNotification from '../hooks/useMaterialNotification'
import { CoreMyntModal } from './modals/CoreMyntModal'
import { useForm } from 'react-hook-form'
import { Amount } from 'types'
import { NumberFormatController } from './react-hook-components'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'

export const RepaymentInvoiceRemindBtn: React.FC<{
  id: string
  onSuccess: (data: RepaymentInvoiceDto) => void
}> = ({ id, onSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const notify = useMaterialNotification()

  const form = useForm<Amount>({
    defaultValues: {
      currency: 'SEK',
      value: 500
    }
  })

  const handleSubmit = (payload: Amount) => {
    setSubmitting(true)

    createRepaymentInvoiceReminder(id, payload)
      .then((data) => {
        onSuccess(data)
        setIsModalOpen(false)

        notify(`Action was successful`)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <>
      <Button style={{ marginLeft: 5 }} onClick={() => setIsModalOpen(true)} size="small">
        Remind
      </Button>

      <CoreMyntModal
        open={isModalOpen}
        title="Penalty amount"
        onClose={() => setIsModalOpen(false)}
        actions={(Wrapper) => (
          <Wrapper>
            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
            <LoadingButton variant="primary" loading={submitting} onClick={form.handleSubmit(handleSubmit)}>
              Submit
            </LoadingButton>
          </Wrapper>
        )}
      >
        <Box sx={{ paddingTop: '16px' }}>
          <NumberFormatController control={form.control} name="value" labelText="Amount" adornment={<>{form.watch('currency')}</>} />
        </Box>
      </CoreMyntModal>
    </>
  )
}
