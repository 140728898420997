import { MutationFunction, MutationKey, useMutation as _useMutation, useQueryClient } from '@tanstack/react-query'
import { getQueriesData } from './helpers'

type HookOptions<Dto> = {
  queryKey: MutationKey
  getId: (item: Dto) => string // Method used to find an items identifier to match and remove old data
}

export const useDeleteListItemMutation = <Dto, PayloadDto>(
  { queryKey, getId }: HookOptions<Dto>,
  fn: MutationFunction<Dto, PayloadDto>,
  invalidateCacheKeyPrefix?: MutationKey
) => {
  const queryClient = useQueryClient()

  const filterRemovedItem = (item: Dto) => (prev: Dto) => getId(prev) !== getId(item)

  return _useMutation(queryKey, fn, {
    onSuccess: (item) => {
      const previous = getQueriesData<Dto[]>(queryClient, queryKey) || []
      const data = previous.filter(filterRemovedItem(item))

      queryClient.setQueriesData(queryKey, data)

      return { previous, data }
    },
    onSettled: () => {
      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach((key) => queryClient.invalidateQueries([key]))
      }
    }
  })
}

type OpportunisticHookOptions<Dto, DtoPayload> = {
  queryKey: MutationKey
  getId: (item: Dto) => string // Method used to find an items identifier to match and replace old data
  getIdOppertunistic: (item: DtoPayload) => string // Method used to find an items identifier to match and replace old data oppertunistically, i.e. before the mutation has completed
}

export const useDeleteListItemMutationOpportunistic = <Dto, PayloadDto>(
  { queryKey, getId, getIdOppertunistic }: OpportunisticHookOptions<Dto, PayloadDto>,
  fn: MutationFunction<Dto, PayloadDto>,
  invalidateCacheKeyPrefix?: MutationKey
) => {
  const queryClient = useQueryClient()

  const filterRemovedItemOppertunistic = (item: PayloadDto) => (prev: Dto) => getId(prev) !== getIdOppertunistic(item)

  return _useMutation(queryKey, fn, {
    onMutate: async (item) => {
      await queryClient.cancelQueries(queryKey)

      const previous = getQueriesData<Dto[]>(queryClient, queryKey) || []
      const data = previous.filter(filterRemovedItemOppertunistic(item))

      queryClient.setQueriesData(queryKey, data)

      return { previous, data }
    },
    onError: (error, newItem, context) => {
      if (context?.previous) {
        queryClient.setQueriesData(queryKey, context.previous)
      }
    },
    onSettled: () => {
      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach((key) => queryClient.invalidateQueries([key]))
      }
    }
  })
}
