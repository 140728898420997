import { formatCurrency } from 'helpers/utils'
import { ReportDto } from '../api/swagger/definitions/backoffice'

interface State {
  loading: boolean
  details: ReportDto | null
}

const sumValues = (values: (number | undefined)[]) => values.reduce<number>((prev, next) => prev + (next ?? 0), 0)

export const getTotalSumAmount = (state: State) => {
  if (!state.details || state.loading) return '-'
  const { principalTotal, feesTotal, penaltiesTotal } = state.details

  const sum = sumValues([principalTotal?.value, feesTotal?.value, penaltiesTotal?.value])

  return formatCurrency({
    value: sum,
    currency: principalTotal?.currency ?? '-'
  })
}

export const getSettledSumAmount = (state: State) => {
  if (!state.details || state.loading) return '-'
  const { principalSettled, feesSettled, penaltiesSettled } = state.details

  const sum = sumValues([principalSettled?.value, feesSettled?.value, penaltiesSettled?.value])

  return formatCurrency({
    value: sum,
    currency: principalSettled?.currency ?? '-'
  })
}

export const getRemainingSumAmount = (state: State) => {
  if (!state.details || state.loading) return '-'
  const { principalRemaining, feesRemaining, penaltiesRemaining } = state.details

  const sum = sumValues([principalRemaining?.value, feesRemaining?.value, penaltiesRemaining?.value])

  return formatCurrency({
    value: sum,
    currency: principalRemaining?.currency ?? '-'
  })
}

export const getAccruedSumAmount = (state: State) => {
  if (!state.details || state.loading) return '-'
  return formatCurrency(state.details.feesAccrued)
}
