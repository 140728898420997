import LoaderRenderProp from '../components/LoaderRenderProp'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { CREDITS_PATH } from 'routes/constants'
import { getCredit, getLoanInvoice } from '../api'

const RepaymentInvoicePage: React.FC = () => {
  const navigate = useNavigate()

  const { id = '' } = useParams<'id'>()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const data = await getLoanInvoice(id)
        const creditData = await getCredit((data as any).creditId)

        navigate(`${CREDITS_PATH}/company-credits/${creditData.id}`)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(err)
        setLoading(false)
      }
    }

    fetchInvoice()
  }, [id, navigate])

  return <LoaderRenderProp showLoader={loading} render={() => null} />
}

export default RepaymentInvoicePage
