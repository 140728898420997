import get from 'lodash/get'
import { useReducer } from 'react'

export enum ActionTypes {
  loading = 'LOADING',
  success = 'SUCCESS',
  error = 'ERROR'
}

interface IInitState<T> {
  loading: boolean
  error: string
  details: T | null
}

interface IActionLoading {
  type: ActionTypes.loading
}

interface IActionSuccess<T> {
  type: ActionTypes.success
  payload: {
    data: T
  }
}

interface IActionError<T> {
  type: ActionTypes.error
  payload: {
    error:
      | {
          response: {
            data: {
              message: string
            }
          }
        }
      | string
      | unknown
    data?: T
  }
}

export function useFetchReducer<T>(overrides?: Partial<IInitState<T>>) {
  type InitState = IInitState<T>

  const initialState: InitState = {
    loading: true,
    error: '',
    details: null,
    ...overrides
  }

  const reducer = (state: InitState, action: IActionLoading | IActionSuccess<T> | IActionError<T>): InitState => {
    switch (action.type) {
      case ActionTypes.loading:
        return { details: null, loading: true, error: '' }
      case ActionTypes.success:
        return {
          details: action.payload.data,
          loading: false,
          error: ''
        }
      case ActionTypes.error:
        return {
          details: null,
          loading: false,
          error: get(action, 'payload.error.response.data.message', 'Unexpected error occured'),
          ...(action.payload.data ? { details: action.payload.data } : {})
        }
      default:
        throw new Error()
    }
  }

  return useReducer(reducer, initialState)
}
