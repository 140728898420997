import dayjs from 'dayjs'
import {
  BackOfficeCardCreditRepaymentTransactionDto2,
  BackOfficeStandardTransactionDto2,
  BackOfficeTopUpTransactionDto2,
  BackOfficeUnknownTransactionDto2,
  BackofficeCardTxType2
} from '../api/swagger/definitions/backoffice'
import * as ApiHandler from '../api'

export const invite = async (personId: string, customerId: string) =>
  ApiHandler.patchPerson({ inviteSentAt: Math.ceil(dayjs().valueOf() / 1000) }, personId, customerId)

export type BackOfficeStandardTransactionDto2ConstEnum = BackOfficeStandardTransactionDto2 & {
  txType: typeof BackofficeCardTxType2.STANDARD
}

export type BackOfficeTopUpTransactionDto2ConstEnum = BackOfficeTopUpTransactionDto2 & {
  txType: typeof BackofficeCardTxType2.TOP_UP
}

export type BackOfficeCardCreditRepaymentTransactionDto2ConstEnum = BackOfficeCardCreditRepaymentTransactionDto2 & {
  txType: typeof BackofficeCardTxType2.CARD_CREDIT_REPAYMENT
}

export type BackOfficeUnknownTransactionDto2ConstEnum = BackOfficeUnknownTransactionDto2 & {
  txType: typeof BackofficeCardTxType2.UNKNOWN
}

export type BackOfficeAllTransactionDtos =
  | BackOfficeStandardTransactionDto2ConstEnum
  | BackOfficeTopUpTransactionDto2ConstEnum
  | BackOfficeCardCreditRepaymentTransactionDto2ConstEnum
  | BackOfficeUnknownTransactionDto2ConstEnum

// Force the types to have a constant enum value instead of a generic enum value
export const getTransactionsV2ConstEnum = async (...args: Parameters<typeof ApiHandler.getTransactionsV2>) =>
  ApiHandler.getTransactionsV2(...args) as Promise<BackOfficeAllTransactionDtos[]>
