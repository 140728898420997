import { formatCurrency } from 'helpers/utils'
import React from 'react'
import { CompanyCreditDto } from '../api/swagger/definitions/backoffice'
import Box from '@mui/material/Box'
import styled from '@emotion/styled'

const Key = styled.span`
  color: black;
`

const Value = styled.span`
  color: #87888b;
`

const CreditAccountingGeneral: React.FC<{
  credit: CompanyCreditDto
}> = ({ credit }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', color: 'black', gap: '8px' }}>
    <span>
      <Key>No: </Key>
      <Value>{credit.no}</Value>
    </span>
    <span>
      <Key>State: </Key>
      <Value>{credit.state}</Value>
    </span>
    <span>
      <Key>Credit Date: </Key>
      <Value>{credit.creditDate}</Value>
    </span>
    <span>
      <Key>Due Date: </Key>
      <Value>{credit.dueDate}</Value>
    </span>
    <span>
      <Key>Monthly Fee: </Key>
      <Value>{credit.monthlyFee}</Value>
    </span>
    <span>
      <Key>Credit Period (months): </Key>
      <Value>{credit.creditPeriodMonths}</Value>
    </span>
    <span>
      <Key>Tie Up Period (months): </Key>
      <Value>{credit.tieUpPeriodMonths}</Value>
    </span>
    <span>
      <Key>Repayment Method: </Key>
      <Value>{credit.repaymentMethod}</Value>
    </span>
    <span>
      <Key>Repayment Model: </Key>
      <Value>{credit.repaymentModel}</Value>
    </span>
    <span>
      <Key>Paid out amount: </Key>
      <Value>{formatCurrency(credit.paidOutAmount)}</Value>
    </span>
  </Box>
)

export default CreditAccountingGeneral
