/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceState = {
    DRAFT: 'DRAFT',
    BOOKED: 'BOOKED',
    SETTLED: 'SETTLED',
    CANCELLED: 'CANCELLED',
    OVER_DUE: 'OVER_DUE',
    REMINDED: 'REMINDED',
    REMINDER_OVERDUE: 'REMINDER_OVERDUE',
    DEBT_COLLECTION: 'DEBT_COLLECTION',
    CREDITED: 'CREDITED'
} as const;

export type InvoiceState = typeof InvoiceState[keyof typeof InvoiceState];



