import * as React from "react";

function DenmarkFlag(props: React.SVGProps<SVGSVGElement>) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" shapeRendering="geometricPrecision"
              textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd"
              clipRule="evenodd" viewBox="0 0 24 24">
    <g fillRule="nonzero">
      <path fill="#C60C30"
            d="M1.322 0H22.678C23.887 0 24 1.209 24 2.418v19.164C24 22.791 23.887 24 22.678 24H1.322C0.113 24 0 22.791 0 21.582V2.418C0 1.209 0.113 0 1.322 0z"/>
      <path fill="#fff" d="M0 10.125h7.756V0h2.611v10.125H24v2.611H10.367V24H7.756V12.736H0z"/>
    </g>
  </svg>

}

export default DenmarkFlag;