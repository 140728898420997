import Switch from '@mui/material/Switch'
import React, { useEffect, useState } from 'react'
import Colors from '../../figma/panda/Colors'
import Durations from '../../figma/tokens/Durations'
import { MUI_SWITCH_BASE_PX } from '../StrongHardCodedSizes'
interface IProps {
  checked?: boolean
  disabled?: boolean
  onChange?: (event: any, checked: boolean) => void
}

const MaterialSwitch: React.FC<React.PropsWithChildren<React.PropsWithChildren<IProps>>> = ({ checked: _checked, disabled, onChange }) => {
  const [checked, setChecked] = useState(_checked || false)

  useEffect(() => {
    setChecked(_checked || false)
  }, [_checked])

  return (
    <Switch
      sx={(theme) => ({
        width: MUI_SWITCH_BASE_PX + 20,
        height: MUI_SWITCH_BASE_PX + 4,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: '2px',
          transitionDuration: `${Durations.medium}ms`,
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: `${Colors.baseWhite} !important`,
            opacity: 1,
            '& + .MuiSwitch-track': {
              backgroundColor: `${Colors.bluePrimary600Cta} !important`,
              opacity: 1,
              border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              backgroundColor: `${Colors.bluePrimary400} !important`,
              opacity: 1
            }
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: `${Colors.base300} !important`,
            opacity: 1
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            backgroundColor: `${Colors.base500} !important`
          }
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: MUI_SWITCH_BASE_PX,
          height: MUI_SWITCH_BASE_PX
        },
        '& .MuiSwitch-track': {
          border: `1px solid ${Colors.base300} !important`,
          borderRadius: (MUI_SWITCH_BASE_PX + 4) / 2,
          backgroundColor: `${Colors.baseWhite} !important`,
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500
          })
        }
      })}
      disabled={disabled}
      type="checkbox"
      size="small"
      disableRipple
      value={checked}
      checked={checked}
      onChange={(event, checked) => (onChange ? onChange(event, checked) : setChecked(checked))}
    />
  )
}

export default MaterialSwitch
