import dayjs from 'dayjs'
import { useState } from 'react'
import { YYYY_MM_DD } from '../tiger/Constants'

const today = dayjs()

const defaultFromDate = dayjs()
  .set('month', today.month() - 1)
  .set('date', 1)

const defaultToDate = defaultFromDate.set('date', defaultFromDate.daysInMonth())

export const useAccountingFilters = () => {
  const [fromDate, setFromDate] = useState<string | undefined>(defaultFromDate.format(YYYY_MM_DD))
  const [toDate, setToDate] = useState<string | undefined>(defaultToDate.format(YYYY_MM_DD))
  const [accountNos, setAccountNos] = useState<number[]>()

  const queryParamitize = (data?: any[]) => (data ? data.join(',') : '')

  const queryParam = {
    from: fromDate,
    to: toDate,
    accounts: queryParamitize(accountNos)
  }

  return {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    accountNos,
    setAccountNos,
    queryParam: Object.entries(queryParam).reduce((prev, [key, value]) => (value?.length ? { ...prev, [key]: value } : prev), {})
  }
}

export type AccountingFilters = ReturnType<typeof useAccountingFilters>
