import { MutationKey, QueryClient } from '@tanstack/react-query'

// the queryClient.getQueriesData returns quite nested data, so we need to do some digging to get the cached data
export const getQueriesData = <T>(queryClient: QueryClient, queryKey: MutationKey): T | undefined => {
  const queryData = queryClient.getQueriesData<T>(queryKey) || []

  if (!queryData.length) return

  return queryData[0][1]
}
