import styled from '@emotion/styled'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Collapse from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import Grow from '@mui/material/Grow'
import MenuList from '@mui/material/MenuList'
import Popper, { PopperPlacementType } from '@mui/material/Popper'
import Slide from '@mui/material/Slide'
import Zoom from '@mui/material/Zoom'
import { VirtualElement } from '@popperjs/core'
import React from 'react'
import Colors from '../../figma/panda/Colors'
import BorderRadius from '../../figma/tokens/BorderRadius'
import Shadows from '../../figma/tokens/Shadows'
import Spacings from '../../figma/tokens/Spacings'
import ZIndices from '../../figma/tokens/ZIndices'
import FigmaBox from './FigmaBox'
import MyntPopperCustomTransition from './MyntPopperCustomTransition'

type Props = {
  disablePortal?: boolean
  anchorRef: VirtualElement | (() => VirtualElement) | null | undefined
  visible: boolean
  content: JSX.Element
  handleClose?: (event: MouseEvent | TouchEvent) => unknown
  width?: string
  marginTop?: string | boolean
  marginBottom?: string | boolean
  noPadding?: boolean
  paddingLeft?: string
  paddingRight?: string
  boxShadow?: string
  offsetLeft?: string
  id?: string
  position?: PopperPlacementType
  zIndex?: number
  style?: React.CSSProperties
  arrowLeft?: boolean
  transitionEffect?: 'collapse' | 'fade' | 'grow' | 'slide' | 'zoom' | 'custom'
  transitionStates?: { [key: string]: React.CSSProperties }
  transitionDuration?: number
  timeout?: number
}

/**
 * Mui popper with support for custom transition component
 *
 * @remarks
 * The popper defaults to using Grow as transition component
 * To change transition you can pass collapse/fade/grow/slide/zoom/custom using transitionEffect prop
 * To use custom transition you also need to set timeout, transitionDuration, transitionStates
 * This timeout/transitionStates format is not strictly following the react-transition-group docs
 * It is a solid workaround for issues we've experienced with transition states not working properly
 * See examples of defining timeout/transitionStates below
 *
 * @param transitionEffect - choose transition style - collapse/fade/grow/slide/zoom/custom
 * @param transitionDuration - define duration for custom transitions
 * @param transitionStates - define states for custom transitions
 *
 * @example
 * Here's how to define custom transition timeout:
 *   timeout={isPopperVisible ? 0 : transitionDuration}
 *
 * @example
 * Here's how to define custom transition transitionStates:
 *   transitionStates={{
 *     entering: { opacity: 0 }, - starting point of enter effect
 *     entered: { opacity: 1 }, - ending point of enter effect / open state / starting point of exit effect
 *     exiting: { opacity: 0 }, - ending point of exit effect
 *     exited: { opacity: 0 } - ending point of exit effect / starting point of enter effect
 *   }}
 *
 */

const MyntPopper: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  disablePortal = false,
  anchorRef,
  visible,
  content: Content,
  handleClose,
  width,
  marginTop,
  marginBottom,
  noPadding,
  paddingLeft: _paddingLeft,
  paddingRight: _paddingRight,
  id,
  position,
  zIndex,
  style = {},
  boxShadow,
  offsetLeft,
  arrowLeft,
  transitionEffect,
  transitionStates,
  transitionDuration,
  timeout
}) => {
  let TransitionComponent
  if (transitionEffect === 'collapse') TransitionComponent = Collapse
  if (transitionEffect === 'fade') TransitionComponent = Fade
  if (transitionEffect === 'slide') TransitionComponent = Slide
  if (transitionEffect === 'zoom') TransitionComponent = Zoom
  if (transitionEffect === 'custom') TransitionComponent = MyntPopperCustomTransition
  if (!transitionEffect) TransitionComponent = Grow

  const paddingLeft = _paddingLeft ? _paddingLeft : true
  const paddingRight = _paddingRight ? _paddingRight : true

  const ref = React.useRef(null)

  return (
    <Popper
      id={id}
      transition
      open={visible}
      role={undefined}
      anchorEl={anchorRef}
      placement={position}
      style={{ ...style, zIndex: zIndex ?? ZIndices.top, width }}
      disablePortal={disablePortal}
    >
      {({ TransitionProps, placement }) => (
        <TransitionComponent
          {...TransitionProps}
          nodeRef={ref}
          appear
          mountOnEnter
          unmountOnExit
          timeout={timeout}
          transitionStates={transitionStates}
          transitionDuration={transitionDuration}
          style={{ transformOrigin: position || placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <StyledFigmaBox
            ref={ref}
            fullWidth
            marginTop={marginTop}
            marginBottom={marginBottom}
            left={noPadding ? !noPadding : paddingLeft}
            right={noPadding ? !noPadding : paddingRight}
            spacing={Spacings.medium}
            boxShadow={boxShadow}
            offsetLeft={offsetLeft}
            arrowLeft={arrowLeft}
          >
            <ClickAwayListener onClickAway={(e) => handleClose?.(e)}>
              <MenuList autoFocusItem={visible} disablePadding={noPadding}>
                {Content}
              </MenuList>
            </ClickAwayListener>
          </StyledFigmaBox>
        </TransitionComponent>
      )}
    </Popper>
  )
}

const StyledFigmaBox = styled(FigmaBox)<{
  arrowLeft?: boolean
  marginTop?: string | boolean
  marginBottom?: string | boolean
  offsetLeft?: string
  boxShadow?: string
}>`
  position: relative;
  width: auto;
  ${({ marginTop }) => marginTop && `margin-top: ${typeof marginTop === 'string' ? marginTop : Spacings.min}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${typeof marginBottom === 'string' ? marginBottom : Spacings.min}`};
  margin-left: ${({ offsetLeft }) => (offsetLeft ? offsetLeft + '!important' : '')};
  background: ${Colors.baseWhite};
  border-radius: ${BorderRadius.soft};
  overflow: visible;
  box-shadow: ${Shadows.illustrationMedium};
  ${({ boxShadow }) => boxShadow && `box-shadow: none; filter: drop-shadow(${boxShadow})`};

  ${({ arrowLeft }) =>
    arrowLeft &&
    `
  &::before {
    content: '';
    position: absolute;
    bottom: 2rem;
    right: 100%;
    border-top: ${Spacings.tiny} solid transparent;
    border-bottom: ${Spacings.tiny} solid transparent;
    border-right: ${Spacings.small} solid white;
  }`}

  .MuiList-root {
    outline: none !important;
  }
`

export default React.memo(MyntPopper)
