/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceType = {
    REQUEST_FOR_PAYMENT: 'REQUEST_FOR_PAYMENT',
    REPAYMENT_INVOICE: 'REPAYMENT_INVOICE',
    SUBSCRIPTION_FEE: 'SUBSCRIPTION_FEE',
    ADMINISTRATION_FEE: 'ADMINISTRATION_FEE',
    WITHDRAWAL_TO_MYNT: 'WITHDRAWAL_TO_MYNT',
    WITHDRAWAL_TO_CUSTOMER: 'WITHDRAWAL_TO_CUSTOMER',
    CREDIT_NOTE: 'CREDIT_NOTE',
    CREDIT_LIMIT_FEE: 'CREDIT_LIMIT_FEE',
    CARD_CREDIT_INTEREST_FEE: 'CARD_CREDIT_INTEREST_FEE',
    CARD_CREDIT_DELAYED_PAYMENT: 'CARD_CREDIT_DELAYED_PAYMENT',
    INVOICE_FEE: 'INVOICE_FEE',
    CASHBACK: 'CASHBACK',
    CARD_CREDIT_MONTHLY_FEE: 'CARD_CREDIT_MONTHLY_FEE',
    CARD_CREDIT_USAGE_FEE: 'CARD_CREDIT_USAGE_FEE'
} as const;

export type InvoiceType = typeof InvoiceType[keyof typeof InvoiceType];



