/* eslint-disable no-console */
import { formatDate } from 'helpers/utils'
import { ActionTypes, useFetchReducer } from 'hooks/fetchReducer'
import React, { useEffect, useState } from 'react'
import { RepaymentInvoiceDto } from '../api/swagger/definitions/backoffice'
import useMaterialNotification from '../hooks/useMaterialNotification'
import { Repayment, RepaymentPlan } from '../interfaces/RepaymentInterfaces'
import {
  attachInvoiceToRepayment as attachInvoiceToRepaymentService,
  createRepayment,
  dropInvoiceFromRepayment as dropInvoiceFromRepaymentService,
  getLoanAllInvoices,
  getRepaymentPlan,
  rebuildInvoicesAndRepaymentPlanFromFortnox,
  recreateRepaymentInvoice as recreateRepaymentInvoiceService,
  syncInvoicesWithFortnox,
  updateRepayment
} from '../api'
import { getRepaymentInvoiceAPI } from './repaymentInvoiceHelper'

export const useRepaymentPlan = (creditId: string | number, repaymentModel?: string) => {
  const [submitting, setSubmitting] = useState<{
    type: 'update' | 'drop' | 'recreate' | ''
    index: number
  }>({ type: '', index: 0 })

  const [state, dispatch] = useFetchReducer<RepaymentPlan>({
    loading: true
  })

  const notify = useMaterialNotification()

  const fetchRepaymentPlan = React.useCallback(async () => {
    try {
      dispatch({ type: ActionTypes.loading })
      const data = await getRepaymentPlan(creditId.toString())
      dispatch({
        type: ActionTypes.success,
        payload: { data: data as RepaymentPlan }
      })
    } catch (e) {
      dispatch({ type: ActionTypes.error, payload: { error: e } })
    }
  }, [dispatch, creditId])

  useEffect(() => {
    fetchRepaymentPlan()
  }, [fetchRepaymentPlan])

  const updateRepaymentField =
    (name: 'amortization' | 'fee' | 'dueDate' | 'adminFee' | 'penalty', index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (state.details === null) return
      const details = { ...state.details }

      if (name === 'dueDate') {
        details.repayments[index][name] = e.target.value
      } else {
        details.repayments[index][name] = {
          currency: state.details.repayments[index][name].currency,
          value: parseFloat(e.target.value)
        }
      }

      dispatch({ type: ActionTypes.success, payload: { data: details } })
    }

  const createRepaymentField = () => {
    if (state.details === null) return
    const details = { ...state.details }
    const { repayments } = details
    const newRepaymentField = { ...repayments[repayments.length - 1] }
    const dueDate = new Date(`${newRepaymentField.dueDate}`)
    let newDueDate

    if (repaymentModel === 'STRAIGHT_WEEKLY' || repaymentModel === 'FEE_FIRST_WEEKLY') {
      newDueDate = new Date(dueDate.setDate(dueDate.getDate() + 7))
    } else {
      newDueDate = new Date(dueDate.setMonth(dueDate.getMonth() + 1))
    }

    newRepaymentField.no = newRepaymentField.no + 1
    newRepaymentField.rev = 1
    newRepaymentField.dueDate = formatDate(newDueDate)
    newRepaymentField.new = true
    newRepaymentField.repaymentInvoiceId = null
    repayments.push(newRepaymentField)
    dispatch({ type: ActionTypes.success, payload: { data: details } })
  }

  const removeRepaymentField = (index: number) => {
    if (state.details === null) return
    const details = { ...state.details }
    details.repayments.splice(index, 1)
    dispatch({ type: ActionTypes.success, payload: { data: details } })
  }

  const updateRepaymentAction = async (index: number) => {
    if (state.details === null) return
    setSubmitting({ type: 'update', index })
    const details = { ...state.details }
    const repayment = details.repayments[index]
    repayment.rev = repayment.rev + 1

    try {
      const { no, planId } = repayment
      const data = await updateRepayment(creditId.toString(), no, planId, repayment)

      details.repayments[index] = data as Repayment
      dispatch({ type: ActionTypes.success, payload: { data: details } })

      notify(`Repayment (${index + 1}) was successfully updated`)

      return data
    } catch (err) {
      console.log(err)
    } finally {
      setSubmitting({ type: '', index: 0 })
    }
  }

  const createRepaymentAction = async (index: number) => {
    if (state.details === null) return
    setSubmitting({ type: 'update', index })

    const details = { ...state.details }
    const repayment = details.repayments[index]

    try {
      const { planId } = repayment
      const data = await createRepayment(creditId.toString(), planId, repayment)
      details.repayments[index] = data as Repayment
      dispatch({ type: ActionTypes.success, payload: { data: details } })

      notify(`Repayment (${index + 1}) was successfully created`)

      return data
    } catch (err) {
      console.log(err)
    } finally {
      setSubmitting({ type: '', index: 0 })
    }
  }

  const dropInvoiceFromRepayment = async (index: number) => {
    if (state.details === null) return
    setSubmitting({ type: 'drop', index })
    const details = { ...state.details }

    try {
      const currentRepayment = details.repayments[index]
      const { no, planId } = currentRepayment
      const data = await dropInvoiceFromRepaymentService(creditId.toString(), no, planId)

      details.repayments[index] = data as Repayment
      dispatch({ type: ActionTypes.success, payload: { data: details } })

      notify(`Repayment invoice (${index + 1}) was successfully dropped`)
    } catch (err) {
      console.warn(err)
    } finally {
      setSubmitting({ type: '', index: 0 })
    }
  }

  const attachInvoiceToRepayment = async (repayment: Repayment, body: { invoiceNo: number }) => {
    if (state.details === null) return
    const details = { ...state.details }
    const repaymentIndex = details.repayments.findIndex((rep) => rep.no === repayment.no)

    try {
      const data = await attachInvoiceToRepaymentService(creditId.toString(), repayment.planId, repayment.no, body)

      details.repayments[repaymentIndex] = data as Repayment
      dispatch({ type: ActionTypes.success, payload: { data: details } })

      notify(`You have attached fortnox invoice no ${body.invoiceNo}`)
    } catch (err) {
      console.warn(err)
    }
  }

  const recreateRepaymentInvoice = async (repayment: Repayment) => {
    if (state.details === null) return
    const details = { ...state.details }
    const repaymentIndex = details.repayments.findIndex((rep) => rep.no === repayment.no)

    setSubmitting({ type: 'recreate', index: repaymentIndex })

    try {
      const data = await recreateRepaymentInvoiceService(creditId.toString(), repayment.planId, repayment.no)

      details.repayments[repaymentIndex] = data as Repayment
      dispatch({ type: ActionTypes.success, payload: { data: details } })

      notify(`Repayment invoice (${repayment.no}) was recreated`)
    } catch (err) {
      console.warn(err)
    } finally {
      setSubmitting({ type: '', index: 0 })
    }
  }

  return {
    ...state,
    updateRepaymentField,
    createRepaymentField,
    removeRepaymentField,
    fetchRepaymentPlan,
    dropInvoiceFromRepayment,
    submitting,
    createRepayment: createRepaymentAction,
    updateRepayment: updateRepaymentAction,
    recreateRepaymentInvoice,
    attachInvoiceToRepayment
  }
}

export const useRepaymentInvoices = (creditId: string | number) => {
  // TODO the RepaymentInvoiceDto type is missing id for some reason, it should be RepaymentInvoiceDto instead of any
  const [state, dispatch] = useFetchReducer<any[]>({
    loading: true
  })

  const fetchInvoices = React.useCallback(async () => {
    try {
      dispatch({ type: ActionTypes.loading })
      const data = await getLoanAllInvoices(creditId.toString())
      dispatch({ type: ActionTypes.success, payload: { data } })
    } catch (e) {
      dispatch({ type: ActionTypes.error, payload: { error: e } })
    }
  }, [dispatch, creditId])

  useEffect(() => {
    fetchInvoices()
  }, [fetchInvoices])

  const setInvoiceById = (id: string, data: RepaymentInvoiceDto) => {
    if (state.details === null) return
    const index = state.details.findIndex((invoice) => invoice.id === id)
    const invoices = [...state.details]
    invoices[index] = data
    dispatch({ type: ActionTypes.success, payload: { data: invoices } })
  }

  const pushInvoice = (invoice: RepaymentInvoiceDto) => {
    if (state.details === null) return
    const invoices = [...state.details, invoice]
    dispatch({ type: ActionTypes.success, payload: { data: invoices } })
  }

  return { ...state, fetchInvoices, setInvoiceById, pushInvoice }
}

export const useInvoicesFromFortnox = (creditId: string | number, planId: string | number, onSubmit?: () => void) => {
  const [submitting, setSubmitting] = useState(false)

  const setupIvoicesFromFortnoxAction = async () => {
    try {
      setSubmitting(true)
      await rebuildInvoicesAndRepaymentPlanFromFortnox(creditId.toString(), planId.toString())
      onSubmit && onSubmit()
    } catch (err) {
      console.warn(err)
    } finally {
      setSubmitting(false)
    }
  }

  return { setupIvoicesFromFortnox: setupIvoicesFromFortnoxAction, submitting }
}

export const useRepaymentInvoiceAction = (
  type: 'cancelled' | 'credited' | 'debt-collection' | 'booked' | 'sync' | 'draft',
  id: string,
  setInvoiceById: (id: string, data: RepaymentInvoiceDto) => void
) => {
  const [submitting, setSubmitting] = useState(false)
  const notify = useMaterialNotification()

  const handleBtnClick = async () => {
    try {
      setSubmitting(true)
      // @ts-expect-error - TypeScript is not working properly for some reason
      const data = type === 'sync' ? await syncInvoicesWithFortnox(id) : await getRepaymentInvoiceAPI(type)(id)

      setInvoiceById(id, data)

      notify(`Action was successful`)
    } catch (e) {
      console.warn(e)
    } finally {
      setSubmitting(false)
    }
  }

  return { submitting, handleBtnClick }
}
