import styled from '@emotion/styled'
import { Formik } from 'formik'
import { useState } from 'react'
import ReactJson from 'react-json-view'
import * as Yup from 'yup'
import { AutogiroApprovalState } from '../api/swagger/definitions/backoffice'
import ButtonIconChangeStatus from '../figma/images/buttonIconChangeStatus'
import Spacings from '../figma/tokens/Spacings'
import { useCardBalanceConfig, useCreateCardBalanceConfig, useMandateApprovalv4 } from '../api/react-query'
import { BorderContainer, BorderContainerHeader } from '../helpers/CreditOnCardHelpers'
import { captureException } from '../helpers/sentryHelpers'
import useMaterialNotification from '../hooks/useMaterialNotification'
import { BO_TWO_COLUMN_SIZE_LEFT, BO_TWO_COLUMN_SIZE_RIGHT } from '../mynt-components/WeakHardCodedSizes'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaButton from '../mynt-components/components/FigmaButton'
import FigmaStatusBox, { FigmaFieldStatusTypes } from '../mynt-components/components/FigmaStatusBox'
import MaterialTextFieldFormik from '../mynt-components/components/MaterialFieldFormik'
import MaterialSwitch from '../mynt-components/components/MaterialSwitch'
import { GenericObjectViewContainer, Line, UnstyledButton } from '../mynt-components/components/StyledComponents'
import { TextKeys, getText } from '../tiger/libs/TextRepository'
import TextContainer from './TextContainer'
import { TooltipChangeAccountStatus } from './Tooltips'

type Props = {
  customerId: string
  setIsChangeStatusModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const DEFAULT = 'default'

const negativeNumberErrorMessage = getText(TextKeys.mFautoTopUpFallsBelowError, 'figma')
const numberErrorMessage = TextKeys.autoTopUpModalformAddaNumber.characters

const ValidationSchema = Yup.object().shape({
  threshold: Yup.number().required(numberErrorMessage).typeError(numberErrorMessage),
  topUpValue: Yup.number().required(negativeNumberErrorMessage).typeError(negativeNumberErrorMessage).min(0, negativeNumberErrorMessage)
})

export default function CustomerCardTabAutoTopup({ customerId, setIsChangeStatusModalOpen }: Props) {
  const notify = useMaterialNotification()

  const [isMutating, setIsMutating] = useState<boolean>(false)

  const { data: autoGiroApproval, isLoading: isLoadingAutoGiro } = useMandateApprovalv4(customerId)
  const { data: cardBalanceConfig, isLoading: isLoadingCardBalanceConfig } = useCardBalanceConfig(customerId)

  const initialValues = {
    threshold: cardBalanceConfig?.topUp?.threshold?.toString(),
    topUpValue: cardBalanceConfig?.topUp?.topUpValue?.toString()
  }

  const directDebitStatus = {
    [AutogiroApprovalState.APPROVED]: FigmaFieldStatusTypes.ACTIVE,
    [AutogiroApprovalState.FAILED]: FigmaFieldStatusTypes.ERROR,
    [AutogiroApprovalState.PENDING]: FigmaFieldStatusTypes.PENDING,
    [AutogiroApprovalState.REQUESTED]: FigmaFieldStatusTypes.REQUESTED,
    [DEFAULT]: FigmaFieldStatusTypes.INACTIVE
  }[autoGiroApproval?.state || DEFAULT]

  const autoTopupStatusSetByCustomer = cardBalanceConfig?.topUp?.active
  const mutation = useCreateCardBalanceConfig(customerId)

  const onToggleAutotopup = async () => {
    try {
      setIsMutating(true)

      await mutation.mutateAsync({
        ...cardBalanceConfig,
        topUp: {
          ...cardBalanceConfig?.topUp,
          active: !cardBalanceConfig?.topUp?.active
        }
      })
    } catch (error) {
      if (error instanceof Error) {
        captureException(error)
      } else {
        throw error
      }
    } finally {
      setIsMutating(false)
    }
  }

  const onSubmit = async ({ threshold, topUpValue }) => {
    try {
      await mutation.mutateAsync({
        ...cardBalanceConfig,
        topUp: {
          ...cardBalanceConfig?.topUp,
          threshold,
          topUpValue
        }
      })

      notify('Successfully set auto top-up limit', 'success')
    } catch (error) {
      if (error instanceof Error) {
        captureException(error)
      } else {
        throw error
      }
    }
  }

  const bankAccountValue = (() => {
    switch (autoGiroApproval?.type) {
      case 'AUTOGIRO':
        return autoGiroApproval.bankAccount
      case 'SEPA_DIRECT_DEBIT':
        return autoGiroApproval.iban
      case 'OVERFORSELSSERVICE':
        return autoGiroApproval.bankAccount
      case undefined:
        return ''
    }
  })()

  if (isLoadingAutoGiro || isLoadingCardBalanceConfig) {
    return null
  }

  return (
    <BorderContainer>
      <BorderContainerHeader>
        <TextContainer textKey={TextKeys.autoTopUpHeading} />

        <TooltipChangeAccountStatus
          content={
            <UnstyledButton onClick={() => setIsChangeStatusModalOpen && setIsChangeStatusModalOpen(true)}>
              <ButtonIconChangeStatus />
            </UnstyledButton>
          }
        />
      </BorderContainerHeader>

      <FigmaBox fullWidth direction="row" justify="space-between" align="stretch" gap={Spacings.medium}>
        <FigmaBox fullWidth direction="column">
          <FigmaBox top={Spacings.small} bottom={Spacings.medium}>
            <TextContainer textKey={TextKeys.autoTopUpSetLimitLabel} />
          </FigmaBox>

          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
            {(formProps) => (
              <FormContainer fullWidth bottom direction="column">
                <MaterialTextFieldFormik
                  fullWidth
                  labelTextKey={TextKeys.mFautoTopUpFallsBelowLabel}
                  name="threshold"
                  formProps={formProps}
                />
                <FigmaBox top fullWidth>
                  <MaterialTextFieldFormik fullWidth labelTextKey={TextKeys.mFautoTopUpToLabel} name="topUpValue" formProps={formProps} />
                </FigmaBox>
                <FigmaBox top fullWidth>
                  <FigmaButton onClick={formProps.handleSubmit} textKey={TextKeys.autoTopUpSetLimitButton} />
                </FigmaBox>
              </FormContainer>
            )}
          </Formik>
        </FigmaBox>

        <Line vertical />

        <GenericObjectViewContainer fullWidth>
          <TextContainer textKey={TextKeys.customersCardAutoTopUpAutoTopUpDetailsHeading} />
          <FigmaBox top={Spacings.medium} bottom={Spacings.medium}>
            <TextContainer textKey={TextKeys.autoTopUpDirectDebitApproved} />
            {directDebitStatus && (
              <FigmaBox top={Spacings.min}>
                <FigmaStatusBox status={directDebitStatus} />
              </FigmaBox>
            )}
          </FigmaBox>
          <FigmaBox fullWidth direction="row" justify="space-between" align="flex-start" gap={Spacings.minimum} bottom={Spacings.medium}>
            <FigmaBox fullWidth flex={BO_TWO_COLUMN_SIZE_LEFT}>
              <TextContainer textKey={TextKeys.autoTopUpCustomerSetDetails} />
            </FigmaBox>
            <FigmaBox fullWidth flex={BO_TWO_COLUMN_SIZE_RIGHT} align="flex-end">
              <MaterialSwitch checked={!!autoTopupStatusSetByCustomer} onChange={onToggleAutotopup} disabled={isMutating} />
            </FigmaBox>
          </FigmaBox>
          <FigmaBox bottom={Spacings.min}>
            <TextContainer textKey={TextKeys.autoTopUpDetailsBankAccount} />
          </FigmaBox>
          <TextContainer textKey={TextKeys.DirectDebitDetailsBankAccountValue} text={bankAccountValue} />
        </GenericObjectViewContainer>
      </FigmaBox>

      <FigmaBox top spacing={Spacings.huge}>
        <ReactJson src={autoGiroApproval || {}} name="" />
      </FigmaBox>
    </BorderContainer>
  )
}

const FormContainer = styled(FigmaBox)`
  min-width: 300px;
`
