import get from 'lodash/get'
import omit from 'lodash/omit'
import { CompanyCreditDto, CreditState } from '../api/swagger/definitions/backoffice'
import { setEmptyPropsToNull } from './utils'

// This method is a nightmare, this needs to be remade
export const formatSubmitValues = (initValues: CompanyCreditDto, submitValues: Partial<CompanyCreditDto>, isCompanyCredit: boolean) => {
  const updatedValues: CompanyCreditDto = {
    ...initValues,
    ...omit(submitValues, 'keys'),
    rev: get(initValues, 'rev', 0) + 1,
    creditAmount: {
      value: submitValues?.creditAmount?.value ?? initValues.creditAmount?.value,
      currency: 'SEK'
    },
    paidOutAmount: {
      value: submitValues?.paidOutAmount?.value ?? initValues.paidOutAmount?.value,
      currency: 'SEK'
    },
    repaymentPlan: get(submitValues, 'repaymentPlan', [])
      .filter((x: any) => !!x)
      .map((item: any) => ({
        ...item,
        no: parseInt(item.no, 10),
        amortization: {
          value: parseFloat(item.amortization),
          currency: 'SEK'
        },
        fee: {
          value: parseFloat(item.fee),
          currency: 'SEK'
        }
      })),
    sumOfExtraAmortizations: {
      value: submitValues.sumOfExtraAmortizations?.value ?? initValues.sumOfExtraAmortizations?.value,
      currency: get(initValues, 'sumOfExtraAmortizations.currency', 'SEK')
    },
    sumOfExtraFeePayments: {
      value: submitValues.sumOfExtraFeePayments?.value ?? initValues.sumOfExtraFeePayments?.value,
      currency: get(initValues, 'sumOfExtraFeePayments.currency', 'SEK')
    },
    ...(isCompanyCredit &&
      ({
        requestAmount: {
          value: submitValues.requestAmount?.value ?? initValues.requestAmount?.value,
          currency: 'SEK'
        },
        requestPeriodMonths: submitValues.requestPeriodMonths ?? initValues.requestPeriodMonths
      } as any))
  }

  setEmptyPropsToNull(updatedValues)
  return updatedValues
}

export const isAccountingTab = (state: CreditState) => [CreditState.PAID_OUT, CreditState.REPAID].includes(state)
