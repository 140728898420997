import { Global } from '@emotion/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { Control, Path, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { InvoiceState } from '../api/swagger/definitions/backoffice'
import Spacings from '../figma/tokens/Spacings'
import { Currencies } from '../flamingo/config/ConfigCurrencies'
import { BO_SUBSCRIPTION_FEE_MODAL_WIDTH } from '../mynt-components/WeakHardCodedSizes'
import ModalForked from '../mynt-components/components/ModalForked'
import { Line } from '../mynt-components/components/StyledComponents'
import { When } from '../mynt-components/components/When'
import { YYYY_MM_DD } from '../tiger/Constants'
import { DatePickerControllerV2, SelectController, TextFieldController } from './react-hook-components'
import FigmaBox from '../mynt-components/components/FigmaBox'
import dayjs from 'dayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'

type Amount = {
  value: number
  currency: string
}

export type Invoice = {
  no: number
  id: string
  latePaymentInterestRate: Amount
  dueDate: string
  amount: Amount
}

type PenaltyFee = {
  currency: string
  latePaymentFee: Amount
  vat: Amount
}

export type DefaultPayloadValues = Partial<{
  state: string
  latePaymentFee: Amount
  latePaymentInterestRate: Amount
  vat: Amount
  reminderDueDate: string
}>

export type Payload = Required<DefaultPayloadValues> & {
  id: string
}

type Props = {
  isOpen: boolean
  onModalCancel: () => void
  onSubmit: (values: Payload) => unknown | Promise<unknown>
  invoice?: Invoice
  defaultPenaltyFee: PenaltyFee
  formatAmount?: (value: number, currency: string) => string
  companyName: string
  onNotification?: (message: string, type: string) => void
}

type FormData = {
  payload: DefaultPayloadValues
  dueDate: string
}

const createInitialValues = (invoice?: Invoice, defaultPenaltyFee?: PenaltyFee): FormData => ({
  payload: {
    state: InvoiceState.REMINDED,
    latePaymentFee: {
      value: defaultPenaltyFee?.latePaymentFee.value ?? 0,
      currency: defaultPenaltyFee?.latePaymentFee.currency ?? Currencies.SEK
    },
    vat: {
      currency: defaultPenaltyFee?.vat.currency ?? Currencies.SEK,
      value: defaultPenaltyFee?.vat.value ?? 0
    },
    latePaymentInterestRate: {
      currency: invoice?.latePaymentInterestRate.currency ?? Currencies.SEK,
      value: invoice?.latePaymentInterestRate.value ?? 0
    },
    reminderDueDate: invoice ? dayjs(invoice.dueDate).add(7, 'days').format(YYYY_MM_DD) : ''
  },
  dueDate: invoice ? dayjs(invoice.dueDate).format(YYYY_MM_DD) : ''
})

const validationSchema = yup.object().shape({
  payload: yup.object().shape({
    latePaymentFee: yup.object().shape({
      value: yup.number().required('fee is required').typeError('fee is required'),
      currency: yup.string().required('currency is required')
    }),
    latePaymentInterestRate: yup.object().shape({
      value: yup.number().required('interest rate is required').typeError('interest rate is required'),
      currency: yup.string().required('currency is required')
    }),
    vat: yup.object().shape({
      value: yup.number().required('vat is required').typeError('vat is required'),
      currency: yup.string().required('currency is required')
    }),
    reminderDueDate: yup.string().required('due date is required')
  })
})

const _ModalInvoicePenaltyFee: React.FC<Props> = ({
  isOpen,
  onModalCancel,
  onSubmit,
  formatAmount,
  invoice,
  defaultPenaltyFee,
  companyName,
  onNotification
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const _formatAmount = (value: number, currency: string) => formatAmount?.(value, currency) ?? value.toString()

  const form = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: createInitialValues(invoice, defaultPenaltyFee)
  })

  const onFormSubmit = async (data: ReturnType<typeof createInitialValues>) => {
    if (!invoice?.id) return onNotification?.('Invoice does not have an ID', 'error')

    setIsLoading(true)

    const payload = {
      id: invoice.id,
      ...data.payload
    }

    const promiseOrVoid = onSubmit(payload as Payload)

    if (promiseOrVoid instanceof Promise) {
      return promiseOrVoid.finally(() => setIsLoading(false))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (isLoading) return

    form.reset(createInitialValues(invoice, defaultPenaltyFee))
  }, [invoice, defaultPenaltyFee, isLoading])

  if (!isOpen) return null

  return (
    <ModalForked
      fullWidth
      alignHeadingWithCloseButton
      wrapperStyle={{ width: BO_SUBSCRIPTION_FEE_MODAL_WIDTH }} // Form adjusts size when you select different options in the selects without fixed width. Value from figma
      onClose={onModalCancel}
      heading={() => (
        <FigmaBox spacing={Spacings.large} left>
          <Typography variant="h6">Reminder invoice</Typography>
        </FigmaBox>
      )}
    >
      <MuiSelectFontFix />
      <FigmaBox
        fullWidth
        direction="row"
        justify="space-between"
        spacing={Spacings.large}
        left
        right
        bottom={Spacings.tiny}
        top={Spacings.medium}
      >
        <Typography>{companyName}</Typography>
        <Typography>{`Credit No ${invoice?.no}`}</Typography>
      </FigmaBox>
      <form onSubmit={form.handleSubmit(onFormSubmit)}>
        <FigmaBox fullWidth spacing={Spacings.large} fullPadding top={Spacings.small} gap={Spacings.min}>
          <Row control={form.control} name="dueDate" labelText="Invoice due date" type="text" disabled />
          <Row control={form.control} name="payload.reminderDueDate" labelText="Reminder due date" type="date" />
          <FigmaBox fullWidth top={Spacings.tiny} bottom={Spacings.tiny}>
            <Line fullWidth color="#ccc" />
          </FigmaBox>
          <Row
            control={form.control}
            name="payload.latePaymentFee.value"
            labelText="Late payment fee"
            type="number"
            adornmentText={form.watch('payload.latePaymentFee.currency')}
          />
          <Row
            control={form.control}
            name="payload.vat.value"
            labelText="VAT"
            type="number"
            adornmentText={form.watch('payload.vat.currency')}
          />
          <Row
            control={form.control}
            name="payload.latePaymentInterestRate.value"
            labelText="Interest rate"
            type="number"
            adornmentText={form.watch('payload.latePaymentInterestRate.currency')}
          />
          <FigmaBox top={Spacings.tiny} fullWidth>
            <LoadingButton variant="primary" disabled={!form.formState.isValid} loading={isLoading} type="submit">
              Send reminder
            </LoadingButton>
          </FigmaBox>
        </FigmaBox>
      </form>
    </ModalForked>
  )
}
export default _ModalInvoicePenaltyFee

type Options = { value: string; label: string; disabled?: boolean }[]

type RowProps<T extends Record<string, any>> = {
  control: Control<T>
  labelText?: string
  name: Path<T>
  type?: 'text' | 'select' | 'number' | 'date'
  options?: Options
  adornmentText?: string
  onChange?: (event: any) => void
  disabled?: boolean
}

const Row = <T extends Record<string, any>>({
  control,
  labelText,
  name,
  type = 'number',
  options,
  adornmentText,
  onChange,
  disabled
}: RowProps<T>) => (
  <FigmaBox fullWidth justify="space-between" direction="row">
    <FigmaBox fullWidth flex={2} style={{ whiteSpace: 'pre' }} justify="center">
      <Typography variant="subtitle1">{labelText}</Typography>
    </FigmaBox>
    <StyledTextFieldControllerContainer fullWidth flex={3}>
      <When is={['text', 'number'].includes(type)}>
        <TextFieldController
          disabled={disabled}
          type={type}
          noLabel
          name={name}
          control={control}
          adornment={adornmentText ? <Typography>{adornmentText}</Typography> : undefined}
        />
      </When>
      <When is={type === 'select'}>
        <SelectController
          noLabel
          control={control}
          name={name}
          options={options as Options}
          adormentText={adornmentText}
          onChange={onChange}
        />
      </When>
      <When is={type === 'date'}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePickerControllerV2 control={control} name={name} onChange={onChange} />
        </LocalizationProvider>
      </When>
    </StyledTextFieldControllerContainer>
  </FigmaBox>
)

// These fixes some issues that should not be applied everywhere that comes from UX. Would be improved once panda components are created
const MuiSelectFontFix = () => <Global styles={{ '.MuiPaper-root li': { fontSize: '14px !important' } }} />

const StyledTextFieldControllerContainer = styled(FigmaBox)`
  *:not(svg) {
    font-family: Inter !important;
    font-size: 14px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
