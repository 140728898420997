import styled from '@emotion/styled'

import Table from '@material-table/core'
import { KYCResponse } from '../api/react-query/interfaces/mynt'
import { useKYCResponses, usePersons } from '../api/react-query'
import Loader from './Loader'
interface Props {
  customerId: string
}

const CustomerFormPep: React.FC<Props> = ({ customerId }) => {
  const { data: kycResponses = [], isLoading: isLoadingKYCResponse } = useKYCResponses(customerId)

  const { data: persons = [], isLoading: isLoadingPersons } = usePersons(customerId)

  if (isLoadingKYCResponse || isLoadingPersons) {
    return <Loader style={{ display: 'block', margin: '50px auto 0' }} />
  }

  const columns = [
    {
      title: 'Namn',
      field: 'personId',
      render: ({ personId }: KYCResponse) => persons.find(({ id }) => id === personId)?.fullName,
      width: 150,
      filterComponent: undefined
    },
    {
      title: 'SSN',
      field: 'personId',
      render: ({ personId }: KYCResponse) => persons.find(({ id }) => id === personId)?.ssn,
      width: 150,
      filterComponent: undefined
    },
    {
      title: 'Beneficial Owner',
      field: 'beneficialOwner',
      filterComponent: undefined
    },
    {
      title: 'PEP',
      field: 'pep',
      filterComponent: undefined
    },
    {
      title: 'Accepted Terms',
      field: 'acceptedTerms',
      filterComponent: undefined
    }
  ]

  return (
    <Container>
      <Table columns={columns} data={kycResponses as KYCResponse[]} title="KYC Responses" />
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 20px;
`

export default CustomerFormPep
