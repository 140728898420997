import { yupResolver } from '@hookform/resolvers/yup'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import * as Yup from 'yup'
import {
  BackOfficeCardGroup,
  BackOfficeFeatureDto,
  BackOfficeFeatureName,
  BackOfficeOverrideField,
  BackOfficeSubscriptionPlanDto,
  CustomerProduct,
  Type,
  TrialDto
} from '../api/swagger/definitions/backoffice'
import Spacings from '../figma/tokens/Spacings'
import {
  useCustomer,
  useDeleteSubscriptionPlanOverrideField,
  useDeleteSubscriptionPlanOverrides,
  usePatchSubscriptionPlan,
  usePatchSubscriptionPlanOverrides,
  useSubscriptionPlan
} from '../api/react-query'
import { CustomerIdContext, prettifyEnumLabel } from '../helpers/CreditOnCardHelpers'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import MyntLoader from '../mynt-components/components/MyntLoader'
import CustomerCardBillingsSettingOtherFees from './CustomerCardBillingSettingsOtherFees'
import CustomerCardBillingSettingsPricingPlan from './CustomerCardBillingSettingsPricingPlan'
import CustomerCardBillingSettingsRecurringFees from './CustomerCardBillingSettingsRecurringFees'
import { ModalAreYouSureWithBody } from './Modals'
import TextContainer from './TextContainer'
import { BorderedContainer } from '../helpers/customerGeneralFormHelper.tsx'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MaterialSwitchExperimental from '../mynt-components/components/MaterialSwitchExperimental.tsx'
import Colors from '../figma/panda/Colors.ts'
import { TooltipResetOverride, TooltipValueOverridden } from './Tooltips.tsx'
import { Line, UnstyledButton } from '../mynt-components/components/StyledComponents.tsx'
import IconAlertInfoYellow from 'figma/images/iconAlertInfoYellow.tsx'
import IconRemoveOverride from '../figma/images/iconRemoveOverride.tsx'
import { DatePickerControllerV2, TextFieldController } from './react-hook-components.tsx'
import { When } from 'mynt-components/components/When.tsx'
import dayjs from 'dayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDayjs from '@mui/lab/AdapterDayjs'

type CreditOnCardV2Props = {
  customerId: string
}

export type BillingSettingsForm = {
  subscription: BackOfficeSubscriptionPlanDto
  payload: {
    subscriptionType: Type | undefined
    fxSurcharge: BackOfficeCardGroup | null
    fee: number | null
  }
}

const validationSchema = Yup.object().shape({
  payload: Yup.object().shape({
    subscriptionType: Yup.mixed().oneOf(Object.values(Type)).required(),
    fxSurcharge: Yup.mixed().oneOf(Object.values(BackOfficeCardGroup)).required(),
    fee: Yup.number()
      .required()
      .moreThan(-1)
      .typeError('Please enter a valid number')
      .transform((value) => (value === null || value === undefined ? 0 : value))
  })
})

const CustomerCardBillingSettings = ({ customerId }: CreditOnCardV2Props) => {
  const { data: customer, isLoading: customerIsLoading } = useCustomer(customerId)
  const { data: subscriptionPlan, isLoading: subscriptionPlanIsLoading, error: subscriptionPlanError } = useSubscriptionPlan(customerId)
  const { mutateAsync: mutateSubscriptionPlan, isLoading: isPatchingSubPlan } = usePatchSubscriptionPlan(customerId)
  const { mutateAsync: mutateSubscriptionPlanOverrides, isLoading: IsPatchingOverride } = usePatchSubscriptionPlanOverrides(customerId)
  const { mutateAsync: resetAllOverrides, isLoading: isDeletingOverrides } = useDeleteSubscriptionPlanOverrides(customerId)
  const { mutateAsync: resetOverride, isLoading: isDeletingOverride } = useDeleteSubscriptionPlanOverrideField(customerId)

  const [confirmAllReset, setConfirmAllReset] = useState(false)
  const [confirmSpecificReset, setConfirmSpecificReset] = useState(false)
  const [confirmEnableBetaFeature, setConfirmEnableBetaFeature] = useState(false)
  const [fieldToReset, setFieldToReset] = useState<BackOfficeOverrideField | undefined>(undefined)
  const anchor = useRef<HTMLDivElement | null>(null)
  const isLoading =
    customerIsLoading || subscriptionPlanIsLoading || isPatchingSubPlan || isDeletingOverrides || IsPatchingOverride || isDeletingOverride

  const form = useForm<BillingSettingsForm>({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      subscription: subscriptionPlan,
      payload: {
        subscriptionType: subscriptionPlan?.type ?? ('' as Type),
        fxSurcharge: subscriptionPlan?.templateOverrides?.cardGroup ?? subscriptionPlan?.template.cardGroup ?? ('' as BackOfficeCardGroup),
        fee: subscriptionPlan?.templateOverrides?.fee ?? subscriptionPlan?.template.fee ?? 0
      }
    }
  })

  useEffect(() => {
    if (!subscriptionPlan) return

    form.reset({
      subscription: subscriptionPlan,
      payload: {
        subscriptionType: subscriptionPlan?.type,
        fxSurcharge: subscriptionPlan?.templateOverrides?.cardGroup ?? subscriptionPlan?.template.cardGroup,
        fee: subscriptionPlan?.templateOverrides?.fee ?? subscriptionPlan?.template.fee
      }
    })
  }, [subscriptionPlan])

  if (!customer && customerIsLoading) return <MyntLoader />

  if (subscriptionPlanError) return <h1>{'Something went wrong (:'}</h1>

  const customerHasCard = Array.from(customer?.products ?? []).includes(CustomerProduct.CARD)
  // Don't render stuff without the card product, it will cause a lot of errors
  if (!customerHasCard)
    return <TextContainer textKey={TextKeys.creditOnCardCreditSettingsHeading} text="The customer does not have the card product enabled" />

  const handleResetOverrides = async () => {
    await resetAllOverrides('')
    form.reset()
  }

  const handleResetSpecificOverride = async (type: BackOfficeOverrideField) => {
    await resetOverride(type)
    setFieldToReset(undefined)
  }

  const handleUpdateSubscriptionPlan = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const type = event.target.value as Type | undefined
    form.setValue('payload.subscriptionType', type)
    await mutateSubscriptionPlan({ type })
  }

  const handleChangeOverrideField = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const cardGroup = event.target.value as BackOfficeCardGroup
    form.setValue('payload.fxSurcharge', cardGroup)
    await mutateSubscriptionPlanOverrides({ cardGroup })
  }

  const handleChangeSubscriptionFee = async (value: number) => {
    form.setValue('payload.fee', value)
    await mutateSubscriptionPlanOverrides({ fee: value })
  }

  const handleToggleAutoReceiptMatching = async (featureName: BackOfficeFeatureName, isEnabled: boolean) => {
    const features = form.getValues('subscription.template.features')
    const updatedFeatures = features.map((feature) => {
      if (feature.name === featureName) {
        return { ...feature, isEnabled }
      }
      return feature
    })

    form.setValue('subscription.template.features', updatedFeatures)
    await mutateSubscriptionPlanOverrides({
      feature: {
        name: featureName,
        isEnabled
      }
    })
  }

  const handleOnPriceChanged = async (feature: BackOfficeFeatureName, value: number) => {
    await mutateSubscriptionPlanOverrides({
      feature: {
        name: feature,
        price: value
      }
    })
  }

  const handleOnTrailChanged = async (feature: BackOfficeFeatureName, lengthInDays: number) => {
    await mutateSubscriptionPlanOverrides({
      trial: {
        name: feature,
        lengthInDays: lengthInDays
      }
    })
  }

  return (
    <CustomerIdContext.Provider value={customerId}>
      <FigmaBox fullWidth gap={Spacings.tiny}>
        <TextContainer textKey={TextKeys.billingSettingsHeading} />
        {confirmAllReset && (
          <ModalAreYouSureWithBody
            bodyText={TextKeys.billingSettingsModalTextResetAllValues}
            onClose={() => setConfirmAllReset(false)}
            onContinue={() => {
              setConfirmAllReset(false)
              handleResetOverrides()
            }}
            anchor={anchor.current}
          />
        )}
        {confirmSpecificReset && (
          <ModalAreYouSureWithBody
            bodyText={TextKeys.billingSettingsModalTextResetSubscriptionFee}
            onClose={() => setConfirmSpecificReset(false)}
            onContinue={() => {
              setConfirmSpecificReset(false)
              if (fieldToReset) {
                handleResetSpecificOverride(fieldToReset)
              }
            }}
            anchor={anchor.current}
          />
        )}
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <FigmaBox>
            <CustomerCardBillingSettingsPricingPlan
              form={form}
              handleUpdateSubscriptionPlan={async (e) => handleUpdateSubscriptionPlan(e)}
              handleResetOverrides={() => setConfirmAllReset(true)}
              subscriptionPlanIsLoading={subscriptionPlanIsLoading}
            />
            <CustomerCardBillingSettingsRecurringFees
              form={form}
              isLoading={isLoading}
              handleChangeSubscriptionFee={async (e) => handleChangeSubscriptionFee(e)}
              handleResetSpecificOverride={() => {
                setConfirmSpecificReset(true)
                setFieldToReset(BackOfficeOverrideField.FEE)
              }}
            />
            <CustomerCardBillingsSettingOtherFees
              form={form}
              isLoading={isLoading}
              handleChangeFxRate={async (e) => handleChangeOverrideField(e)}
              handleResetSpecificOverride={() => {
                setConfirmSpecificReset(true)
                setFieldToReset(BackOfficeOverrideField.CARD_GROUP)
              }}
            />
          </FigmaBox>
          <ToggleFeatures
            form={form}
            handleToggleAutoReceiptMatching={handleToggleAutoReceiptMatching}
            confirmToggleBetaFeature={confirmEnableBetaFeature}
            setConfirmToggleBetaFeature={setConfirmEnableBetaFeature}
            onPriceChange={handleOnPriceChanged}
            onTrailChanged={handleOnTrailChanged}
            anchor={anchor}
            handleResetSpecificOverride={(field: BackOfficeOverrideField) => {
              setConfirmSpecificReset(true)
              setFieldToReset(field)
            }}
          />
        </Box>
      </FigmaBox>
    </CustomerIdContext.Provider>
  )
}

export default CustomerCardBillingSettings

type PriceFormValues = Record<BackOfficeFeatureName, BackOfficeFeatureDto>
type TrailFormValues = Record<BackOfficeFeatureName, TrialDto>

export const ToggleFeatures = ({
  form,
  handleToggleAutoReceiptMatching,
  confirmToggleBetaFeature,
  setConfirmToggleBetaFeature,
  handleResetSpecificOverride,
  onPriceChange,
  onTrailChanged,
  anchor
}: {
  form: UseFormReturn<BillingSettingsForm>
  handleToggleAutoReceiptMatching: (featureName: BackOfficeFeatureName, isEnabled: boolean) => void
  confirmToggleBetaFeature: boolean
  setConfirmToggleBetaFeature: (value: boolean) => void
  handleResetSpecificOverride: (field: BackOfficeOverrideField) => void
  onPriceChange: (feature: BackOfficeFeatureName, value: number) => void
  onTrailChanged: (feature: BackOfficeFeatureName, lengthInDays: number) => void
  anchor: MutableRefObject<HTMLDivElement | null>
}) => {
  const features = form.watch('subscription.template.features')
  const overriddenFeatures = form.watch('subscription.templateOverrides.features')
  const templateOverrides = form.watch('subscription.templateOverrides')
  const [featureToToggle, setFeatureToToggle] = useState<BackOfficeFeatureDto | undefined>(undefined)

  const overridenFeatues = getOverridenFeatures(features, overriddenFeatures)
  const overridenFeaturesByName = overridenFeatues.reduce((acc, feature) => ({ ...acc, [feature.name]: feature }), {} as PriceFormValues)

  const handleOnToggle = (featureName: BackOfficeFeatureName, isEnabled: boolean, isBeta: boolean) => {
    if (isBeta) {
      setConfirmToggleBetaFeature(true)
      setFeatureToToggle({ name: featureName, isEnabled: isEnabled, isBeta: isBeta })
      return
    }
    handleToggleAutoReceiptMatching(featureName, isEnabled)
  }

  const priceForm = useForm<PriceFormValues>()
  const trailForm = useForm<TrailFormValues>({
    defaultValues: createTrailsByName(templateOverrides.trials)
  })

  useEffect(() => {
    priceForm.reset(overridenFeaturesByName)
  }, [features])

  useEffect(() => {
    trailForm.reset(createTrailsByName(templateOverrides.trials))
  }, [templateOverrides])

  const handleOnPriceBlur = (feature: BackOfficeFeatureName) => (values: PriceFormValues) => {
    const price = Number(values[feature].price?.value)
    const initialPrice = Number(overridenFeaturesByName[feature].price?.value)

    // Price was never changed
    if (isNaN(price) || price === initialPrice) return

    onPriceChange(feature, price)
  }

  const handleOnTrialBlur = (feature: BackOfficeFeatureName) => (values: TrailFormValues) => {
    const lengthInDays = Number(values[feature].lengthInDays)
    const initialLength = Number(templateOverrides.trials.find((trial) => trial.name === feature)?.lengthInDays)

    // Days was never changed
    if (isNaN(lengthInDays) || lengthInDays === initialLength) return

    onTrailChanged(feature, lengthInDays)
  }

  return (
    <FigmaBox fullWidth>
      {confirmToggleBetaFeature && featureToToggle?.isBeta && (
        <ModalAreYouSureWithBody
          bodyText={TextKeys.billingSettingsModalTextBeta}
          onClose={() => setConfirmToggleBetaFeature(false)}
          onContinue={() => {
            setConfirmToggleBetaFeature(false)
            if (featureToToggle) {
              handleToggleAutoReceiptMatching(featureToToggle.name, featureToToggle.isEnabled)
            }
          }}
          anchor={anchor.current}
        />
      )}
      <Box sx={{ display: 'flex', flexGrow: '1', width: '100%' }}>
        <BorderedContainer align="center" justify={'flex-start'} direction="column" gap="24px">
          <FigmaBox fullWidth direction="row" gap={Spacings.medium}>
            <FigmaBox direction="column" gap={Spacings.medium}>
              <FigmaBox fullWidth direction="row" gap={Spacings.medium}>
                <Typography sx={{ color: Colors.baseBlack }} fontSize={'16px'} fontWeight={700} variant="body1">
                  Features
                </Typography>
              </FigmaBox>
              {overridenFeatues
                ?.toSorted((a, b) => mapFeaturesNameToReadable(a.name).localeCompare(mapFeaturesNameToReadable(b.name)))
                ?.map((feature) => (
                  <FigmaBox key={feature.name} fullWidth gap={Spacings.medium} direction="row" justify="space-between" align="center">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', flex: 1 }}>
                      <Typography sx={{ color: Colors.baseBlack }} variant="body2" style={{ minWidth: '200px' }}>
                        {mapFeaturesNameToReadable(feature.name)}
                      </Typography>
                      <MaterialSwitchExperimental
                        data-testid={`switch-${feature.name}`}
                        onChange={(e) => handleOnToggle(feature.name, e.target.checked, feature.isBeta)}
                        name={feature.name}
                        checked={feature.isEnabled}
                        value={feature.isEnabled}
                      />
                    </Box>
                    <Box data-testid="price-input-container">
                      <TextFieldController
                        control={priceForm.control}
                        name={`${feature.name}.price.value`}
                        adornment={<span>{feature.price?.currency}</span>}
                        labelText="Price"
                        disabled={!feature.isEnabled}
                        onBlur={priceForm.handleSubmit(handleOnPriceBlur(feature.name))}
                      />
                    </Box>
                    <Box width={50}>
                      {feature.overridden && (
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'flex-start' }}>
                          <TooltipValueOverridden
                            defaultValue={`${feature.isEnabled}`}
                            content={
                              <UnstyledButton>
                                <IconAlertInfoYellow />
                              </UnstyledButton>
                            }
                          />
                          <TooltipResetOverride
                            content={
                              <UnstyledButton
                                data-testid="reset-override"
                                onClick={() => handleResetSpecificOverride(feature.name as BackOfficeOverrideField)}
                              >
                                <IconRemoveOverride />
                              </UnstyledButton>
                            }
                          />
                        </Box>
                      )}
                    </Box>
                  </FigmaBox>
                ))}
            </FigmaBox>
          </FigmaBox>
          <When is={templateOverrides.trials.length > 0}>
            <Box sx={{ width: '100%', height: '5px' }}>
              <Line fullWidth />
            </Box>
            <Box sx={{ width: '100%' }}>
              <Typography sx={{ color: Colors.baseBlack }} fontSize={'16px'} fontWeight={700} variant="body1">
                Trials
              </Typography>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', gap: '24px', flexDirection: 'column' }}>
              {templateOverrides.trials
                .toSorted((a, b) => mapFeaturesNameToReadable(a.name).localeCompare(mapFeaturesNameToReadable(b.name)))
                .map((trial) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                      gap: '16px'
                    }}
                    key={trial.name}
                  >
                    <Typography sx={{ color: Colors.baseBlack }} variant="body2" style={{ minWidth: '200px' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {mapFeaturesNameToReadable(trial.name)}
                        {trial.startDate && (
                          <Typography color={Colors.base500} variant="subtitle2">
                            {getTrailDurationLeft(trial.startDate, trial.lengthInDays)}
                          </Typography>
                        )}
                      </Box>
                    </Typography>
                    <Box data-testid="trail-date-container" sx={{ maxWidth: '400px', display: 'flex', gap: '16px', flexDirection: 'row' }}>
                      <Box sx={{ minWidth: '180px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePickerControllerV2
                            readOnly
                            control={trailForm.control}
                            name={`${trial.name}.startDate`}
                            labelText="Start date"
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box style={{ minWidth: '120px' }}>
                        <TextFieldController
                          control={trailForm.control}
                          name={`${trial.name}.lengthInDays`}
                          labelText="Duration"
                          adornment={<span>Days</span>}
                          onBlur={trailForm.handleSubmit(handleOnTrialBlur(trial.name))}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
          </When>
        </BorderedContainer>
      </Box>
    </FigmaBox>
  )
}

const createTrailsByName = (trials: TrialDto[]) => trials.reduce((acc, trial) => ({ ...acc, [trial.name]: trial }), {} as TrailFormValues)

// Merges default features with overridden features and adds a flag to indicate if the feature is overridden. Easier to work with
const getOverridenFeatures = (features: BackOfficeFeatureDto[], overriddenFeatures: BackOfficeFeatureDto[]) =>
  features.map((feature) => {
    const overriddenFeature = overriddenFeatures.find((overriddenFeature) => overriddenFeature.name === feature.name)
    return {
      ...(overriddenFeature ?? feature),
      overridden: Boolean(overriddenFeature)
    }
  })

const getTrailDurationLeft = (startDate: string, duration: number) => {
  const length = dayjs(startDate)
    .add(duration + 1, 'days') // Add 1 day to include the current day
    .diff(dayjs(), 'days')

  return length < 0 ? `Expired ${Math.abs(length)} days ago` : `${length} days left`
}

const mapFeaturesNameToReadable = (featureName: BackOfficeFeatureName) => {
  switch (featureName) {
    case 'AUTO_RECEIPT_TRANSACTION_MATCHING':
      return 'Auto Receipt Matching'
    case 'ERP_INTEGRATION_BUSINESS_CENTRAL':
      return 'Business Central'
    case 'ERP_INTEGRATION_VISMA_NET':
      return 'Visma Net'
    default:
      return prettifyEnumLabel(featureName)
  }
}
