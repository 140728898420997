import styled from '@emotion/styled'
// eslint-disable-next-line no-restricted-imports
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import React, { useMemo } from 'react'
import { getTooltipArrowThemeStyles, getTooltipThemeStyles } from '../libs/TooltipsHelper'
import { IDefaultTooltip, TooltipThemeVariants } from './MyntTooltip'
import MyntTooltipEmptyContent from './MyntTooltipEmptyContent'

interface IDefaultTooltipWrapper extends Partial<TooltipProps> {
  fullWidth?: boolean
  variant?: TooltipThemeVariants
  title?: NonNullable<React.ReactNode>
  onClick?: React.MouseEventHandler<HTMLDivElement>
  arrow?: boolean
  followCursor?: boolean
  children?: React.ReactElement<any, any>
}

const _DefaultTooltipWrapperDesktop: React.FC<React.PropsWithChildren<React.PropsWithChildren<IDefaultTooltipWrapper>>> = ({
  onClick,
  fullWidth,
  title,
  variant = TooltipThemeVariants.LIGHT,
  placement,
  arrow,
  followCursor,
  children
}) => {
  const tooltipStyles = useMemo(() => getTooltipThemeStyles(variant), [variant])
  const tooltipArrowStyles = useMemo(() => getTooltipArrowThemeStyles(variant), [variant])
  return (
    <Tooltip
      title={!!title && <React.Fragment>{title}</React.Fragment>}
      placement={placement}
      arrow={arrow}
      followCursor={followCursor}
      componentsProps={{
        tooltip: {
          sx: tooltipStyles
        },
        arrow: {
          sx: tooltipArrowStyles
        }
      }}
    >
      <Wrapper onClick={onClick} fullWidth={fullWidth}>
        {children}
      </Wrapper>
    </Tooltip>
  )
}

const _DefaultTooltipWrapperMobile: React.FC<React.PropsWithChildren<React.PropsWithChildren<IDefaultTooltipWrapper>>> = ({
  onClick,
  fullWidth,
  title,
  variant = TooltipThemeVariants.LIGHT,
  placement,
  arrow,
  followCursor,
  children
}) => {
  const tooltipStyles = useMemo(() => getTooltipThemeStyles(variant), [variant])
  const tooltipArrowStyles = useMemo(() => getTooltipArrowThemeStyles(variant), [variant])
  return (
    <Tooltip
      title={!!title && <React.Fragment>{title}</React.Fragment>}
      componentsProps={{
        tooltip: {
          sx: tooltipStyles
        },
        arrow: {
          sx: tooltipArrowStyles
        }
      }}
      placement={placement}
      arrow={arrow}
      followCursor={followCursor}
    >
      <Wrapper onClick={onClick} fullWidth={fullWidth}>
        {children}
      </Wrapper>
    </Tooltip>
  )
}

const _DefaultTooltipContent: React.FC<React.PropsWithChildren<React.PropsWithChildren<Partial<IDefaultTooltip>>>> = ({
  svgIcon,
  svgColor,
  noMargin,
  noPadding,
  content: Content
}) => (
  <>
    {!!Content && (typeof Content === 'function' ? <Content /> : Content)}
    {!Content && <MyntTooltipEmptyContent noPadding={noPadding} noMargin={noMargin} svgIcon={svgIcon} svgColor={svgColor} />}
  </>
)

const Wrapper = styled.div<{ fullWidth?: boolean }>`
  ${(props) => props.fullWidth && 'width: 100%;'}
`

export const DefaultTooltipWrapperDesktop = React.memo(_DefaultTooltipWrapperDesktop)
export const DefaultTooltipWrapperMobile = React.memo(_DefaultTooltipWrapperMobile)
export const DefaultTooltipContent = React.memo(_DefaultTooltipContent)
