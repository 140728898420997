import styled from '@emotion/styled'
import { UseFormReturn } from 'react-hook-form'
import { Type } from '../api/swagger/definitions/backoffice'
import { default as IconRemoveOverride } from '../figma/images/iconRemoveOverride'
import Colors from '../figma/panda/Colors'
import Spacings from '../figma/tokens/Spacings'
import { MinWidthFigmaBox } from '../helpers/CreditOnCardHelpers'
import { BorderedContainer } from '../helpers/customerGeneralFormHelper'
import TextKeys from '../libs/TextKeys'
import { CREDIT_REQUEST_WIDTH } from '../mynt-components/StrongHardCodedSizes'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { UnstyledButton } from '../mynt-components/components/StyledComponents'
import { BillingSettingsForm } from './CustomerCardBillingSetting'
import TextContainer from './TextContainer'
import { TooltipResetAllOverrides } from './Tooltips'
import { SelectController } from './react-hook-components'

type CustomerCardBillingSettingsPricingPlanProps = {
  form: UseFormReturn<BillingSettingsForm>
  handleResetOverrides: () => void
  handleUpdateSubscriptionPlan: (e: React.ChangeEvent<HTMLSelectElement>) => void
  subscriptionPlanIsLoading: boolean
}

const CustomerCardBillingSettingsPricingPlan = ({
  form,
  handleResetOverrides,
  handleUpdateSubscriptionPlan,
  subscriptionPlanIsLoading
}: CustomerCardBillingSettingsPricingPlanProps) => (
  <GreyBorderedContainer align="center" justify="center" fullWidth direction="column">
    <MinWidthFigmaBox width={CREDIT_REQUEST_WIDTH} direction="row" gap={Spacings.medium} fullWidth>
      <FigmaBox direction="column" gap={Spacings.medium} fullWidth>
        <FigmaBox direction="row" gap={Spacings.medium} fullWidth>
          <FigmaBox fullWidth justify="center">
            <TextContainer textKey={TextKeys.billingSettingsPricingPlanHeading} />
          </FigmaBox>
          <FigmaBox>
            <TooltipResetAllOverrides
              content={
                <UnstyledButton onClick={() => handleResetOverrides()}>
                  <IconRemoveOverride />
                </UnstyledButton>
              }
            />
          </FigmaBox>
        </FigmaBox>
        <FigmaBox direction="row" gap={Spacings.medium} fullWidth>
          <FigmaBox fullWidth justify="center">
            <TextContainer textKey={TextKeys.billingSettingsPricingPlanSubscriptionPlan} />
          </FigmaBox>
          <SelectController
            control={form.control}
            name="payload.subscriptionType"
            noLabel
            options={Object.values(Type).map((plan) => ({
              label: plan,
              value: plan
            }))}
            onChange={(e) => handleUpdateSubscriptionPlan(e)}
            loading={subscriptionPlanIsLoading}
            disabled={subscriptionPlanIsLoading}
            inheritBackgroundColor={false}
          />
        </FigmaBox>
      </FigmaBox>
    </MinWidthFigmaBox>
  </GreyBorderedContainer>
)

export default CustomerCardBillingSettingsPricingPlan

const GreyBorderedContainer = styled(BorderedContainer)`
  background-color: ${Colors.backgroundColorsCloud};
`
