import styled from '@emotion/styled'
import CircularProgress from '@mui/material/CircularProgress'
import React, { CSSProperties } from 'react'
import Colors from '../../figma/panda/Colors'
import Spacings from '../../figma/tokens/Spacings'
import FigmaBox from './FigmaBox'

export type MyntLoaderVariantType = 'small' | 'normal' | 'large' | number

export type MyntLoaderProps = {
  className?: string
  fullWidth?: boolean
  variant?: MyntLoaderVariantType
  stroke?: number
  fullscreen?: boolean
  style?: CSSProperties
}

export const getLoaderSize = (variant: MyntLoaderProps['variant']) => {
  switch (true) {
    case variant === 'small':
      return 20
    case variant === 'normal':
      return 40
    case variant === 'large':
      return 60
    default:
      return variant
  }
}

const MyntLoader: React.FC<React.PropsWithChildren<React.PropsWithChildren<MyntLoaderProps>>> = ({
  fullWidth,
  className,
  variant = 'normal',
  stroke,
  fullscreen,
  style
}) => {
  const size = getLoaderSize(variant)

  return (
    <LoaderWrapper
      fullscreen={fullscreen}
      align={fullscreen ? 'center' : undefined}
      top
      bottom
      className={className}
      spacing={Spacings.tiny}
      direction="row"
      fullWidth={fullWidth}
      stroke={stroke}
      style={style}
    >
      <CircularProgress size={size} />
    </LoaderWrapper>
  )
}

export const LoaderWrapper = styled(FigmaBox)<{ fullscreen?: boolean; stroke?: number }>`
  justify-content: center;
  ${({ fullscreen }) => (fullscreen ? 'width: 100%; height: 100%;' : '')}

  .MuiCircularProgress-circle {
    color: ${Colors.bluePrimary600Cta};
    stroke-width: ${({ stroke }) => stroke && stroke};
  }
`

export default MyntLoader
