import MaterialTable, { Filter } from '@material-table/core'
import React, { useEffect, useState } from 'react'
import { BackOfficePaymentResponseDto, PaymentSource, PaymentState } from '../api/swagger/definitions/backoffice'
import config from '../config'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import { useAddPaymentToWhitelist, usePaymentReceiver, usePayments, useUpdatePayments } from '../api/react-query'
import { getPaymentsActions } from '../helpers/PaymentsActionsHelper'
import { GetTableOptions } from '../helpers/getTableOptions'
import { formatCurrency } from '../helpers/utils'
import { TextKeys } from '../tiger/libs/TextRepository'
import JSONDetailPanel from './JSONDetailPanel'
import TableWrapper from './TableWrapper'
import FeatherIcon from 'feather-icons-react'
import Box from '@mui/material/Box'
import { colors } from 'themes'
import { CoreMyntModal } from './modals/CoreMyntModal'
import Button from '@mui/material/Button'
import { useForm } from 'react-hook-form'
import { TextFieldController } from './react-hook-components'
import LoadingButton from '@mui/lab/LoadingButton'
import useMaterialNotification from 'hooks/useMaterialNotification'
import Tooltip from '@mui/material/Tooltip'

const STATE = 'state'

export function PaymentsTable() {
  const [stateFilter, setStateFilter] = React.useState([
    PaymentState.MAPPED,
    PaymentState.INCOMING,
    PaymentState.SENDER_UNKNOWN,
    PaymentState.TO_BE_RETURNED
  ])

  const [selectedPayment, setSelectedPayment] = useState<BackOfficePaymentResponseDto>()
  const [loading, setIsLoading] = useState(false)

  const [sender, setSender] = useState<BackOfficePaymentResponseDto | undefined>()

  const notify = useMaterialNotification()

  const { data: payments = [] } = usePayments(stateFilter)
  const mutation = useUpdatePayments()

  const addToWhiteList = useAddPaymentToWhitelist()

  const form = useForm({
    defaultValues: {
      ocr: ''
    }
  })

  const onFilterChange = (filters: Filter<any>[]) => {
    const state = filters.find((f) => f.column.field === STATE)
    if (state) setStateFilter(state.value)
  }

  const handleModalClose = () => {
    setSelectedPayment(undefined)
    form.reset({ ocr: '' })
  }

  const handleAddOcr = ({ ocr }) => {
    if (!selectedPayment) return

    setIsLoading(true)

    mutation
      .mutateAsync({
        paymentId: selectedPayment.id,
        payout: {
          ocr
        }
      })
      .then(() => {
        notify(`Updated payment with OCR ${ocr}`)
        setSelectedPayment(undefined)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (selectedPayment) {
      // If the payment has an OCR use that
      const ocrValue = selectedPayment.ocr ?? ''

      form.setValue('ocr', ocrValue)
    }
  }, [selectedPayment])

  const handleAddToWhitelist = (payment: BackOfficePaymentResponseDto) => addToWhiteList.mutateAsync({ paymentId: payment.id })

  const onReturnMoney = (payment: BackOfficePaymentResponseDto) =>
    mutation.mutateAsync({
      paymentId: payment.id,
      payout: {
        state: PaymentState.TO_BE_RETURNED
      }
    })

  return (
    <>
      <VerifyPaymentModal sender={sender} onApprove={onReturnMoney} onWhiteList={handleAddToWhitelist} />
      <CoreMyntModal
        wrapperProps={{ sx: { maxWidth: '300px' } }}
        open={Boolean(selectedPayment)}
        title="Add OCR manually"
        onClose={handleModalClose}
        actions={(Wrapper) => (
          <Wrapper>
            <Button onClick={handleModalClose}>Cancel</Button>
            <LoadingButton loading={loading} variant="primary" onClick={form.handleSubmit(handleAddOcr)}>
              Set OCR
            </LoadingButton>
          </Wrapper>
        )}
      >
        <Box sx={{ paddingTop: '8px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <span>Payment extra info:</span>
            <pre
              style={{
                margin: 0,
                backgroundColor: '#eeeeee',
                padding: '8px',
                borderRadius: '8px',
                border: '1px solid #cccccc',
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap'
              }}
            >
              {selectedPayment?.info}
            </pre>
          </Box>
          <TextFieldController control={form.control} name="ocr" labelText="OCR" />
        </Box>
      </CoreMyntModal>
      <TableWrapper>
        <MaterialTable
          onFilterChange={onFilterChange}
          columns={[
            {
              title: 'OCR',
              field: 'ocr',
              render: (payment: BackOfficePaymentResponseDto) => {
                const ocr = payment.ocr ?? 'Add OCR'

                return (
                  <Box
                    onClick={() => setSelectedPayment(payment)}
                    sx={{ display: 'flex', alignItems: 'center', gap: '8px', color: colors.product.blue[600], cursor: 'pointer' }}
                  >
                    <span>{ocr}</span>
                    <FeatherIcon size={16} icon="edit-2" />
                  </Box>
                )
              }
            },
            {
              title: 'State',
              field: STATE,
              lookup: PaymentState,
              defaultFilter: stateFilter,
              filterCellStyle: {
                overflow: 'hidden',
                maxWidth: `${Spacings.max}`,
                fontSize: 8
              }
            },
            {
              title: 'Date',
              field: 'paymentDate'
            },
            {
              title: 'Amount',
              field: 'amount.value',
              render: (payment: BackOfficePaymentResponseDto) => formatCurrency(payment.amount)
            },
            {
              title: 'Currency',
              field: 'amount.currency'
            },
            {
              title: 'External source',
              field: 'source',
              lookup: PaymentSource
            },
            {
              title: 'External reference',
              field: 'billectaExternalReference'
            }
          ]}
          data={payments}
          title="Payments"
          detailPanel={({ rowData }: { rowData: BackOfficePaymentResponseDto }) => <JSONDetailPanel src={rowData} />}
          options={GetTableOptions({
            headerTextKey: TextKeys.accountingPaymentsOCR,
            extraOptions: { filtering: config.enablePaymentsFiltering, headerStyle: { backgroundColor: ColorsDeprecated.surfaceOnWhite } }
          })}
          actions={getPaymentsActions({ mutation: mutation as any, onVerifySender: (payment) => setSender(payment) }) as any}
        />
      </TableWrapper>
    </>
  )
}

type VerifyPaymentProps = {
  sender: BackOfficePaymentResponseDto | undefined
  onWhiteList: (sender: BackOfficePaymentResponseDto) => Promise<unknown> | void
  onApprove: (sender: BackOfficePaymentResponseDto) => Promise<unknown> | void
}

const VerifyPaymentModal = ({ sender, onWhiteList, onApprove }: VerifyPaymentProps) => {
  const [open, setOpen] = useState(false)

  const [isApproveLoading, setIsApproveLoading] = useState(false)
  const [isWhiteListLoading, setIsWhiteListLoading] = useState(false)

  const { data: receiver, isLoading } = usePaymentReceiver(sender?.id, { enabled: Boolean(sender?.id) })

  useEffect(() => {
    if (sender) setOpen(true)
  }, [sender])

  const handleClose = () => {
    setOpen(false)
    setIsApproveLoading(false)
    setIsWhiteListLoading(false)
  }

  if (!sender) return null

  const handleApprove = () => {
    setIsApproveLoading(true)

    const promiseOrVoid = onApprove(sender)

    if (promiseOrVoid instanceof Promise) {
      setIsApproveLoading(true)

      promiseOrVoid
        .then(() => {
          handleClose()
        })
        .finally(() => {
          setIsApproveLoading(false)
        })
    } else {
      handleClose()
    }
  }

  const handleWhitelist = () => {
    setIsWhiteListLoading(true)

    const promiseOrVoid = onWhiteList(sender)

    if (promiseOrVoid instanceof Promise) {
      setIsWhiteListLoading(true)

      promiseOrVoid
        .then(() => {
          handleClose()
        })
        .finally(() => {
          setIsWhiteListLoading(false)
        })
    } else {
      handleClose()
    }
  }

  return (
    <CoreMyntModal
      actions={(Wrapper) => (
        <Wrapper>
          <LoadingButton loading={isApproveLoading} onClick={handleApprove}>
            Return money
          </LoadingButton>
          <Tooltip title="Future payments payments from this sender to this receiver will be automatically approved. This can be removed">
            <LoadingButton loading={isWhiteListLoading} onClick={handleWhitelist} variant="primary">
              <Box style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                <FeatherIcon size={16} icon="info" />
                Add to whitelist
              </Box>
            </LoadingButton>
          </Tooltip>
        </Wrapper>
      )}
      open={open && !isLoading}
      title="Verify Sender"
      onClose={handleClose}
    >
      <Box style={{ display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'auto 1fr 1fr', gap: 8 }}>
        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumn: '2 / span 4',
            gap: 8
          }}
        >
          <Box>Sender</Box>
          <Box>Receiver</Box>
        </Box>
        <Box
          style={{
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
            gridColumn: 1,
            gridRow: '2 / span 2',
            paddingRight: 8,
            textAlign: 'right'
          }}
        >
          <Box>Name</Box>
          <Box style={{ alignSelf: 'flex-end' }}>Regno</Box>
        </Box>
        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridRow: 2,
            gridColumn: '2 / span 4',
            fontWeight: 'bold',
            color: colors.brand.black,
            gap: 8
          }}
        >
          <Box>{sender.senderInformation.name ?? '-'}</Box>
          <Box>{receiver?.name ?? '-'}</Box>
        </Box>
        <Box style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridRow: 3, gridColumn: '2 / span 4', gap: 8 }}>
          <Box>{sender.senderInformation.number ?? '-'}</Box>
          <Box>{receiver?.number ?? '-'}</Box>
        </Box>
      </Box>
    </CoreMyntModal>
  )
}
