import { Checkbox } from '../components/Checkbox'
import LoaderRenderProp from '../components/LoaderRenderProp'
import { ActionTypes, useFetchReducer } from 'hooks/fetchReducer'
import { useEffect, useState } from 'react'
import { useInboxFilterContext } from '../context/InboxFilterContext'

import { getInbox } from '../api'
import { getRepaymentInvoiceAPI } from '../helpers/repaymentInvoiceHelper'
import useMaterialNotification from '../hooks/useMaterialNotification'
import { IInboxDeprecated } from '../interfaces/InboxInterface'
import { isNotSnoozed } from '../libs/inboxHelper'
import FigmaBox from '../mynt-components/components/FigmaBox'
import InboxTypeFilters from './InboxFilters'
import InboxRow from './InboxRow'
import { usePermissions } from 'contexts/permissions'
import FormGroup from '@mui/material/FormGroup'

const InboxContainer = () => {
  const { inboxFilterState } = useInboxFilterContext()
  const hasInboxPermission = usePermissions('inbox')

  const [showSnoozed, setShowSnoozed] = useState(false)
  const [debtBtnSubmittingId, setDebtBtnSubmittingId] = useState('')
  const notify = useMaterialNotification()

  const [state, dispatch] = useFetchReducer<IInboxDeprecated[]>({
    loading: true
  })

  const items = state.details || []

  const filteredList: IInboxDeprecated[] = items
    .filter((item) => inboxFilterState.filters.includes(item.type))
    .filter((item) => showSnoozed || isNotSnoozed(item))

  const removeInboxItem = (id: string) => {
    if (state.details === null) return
    const filteredList = state.details.filter((item) => item.id !== id)
    dispatch({ type: ActionTypes.success, payload: { data: filteredList } })
  }

  const updateInboxItem = (item: IInboxDeprecated) => {
    if (state.details === null) return
    const index = state.details.findIndex((i) => i.id === item.id)
    if (index === -1) return

    const newList = [...state.details]
    newList[index] = item
    dispatch({ type: ActionTypes.success, payload: { data: newList } })
  }

  const handleDebtCollectionClick = async (item: IInboxDeprecated) => {
    setDebtBtnSubmittingId(item.id)

    try {
      // @ts-expect-error - TypeScript is not working properly for some reason
      await getRepaymentInvoiceAPI('debt-collection')(item.typeId)
      removeInboxItem(item.id)

      notify('Success 👍')
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err)
      setDebtBtnSubmittingId('')
    }
  }

  useEffect(() => {
    const fetchInbox = async () => {
      if (!hasInboxPermission) return dispatch({ type: ActionTypes.success, payload: { data: [] } })
      try {
        const data = await getInbox()
        dispatch({ type: ActionTypes.success, payload: { data } })
      } catch (e) {
        dispatch({ type: ActionTypes.error, payload: { error: e } })
      }
    }

    fetchInbox()
    // eslint-disable-next-line
  }, [])

  return (
    <LoaderRenderProp
      showLoader={state.loading}
      render={() => (
        <>
          <FigmaBox style={{ color: 'black' }} fullWidth>
            <InboxTypeFilters filters={inboxFilterState.filters}>
              <FormGroup onChange={() => setShowSnoozed((s) => !s)}>
                <Checkbox checked={showSnoozed}>Snooze</Checkbox>
              </FormGroup>
            </InboxTypeFilters>
          </FigmaBox>

          {filteredList.map((item, idx) => (
            <InboxRow
              key={`${item.id}-${idx}`}
              item={item}
              debtBtnSubmittingId={debtBtnSubmittingId}
              onDebtCollectionClick={handleDebtCollectionClick}
              removeInboxItem={removeInboxItem}
              updateInboxItem={updateInboxItem}
            />
          ))}
        </>
      )}
    />
  )
}

export default InboxContainer
