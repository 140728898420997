const FortnoxLogo = (props: React.SVGProps<SVGSVGElement>) => (<svg width="185" height="39" viewBox="0 0 185 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_357_9545)">
      <path d="M21.0199 1.10352H11.3208C4.57822 1.10352 0 5.75815 0 12.6196V37.3069C0 38.0172 0.386716 38.4103 1.0853 38.4103H6.47438C7.17296 38.4103 7.55968 38.0172 7.55968 37.3069V27.0528H21.0573C21.7559 27.0528 22.1426 26.6596 22.1426 25.9494V20.705C22.1426 19.9947 21.7559 19.6016 21.0573 19.6016H7.52225V12.423C7.52225 10.1338 9.03793 8.59913 11.2834 8.59913H21.0199C21.7185 8.59913 22.1052 8.20595 22.1052 7.49571V2.20693C22.1052 1.49669 21.7185 1.10352 21.0199 1.10352Z" fill="black" />
      <path d="M39.6009 7.69214C30.6815 7.69214 24.282 14.5536 24.282 23.3492C24.282 32.1448 30.7189 39.0063 39.6009 39.0063C48.4829 39.0063 54.9199 32.1448 54.9199 23.3492C54.9199 14.5536 48.5204 7.69214 39.6009 7.69214ZM39.6009 31.6312C35.0227 31.6312 31.6483 28.1624 31.6483 23.3492C31.6483 18.536 34.9853 15.0673 39.6009 15.0673C44.2166 15.0673 47.5536 18.536 47.5536 23.3492C47.5536 28.1624 44.2166 31.6312 39.6009 31.6312Z" fill="black" />
      <path d="M91.6518 8.2439H82.4579V1.10341C82.4579 0.393171 82.0712 0 81.3726 0H75.9835C75.2849 0 74.8982 0.393171 74.8982 1.10341V8.2439H68.4238C61.7124 8.2439 57.0968 12.8985 57.0968 19.76V37.3068C57.0968 38.0171 57.4835 38.4102 58.1821 38.4102H63.5712C64.2698 38.4102 64.6565 38.0171 64.6565 37.3068V19.5254C64.6565 17.2361 66.1722 15.7015 68.4176 15.7015H74.892V26.9005C74.892 33.762 79.4702 38.4166 86.219 38.4166H91.6081C92.3067 38.4166 92.6934 38.0234 92.6934 37.3132V32.0688C92.6934 31.3585 92.3067 30.9654 91.6081 30.9654H86.2564C84.0048 30.9654 82.4953 29.4244 82.4953 27.1415V15.7078H91.6892C92.3878 15.7078 92.7745 15.3146 92.7745 14.6044V9.36C92.7371 8.61171 92.3503 8.25659 91.6518 8.25659V8.2439Z" fill="black" />
      <path d="M108.405 7.69214C100.259 7.69214 94.9076 13.0126 94.9076 21.136V37.3448C94.9076 38.0551 95.2943 38.4482 95.9929 38.4482H101.382C102.081 38.4482 102.467 38.0551 102.467 37.3448V21.136C102.467 17.4707 104.831 15.1053 108.399 15.1434C111.967 15.1053 114.331 17.4326 114.331 21.136V37.3448C114.331 38.0551 114.717 38.4482 115.416 38.4482H120.805C121.504 38.4482 121.89 38.0551 121.89 37.3448V21.136C121.89 13.0126 116.539 7.69214 108.393 7.69214H108.405Z" fill="black" />
      <path d="M139.399 7.69214C130.479 7.69214 124.08 14.5536 124.08 23.3492C124.08 32.1448 130.517 39.0063 139.399 39.0063C148.281 39.0063 154.718 32.1448 154.718 23.3492C154.718 14.5536 148.281 7.69214 139.399 7.69214ZM139.399 31.6312C134.82 31.6312 131.446 28.1624 131.446 23.3492C131.446 18.536 134.783 15.0673 139.399 15.0673C144.014 15.0673 147.351 18.536 147.351 23.3492C147.351 28.1624 143.977 31.6312 139.399 31.6312Z" fill="black" />
      <path d="M175.083 23.2668L184.626 13.5643C185.131 13.0507 185.131 12.537 184.626 12.0234L180.865 8.19945C180.359 7.68579 179.854 7.68579 179.349 8.19945L169.806 17.9019L160.263 8.19945C159.757 7.68579 159.252 7.68579 158.747 8.19945L154.986 12.0234C154.481 12.537 154.481 13.0507 154.986 13.5643L164.529 23.2668L154.911 32.9692C154.406 33.4829 154.406 33.9965 154.911 34.5102L158.672 38.3341C159.177 38.8477 159.683 38.8477 160.188 38.3341L169.731 28.6316L179.274 38.3341C179.779 38.8477 180.285 38.8477 180.79 38.3341L184.551 34.5102C185.056 33.9965 185.056 33.4829 184.551 32.9692L175.089 23.2668H175.083Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_357_9545">
        <rect width="185" height="39" fill="white" />
      </clipPath>
    </defs>
  </svg>)

export default FortnoxLogo;