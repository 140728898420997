import styled from '@emotion/styled'
import React from 'react'
import BorderRadius from '../figma/tokens/BorderRadius'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { formatCurrency } from './utils'

export const CustomerIdContext = React.createContext<string>('')

// This is just to make it more pretty, may need to remove it if the format changes in the api
export const parseCreditRequest = (request?: string) => {
  if (!request) return ''

  const { desiredAmount, increseAmount, purposeText } = tryParseJson(request?.slice(request?.indexOf('{')), {})

  const amount = desiredAmount ?? increseAmount

  const value = parseInt(amount)

  if (amount === undefined || isNaN(value)) {
    return request
  }

  const formattedAmount = formatCurrency({ value, currency: 'SEK' })

  if (!purposeText) return formattedAmount

  return `${formattedAmount}\n${purposeText}`
}

export const tryParseJson = (value: string, fallback?: any) => {
  try {
    return JSON.parse(value)
  } catch {
    return fallback
  }
}

export const EnumToOptions = <T extends string>(enumValue: Record<string, T>, labelTransform?: (label: string) => string) =>
  Object.values(enumValue).map((value) => ({ value: value, label: labelTransform?.(value) ?? value }))

export const MinWidthFigmaBox = styled(FigmaBox)<{ width?: number }>`
  min-width: ${({ width }) => width}px;
`

export const BorderContainer = styled(FigmaBox)<{ width?: number; smallSpacing?: boolean; borderTop?: boolean; borderBottom?: boolean }>`
  ${({ width }) => (width ? `width: ${width}px` : '')}
  padding: ${Spacings.small} ${Spacings.medium} ${Spacings.medium};
  ${({ smallSpacing }) => (smallSpacing ? `padding: ${Spacings.medium} ${Spacings.small};` : '')}
  background: ${ColorsDeprecated.baseWhite};
  border: 1px solid ${ColorsDeprecated.baseStone};
  border-radius: ${BorderRadius.soft};
  ${({ borderTop }) => borderTop && 'border-bottom-left-radius:0;border-bottom-right-radius:0;border-bottom: none;'}
  ${({ borderBottom }) => borderBottom && 'border-top-left-radius:0;border-top-right-radius:0;'}
`

export const BorderContainerHeader = styled(FigmaBox)<{ noBorder?: boolean; noSpacingBottom?: boolean }>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${({ noSpacingBottom }) => !noSpacingBottom && `min-height: ${Spacings.huge};`}
  ${({ noSpacingBottom }) => !noSpacingBottom && `padding-bottom: ${Spacings.small};`}
  ${({ noBorder }) => !noBorder && `margin-bottom: ${Spacings.medium};`}
  ${({ noBorder }) => !noBorder && `border-bottom: 1px solid ${ColorsDeprecated.baseStone};`}
`

export const prettifyEnumLabel = (label: string) => {
  const spacedLabel = label.replaceAll('_', ' ')

  return spacedLabel.slice(0, 1).toUpperCase() + spacedLabel.slice(1).toLowerCase()
}
