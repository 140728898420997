import React from 'react'

import CustomerDetailsKYC from './CustomerDetailsKYC'
import CustomerPepFiles from './CustomerPepFiles'
import CustomerFormPep from './CustomorFormPep'

import CustomerPepRiskExperimental from './PepRiskExperimental'

interface CustomerDetailsPepProps {
  customerId: string
}

const CustomerDetailsPep: React.FC<CustomerDetailsPepProps> = ({ customerId }) => (
  <>
    <CustomerFormPep customerId={customerId} />
    <CustomerPepRiskExperimental customerId={customerId} />
    <CustomerDetailsKYC customerId={customerId} />
    <CustomerPepFiles customerId={customerId} />
  </>
)

export default CustomerDetailsPep
