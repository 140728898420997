import Box from '@mui/material/Box'
import { CoreMyntModal } from './CoreMyntModal'
import Button from '@mui/material/Button'
import { BackOfficeCustomerDtoV2, PersonDto, SmsTemplateDto } from 'api/swagger/definitions/backoffice'
import { UseFormReturn, useForm } from 'react-hook-form'
import { AutoCompleteController, TextFieldController } from 'components/react-hook-components'
import { useCreateSmsTemplate, useDeleteSmsTemplate, useSmsTemplates, useUpdateSmsTemplate } from 'api/react-query'
import { useEffect, useRef, useState } from 'react'
import useMaterialNotification from 'hooks/useMaterialNotification'
import { When } from 'mynt-components/components/When'
import { Line } from 'mynt-components/components/StyledComponents'
import LoadingButton from '@mui/lab/LoadingButton'
import { ModalAreYouSureWithBody } from 'components/Modals'
import {
  SmsBodyVariables,
  formatSmsText,
  getCompanyNameForSMS,
  getFirstNameForSMS,
  getFullNameForSMS,
  getLastNameForSMS,
  getLimitForSMS
} from 'helpers/SmsHelpers'
import Chip from '@mui/material/Chip'
import styled from '@emotion/styled'
import { colors } from 'themes'
import { sendSms } from 'api'
import Tooltip from '@mui/material/Tooltip'

type SmsModalProps = {
  open: boolean
  customer: BackOfficeCustomerDtoV2
  person: PersonDto
  onClose?: () => unknown
}

type Views = 'write' | 'list' | 'edit' | 'create'

const ViewToTitle: Record<Views, string> = {
  write: 'Send SMS',
  list: 'List templates',
  edit: 'Edit template',
  create: 'Create template'
}

export const SmsModal = ({ open, onClose, customer, person }: SmsModalProps) => {
  const { data: smsTemplates = [] } = useSmsTemplates()
  const [view, setView] = useState<Views>('write')
  const [selectedTemplate, setSelectedTemplate] = useState<SmsTemplateDto | undefined>()
  const notify = useMaterialNotification()
  const [showSendConfirm, setShowSendConfirm] = useState(false)
  const [isSendLoading, setIsSendLoading] = useState(false)

  const createSmsTemplateMutation = useCreateSmsTemplate()
  const updateSmsTemplateMutation = useUpdateSmsTemplate()
  const deleteSmsTemplateMutation = useDeleteSmsTemplate()

  const templateForm = useForm<SmsTemplateDto>({
    defaultValues: {
      ...selectedTemplate,
      title: selectedTemplate?.title ?? '',
      text: selectedTemplate?.text ?? ''
    }
  })

  const currentTemplateId = templateForm.watch('id')
  const smsText = templateForm.watch('text')
  const formattedSmsText = formatSmsText(smsText ?? '', person, customer)

  const handleOnEditTemplate = (template: SmsTemplateDto) => {
    setView('edit')
    setSelectedTemplate(template)
    templateForm.reset(template)
  }

  const handleUpdateTemplate = (template: SmsTemplateDto) => {
    updateSmsTemplateMutation
      .mutateAsync({
        id: template.id as string,
        body: {
          rev: template.rev as number,
          text: template.text as string,
          title: template.title as string
        }
      })
      .then((newTemplate) => {
        templateForm.reset(newTemplate)

        notify('Template updated')
        setView('list')
      })
  }

  const handleCreateTemplate = (template: SmsTemplateDto) => {
    createSmsTemplateMutation
      .mutateAsync({
        text: template.text as string,
        title: template.title as string
      })
      .then(() => {
        notify('Template created')
        setView('list')
      })
  }

  const handleSendSms = () => {
    setIsSendLoading(true)

    sendSms(customer.id as string, person.id as string, formattedSmsText)
      .then(() => {
        notify('SMS sent')
        setShowSendConfirm(false)
      })
      .finally(() => {
        setIsSendLoading(false)
      })
  }

  const handleBack = () => {
    let goToView: Views = 'list'

    if (view === 'create') {
      goToView = 'list'
    }

    if (view === 'list') {
      goToView = 'write'
    }

    setView(goToView)
    templateForm.reset(
      {
        id: undefined,
        text: '',
        title: ''
      },
      { keepValues: false }
    )
  }

  const handleDeleteTemplate = (template: SmsTemplateDto) => deleteSmsTemplateMutation.mutateAsync(template.id as string)

  const activeTemplates = smsTemplates.filter((template) => !template.deleted)

  return (
    <>
      <When is={showSendConfirm}>
        <ModalAreYouSureWithBody
          title="Is the SMS correct?"
          bodyText={<Box sx={{ whiteSpace: 'pre-wrap' }}>{formattedSmsText}</Box>}
          confirmText={`Yes, send sms to ${person?.fullName}`}
          loading={isSendLoading}
          onContinue={templateForm.handleSubmit(handleSendSms)}
          onClose={() => setShowSendConfirm(false)}
        />
      </When>
      <CoreMyntModal
        wrapperProps={{ sx: { width: '600px' } }}
        onClose={onClose}
        open={open}
        title={ViewToTitle[view]}
        actions={(Wrapper) => (
          <Wrapper>
            <When is={view !== 'write'}>
              <Button variant="outlined" onClick={handleBack}>
                Back
              </Button>
            </When>
            <When is={view === 'edit'}>
              <LoadingButton
                loading={updateSmsTemplateMutation.isLoading}
                variant="primary"
                onClick={templateForm.handleSubmit(handleUpdateTemplate)}
              >
                Update
              </LoadingButton>
            </When>
            <When is={view === 'write'}>
              <LoadingButton variant="primary" onClick={() => setShowSendConfirm(true)}>
                Send
              </LoadingButton>
            </When>
            <When is={view === 'list'}>
              <Button
                variant="primary"
                onClick={() => {
                  setView('create')
                  templateForm.reset(
                    {
                      id: undefined,
                      text: '',
                      title: ''
                    },
                    { keepValues: false }
                  )
                }}
              >
                Create new template
              </Button>
            </When>
            <When is={view === 'create'}>
              <LoadingButton
                loading={createSmsTemplateMutation.isLoading}
                variant="primary"
                onClick={templateForm.handleSubmit(handleCreateTemplate)}
              >
                Create template
              </LoadingButton>
            </When>
          </Wrapper>
        )}
      >
        <>
          <When is={view === 'write'}>
            <WriteSms currentTemplateId={currentTemplateId} form={templateForm} setView={setView} smsTemplates={activeTemplates} />
          </When>
          <When is={view === 'list'}>
            <ListTemplates onEditTemplate={handleOnEditTemplate} onDeleteTemplate={handleDeleteTemplate} smsTemplates={activeTemplates} />
          </When>
          <When is={view === 'edit'}>
            <EditTemplate person={person} customer={customer} form={templateForm} />
          </When>
          <When is={view === 'create'}>
            <EditTemplate person={person} customer={customer} form={templateForm} />
          </When>
        </>
      </CoreMyntModal>
    </>
  )
}

const EditTemplate = ({
  form,
  customer,
  person
}: {
  form: UseFormReturn<SmsTemplateDto>
  customer: BackOfficeCustomerDtoV2
  person: PersonDto
}) => {
  const templateText = form.watch('text')

  const inputRef = useRef<any>()
  const [selectionStart, setSelectionStart] = useState(0)
  const updateSelectionStart = () => setSelectionStart(inputRef.current?.selectionStart ?? 0)
  const handleInjectVariable = (variable: string) => templateText?.slice(0, selectionStart) + variable + templateText?.slice(selectionStart)

  const SmsVariableToLabel = {
    [SmsBodyVariables.firstName]: (customer, person) => getFirstNameForSMS(person),
    [SmsBodyVariables.lastName]: (customer, person) => getLastNameForSMS(person),
    [SmsBodyVariables.fullName]: (customer, person) => getFullNameForSMS(person),
    [SmsBodyVariables.companyName]: (customer, person) => getCompanyNameForSMS(customer),
    [SmsBodyVariables.limit]: (customer, person) => getLimitForSMS(customer)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingTop: '16px' }}>
      <TextFieldController control={form.control} name="title" labelText="Title" />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Box sx={{ fontSize: '12px', letterSpacing: '0.1px' }}>Dynamic variables</Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', paddingBottom: '8px' }}>
          {Object.values(SmsBodyVariables).map((variable) => (
            <Tooltip key={variable} title={SmsVariableToLabel[variable](customer, person)} placement="top">
              <StyledChip onClick={() => form.setValue('text', handleInjectVariable(variable))} key={variable} label={variable} />
            </Tooltip>
          ))}
        </Box>
      </Box>
      <TextFieldController
        smallCaseInputProps={{ ref: inputRef }}
        InputProps={{
          onSelect: updateSelectionStart
        }}
        control={form.control}
        name="text"
        labelText="Text"
        multiline
        rows={10}
      />
    </Box>
  )
}

const StyledChip = styled(Chip)`
  color: ${colors.product.grey[500]};
  cursor: pointer;

  &:hover {
    background-color: ${colors.product.grey[200]};
  }
`

const ListTemplates = ({ smsTemplates, onEditTemplate, onDeleteTemplate }) => {
  const [loadingId, setLoadingId] = useState<string | undefined>()
  const [activeTemplate, setActiveTemplate] = useState<SmsTemplateDto>()

  const handleDeleteTemplate = async (template: SmsTemplateDto) => {
    setActiveTemplate(template)
  }

  const deleteTemplate = (template: SmsTemplateDto) => {
    setActiveTemplate(undefined)
    setLoadingId(template.id)

    onDeleteTemplate(template).finally(() => setLoadingId(undefined))
  }

  return (
    <>
      <When is={activeTemplate}>
        <ModalAreYouSureWithBody
          title="Are you sure?"
          bodyText={`Do you want to delete the ${activeTemplate?.title} SMS template?`}
          confirmText="Yes, delete template"
          onContinue={() => deleteTemplate(activeTemplate as SmsTemplateDto)}
          onClose={() => setActiveTemplate(undefined)}
        />
      </When>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', maxHeight: '60vh', overflowY: 'auto' }}>
        {smsTemplates.map((template: SmsTemplateDto) => (
          <Box key={template.id}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '32px' }}>
              <Box sx={{ flex: 3 }}>
                <Box>
                  <b>{template.title}</b>
                </Box>
                <Box>{template.text}</Box>
              </Box>
              <Box sx={{ display: 'flex', gap: '8px', flex: 1 }}>
                <Button variant="outlined" onClick={() => onEditTemplate(template)}>
                  Edit
                </Button>
                <LoadingButton loading={loadingId === template.id} variant="outlined" onClick={() => handleDeleteTemplate(template)}>
                  Delete
                </LoadingButton>
              </Box>
            </Box>
            <Box sx={{ height: '1px', paddingTop: '16px' }}>
              <Line fullWidth />
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}

type WriteSmsProps = {
  smsTemplates: SmsTemplateDto[]
  setView: (view: Views) => void
  form: UseFormReturn<SmsTemplateDto>
  currentTemplateId?: string
}

const WriteSms = ({ smsTemplates, setView, form, currentTemplateId }: WriteSmsProps) => {
  const templateIdForm = useForm({
    defaultValues: {
      templateId: currentTemplateId ?? ''
    }
  })

  const selectedTemplate = templateIdForm.watch('templateId')

  useEffect(() => {
    const template = smsTemplates.find((t) => t.id === selectedTemplate)

    if (!template) return

    form.reset(template)
  }, [selectedTemplate])

  const templateOptions = smsTemplates.map(({ title, id }) => ({
    label: title ?? 'unknown',
    value: id ?? '-'
  }))

  return (
    <>
      <Box sx={{ display: 'flex', gap: '32px', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: '32px', justifyContent: 'space-between', paddingTop: '8px' }}>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
            <AutoCompleteController control={templateIdForm.control} name="templateId" options={templateOptions} label="Select template" />
          </Box>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Button variant="outlined" onClick={() => setView('list')}>
              Edit templates
            </Button>
          </Box>
        </Box>
        <TextFieldController control={form.control} name="text" labelText="Message" multiline rows={10} />
      </Box>
    </>
  )
}
