import styled from '@emotion/styled'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'
import { Field, FormikProps } from 'formik'
import React from 'react'
import TextContainer from '../../components/TextContainer'
import Colors from '../../figma/panda/Colors'
import BorderRadius from '../../figma/tokens/BorderRadius'
import Spacings from '../../figma/tokens/Spacings'
import { getText } from '../../helpers/getText'
import { useLanguage } from '../../hooks/useLanguage'
import { AntiloopTextType } from '../../tiger/interfaces/Antiloop'
import useTextVariant from '../hooks/useTextVariant'
import FigmaBox from './FigmaBox'
import MyntLoader from './MyntLoader'
import placeholderGenericV2 from './placeholderGenericV2'

export enum MaterialFieldInputTypes {
  email = 'email',
  number = 'number',
  password = 'password',
  search = 'search',
  tel = 'tel',
  text = 'text'
}

export type MaterialFieldProps = {
  adormentTextKey?: AntiloopTextType
  adormentText?: string
  className?: string
  defaultValue?: string | number
  disabled?: boolean
  error?: string
  fieldWidth?: number
  formProps: FormikProps<any>
  fullWidth?: boolean
  labelText?: string
  labelTextKey?: AntiloopTextType
  name: string
  onBlur?: (e: React.ChangeEvent<any>) => void
  onChange?: (e: React.ChangeEvent<any>) => void
  placeHolderTextKey?: AntiloopTextType
  select?: boolean
  type?: keyof typeof MaterialFieldInputTypes
  validate?: (value: string | number, formProps?: FormikProps<any>) => string
  value?: string | number
  loading?: boolean
  inputProps?: OutlinedInputProps
}

const MaterialFieldFormik: React.FC<React.PropsWithChildren<React.PropsWithChildren<MaterialFieldProps>>> = ({
  adormentTextKey,
  adormentText,
  children,
  className,
  defaultValue,
  disabled,
  error: errorMessage,
  fieldWidth,
  formProps,
  fullWidth,
  labelText,
  labelTextKey,
  name,
  onBlur,
  onChange,
  placeHolderTextKey,
  select,
  type,
  validate,
  value,
  loading,
  inputProps = {},
  ...rest
}) => {
  const language = useLanguage()
  const textVariant = useTextVariant(placeholderGenericV2)
  const placeholder = !!placeHolderTextKey && getText(placeHolderTextKey, language)
  const label = labelText ? labelText : !!labelTextKey && getText(labelTextKey, language)
  const hasLabel = !!labelText || !!labelTextKey

  return (
    <Wrapper fullWidth={fullWidth} hasLabel={hasLabel} {...rest}>
      <StyledOutlinedInput
        select={select}
        className={className}
        component={TextField}
        disabled={disabled}
        error={!!errorMessage || (!!formProps.errors && formProps.errors[name] && formProps.touched[name])}
        fullWidth={fullWidth}
        label={label ? label : undefined}
        placeholder={placeholder && !value ? placeholder : undefined}
        type={type}
        validate={(value: string | number) => !!validate && validate(value, formProps)}
        value={value ?? formProps.values[name]}
        variant="outlined"
        InputProps={{
          endAdornment: createAdornment(adormentTextKey, adormentText, loading, select),
          ...inputProps
        }}
        defaultValue={defaultValue}
        name={name}
        onBlur={onBlur ?? formProps.handleBlur(name)}
        onChange={onChange ?? formProps.handleChange(name)}
        style={{
          ...textVariant.style,
          width: fieldWidth
        }}
      >
        {children}
      </StyledOutlinedInput>
      {!errorMessage ? (
        !!formProps.errors &&
        formProps.errors[name] &&
        formProps.touched[name] && <FormHelperText error>{<>{errorMessage ?? formProps.errors[name]}</>}</FormHelperText>
      ) : (
        <FormHelperText error>{errorMessage}</FormHelperText>
      )}
    </Wrapper>
  )
}

export const createAdornment = (adormentTextKey?: AntiloopTextType, adormentText?: string, loading?: boolean, select?: boolean) => (
  <>
    {!!adormentTextKey && (
      <InputAdornment
        position="end"
        style={{
          ...(!!adormentTextKey.variants.VariantDesktop
            ? adormentTextKey.variants.VariantDesktop.style
            : adormentTextKey.variants.VariantMobile.style)
        }}
      >
        <FigmaBox spacing={Spacings.tiny} right>
          <TextContainer textKey={adormentTextKey} text={adormentText} />
        </FigmaBox>
      </InputAdornment>
    )}
    {loading && (
      <FigmaBox spacing={select ? Spacings.large : Spacings.small} right>
        <MyntLoader variant="small" />
      </FigmaBox>
    )}
  </>
)

const Wrapper = styled(FigmaBox)<{ hasLabel: boolean }>`
  ${({ hasLabel }) => !hasLabel && `fieldset {top: 0 !important}`}
  ${({ hasLabel }) => !hasLabel && `legend {display: none !important}`}
`

const StyledOutlinedInput = styled(Field)<{ select?: boolean }>`
  border-radius: ${BorderRadius.soft};
  border-color: ${Colors.base300};
  background-color: ${Colors.baseWhite};

  & .MuiInputBase-input.MuiOutlinedInput-input {
    color: ${({ select }) => select && Colors.baseBlack};

    border-radius: ${BorderRadius.soft};
  }
  .MuiSelect-select:focus {
    background-color: ${Colors.baseWhite};
  }
`

export default MaterialFieldFormik
