import styled from '@emotion/styled'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import React from 'react'
import Colors from '../../figma/panda/Colors'
import X1 from '../../figma/svgs/X1'
import BorderRadius from '../../figma/tokens/BorderRadius'
import MediaQueries from '../../figma/tokens/MediaQueries'
import Shadows from '../../figma/tokens/Shadows'
import Spacings from '../../figma/tokens/Spacings'
import ZIndices from '../../figma/tokens/ZIndices'
import Button from './Button'
import FigmaBox from './FigmaBox'
import SVGContainer from './SVGContainer'
import { When } from './When'

export type ModalProps = {
  containerStyle?: React.CSSProperties
  onClose: (value?: any, value1?: any) => void
  hideCloseButton?: boolean
  fullWidth?: boolean
  alignHeadingWithCloseButton?: boolean
  wrapperStyle?: React.CSSProperties
}

type Props = {
  children: React.ReactNode
  heading?: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>>
} & ModalProps

export default function ModalForked({
  children,
  alignHeadingWithCloseButton,
  onClose,
  heading: Heading,
  hideCloseButton,
  containerStyle,
  wrapperStyle,
  fullWidth
}: Props) {
  return (
    <StyledModal open={true} onClose={onClose} BackdropComponent={StyledBackDrop}>
      <Container style={containerStyle} fullWidth={fullWidth} align="center">
        <StyledContainer style={wrapperStyle}>
          <ContentContainer spacing={Spacings.large} top fullWidth={fullWidth}>
            <When is={alignHeadingWithCloseButton}>
              {!hideCloseButton && <AlignedHeadingAndCloseButton heading={Heading && <Heading />} onClose={onClose} />}
            </When>
            <When is={!alignHeadingWithCloseButton}>
              {Heading && <Heading />}
              <CloseButtonContainer>
                <Button variant="filter" onClick={onClose}>
                  <SVGContainer SVG={X1} />
                </Button>
              </CloseButtonContainer>
            </When>
            {children}
          </ContentContainer>
        </StyledContainer>
      </Container>
    </StyledModal>
  )
}

const AlignedHeadingAndCloseButton = ({ onClose, heading }) => (
  <FigmaBox fullWidth direction="row" right={Spacings.large} align="center" justify="space-between">
    {heading}
    <FigmaBox>
      <Button startIcon={<SVGContainer SVG={X1} />} square variant="secondary" onClick={onClose} />
    </FigmaBox>
  </FigmaBox>
)

const Container = styled(FigmaBox)`
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  z-index: ${ZIndices.heaven};
`

const StyledContainer = styled(FigmaBox)`
  position: relative;
  background: ${Colors.baseWhite};
  border-radius: ${BorderRadius.soft};
  box-shadow: ${Shadows.baseHeavy};

  @media (max-width: ${MediaQueries.sm}px) {
    width: 100%;
  }
`

const CloseButtonContainer = styled(FigmaBox)`
  position: absolute;
  right: ${Spacings.small};
  top: ${Spacings.small};
`

const StyledBackDrop = styled(Backdrop)``

const ContentContainer = styled(FigmaBox)``

const StyledModal = styled(Modal)`
  .MuiBackdrop-root {
    background: ${Colors.backgroundColorsBlurred};
    opacity: 0.34;
  }

  &&& {
    z-index: ${ZIndices.focus} !important;
  }
`
