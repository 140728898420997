import styled from '@emotion/styled';
import ButtonTertiaryDefaultCss from './ButtonTertiaryDefaultCss';


interface ButtonTertiaryDefaultProps {
  children: any;
  [propName: string]: {};
}

// Extend the below as needed
const ButtonTertiaryDefaultStyled = styled.div<ButtonTertiaryDefaultProps>`
  ${ButtonTertiaryDefaultCss};
`;

export default ButtonTertiaryDefaultStyled;