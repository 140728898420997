import styled from '@emotion/styled'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import { Field, FormikProps } from 'formik'
import React from 'react'
import Colors from '../../figma/panda/Colors'
import IconCalendar24 from '../../figma/svgs/IconCalendar24'
import BorderRadius from '../../figma/tokens/BorderRadius'
import { getText } from '../../helpers/getText'
import { useLanguage } from '../../hooks/useLanguage'
import { AntiloopTextType } from '../../tiger/interfaces/Antiloop'
import { YYYY_MM_DD } from '../Constants'
import FigmaBox from './FigmaBox'

export type MaterialFieldProps = {
  name: string
  formProps: FormikProps<any>
  value?: dayjs.Dayjs | null
  onChange?: (value: dayjs.Dayjs) => unknown
  format?: string
  placeHolderTextKey?: AntiloopTextType
  fullWidth?: boolean
  className?: string
  error?: string
  validate?: ((value: dayjs.Dayjs, formProps?: FormikProps<any>) => string | undefined) | false
  fieldWidth?: number
  disabled?: boolean
  icon?: JSX.Element
  labelTextKey?: AntiloopTextType
  labelText?: string
  inputFormat?: string
}

const MaterialDatePickerFormik: React.FC<React.PropsWithChildren<React.PropsWithChildren<MaterialFieldProps>>> = ({
  name,
  value,
  onChange,
  formProps,
  format,
  fullWidth,
  placeHolderTextKey,
  className,
  error: errorMessage,
  validate,
  fieldWidth,
  disabled,
  icon = <IconCalendar24 />,
  labelTextKey,
  labelText,
  inputFormat: _inputFormat
}) => {
  const language = useLanguage()
  const hasLabel = !!labelText || !!labelTextKey
  const inputFormatProp = _inputFormat ? { inputFormat: _inputFormat } : {}

  return (
    <Wrapper fullWidth={fullWidth} hasLabel={hasLabel}>
      <StyledOutlinedInput
        className={className}
        component={DesktopDatePicker}
        disabled={disabled}
        error={!!errorMessage || (!!formProps.errors && formProps.errors[name] && formProps.touched[name])}
        format={format || YYYY_MM_DD}
        fullWidth={fullWidth}
        helperText={
          !errorMessage
            ? !!formProps.errors && formProps.errors[name] && formProps.touched[name] && (errorMessage || formProps.errors[name])
            : errorMessage
        }
        inputVariant="outlined"
        keyboardIcon={icon}
        name={name}
        onChange={onChange || (async (value) => formProps.setFieldValue(name, value))}
        OpenPickerButtonProps={{ style: { margin: 0 } }}
        placeholder={(!!placeHolderTextKey && !value && getText(placeHolderTextKey, language)) || ''}
        PopperProps={{ style: { zIndex: 30000 } }}
        renderInput={(props) => <TextField {...props} />}
        style={{ width: fieldWidth }}
        validate={(value: dayjs.Dayjs) => !!validate && validate(value, formProps)}
        value={value || formProps.values[name]}
        label={labelText ?? (!!labelTextKey && getText(labelTextKey, language))}
        variant="inline"
        {...inputFormatProp}
      />
      {!errorMessage ? (
        !!formProps.errors &&
        formProps.errors[name] &&
        formProps.touched[name] && <FormHelperText error>{<>{errorMessage ?? formProps.errors[name]}</>}</FormHelperText>
      ) : (
        <FormHelperText error>{errorMessage}</FormHelperText>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(FigmaBox)<{ hasLabel: boolean }>`
  ${({ hasLabel }) => !hasLabel && `legend {display: none !important}`}
`

const StyledOutlinedInput = styled(Field)<{ error: boolean }>`
  border-radius: ${BorderRadius.soft};
  border-color: ${Colors.base300};
  background-color: ${Colors.baseWhite};

  input {
    color: ${({ error }) => error && Colors.notificationErrors600};
  }

  input::-webkit-input-placeholder {
    color: ${({ error }) => (error ? Colors.notificationErrors600 : Colors.base500)};
    opacity: 1;
  }
`

export default MaterialDatePickerFormik
