import styled from '@emotion/styled'
import React from 'react'
import TextContainer from '../../components/TextContainer'
import Colors from '../../figma/panda/Colors'
import BorderRadius from '../../figma/tokens/BorderRadius'
import Spacings from '../../figma/tokens/Spacings'
import { AntiloopTextType } from '../../tiger/interfaces/Antiloop'
import { TextKeys } from '../../tiger/libs/TextRepository'
import FigmaBox from './FigmaBox'

export enum FigmaFieldStatusTypes {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PAUSED = 'PAUSED',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  REQUESTED = 'REQUESTED'
}

type Props = {
  textKey?: AntiloopTextType
  text?: string
  status: FigmaFieldStatusTypes
  fullWidth?: boolean
  left?: boolean
  right?: boolean
  align?: 'center' | 'flex-start' | 'flex-end' | 'normal'
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly'
  spacing?: string
}

const FigmaStatusBox: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  textKey,
  status,
  fullWidth,
  left,
  right,
  align = 'flex-end',
  justify = 'flex-start',
  spacing: _spacing = Spacings.min,
  text
}) => {
  const statusFormated = getStatusColors(status)

  return (
    <StyledRow left={left} right={right} direction="row" align={align} justify={justify} fullWidth alignText="center">
      <FlexWrapper backgroundColor={statusFormated.backgroundColor} fullWidth={fullWidth} direction="row">
        <FigmaBox fullPadding fullWidth spacing={_spacing || Spacings.min} direction="row">
          <TextContainer textKey={textKey || statusFormated.textKey} text={text} />
        </FigmaBox>
      </FlexWrapper>
    </StyledRow>
  )
}

const FlexWrapper = styled(FigmaBox)<{ backgroundColor?: string }>`
  background: ${(props) => (!!props.backgroundColor ? props.backgroundColor : Colors.bluePrimary600Cta)};

  border-radius: ${BorderRadius.rounded};
`
const StyledRow = styled(FigmaBox)<{ left?: boolean; right?: boolean }>`
  ${(props) => !!props.left && `margin-left: ${Spacings.tiny};`}
  ${(props) => !!props.right && `margin-right: ${Spacings.tiny};`}
`

export default FigmaStatusBox

export const getStatusColors = (status: FigmaFieldStatusTypes): { backgroundColor: string; textKey: AntiloopTextType } => {
  switch (true) {
    case status === FigmaFieldStatusTypes.ACTIVE:
      return { backgroundColor: Colors.bluePrimary600Cta, textKey: TextKeys.statusBadgeActive }

    case status === FigmaFieldStatusTypes.ERROR:
      return { backgroundColor: Colors.notificationErrors600, textKey: TextKeys.statusBadgeError }

    case status === FigmaFieldStatusTypes.PENDING:
      return { backgroundColor: Colors.alert400, textKey: TextKeys.statusBadgePending }

    case status === FigmaFieldStatusTypes.PAUSED:
      return { backgroundColor: Colors.base300, textKey: TextKeys.statusBadgePaused }

    case status === FigmaFieldStatusTypes.REQUESTED:
      return { backgroundColor: Colors.base300, textKey: TextKeys.statusBadgeRequested }

    default:
      return { backgroundColor: Colors.base300, textKey: TextKeys.statusBadgeInactive }
  }
}
