/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceMethod = {
    BILLECTA_AG: 'BILLECTA_AG',
    NONE: 'NONE',
    CARD_ACCOUNT: 'CARD_ACCOUNT',
    DIRECT_DEBIT: 'DIRECT_DEBIT',
    E_INVOICE: 'E_INVOICE',
    CREDITED_PAYMENT: 'CREDITED_PAYMENT',
    CASHBACK_PAYMENT: 'CASHBACK_PAYMENT'
} as const;

export type InvoiceMethod = typeof InvoiceMethod[keyof typeof InvoiceMethod];



