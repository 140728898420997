import React from 'react'
import { TextKeys } from 'tiger/libs/TextRepository'
import { CorporateCustomerResponse } from '../api/swagger/definitions/backoffice'
import Spacings from '../figma/tokens/Spacings'
import { BorderContainer, BorderContainerHeader } from '../helpers/CreditOnCardHelpers'
import FigmaBox from '../mynt-components/components/FigmaBox'
import CustomerCardTabOverviewDataRow from './CustomerCardTabOverviewDataRow'
import TextContainer from './TextContainer'

interface CustomerCardTabOverviewCorporateCustomerProps {
  corporateCustomer: CorporateCustomerResponse
}

const CustomerCardTabOverviewCorporateCustomer: React.FC<CustomerCardTabOverviewCorporateCustomerProps> = ({ corporateCustomer }) => (
  <BorderContainer fullWidth>
    <BorderContainerHeader noBorder>
      <TextContainer textKey={TextKeys.customersCardOverviewEnfuseCustomerEntityHeading} />
    </BorderContainerHeader>

    <FigmaBox fullWidth direction="row" justify="space-between" gap={Spacings.small} bottom={Spacings.medium}>
      <FigmaBox fullWidth gap={Spacings.min}>
        <CustomerCardTabOverviewDataRow name="Customer representative" value={corporateCustomer.customerRepresentative} />
      </FigmaBox>

      <FigmaBox fullWidth gap={Spacings.min}>
        <CustomerCardTabOverviewDataRow name="Role" value={corporateCustomer.role} />
        <CustomerCardTabOverviewDataRow name="Locale" value={corporateCustomer.locale} />
      </FigmaBox>
    </FigmaBox>

    <FigmaBox fullWidth direction="row" justify="space-between" gap={Spacings.small} bottom={Spacings.medium}>
      <FigmaBox fullWidth gap={Spacings.min}>
        <CustomerCardTabOverviewDataRow name="Name" value={corporateCustomer.name} />
        <CustomerCardTabOverviewDataRow name="Email" value={corporateCustomer.email} />
        <CustomerCardTabOverviewDataRow name="Phone number" value={corporateCustomer.phoneNumber} />
        <CustomerCardTabOverviewDataRow name="Mobile" value={corporateCustomer.mobileNumber} />
      </FigmaBox>

      <FigmaBox fullWidth gap={Spacings.min}>
        <CustomerCardTabOverviewDataRow name="Role" value={corporateCustomer.role} />
        <CustomerCardTabOverviewDataRow name="Address 1" value={corporateCustomer.address?.address1} />
        <CustomerCardTabOverviewDataRow name="Address 2" value={corporateCustomer.address?.address2} />
        <CustomerCardTabOverviewDataRow name="City" value={corporateCustomer.address?.city} />
        <CustomerCardTabOverviewDataRow name="Zip code" value={corporateCustomer.address?.areaCode} />
        <CustomerCardTabOverviewDataRow name="Country" value={corporateCustomer.address?.country} />
      </FigmaBox>
    </FigmaBox>

    <FigmaBox fullWidth gap={Spacings.min}>
      <CustomerCardTabOverviewDataRow name="Customer number" value={corporateCustomer.customerNumber} gap={Spacings.small} />
      <CustomerCardTabOverviewDataRow name="Reg. no" value={corporateCustomer.regNo} gap={Spacings.small} />
    </FigmaBox>
  </BorderContainer>
)

export default CustomerCardTabOverviewCorporateCustomer
