import React from 'react'
import { CompanyCreditDto, Partner, RepaymentMethod } from '../api/swagger/definitions/backoffice'
import { DatePickerControllerV2, SelectChipController, SelectController, TextFieldController } from './react-hook-components'
import { Control, useFormContext } from 'react-hook-form'
import Box from '@mui/material/Box'
import * as Yup from 'yup'
import { EnumToOptions } from 'helpers/CreditOnCardHelpers'

export const CreditFormGeneralInformationValidationSchema = Yup.object().shape({
  orgNo: Yup.string().required('Organisation number is required'),
  creditAmount: Yup.object().shape({
    value: Yup.number().required('Credit amount is required').typeError('Credit amount must be a number')
  }),
  paidOutAmount: Yup.object().shape({
    value: Yup.number().required('Paid out amount is required').typeError('Paid out amount must be a number')
  }),
  monthlyFee: Yup.number().required('Monthly fee is required').typeError('Monthly fee must be a number'),
  creditPeriodMonths: Yup.number().required('Credit period is required').typeError('Credit period must be a number'),
  repaymentMethod: Yup.string().required('Repayment method is required'),
  repaymentModel: Yup.string().required('Repayment model is required'),
  partner: Yup.string().required('Partner is required'),
  creditDate: Yup.date().required('Credit date is required'),
  dueDate: Yup.date().required('Due date is required'),
  sumOfExtraAmortizations: Yup.object().shape({
    value: Yup.number().required('Sum of extra amortization is required').typeError('Sum of extra amortization must be a number')
  }),
  sumOfExtraFeePayments: Yup.object().shape({
    value: Yup.number().required('Sum of extra fee payments is required').typeError('Sum of extra fee payments must be a number')
  }),
  personalGuarantees: Yup.array(Yup.string()).required('Personal guarantees is required')
})

interface IProps {
  control: Control<CompanyCreditDto>
  repaymentModelOptions: { label: string; value: string }[]
  personalGuaranteesOptions: { label: string; value: string }[]
}

export const CreditFormGeneralInformation = ({ control, repaymentModelOptions, personalGuaranteesOptions }: IProps) => {
  const form = useFormContext<CompanyCreditDto>()

  const fieldCurrencies = {
    paidOutAmount: form.watch('paidOutAmount.currency'),
    creditAmount: form.watch('creditAmount.currency'),
    sumOfExtraAmortizations: form.watch('sumOfExtraAmortizations.currency'),
    sumOfExtraFeePayments: form.watch('sumOfExtraFeePayments.currency')
  }

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', columnGap: '16px', rowGap: '24px', alignItems: 'flex-start' }}>
      <TextFieldController control={control} name="orgNo" labelText="Organisation number" />
      <TextFieldController
        control={control}
        name="creditAmount.value"
        labelText="Credit amount"
        adornment={<span>{fieldCurrencies.creditAmount}</span>}
      />
      <TextFieldController
        control={control}
        name="paidOutAmount.value"
        labelText="Paid out amount"
        adornment={<span>{fieldCurrencies.paidOutAmount}</span>}
      />
      <TextFieldController control={control} name="creditPeriodMonths" labelText="Credit period (months)" />
      <TextFieldController control={control} name="otherTerms" labelText="Other terms" />
      <SelectController control={control} name="repaymentMethod" labelText="Repayment method" options={EnumToOptions(RepaymentMethod)} />
      <SelectController control={control} name="repaymentModel" labelText="Repayment model" options={repaymentModelOptions} />
      <SelectController control={control} name="partner" labelText="Partner" options={EnumToOptions(Partner)} />
      <DatePickerControllerV2 control={control} name="creditDate" labelText="Credit date" />
      <DatePickerControllerV2 control={control} name="dueDate" labelText="Due date" />
      <TextFieldController control={control} name="monthlyFee" labelText="Monthly fee" />
      <TextFieldController control={control} name="tieUpPeriodMonths" labelText="Tie up period (months)" />
      <TextFieldController
        control={control}
        name="sumOfExtraAmortizations.value"
        adornment={<span>{fieldCurrencies.sumOfExtraAmortizations}</span>}
        labelText="Sum of extra amortization"
      />
      <TextFieldController
        control={control}
        name="sumOfExtraFeePayments.value"
        adornment={<span>{fieldCurrencies.sumOfExtraFeePayments}</span>}
        labelText="Sum of extra fee payments"
      />
      <SelectChipController
        options={personalGuaranteesOptions}
        disabled
        control={control}
        name="personalGuarantees"
        labelText="Personal guarantees"
      />
      <TextFieldController control={control} name="log" labelText="Log" />
    </Box>
  )
}
