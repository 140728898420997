import React from 'react'

/**
 * Conditionally renders its child content wheter the visibile property is true.
 * This component is more readable compared to ternaries, use this in those cases
 */
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const When: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ is?: unknown | unknown[] }>>> = ({ is, children }) => {
  if (Array.isArray(is) && !is.every((value) => Boolean(value))) return null
  if (!Boolean(is)) return null

  return <>{children}</>
}
