import { useState } from 'react'
import Spacings from '../figma/tokens/Spacings'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import RateSelector from '../mynt-components/components/RateSelector'
import RollingCreditAdapter from '../pages/RollingCredit'

export default function Sandbox() {
  return <RollingCreditAdapter />
}

function Sandbox2() {
  const [value, setValue] = useState(1)

  return (
    <FigmaBox spacing={Spacings.medium} fullPadding>
      <RateSelector value={value} onValueChange={setValue}>
        <RateSelector.Item value="CUSTOM1" key={1} labelTextKey={TextKeys.Active} />
        <RateSelector.Item value="CUSTOM2" key={2} labelTextKey={TextKeys.Active} />
        <RateSelector.Item value="CUSTOM3" key={3} labelTextKey={TextKeys.Active} />
        <RateSelector.Item value="CUSTOM4" key={4} labelTextKey={TextKeys.Active} />
      </RateSelector>
    </FigmaBox>
  )
}
