import styled from '@emotion/styled'
import React from 'react'
import Colors from '../../figma/panda/Colors'
import IconInfo24 from '../../figma/svgs/IconInfo24'
import MediaQueries from '../../figma/tokens/MediaQueries'
import Spacings from '../../figma/tokens/Spacings'
import FigmaBox from './FigmaBox'
import SVGContainer from './SVGContainer'

type MyntTooltipEmptyContentProps = {
  noMargin?: boolean
  noPadding?: boolean
  svgColor?: string
  svgIcon?: React.FC<React.PropsWithChildren<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>>
}

const TooltipEmptyContent = ({ noPadding, noMargin, svgIcon, svgColor }: MyntTooltipEmptyContentProps) => (
  <Info noPadding={noPadding} noMargin={noMargin}>
    <SVGContainer SVG={svgIcon || IconInfo24} svgColor={svgColor || Colors.base500} />
  </Info>
)

const Info = styled(FigmaBox)<{ noMargin?: boolean; noPadding?: boolean }>`
  > span {
    align-items: center;
    display: flex;
  }

  margin-left: ${({ noMargin }) => (noMargin ? 'initial' : Spacings.tiny)};

  @media (max-width: ${MediaQueries.sm}px) {
    padding-top: ${({ noPadding }) => (noPadding ? 'initial' : Spacings.tiny)};
  }
`

export default React.memo(TooltipEmptyContent)
