import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { usePaymentWhitelist, useRemovePaymentWhitelist } from 'api/react-query'
import FeatherIcon from 'feather-icons-react'
import dayjs from 'dayjs'

type CustomerPaymentWhitelistProps = {
  customerId: string
}

export const CustomerPaymentWhitelist = ({ customerId }: CustomerPaymentWhitelistProps) => {
  const { data: whitelist } = usePaymentWhitelist(customerId)

  const removeItem = useRemovePaymentWhitelist(customerId)

  return (
    <Box style={{ display: 'grid', maxWidth: 1000, gap: 8 }}>
      <Box style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)' }}>
        <Typography variant="h6">Sender</Typography>
        <Typography variant="h6">Regno</Typography>
        <Typography variant="h6">Created at</Typography>
        <Typography variant="h6">Added by</Typography>
        <Typography variant="h6">Remove</Typography>
      </Box>
      {whitelist?.map((item) => (
        <Box style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)' }} key={item.id}>
          <Typography>{item.name}</Typography>
          <Typography>{item.number}</Typography>
          <Typography>{dayjs.unix(Number(item.createdAt)).utc().tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</Typography>
          <Typography>{item.addedBy}</Typography>
          <Box onClick={() => removeItem.mutate({ paymentId: item.id })} sx={{ cursor: 'pointer' }}>
            <FeatherIcon icon="trash-2" />
          </Box>
        </Box>
      ))}
    </Box>
  )
}
