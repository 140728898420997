import { css, Global } from '@emotion/react'
import '@fontsource/manrope/200.css'
import '@fontsource/manrope/300.css'
import '@fontsource/manrope/400.css'
import '@fontsource/manrope/500.css'
import '@fontsource/manrope/600.css'
import '@fontsource/manrope/700.css'
import '@fontsource/manrope/800.css'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'

export const GlobalStyles: React.FC = () => (
  <Global
    styles={css`
      // Used in Button.tsx
      @value white, green, darkgreen, darkgreen, yellow, darkyellow from "../../variables.module.css";

      .ant-btn.button {
        border-radius: 4px;
        color: green;
        font-family: paralucent;
        background: white;
        border-color: green;
        font-size: 16px;
        border-width: 2px;
        min-height: 43px;
        font-weight: 500;
      }

      .ant-btn.button:hover,
      .ant-btn.button:focus,
      .ant-btn.button:active,
      .ant-btn.active.button {
        color: darkgreen;
        background: white;
        border-color: darkgreen;
      }

      .ant-btn.button.small {
        font-size: 15px;
        height: 35px;
        min-height: 35px;
      }

      .ant-btn.button.large {
        font-size: 20px;
        min-width: 200px;
        height: 50px;
      }

      .ant-btn.button.primary {
        color: white;
        background: green;
        border-color: green;
      }

      .ant-btn.button.primary:hover,
      .ant-btn.button.primary:focus,
      .ant-btn.button.primary:active {
        background: #2acc88;
        border-color: #2acc88;
      }

      .ant-btn.button.cta {
        color: white;
        background: yellow;
        border-color: yellow;
      }

      .ant-btn.button.cta:hover,
      .ant-btn.button.cta:focus,
      .ant-btn.button.cta:active {
        background: darkyellow;
        border-color: darkyellow;
      }

      .ant-btn.button.rounded {
        border-radius: 500px;
      }

      .ant-btn:disabled.button,
      .ant-btn:disabled.button:hover {
        color: white;
        background: rgb(215, 215, 216);
        border-color: rgb(215, 215, 216);
      }

      @media only screen and (max-width: 768px) {
        .ant-btn.button.large {
          font-size: 18px;
        }
        .ant-btn.button {
          font-size: 14px;
        }
      }

      // Used in Checkbox.tsx
      @value variables: "../../variables.module.css";
      @value green, black, red, lightred from variables;

      .checkbox:global(.ant-checkbox-wrapper) {
        font-family: paralucent;
        font-size: 12px;
        text-align: left;
      }

      .checkbox:global(.ant-checkbox-wrapper a) {
        color: black;
      }

      .checkbox :global(.ant-checkbox-checked .ant-checkbox-inner),
      .checkbox :global(.ant-checkbox-indeterminate .ant-checkbox-inner::after) {
        background-color: green;
        border-color: green;
      }

      .checkbox:global(.ant-checkbox-wrapper:hover .ant-checkbox-inner),
      .checkbox :global(.ant-checkbox:hover .ant-checkbox-inner),
      .checkbox :global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
        border-color: green;
      }

      .error {
        color: red;
        font-size: 12px;
        text-align: left;
        position: relative;
        top: 5px;
      }

      .checkboxError :global(.ant-checkbox-inner) {
        border-color: lightred;
      }

      // Used in CustomerFormOverrides.tsx
      .input {
        display: flex;
        width: 100%;
      }

      .input > div {
        width: 100%;
      }

      .resetButton {
        margin-top: 0;
        margin-left: 20px;
        min-height: 37px !important;
      }

      // Used in CustomerLink.tsx
      .header {
        color: #076b5f;
      }

      // Used in Input.tsx
      @value variables: "../../variables.module.css";
      @value black, darkgreen, red, lightred, lightgreen from variables;

      :global(.ant-input).input {
        color: black;
        border-radius: 0;
        font-weight: 400;
        font-family: paralucent;
        height: 35px;
        border-radius: 4px;
        font-size: 14px;
      }

      :global(.ant-input).input:focus,
      :global(.ant-input).input:hover {
        border-color: darkgreen;
        box-shadow: none;
      }

      .error {
        color: red;
        font-size: 14px;
        text-align: left;
        font-family: paralucent;
      }

      :global(.ant-input).input.inputError {
        border-color: lightred;
      }

      .label {
        color: black;
        font-size: 14px;
        font-family: paralucent;
        margin-bottom: 5px;
      }

      // Used in Loader.tsx
      .small {
        width: 20px;
      }

      .medium {
        width: 75px;
        height: 75px;
      }

      .large {
        width: 125px;
        height: 125px;
      }

      @media only screen and (max-width: 768px) {
        .small {
          width: 15px;
          height: 15px;
        }

        .medium {
          width: 50px;
          height: 50px;
        }

        .large {
          width: 80px;
          height: 80px;
        }
      }

      // Used in Modal.tsx
      .modal :global(h2) {
        font-size: 35px;
        text-align: center;
        font-family: paralucent;
        padding-bottom: 40px;
        padding-top: 15px;
        margin-bottom: 0;
      }

      .modal :global(h3) {
        font-size: 20px;
        text-align: center;
        font-family: paralucent;
      }

      .modal :global(p) {
        font-family: paralucent;
        text-align: center;
      }

      .titleless :global(.ant-modal-content) {
        padding-top: 25px;
      }

      @media only screen and (min-width: 767px) {
        .modal:global(.ant-modal) {
          width: 620px !important;
        }

        .modal.big:global(.ant-modal) {
          width: 1200px !important;
        }
      }

      @media screen and (max-width: 768px) {
        .modal :global(h2) {
          font-size: 28px;
        }
      }

      // Used in Select.tsx
      @value variables: "../../variables.module.css";
      @value darkgreen, lightgreen, lightred, red from variables;

      .select {
        width: 100%;
      }

      .select :global(.ant-select-selection) {
        min-height: 35px;
        border-radius: 0;
        font-weight: 400;
        font-family: paralucent;
        font-size: 14px;
        border-radius: 4px;
      }

      .select :global(.ant-select-selection:focus),
      .select :global(.ant-select-selection:hover) {
        border-color: darkgreen !important;
        box-shadow: none !important;
      }

      .roboto :global(.ant-select-selection) {
        font-family: Inter;
      }

      .roboto :global(.ant-select-selection__rendered) {
        line-height: 33px;
      }

      .error {
        color: red;
        font-size: 14px;
        text-align: left;
        font-family: paralucent;
      }

      .select.selectError :global(.ant-select-selection) {
        border-color: lightred !important;
      }

      // Used in Table.tsx
      @value yellow, white, lightsilver, lightyellow, black from "../../variables.module.css";
      .table :global(.ant-table) {
        font-family: paralucent !important;
        font-size: 16px;
        color: black;
      }

      .table :global(.ant-table-tbody > tr:hover > td) {
        background-color: rgba(69, 221, 203, 0.2);
      }

      .table :global(.ant-table-body) {
        overflow-x: auto;
      }

      .table :global(.ant-table-tbody > tr > td),
      .table :global(.ant-table-thead > tr > th) {
        border: 0;
      }

      .table .ant-table-tbody > tr.ant-table-row-selected td {
        background: lightyellow;
      }

      .table .ant-table-thead > tr > th {
        background: lightsilver;
      }

      .table :global(table table thead) {
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
      }

      // Used in Repayment.tsx and RepaymentPlan.tsx
      @value red from "../../../../../variables.module.css";
      .container {
        font-family: 'Inter';
        color: #37383d;
        padding-bottom: 30px;
      }

      .input {
        margin-bottom: 0 !important;
      }

      .updateBtn {
        min-height: 35px !important;
        font-size: 14px !important;
        margin-left: 20px;
        min-width: 70px;
      }

      .formRow {
        margin-bottom: 10px;
      }

      .repaymentHeaderRow {
        background-color: rgb(249, 249, 249);
        padding: 10px;
        min-height: 41px;
      }

      .repaymentInvoiceColumn {
        padding: 10px;
      }

      .deleteRepayment {
        margin-left: 23px;
        font-size: 23px;
        color: #dd534e;
        position: absolute;
        top: 5px;
      }

      .table :global(.ant-table-tbody > tr > td) {
        padding: 12px 6px;
      }

      .table :global(tr.ant-table-expanded-row) {
        background-color: transparent;
      }

      // Used in Inbox.tsx
      .checkbox span {
        font-family: Inter;
        color: black;
      }

      // Used in CustomerFormGeneral
      .submitButton {
        margin: 60px auto;
        display: block;
        width: 300px;
      }

      // Used in CustomerFormGeneralInformation
      .submitButtonInformation {
        display: block;
        margin: 0 auto;
        width: 300px;
      }

      // Used in NegativeFlags.tsx
      .loaderContainer {
        position: absolute;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.5);
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .MuiTablePagination-selectLabel {
        margin-bottom: 0;
      }

      .MuiTablePagination-displayedRows {
        margin-bottom: 0;
      }
    `}
  />
)
