/* eslint-disable no-console */
import React from 'react'
import { FileContext, FilexDto } from '../api/swagger/definitions/backoffice'
import { useCustomerFiles, useDeleteCustomerFile, useSyncScoringFile, useUploadScoringFile } from '../api/react-query'
import { getScoringFileUploadURL } from '../api'
import useMaterialNotification from '../hooks/useMaterialNotification'
import CustomerFilesTable from './CustomerFilesTable'
import UploadButton from './UploadButton'

interface ICustomerFiles {
  customerId: string
  contexts: FileContext[]
  fileUploadContext: FileContext
  renderButtonChildren?: () => React.ReactElement
}

const CustomerFiles: React.FC<ICustomerFiles> = ({ customerId, contexts, fileUploadContext, renderButtonChildren }) => {
  const { data: files, isLoading } = useCustomerFiles(customerId, contexts)
  const deleteMutation = useDeleteCustomerFile(customerId)
  const uploadFileMutation = useUploadScoringFile()
  const syncFileMutation = useSyncScoringFile(customerId)
  const notify = useMaterialNotification()

  const handleDelete = (file: FilexDto) => {
    if (!file.id) {
      console.error(file)
      return notify('File is missing an ID', 'error')
    }

    deleteMutation.mutateAsync(file.id).then(() => notify(`Deleted '${file.filename}'`))
  }

  const handleInputFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const file = event.target.files[0]

    try {
      const { url, id } = await getScoringFileUploadURL(customerId, fileUploadContext)

      if (!url || !id) {
        notify('missing upload url or id', 'error')
        return
      }

      await uploadFileMutation.mutateAsync({ url, file })
      await syncFileMutation.mutateAsync({ id, context: fileUploadContext })

      notify('You have successfully uploaded a file')

      // fetchCustomerFiles()
    } catch (err) {
      console.warn(err)
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 20,
          alignItems: 'center'
        }}
      >
        <h2 style={{ marginBottom: 0 }}>Files</h2>

        <div>
          {renderButtonChildren && renderButtonChildren()}

          <UploadButton onChange={handleInputFileChange} />
        </div>
      </div>

      <CustomerFilesTable data={files} isLoading={isLoading} onDelete={handleDelete} />
    </div>
  )
}

export default CustomerFiles
