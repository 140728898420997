import BookIcon from 'figma/images/bookIcon'
import CancelIcon from '@mui/icons-material/Cancel'
import CreditIcon from '@mui/icons-material/RequestQuote'
import RemindIcon from '@mui/icons-material/NotificationAdd'
import DebtCollectionIcon from 'figma/images/debtCollection'
import { BackOfficeInvoiceResponseDtoV2, InvoiceMethod, InvoiceState, InvoiceType } from 'api/swagger/definitions/backoffice'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PaymentIcon from '@mui/icons-material/Payment'
import { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingIcon = ({ isLoading, children }: React.PropsWithChildren<{ isLoading: boolean }>) => {
  if (isLoading) return <CircularProgress size="22px" />

  return <>{children}</>
}

const usePromiseLoadingState = <T,>(method: (invoice: T) => Promise<unknown>) => {
  const [isLoading, setIsLoading] = useState(false)

  const call = async (invoice: T) => {
    setIsLoading(true)
    method(invoice).finally(() => setIsLoading(false))
  }

  return { isLoading, call }
}

type ActionItemProps = {
  invoice: BackOfficeInvoiceResponseDtoV2
  onClick: (updatedInvoice: Partial<BackOfficeInvoiceResponseDtoV2>) => Promise<unknown>
}

type ActionMenuItem = {
  ({ invoice, onClick }: ActionItemProps): JSX.Element
  disabled?: (invoice: BackOfficeInvoiceResponseDtoV2) => boolean
}

export const BookAction: ActionMenuItem = ({ invoice, onClick }) => {
  const { isLoading, call } = usePromiseLoadingState(onClick)

  return (
    <MenuItem
      sx={{ display: 'flex', gap: '0.5rem' }}
      disabled={BookAction.disabled?.(invoice) || isLoading}
      onClick={async () => call({ state: InvoiceState.BOOKED })}
    >
      <ListItemIcon>
        <LoadingIcon isLoading={isLoading}>
          <BookIcon fontSize="small" style={{ fill: 'rgba(0, 0, 0, 0.54)', marginLeft: '4%' }} />
        </LoadingIcon>
      </ListItemIcon>
      <ListItemText>Book invoice</ListItemText>
    </MenuItem>
  )
}

BookAction.disabled = (invoice) => ![InvoiceState.DRAFT].includes(invoice.state)

export const CancelAction: ActionMenuItem = ({ invoice, onClick }) => {
  const { isLoading, call } = usePromiseLoadingState(onClick)

  return (
    <MenuItem
      sx={{ display: 'flex', gap: '0.5rem' }}
      disabled={CancelAction.disabled?.(invoice) || isLoading}
      onClick={() => {
        if (window.confirm('Are you sure?')) {
          call({ state: InvoiceState.CANCELLED })
        }
      }}
    >
      <ListItemIcon>
        <LoadingIcon isLoading={isLoading}>
          <CancelIcon fontSize="small" />
        </LoadingIcon>
      </ListItemIcon>
      <ListItemText>Cancel invoice</ListItemText>
    </MenuItem>
  )
}

CancelAction.disabled = (invoice) => ![InvoiceState.DRAFT].includes(invoice.state)

export const CreditAction: ActionMenuItem = ({ invoice, onClick }) => {
  const { isLoading, call } = usePromiseLoadingState(onClick)

  return (
    <MenuItem
      sx={{ display: 'flex', gap: '0.5rem' }}
      disabled={CreditAction.disabled?.(invoice) || isLoading}
      onClick={() => {
        if (window.confirm('Are you sure?')) {
          call({ state: InvoiceState.CREDITED })
        }
      }}
    >
      <ListItemIcon>
        <LoadingIcon isLoading={isLoading}>
          <CreditIcon fontSize="small" />
        </LoadingIcon>
      </ListItemIcon>
      <ListItemText>Credit invoice</ListItemText>
    </MenuItem>
  )
}

CreditAction.disabled = (invoice) =>
  ![InvoiceState.BOOKED, InvoiceState.SETTLED, InvoiceState.OVER_DUE, InvoiceState.REMINDED, InvoiceState.REMINDER_OVERDUE].includes(
    invoice.state
  )

export const ReminderAction: ActionMenuItem = ({ invoice, onClick }) => {
  const { isLoading, call } = usePromiseLoadingState(onClick)

  return (
    <MenuItem
      sx={{ display: 'flex', gap: '0.5rem' }}
      disabled={ReminderAction.disabled?.(invoice) || isLoading}
      onClick={async () => call(invoice)}
    >
      <ListItemIcon>
        <LoadingIcon isLoading={isLoading}>
          <RemindIcon fontSize="small" />
        </LoadingIcon>
      </ListItemIcon>
      <ListItemText>Send invoice reminder</ListItemText>
    </MenuItem>
  )
}
ReminderAction.disabled = (invoice) => {
  const isInvalidState = ![InvoiceState.BOOKED, InvoiceState.OVER_DUE].includes(invoice.state)
  const isInvalidType = [InvoiceType.WITHDRAWAL_TO_MYNT].includes(invoice.type)

  return isInvalidState || isInvalidType
}

export const DebtCollectionAction: ActionMenuItem = ({ invoice, onClick }) => {
  const { isLoading, call } = usePromiseLoadingState(onClick)

  return (
    <MenuItem
      sx={{ display: 'flex', gap: '0.5rem' }}
      disabled={DebtCollectionAction.disabled?.(invoice) || isLoading}
      onClick={() => {
        if (window.confirm('Are you sure?')) {
          call({
            state: InvoiceState.DEBT_COLLECTION
          })
        }
      }}
    >
      <ListItemIcon>
        <LoadingIcon isLoading={isLoading}>
          <DebtCollectionIcon
            fontSize="small"
            style={{ opacity: DebtCollectionAction.disabled?.(invoice) ? '26%' : '', stroke: 'rgba(0, 0, 0, 0.54)', marginLeft: '4%' }}
          />
        </LoadingIcon>
      </ListItemIcon>
      <ListItemText>Send to debt collection</ListItemText>
    </MenuItem>
  )
}
DebtCollectionAction.disabled = (invoice) => {
  const isInvalidState = ![InvoiceState.BOOKED, InvoiceState.OVER_DUE, InvoiceState.REMINDED, InvoiceState.REMINDER_OVERDUE].includes(
    invoice.state
  )

  const isInvalidType = [InvoiceType.WITHDRAWAL_TO_MYNT].includes(invoice.type)

  return isInvalidState || isInvalidType
}

export const ChangePaymentMethod: ActionMenuItem = ({ invoice, onClick }) => {
  const { isLoading, call } = usePromiseLoadingState(onClick)

  const methodToChangeTo = invoice.method === InvoiceMethod.DIRECT_DEBIT ? InvoiceMethod.NONE : InvoiceMethod.DIRECT_DEBIT
  const methodLabel = methodToChangeTo === InvoiceMethod.DIRECT_DEBIT ? 'Autogiro' : 'Bankgiro'

  return (
    <MenuItem
      sx={{ display: 'flex', gap: '0.5rem' }}
      disabled={ChangePaymentMethod.disabled?.(invoice) || isLoading}
      onClick={async () => call({ method: methodToChangeTo })}
    >
      <ListItemIcon>
        <LoadingIcon isLoading={isLoading}>
          <PaymentIcon fontSize="small" />
        </LoadingIcon>
      </ListItemIcon>
      <ListItemText>Set pay method to {methodLabel}</ListItemText>
    </MenuItem>
  )
}

ChangePaymentMethod.disabled = (invoice) => ![InvoiceState.DRAFT].includes(invoice.state)
