export default {
  name: 'placeholderGenericV2',
  characters: 'GenericPlaceholder',
  variants: {
    VariantDesktop: {
      style: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '-0.3px',
        lineHeight: '19.6px',
        color: 'rgba(104, 103, 106, 1)'
      }
    }
  }
}
