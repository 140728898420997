import { FormikProps } from 'formik'
import React from 'react'
import MaterialSwitchExperimental from './MaterialSwitchExperimental'

export interface MaterialSwitchExperimentalProps {
  checked?: boolean
  disabled?: boolean
  onChange?: (event: any, checked: boolean) => void
}

type MaterialSwitchFormikProps = MaterialSwitchExperimentalProps & {
  formProps: FormikProps<any>
  name: string
  onSet?: (name: string, value: boolean) => void
}

const MaterialSwitchFormik: React.FC<React.PropsWithChildren<React.PropsWithChildren<MaterialSwitchFormikProps>>> = (props) => {
  const { onSet, onChange, name, formProps, ...switchProps } = props

  const setValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event, event.target.checked)

    const value = onSet?.(name, event.target.checked) ?? event.target.checked
    formProps.setFieldValue(name, value)
  }

  return <MaterialSwitchExperimental checked={formProps.values[name]} {...switchProps} onChange={setValue} />
}

export default MaterialSwitchFormik
