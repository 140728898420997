import { isEqual } from 'lodash'
import { UseFormReturn } from 'react-hook-form'
import { default as IconAlertInfoYellow } from '../figma/images/iconAlertInfoYellow'
import { default as IconRemoveOverride } from '../figma/images/iconRemoveOverride'
import Spacings from '../figma/tokens/Spacings'
import { MinWidthFigmaBox } from '../helpers/CreditOnCardHelpers'
import { BorderedContainer } from '../helpers/customerGeneralFormHelper'
import TextKeys from '../libs/TextKeys'
import { CREDIT_REQUEST_WIDTH } from '../mynt-components/StrongHardCodedSizes'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { UnstyledButton } from '../mynt-components/components/StyledComponents'
import { BillingSettingsForm } from './CustomerCardBillingSetting'
import TextContainer from './TextContainer'
import { TooltipResetOverride, TooltipValueOverridden } from './Tooltips'
import { TextFieldController } from './react-hook-components'

type CustomerCardBillingSettingsRecurringFeesProps = {
  form: UseFormReturn<BillingSettingsForm>
  isLoading: boolean
  handleResetSpecificOverride: () => void
  handleChangeSubscriptionFee: (value: number) => void
}

const CustomerCardBillingSettingsRecurringFees = ({
  form,
  isLoading,
  handleResetSpecificOverride,
  handleChangeSubscriptionFee
}: CustomerCardBillingSettingsRecurringFeesProps) => {
  const defaultFee = form.watch('subscription.template.fee')
  const overriddenFee = form.watch('subscription.templateOverrides.fee')
  const selectedFee = form.watch('subscription.templateOverrides.fee') ?? defaultFee
  const currencyCode = form.watch('subscription.currencyCode')
  const nextBillingDate = form.watch('subscription.nextBillingDate')

  const onBlurUpdate = () => (data: BillingSettingsForm) => {
    const isDirty = form.getFieldState('payload.fee').isDirty
    const fee = data.payload.fee ?? selectedFee
    form.setValue('payload.fee', fee)
    if ((isEqual(fee, defaultFee) && !isDirty) || !isDirty || isEqual(fee, selectedFee)) {
      return
    }

    handleChangeSubscriptionFee(fee)
  }

  return (
    <BorderedContainer align="center" justify="center" direction="column" fullWidth>
      <MinWidthFigmaBox width={CREDIT_REQUEST_WIDTH} direction="row" gap={Spacings.medium} fullWidth>
        <FigmaBox direction="column" gap={Spacings.medium} fullWidth>
          <FigmaBox fullWidth direction="row" gap={Spacings.medium}>
            <FigmaBox fullWidth justify="center">
              <TextContainer textKey={TextKeys.billingSettingsRecurringFeesHeading} />
            </FigmaBox>
            <FigmaBox>
              <div></div>
            </FigmaBox>
          </FigmaBox>
          <FigmaBox fullWidth direction="row" align="center" gap={Spacings.medium}>
            <FigmaBox fullWidth justify="center">
              <TextContainer textKey={TextKeys.billingSettingsRecurringFeesSubscriptionFee} />
            </FigmaBox>
            {overriddenFee !== null && (
              <>
                <TooltipValueOverridden
                  defaultValue={`${defaultFee}`}
                  content={
                    <UnstyledButton>
                      <IconAlertInfoYellow />
                    </UnstyledButton>
                  }
                />
                <TooltipResetOverride
                  content={
                    <UnstyledButton onClick={() => handleResetSpecificOverride()}>
                      <IconRemoveOverride />
                    </UnstyledButton>
                  }
                />
              </>
            )}
            <TextFieldController
              name="payload.fee"
              loading={isLoading}
              disabled={isLoading}
              labelTextKey={TextKeys.billingSettingsRecurringFeesSubscriptionFee}
              control={form.control}
              type="text"
              onBlur={form.handleSubmit(onBlurUpdate())}
              adornment={<span>{currencyCode}</span>}
            />
          </FigmaBox>
          <FigmaBox fullWidth direction="row" align="center" gap={Spacings.medium}>
            <FigmaBox fullWidth justify="center">
              <TextContainer textKey={TextKeys.billingSettingsNextBillingDate} />
            </FigmaBox>
            <FigmaBox fullWidth justify="center" alignText="right">
              <TextContainer textKey={TextKeys.billingSettingsNextBillingDateValue} text={nextBillingDate ?? '-'} />
            </FigmaBox>
          </FigmaBox>
        </FigmaBox>
      </MinWidthFigmaBox>
    </BorderedContainer>
  )
}

export default CustomerCardBillingSettingsRecurringFees
