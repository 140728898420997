import ReactJson from 'react-json-view'
import Spacings from '../figma/tokens/Spacings'
import { useCustomer, useDirectDebitMandatesV4 } from '../api/react-query'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { When } from '../mynt-components/components/When'
import { TextKeys } from '../tiger/libs/TextRepository'
import { DirectDebitTabAutoGiroApproval } from './DirectDebitTabAutoGiroApproval'
import Loader from './Loader'
import TextContainer from './TextContainer'
import DirectDebitTabNewConnection from './DirectDebitTabNewConnection'
import DirectDebitTabMandateAgreements from './DirectDebitTabMandateAgreements'

type DirectDebitTabProps = { customerId: string }

export default function DirectDebitTab({ customerId }: DirectDebitTabProps) {
  const { data: directDebitMandates, isLoading: mandatesIsLoading } = useDirectDebitMandatesV4(customerId)
  const customer = useCustomer(customerId)

  if (mandatesIsLoading || customer.isLoading) {
    return (
      <FigmaBox fullPadding fullWidth spacing={Spacings.large}>
        <Loader />
      </FigmaBox>
    )
  }

  return (
    <FigmaBox fullWidth direction="column">
      <FigmaBox fullWidth bottom={Spacings.tiny}>
        <TextContainer textKey={TextKeys.directDebitHeading} />
      </FigmaBox>

      <FigmaBox fullWidth direction="row" align="flex-start" gap={Spacings.medium}>
        <When is={directDebitMandates}>
          <DirectDebitTabNewConnection customerId={customerId} />
        </When>

        <DirectDebitTabMandateAgreements />

        <FigmaBox direction="column" justify="flex-start" fullWidth gap={Spacings.medium}>
          {directDebitMandates?.map((directDebitMandate) => (
            <DirectDebitTabAutoGiroApproval customerId={customerId} directDebitMandate={directDebitMandate} key={directDebitMandate.id} />
          ))}
        </FigmaBox>
      </FigmaBox>

      <FigmaBox top={Spacings.huge}>
        <ReactJson src={directDebitMandates || {}} collapsed />
      </FigmaBox>
    </FigmaBox>
  )
}
