import { BackOfficeGoogleAuthResponseDto } from 'api/swagger/definitions/backoffice'
import { AxiosError } from 'axios'
import { getSignInType } from 'api'
import React, { useEffect, useRef, useState } from 'react'

export interface Session {
  role: SessionRole
  isLoading: boolean
  isError: boolean
  errorMessage: string | undefined
}

export enum SessionRole {
  ADMIN = 'ADMIN',
  SALES = 'SALES',
  UNAUTHORIZED = 'UNAUTHORIZED'
}

const defaultSetState: (newMeta: Partial<SessionRole>) => void = () => {
  throw new Error('SessionContextProvider not found')
}

export const SessionContext = React.createContext<[SessionRole, (newRole: SessionRole) => void]>([
  SessionRole.UNAUTHORIZED,
  defaultSetState
])

export const SessionContextProvider = ({
  children,
  value = SessionRole.UNAUTHORIZED
}: React.PropsWithChildren<{ value?: SessionRole }>) => {
  const [role, setRole] = useState(value)

  return <SessionContext.Provider value={[role, setRole]}>{children}</SessionContext.Provider>
}

export const useSessionContext = () => React.useContext(SessionContext)

export const mapSessionRole = (type: BackOfficeGoogleAuthResponseDto) => {
  switch (type) {
    case 'BACKOFFICE':
    case 'BACKOFFICE_FULL':
      return SessionRole.ADMIN
    case 'BACKOFFICE_SALES':
      return SessionRole.SALES
    case 'UNAUTHORIZED':
      return SessionRole.UNAUTHORIZED
    default:
      return SessionRole.UNAUTHORIZED
  }
}

export const isSignedIn = (role: SessionRole) => [SessionRole.ADMIN, SessionRole.SALES].includes(role)

export const useSignOut = () => {
  const [, setRole] = useSessionContext()

  return () => setRole(SessionRole.UNAUTHORIZED)
}

export const useSessionRole = (timeout = 3000): Session => {
  const [role, setRole] = useSessionContext()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const isMountedRef = useRef(true)

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (!isMountedRef.current) return

    if (isSignedIn(role)) return setIsLoading(false)

    setIsLoading(true)

    getSignInType({ timeout, disableCustomErrorHandler: true })
      .then((type) => {
        if (!isMountedRef.current) return

        const mapped = mapSessionRole(type)

        setIsError(false)
        setErrorMessage(undefined)

        setRole(mapped)
      })
      .catch((err: AxiosError<any>) => {
        if (!isMountedRef.current) return

        setIsError(true)

        // Vpn is disconnected
        if (err.code === 'ECONNABORTED') return

        const errorMessage = err.response?.data.message || err.response?.data.error

        // Api has failed if its not ECONNABORTED, display error message
        setErrorMessage(errorMessage)
      })
      .finally(() => {
        if (!isMountedRef.current) return

        setIsLoading(false)
      })
  }, [])

  return { role, isLoading, isError, errorMessage }
}

export const useIsSignedIn = () => {
  const { role } = useSessionRole()

  return isSignedIn(role)
}
