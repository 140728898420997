import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Shadows from '../figma/tokens/Shadows'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { BO_HEADER_LARGE_DESKTOP_WIDTH, BO_MAX_CONTENT_WIDTH, BO_NOTES_MAX_WIDTH } from '../mynt-components/WeakHardCodedSizes'
import HeaderLogo from './HeaderLogo'
import HeaderVariantDesktop from './HeaderVariantDesktop'
import { useIsSignedIn } from 'contexts/session'

const Header: React.FC = () => {
  const isLoggedIn = useIsSignedIn()
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  const isViewDesktopLarge = windowWidth >= BO_HEADER_LARGE_DESKTOP_WIDTH

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <HeaderWrapper>
        <HeaderContainer fullWidth left right>
          {!isLoggedIn && <HeaderLogo />}
          {isLoggedIn && <HeaderVariantDesktop isViewDesktopLarge={isViewDesktopLarge} />}
        </HeaderContainer>
      </HeaderWrapper>
    </>
  )
}

const HeaderWrapper = styled.header`
  position: relative; // this keeps the header above the content, so the shadow works
  display: flex;
  justify-content: center;
  background: ${ColorsDeprecated.baseWhite};
  box-shadow: ${Shadows.generalNavBar};
`

const HeaderContainer = styled(FigmaBox)`
  max-width: calc(${BO_MAX_CONTENT_WIDTH}px + ${BO_NOTES_MAX_WIDTH}px);
  width: 100%;
  padding-left: 32px;
`

export default Header
