import styled from '@emotion/styled'
import Link from '@mui/material/Link'
import React, { SVGProps } from 'react'
import Colors from '../../figma/panda/Colors'
import Spacings from '../../figma/tokens/Spacings'
import { useLanguage } from '../../hooks/useLanguage'
import { TARGET } from '../../tiger/Constants'
import { AntiloopTextType } from '../../tiger/interfaces/Antiloop'
import FigmaTextContainer from './FigmaTextContainer'
import SVGContainer from './SVGContainer'

type Props = {
  url?: string
  textKey: AntiloopTextType
  leftSvg?: React.FC<React.PropsWithChildren<React.PropsWithChildren<SVGProps<SVGSVGElement>>>>
  rightSvg?: React.FC<React.PropsWithChildren<React.PropsWithChildren<SVGProps<SVGSVGElement>>>>
  isSamePage?: boolean
  target?: TARGET
  text?: string
}

const FigmaTextLink: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  url,
  textKey,
  leftSvg,
  rightSvg,
  isSamePage,
  target,
  text
}) => {
  const language = useLanguage()
  return (
    // eslint-disable-next-line no-nested-ternary
    <Wrapper href={url} target={isSamePage ? TARGET.SELF : target === TARGET.SELF ? TARGET.SELF : TARGET.BLANK}>
      {leftSvg && (
        <SVGWrapper left>
          <SVGContainer SVG={leftSvg} />
        </SVGWrapper>
      )}
      <FigmaTextContainer textKey={textKey} language={language} text={text} />
      {rightSvg && (
        <SVGWrapper>
          <SVGContainer SVG={rightSvg} />
        </SVGWrapper>
      )}
    </Wrapper>
  )
}

const SVGWrapper = styled.div<{ left?: boolean }>`
  ${(props) => (props.left ? `padding-left: ${Spacings.tiny}; ` : ` padding-right: ${Spacings.tiny};`)}
`
const Wrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;

  && {
    text-decoration: none;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
    &:hover {
      span,
      svg {
        color: ${Colors.bluePrimary500} !important;
      }
    }
    &:active {
      span,
      svg {
        color: ${Colors.bluePrimary600Cta} !important;
      }
    }
  }
`

export default FigmaTextLink
