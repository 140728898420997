import Popconfirm from '../components/Popconfirm'
import { formatBytes } from 'helpers/utils'
import React from 'react'
import { FilexDto } from '../api/swagger/definitions/backoffice'
import { getPresignedFileURL } from '../api'
import DateFromEpoch from './DateFromEpoch'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'

interface CustomerFilesTableProps {
  data?: FilexDto[]
  onDelete?: (file: FilexDto) => void
  isLoading?: boolean
}

const CustomerFilesTable: React.FC<CustomerFilesTableProps> = ({ data = [], onDelete, isLoading }) => {
  const columns: GridColDef<FilexDto>[] = [
    {
      headerName: 'Date',
      flex: 1,
      field: 'createdAt',
      sortingOrder: ['desc'],
      renderCell: (params) => <DateFromEpoch epoch={params.value * 1000} />
    },
    {
      headerName: 'File name',
      flex: 2,
      field: 'filename'
    },
    {
      headerName: 'Content type',
      flex: 1,
      field: 'contentType'
    },
    {
      headerName: 'Size',
      flex: 1,
      field: 'size',
      renderCell: (params) => formatBytes(params.value)
    },
    {
      headerName: 'Context',
      flex: 1,
      field: 'context'
    },
    {
      headerName: '',
      flex: 1,
      field: '',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <Popconfirm title="Are you sure you want to delete this file?" onConfirm={() => onDelete?.(row)}>
          <Button variant="primary" size="small">
            Delete
          </Button>
        </Popconfirm>
      )
    }
  ]

  const handleOnRowClick = async ({ id, customerId }: { id: string; customerId: string }) => {
    window.open(await getPresignedFileURL(customerId, id))
  }

  const showMinHeight = isLoading || data.length === 0

  return (
    <>
      <span>Hint: Double click row to open file</span>
      <Box sx={{ height: showMinHeight ? '200px' : 'auto' }}>
        <DataGridPro
          disableRowSelectionOnClick
          rows={data.filter((file) => !file.deleted)}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No files found
              </Stack>
            )
          }}
          columns={columns}
          loading={isLoading}
          style={{ marginBottom: 20, width: '100%' }}
          className="small"
          onRowDoubleClick={({ row }) => handleOnRowClick({ id: row.id, customerId: row.customerId })}
        />
      </Box>
    </>
  )
}

export default CustomerFilesTable
