/* eslint-disable */
// prettier-ignore
// THIS FILE IS AUTO-GENERATED BY EMU. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.
export default {
  "bluePrimary700": "rgb(34, 92, 204)",
  "bluePrimary600Cta": "rgb(42, 115, 255)",
  "bluePrimary500": "rgb(85, 143, 255)",
  "bluePrimary400": "rgb(128, 171, 255)",
  "bluePrimary300": "rgb(175, 203, 255)",
  "bluePrimary200": "rgb(204, 221, 255)",
  "bluePrimary100": "rgb(234, 241, 255)",
  "success700": "rgb(13, 130, 81)",
  "success600": "rgb(42, 204, 136)",
  "success500": "rgb(77, 212, 156)",
  "success400": "rgb(113, 221, 176)",
  "success300": "rgb(148, 229, 195)",
  "success200": "rgb(184, 238, 215)",
  "success100": "rgb(223, 248, 237)",
  "alert600": "rgb(120, 83, 13)",
  "alert500": "rgb(171, 129, 49)",
  "alert400": "rgb(255, 200, 95)",
  "alert300": "rgb(255, 214, 128)",
  "alert200": "rgb(255, 236, 197)",
  "alert100": "rgb(255, 245, 226)",
  "notificationErrors600": "rgb(255, 71, 82)",
  "notificationErrors500": "rgb(249, 128, 128)",
  "notificationErrors400": "rgb(248, 180, 180)",
  "notificationErrors300": "rgb(251, 213, 213)",
  "notificationErrors200": "rgb(253, 232, 232)",
  "notificationErrors100": "rgb(253, 242, 242)",
  "baseWhite": "rgb(255, 255, 255)",
  "baseBeige50": "rgb(249, 248, 245)",
  "baseBeige100": "rgb(248, 243, 236)",
  "baseBlack": "rgb(28, 27, 31)",
  "reminder600": "rgb(126, 63, 108)",
  "reminder500": "rgb(148, 79, 128)",
  "reminder400": "rgb(175, 105, 155)",
  "reminder300": "rgb(210, 132, 187)",
  "reminder200": "rgb(239, 182, 222)",
  "reminder100": "rgb(255, 221, 245)",
  "base500": "rgb(104, 103, 106)",
  "base400": "rgb(179, 179, 180)",
  "base350": "rgb(199, 199, 199)",
  "base300": "rgb(238, 238, 238)",
  "base200": "rgb(245, 245, 245)",
  "base100": "rgb(252, 252, 252)",
  "categoryColorsSand": "rgb(236, 190, 162)",
  "categoryColorsPink": "rgb(237, 176, 185)",
  "categoryColorsPurple": "rgb(218, 137, 179)",
  "categoryColorsViolet": "rgb(191, 155, 228)",
  "categoryColorsBlue": "rgb(156, 189, 244)",
  "categoryColorsGreen": "rgb(146, 210, 164)",
  "categoryColorsMoss": "rgb(189, 210, 146)",
  "categoryColorsAppleGreen": "rgb(192, 227, 164)",
  "categoryColorsYellow": "rgb(248, 230, 127)",
  "decorativeColorsTiger100": "rgb(255, 128, 87)",
  "decorativeColorsTiger10": "rgb(255, 242, 238)",
  "decorativeColorsViolet100": "rgb(90, 52, 161)",
  "decorativeColorsViolet10": "rgb(238, 235, 246)",
  "decorativeColorsVirtualCard": "rgb(16, 118, 150)",
  "backgroundColorsBlurred": "rgba(37, 37, 37, 0.34)",
  "backgroundColorsCloud": "rgb(251, 251, 252)",
  "backgroundColorsBackground": "rgb(248, 248, 247)",
  "brandBlue100": "rgb(234, 238, 245)",
  "brandBlue200": "rgb(193, 205, 228)",
  "brandBlue300": "rgb(94, 119, 169)",
  "brandBlue400": "rgb(48, 79, 142)",
  "brandBlue500": "rgb(22, 48, 98)",
  "brandBlue600": "rgb(25, 31, 60)",
  "brandBlueCTA": "rgb(42, 115, 255)",
  "brandBiege100": "rgb(248, 243, 236)",
  "brandBiege200": "rgb(241, 237, 235)",
  "brandBiege300": "rgb(223, 216, 212)",
  "brandBiege400": "rgb(206, 196, 190)",
  "brandBiege500": "rgb(190, 177, 169)",
  "brandBiege600": "rgb(175, 162, 154)",
  "brandBiegeWhite": "rgb(255, 255, 255)",
  "brandBiegeGray100": "rgb(249, 249, 249)",
  "brandBiegeGray200": "rgb(242, 242, 242)",
  "brandBiegeGray300": "rgb(141, 141, 143)",
  "brandBiegeGray400": "rgb(85, 84, 87)",
  "brandBiegeBlack": "rgba(28, 27, 31, 0.5)",
  "brandBiegeLightBlack": "rgba(28, 27, 31, 0.1)",
}