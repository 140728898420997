import {
  FIFTY_THOUSAND,
  FIVE_THOUSAND,
  TEN_MILLION,
  TWENTY_FIVE_HUNDRED,
  TWENTY_FIVE_THOUSAND,
  TWENTY_THOUSAND,
  TWO_MILLION,
  TWO_THOUSAND
} from '../../tiger/Constants'

// Don't use "?:", instead use " | undefined", as it requires the property to be explicitly added and set to undefined
// This improves readability and makes it easy to reason about the config format quickly
export type ConfigCurrency = {
  name: Currencies
  symbol: string
  prefix: string
  suffix: string
  startAdornment: string | undefined
  endAdornment: string | undefined
  creditLimit: {
    min: number
    max: number
  }
  creditLimitMedium: {
    min: number
    max: number
  }
  creditLimitHigher: {
    min: number
    max: number
  }
}

export enum Currencies {
  SEK = 'SEK',
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
  DKK = 'DKK'
}

export const ConfigCurrencies: Record<Currencies, ConfigCurrency> = {
  [Currencies.SEK]: {
    name: Currencies.SEK,
    symbol: 'kr',
    prefix: '',
    suffix: ' kr',
    startAdornment: undefined,
    endAdornment: 'kr',
    creditLimit: {
      min: TWENTY_THOUSAND,
      max: TEN_MILLION
    },
    creditLimitMedium: {
      min: TWENTY_FIVE_THOUSAND,
      max: TEN_MILLION
    },
    creditLimitHigher: {
      min: FIFTY_THOUSAND,
      max: TEN_MILLION
    }
  },
  [Currencies.EUR]: {
    name: Currencies.EUR,
    symbol: '€',
    prefix: '€',
    suffix: '',
    startAdornment: undefined,
    endAdornment: '€',
    creditLimit: {
      min: TWO_THOUSAND,
      max: TWO_MILLION
    },
    creditLimitMedium: {
      min: TWENTY_FIVE_HUNDRED,
      max: TWO_MILLION
    },
    creditLimitHigher: {
      min: FIVE_THOUSAND,
      max: TWO_MILLION
    }
  },
  [Currencies.GBP]: {
    name: Currencies.GBP,
    symbol: '£',
    prefix: '£',
    suffix: '',
    startAdornment: '£',
    endAdornment: undefined,
    creditLimit: {
      min: TWO_THOUSAND,
      max: TWO_MILLION
    },
    creditLimitMedium: {
      min: TWENTY_FIVE_HUNDRED,
      max: TWO_MILLION
    },
    creditLimitHigher: {
      min: FIVE_THOUSAND,
      max: TWO_MILLION
    }
  },
  [Currencies.USD]: {
    name: Currencies.USD,
    symbol: '$',
    prefix: '$',
    suffix: '',
    startAdornment: '$',
    endAdornment: undefined,
    creditLimit: {
      min: TWO_THOUSAND,
      max: TWO_MILLION
    },
    creditLimitMedium: {
      min: TWENTY_FIVE_HUNDRED,
      max: TWO_MILLION
    },
    creditLimitHigher: {
      min: FIVE_THOUSAND,
      max: TWO_MILLION
    }
  },
  [Currencies.DKK]: {
    name: Currencies.DKK,
    symbol: 'dkk',
    prefix: '',
    suffix: ' dkk',
    startAdornment: undefined,
    endAdornment: 'dkk',
    creditLimit: {
      min: TWENTY_THOUSAND,
      max: TEN_MILLION
    },
    creditLimitMedium: {
      min: TWENTY_FIVE_THOUSAND,
      max: TEN_MILLION
    },
    creditLimitHigher: {
      min: FIFTY_THOUSAND,
      max: TEN_MILLION
    }
  }
}
