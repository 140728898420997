import React from 'react'
import { TextKeys } from 'tiger/libs/TextRepository'
import FigmaBox from '../mynt-components/components/FigmaBox'
import TextContainer from './TextContainer'

interface CustomerCardTabOverviewKeyValueLabelProps {
  name: string
  value: string | undefined
  top?: boolean
  bottom?: boolean
  gap?: string
}

const CustomerCardTabOverviewDataRow: React.FC<CustomerCardTabOverviewKeyValueLabelProps> = ({ name, value, top, bottom, gap }) => (
  <FigmaBox direction="row" fullWidth justify="space-between" top={top} bottom={bottom} gap={gap}>
    <FigmaBox flex={2}>
      <TextContainer text={name + ':'} textKey={TextKeys.customersCardOverviewAccountNameTitle} />
    </FigmaBox>
    <FigmaBox flex={3}>
      <TextContainer textKey={TextKeys.customersCardOverviewAccountNameInfo} text={value ?? '-'} />
    </FigmaBox>
  </FigmaBox>
)

export default CustomerCardTabOverviewDataRow
