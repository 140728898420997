import useIsBrowser from './useIsBrowser'

const useUrlParam = (param: string, search?: string) => {
  const isBrowser = useIsBrowser()

  let params: URLSearchParams | undefined

  if (isBrowser) params = new URLSearchParams(search || window.location.search)

  return params ? params.get(param) : undefined
}

export default useUrlParam
