import { formatCurrency } from 'helpers/utils'
import { ActionTypes, useFetchReducer } from 'hooks/fetchReducer'
import React, { useEffect } from 'react'
import { CompanyCreditDto, ReportDto } from '../api/swagger/definitions/backoffice'
import { getCreditReport } from '../api'
import { getAccruedSumAmount, getRemainingSumAmount, getSettledSumAmount, getTotalSumAmount } from '../helpers/DebtHelpers'
import { Report } from '../interfaces/DebtInterfaces'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import styled from '@emotion/styled'

interface CreditAccountingDebtProps {
  credit: CompanyCreditDto
}

const CreditAccountingDebt: React.FC<CreditAccountingDebtProps> = ({ credit }) => {
  const [state, dispatch] = useFetchReducer<ReportDto>({
    loading: true
  })

  const { details, loading } = state

  useEffect(() => {
    const fetchAccounting = async () => {
      try {
        if (credit.id === undefined)
          return dispatch({
            type: ActionTypes.error,
            payload: { error: new Error('invalid credit id') }
          })
        const data = await getCreditReport(credit.id)
        dispatch({ type: ActionTypes.success, payload: { data } })
      } catch (e) {
        dispatch({ type: ActionTypes.error, payload: { error: e } })
      }
    }

    fetchAccounting()
  }, [credit.id, credit.rev, dispatch])

  const getValue = (name: keyof Report) => {
    if (!details || loading) return '-'
    return formatCurrency(details[name])
  }

  return (
    <Table>
      <TableHead>
        <StyledRow sx={{ backgroundColor: 'rgb(249, 249, 249)' }}>
          <TableCell></TableCell>
          <TableCell>Total</TableCell>
          <TableCell>Settled</TableCell>
          <TableCell>Remaining</TableCell>
          <TableCell>Accrued</TableCell>
        </StyledRow>
      </TableHead>
      <TableBody>
        <StyledRow>
          <StyledCell sx={{ backgroundColor: 'rgb(249, 249, 249)' }}>Principal</StyledCell>
          <StyledCell>{getValue('principalTotal')}</StyledCell>
          <StyledCell>{getValue('principalSettled')}</StyledCell>
          <StyledCell>{getValue('principalRemaining')}</StyledCell>
          <StyledCell>-</StyledCell> {/* Accrued was always "-" in old antd table */}
        </StyledRow>
        <StyledRow>
          <StyledCell sx={{ backgroundColor: 'rgb(249, 249, 249)' }}>Fees</StyledCell>
          <StyledCell>{getValue('feesTotal')}</StyledCell>
          <StyledCell>{getValue('feesSettled')}</StyledCell>
          <StyledCell>{getValue('feesRemaining')}</StyledCell>
          <StyledCell>{getValue('feesAccrued')}</StyledCell>
        </StyledRow>
        <StyledRow>
          <TableCell sx={{ backgroundColor: 'rgb(249, 249, 249)' }}>Penalties</TableCell>
          <TableCell>{getValue('penaltiesTotal')}</TableCell>
          <TableCell>{getValue('penaltiesSettled')}</TableCell>
          <TableCell>{getValue('penaltiesRemaining')}</TableCell>
          <TableCell>-</TableCell> {/* Accrued was always "-" in old antd table */}
        </StyledRow>
        <StyledRow>
          <StyledCell sx={{ backgroundColor: 'rgb(249, 249, 249)' }}>Total</StyledCell>
          <StyledCell>{getTotalSumAmount({ details, loading })}</StyledCell>
          <StyledCell>{getSettledSumAmount({ details, loading })}</StyledCell>
          <StyledCell>{getRemainingSumAmount({ details, loading })}</StyledCell>
          <StyledCell>{getAccruedSumAmount({ details, loading })}</StyledCell>
        </StyledRow>
      </TableBody>
    </Table>
  )
}

const StyledCell = styled(TableCell)`
  border-color: transparent;
  border: none;
`

const StyledRow = styled(TableRow)`
  border-color: transparent;
  border: none;
`

export default CreditAccountingDebt
