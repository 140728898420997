export enum StatusColors {
  red = 'rgb(247, 32, 7)',
  orange = '#f88d68',
  green = 'rgb(0, 197, 174)',
  black = '#000000',
  yellow = '#f7ce50',
  tuna = '#37383d',
  gray = 'rgb(249, 249, 249)',
  darkgray = '#a9a9a9'
}
