import styled from '@emotion/styled';
import ButtonTertiaryHoverCss from './ButtonTertiaryHoverCss';


interface ButtonTertiaryHoverProps {
  children: any;
  [propName: string]: {};
}

// Extend the below as needed
const ButtonTertiaryHoverStyled = styled.div<ButtonTertiaryHoverProps>`
  ${ButtonTertiaryHoverCss};
`;

export default ButtonTertiaryHoverStyled;