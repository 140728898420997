/* eslint-disable */
// prettier-ignore
// THIS FILE IS AUTO-GENERATED BY EMU. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.
export default {
  "baseSoft": "0px 0px 2px rgba(27, 28, 31, 0.15)",
  "baseMedium": "0px 0px 10px rgba(120, 120, 122, 0.15)",
  "baseHeavy": "0px 0px 6px rgba(120, 120, 122, 0.4)",
  "baseCard": "0px 2px 1px rgba(0, 0, 0, 0.03)",
  "infoboxSoftBottom": "0px 2px 1px rgba(120, 120, 122, 0.1)",
  "buttonGreen": "0px 0px 6px rgba(42, 204, 136, 0.4)",
  "hoverMedium": "0px 8px 25px rgba(120, 120, 122, 0.4)",
  "illustrationMedium": "0px 2px 8px rgba(0, 0, 0, 0.3)",
  "illustrationHeavy": "-4px 20px 20px rgba(0, 0, 0, 0.1)",
  "generalEagleBottomNav": "0px 0px 1px rgba(0, 0, 0, 0.2)",
  "illustrationSoft": "0px 2px 4px rgba(0, 0, 0, 0.1)",
  "generalContextualBar": "0px -1px 1px rgba(0, 0, 0, 0.1)",
  "generalNavBar": "0px 0px 1px rgba(0, 0, 0, 0.45)"
}