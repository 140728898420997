import React, { useRef, useState } from 'react'

import { Button } from '../components/Button'

interface UploadButtonProps {
  title?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
}

const UploadButton: React.FC<UploadButtonProps> = ({ title = 'Upload', onChange }) => {
  const [submitting, setSubmitting] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitting(true)
    await onChange(event)
    setSubmitting(false)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <>
      <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
        <Button size="small" loading={submitting} style={{ pointerEvents: 'none' }}>
          {title}
        </Button>
      </label>

      <input id="file-upload" type="file" style={{ display: 'none' }} onChange={handleOnChange} ref={inputRef} />
    </>
  )
}

export default UploadButton
