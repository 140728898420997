import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { BO_HEADER_PHONE_SEARCH_WIDTH } from '../mynt-components/WeakHardCodedSizes'
import { SEARCH_PATH } from '../routes/constants'
import TextField from '@mui/material/TextField'
import Search from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import MetaButtonIcon from 'figma/svgs/metaButtonIcon'
import CtrlButtonIcon from 'figma/svgs/ctrlButtonIcon'
import KButtonIcon from 'figma/svgs/kButtonIcon'
import { isMacOs } from 'react-device-detect'
import Box from '@mui/material/Box'

export const SEARCH_ID = 'customer-search'

const HeaderSearch: React.FC = () => {
  const navigate = useNavigate()
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const textFieldRef = useRef<HTMLDivElement | null>(null)

  const [query, setQuery] = useState<string>('')

  useEffect(() => {
    if (window.location.search.length > 0) {
      const searchParams = new URLSearchParams(window.location.search)
      setQuery(searchParams.get('query') ?? '')
    }
  }, [window.location.search])

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && query.length > 0) {
      const urlEncodedQuery = encodeURIComponent(query)
      navigate(`${SEARCH_PATH}?query=${urlEncodedQuery}&size=10`)
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const handleCtrlK = (event: KeyboardEvent) => {
    const isPressingCtrlOrCmd = event.ctrlKey || event.metaKey

    if (event.key === 'k' && isPressingCtrlOrCmd) {
      event.preventDefault()
      textFieldRef.current?.focus()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleCtrlK)

    return () => {
      window.removeEventListener('keydown', handleCtrlK)
    }
  }, [])

  // These custom styles should preferably be applied in the theme.
  // But the inputs seems to be quite rigid to work with, doing it inline for now.

  const shouldShrink = Boolean(isFocused || query.length > 0)

  return (
    <SearchInputContainer>
      <TextField
        id={SEARCH_ID}
        value={query}
        InputLabelProps={{ shrink: shouldShrink, sx: { paddingLeft: shouldShrink ? undefined : '16px' } }}
        onKeyDown={onKeyPress}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        inputProps={{ sx: { padding: '4px 0px' }, ref: textFieldRef }}
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ width: '24px' }} position="start">
              <Search sx={{ fontSize: 18, marginLeft: '6px' }} />
            </InputAdornment>
          ),
          endAdornment: !isFocused && (
            <Box sx={{ justifyContent: 'center', alignItems: 'center', paddingRight: '10px' }}>
              <InputAdornment sx={{ width: '48px' }} position="end">
                <Box sx={{ display: 'flex', gap: '2px' }}>
                  {isMacOs ? <MetaButtonIcon /> : <CtrlButtonIcon />}
                  <KButtonIcon />
                </Box>
              </InputAdornment>
            </Box>
          )
        }}
        name="search-in-bo"
        variant="outlined"
        size="small"
        label="Search..."
      />
    </SearchInputContainer>
  )
}

const SearchInputContainer = styled(FigmaBox)`
  width: ${BO_HEADER_PHONE_SEARCH_WIDTH};
`

export default HeaderSearch
