import React from 'react'
import { Link } from 'react-router-dom'
import { CUSTOMERS_PATH } from '../routes/constants'
import CustomerTitle from './CustomerTitle'

interface IProps {
  orgNo: string
  name: string
  id: string
}

const CustomerLink: React.FC<IProps> = ({ id, orgNo, name, children }) => {
  if (name === 'NON-EXISTING CUSTOMER') {
    return (
      <CustomerTitle orgno={orgNo} name={name}>
        {children}
      </CustomerTitle>
    )
  }

  return (
    <Link to={`${CUSTOMERS_PATH}/${id}`}>
      <CustomerTitle orgno={orgNo} name={name} headerClassName="header">
        {children}
      </CustomerTitle>
    </Link>
  )
}

export default CustomerLink
