import React, { useState } from 'react'
import { syncCompanyCreditWithFortnox } from '../api'
import { CreditTypes } from '../helpers/utils'
import useMaterialNotification from '../hooks/useMaterialNotification'
import { Button } from './Button'

const getSyncFortnoxAPI = (creditType: CreditTypes) => {
  switch (creditType) {
    case CreditTypes.COMPANYCREDIT: {
      return syncCompanyCreditWithFortnox
    }

    default: {
      throw new Error(`${creditType} api does not exist`)
    }
  }
}

interface IProps {
  id: string
  type: CreditTypes
  onSubmit?: () => void
}

const CreditAccountingActions: React.FC<IProps> = ({ id, type, onSubmit }) => {
  const [submitting, setSubmitting] = useState(false)
  const notify = useMaterialNotification()

  const handleButtonClick = async () => {
    try {
      setSubmitting(true)
      await getSyncFortnoxAPI(type)(id)
      onSubmit && (await onSubmit())

      notify(`Sync with Fortnox was successful`)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Button onClick={handleButtonClick} loading={submitting}>
        Sync with Fortnox
      </Button>
    </>
  )
}

export default CreditAccountingActions
