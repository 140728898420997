/* eslint-disable prettier/prettier */
export const validInputRegex = [
  /(^[0-9]{4}-[0-9]{7}$)/,
  /(^[0-9]{4}-[0-9]{9}$)/,
  /(^[0-9]{4}-[0-9]{10}$)/,
  /(^[0-9]{5}-[0-9]{8}$)/,
  /(^[0-9]{5}-[0-9]{9}$)/,
  /(^[0-9]{5}-[0-9]{10}$)/
]

export const YYYY_MM_DD = 'YYYY-MM-DD'