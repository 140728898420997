import styled from '@emotion/styled'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useQueryClient } from '@tanstack/react-query'
import { Checkbox } from '../components/Checkbox'
import React, { useRef, useState } from 'react'
import { KycAccessLevel } from '../api/swagger/definitions/backoffice'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import useLanguage from '../flamingo/hooks/useLanguage'
import {
  InvalidateStarterGuide,
  useCustomer,
  useKycPeople,
  useKycRisks,
  usePatchCustomer,
  useUpdateKycPeople,
  useUpdateKycRisks
} from '../api/react-query'
import { sendKYCApprovedEmail } from '../api'
import useMaterialNotification from '../hooks/useMaterialNotification'
import FigmaBox, { FigmaBoxProps } from '../mynt-components/components/FigmaBox'
import FigmaButton from '../mynt-components/components/FigmaButton'
import MyntLoader from '../mynt-components/components/MyntLoader'
import MyntPopper from '../mynt-components/components/MyntPopper'
import { TextKeys, getText } from '../tiger/libs/TextRepository'
import { ModalConfirmDialog } from './Modals'
import TextContainer from './TextContainer'
import { CoreMyntModal } from './modals/CoreMyntModal'
import Box from '@mui/material/Box'
import { TextFieldController } from './react-hook-components'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'

interface CustomerPepRiskProps {
  customerId: string
}

const riskLevels = ['Low', 'Normal', 'High', 'Unacceptable']

type CircleProps = {
  color: string
  noSpacing?: boolean
  size?: number
}

const Circle: React.FC<CircleProps & FigmaBoxProps> = ({ color, noSpacing, size = 24, ...rest }) => (
  <FigmaBox spacing={Spacings.min} right={!noSpacing} {...rest}>
    <svg width={size} height={size}>
      <circle cy={size / 2} cx={size / 2} r={size / 2} fill={color} />
    </svg>
  </FigmaBox>
)

const reasons = [
  'Complicated ownership structure',
  'Suspicious or unclear bank transactions',
  'High risk industry',
  'Suspicious address',
  'PEP involved',
  'Sanction listed individual'
]

const getIsRiskLevelTooHigh = (level?: string) => level === 'High' || level === 'Unacceptable'

const CustomerPepRiskExperimental: React.FC<CustomerPepRiskProps> = ({ customerId }) => {
  const [reasonsList, setReasonsList] = useState(reasons)
  const [loading, setIsLoading] = useState(false)
  const [isKYCEmailSendOpen, setIsKYCEmailSendOpen] = useState(false)
  const sendKYCEmailRef = useRef<HTMLDivElement>(null)
  const language = useLanguage()
  const queryClient = useQueryClient()
  const [isOtherReasonModalOpen, setIsOtherReasonModalOpen] = useState(false)
  const updatePeopleMutation = useUpdateKycPeople()
  const { data: riskConfig, isLoading: riskConfigisLoading } = useKycRisks(customerId)
  const { data: customer, isLoading: customerIsLoading } = useCustomer(customerId)

  // const { fetchFullKYCData, fetchKYCAnalysis, analysis } =
  //   useKYCData(customerId)

  const { isLoading: analysisIsLoading, isRefetching } = useKycPeople(customerId)

  const customerMutation = usePatchCustomer(customerId)
  const kycMutation = useUpdateKycRisks(customerId)
  const notify = useMaterialNotification()

  const { GREEN, YELLOW, RED } = KycAccessLevel

  const kycValueLabels = {
    [GREEN]: getText(TextKeys.kycStatusGreen, language),
    [YELLOW]: getText(TextKeys.kycStatusYellow, language),
    [RED]: getText(TextKeys.kycStatusRed, language)
  }

  const kycValueColors = {
    [GREEN]: <Circle right={Spacings.smallest} color={ColorsDeprecated.baseGreen} />,
    [YELLOW]: <Circle right={Spacings.smallest} color={ColorsDeprecated.baseWarning} />,
    [RED]: <Circle right={Spacings.smallest} color={ColorsDeprecated.baseCritical} />
  }

  const RefreshBoardMembers = async () => {
    // await generateKYCAnalysis(customerId)
    // await Promise.all([fetchKYCAnalysis(), fetchFullKYCData()])
    await updatePeopleMutation.mutateAsync(customerId)
    notify('Refreshed owners and board members', 'info')
  }

  const handleSelectKycStatus = (event: SelectChangeEvent<KycAccessLevel>) => {
    const status = event.target.value as KycAccessLevel

    customerMutation
      .mutateAsync({
        kycAccessLevel: status
      })
      .then(() => {
        notify(`Updated KYC status to ${status}`)
        InvalidateStarterGuide(queryClient)
      })
  }

  const handleSelectKycRisk = (event: SelectChangeEvent<string>) => {
    const risk = event.target.value

    kycMutation
      .mutateAsync({
        class: risk,
        reasons: riskConfig?.reasons || []
      })
      .then(() => {
        notify(`Updated KYC risk to ${risk}`)
      })
  }

  const handleOnOtherFormSubmit = (reason: string) => {
    setReasonsList((list) => [...list, reason])
  }

  const handleOnCheckboxClick = (reason: string) => {
    if (!riskConfig?.class) return

    let newReasons = riskConfig?.reasons || []
    const shouldRemove = riskConfig?.reasons?.includes(reason)

    if (shouldRemove) {
      newReasons = newReasons.filter((r) => r !== reason)
    } else {
      newReasons = [...newReasons, reason]
    }

    kycMutation
      .mutateAsync({
        ...riskConfig,
        reasons: newReasons
      })
      .finally(() => setIsLoading(false))
  }

  const getReasonsList = () => {
    const newReasons: string[] = []

    riskConfig?.reasons?.forEach((reason) => {
      if (!reasonsList.includes(reason)) {
        newReasons.push(reason)
      }
    })

    return [...reasonsList, ...newReasons]
  }

  const reasonsCheckboxes = getReasonsList()

  if (riskConfigisLoading || customerIsLoading) return <MyntLoader />

  const handleKYCEmailSending = () => {
    sendKYCApprovedEmail(customerId).then(() => notify(`KYC approved email has been sent`))
    setIsKYCEmailSendOpen(false)
  }

  return (
    <>
      <MyntPopper
        visible={isKYCEmailSendOpen}
        noPadding
        marginBottom={Spacings.large}
        position="top"
        anchorRef={sendKYCEmailRef.current}
        content={
          <ModalConfirmDialog
            onCancel={() => setIsKYCEmailSendOpen(false)}
            onConfirm={handleKYCEmailSending}
            description={TextKeys.toolTipAreYouSureSendKYCEmail}
          />
        }
      />
      <FigmaBox spacing={Spacings.large} bottom direction="row">
        <FigmaBox spacing={Spacings.large} right>
          <FigmaBox spacing={Spacings.tiny} bottom>
            <TextContainer textKey={TextKeys.KYCRefreshLabel} />
          </FigmaBox>
          <FigmaButton
            variant="tertiary"
            fullWidth={false}
            textKey={TextKeys.KYCRefreshButton}
            onClick={RefreshBoardMembers}
            loading={updatePeopleMutation.isLoading}
            disabled={analysisIsLoading || isRefetching}
            // style={analysisIsLoading || isRefetching ? { padding: `${Spacings.smallest} ${Spacings.tiny}` } : {}}
          />
        </FigmaBox>
        <FigmaBox spacing={Spacings.large} right>
          <FigmaBox spacing={Spacings.small} bottom>
            <TextContainer textKey={TextKeys.kycStatusLabel} />
          </FigmaBox>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              fullWidth
              style={{ width: 300 }}
              disabled={customerIsLoading}
              value={customer?.kycAccessLevel}
              onChange={handleSelectKycStatus}
            >
              {Object.entries(kycValueLabels).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  <FigmaBox direction="row">
                    <FigmaBox spacing={Spacings.minimum} right>
                      {kycValueColors[value]}
                    </FigmaBox>
                    {label}
                  </FigmaBox>
                </MenuItem>
              ))}
              <MenuItem key={KycAccessLevel.GREY} value={KycAccessLevel.GREY}>
                <FigmaBox direction="row">
                  <Circle color={ColorsDeprecated.baseStone} />- Not done
                </FigmaBox>
              </MenuItem>
            </Select>
          </FormControl>
        </FigmaBox>
        <FigmaBox spacing={Spacings.large} right>
          <FigmaBox spacing={Spacings.small} bottom>
            <TextContainer textKey={TextKeys.kycRiskAssesmentLabel} />
          </FigmaBox>
          <FormControl fullWidth>
            <InputLabel>Risk level</InputLabel>
            <Select
              label="Risk level"
              fullWidth
              style={{ width: 200 }}
              disabled={customerIsLoading}
              value={riskConfig?.class}
              onChange={handleSelectKycRisk}
            >
              {riskLevels.map((value) => (
                <MenuItem key={value} value={value}>
                  <FigmaBox direction="row">{value}</FigmaBox>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FigmaBox>
        <KYCEmailButtonWrapper ref={sendKYCEmailRef}>
          <FigmaButton onClick={() => setIsKYCEmailSendOpen(true)} textKey={TextKeys.kycSendKycEmailButton} />
        </KYCEmailButtonWrapper>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {getIsRiskLevelTooHigh(riskConfig?.class) && (
            <Box>
              <Button size="small" variant="primary" onClick={() => setIsOtherReasonModalOpen(true)}>
                Add other reason
              </Button>
            </Box>
          )}
          <FigmaBox justify="flex-start" fullWidth>
            <FigmaBox spacing={Spacings.medium} bottom>
              <OtherLevelModal
                show={isOtherReasonModalOpen}
                onSubmit={handleOnOtherFormSubmit}
                onClose={() => setIsOtherReasonModalOpen(false)}
              />
            </FigmaBox>
            <FigmaBox align="center" justify="center" fullWidth direction="row">
              <CheckboxWrapper spacing={Spacings.large} right fullWidth>
                {getIsRiskLevelTooHigh(riskConfig?.class) &&
                  reasonsCheckboxes.map((reason) => (
                    <Checkbox
                      style={{ marginLeft: 0, textAlign: 'left' }}
                      key={reason}
                      className="small"
                      checked={riskConfig?.reasons?.includes(reason)}
                      onClick={() => handleOnCheckboxClick(reason)}
                      disabled={loading}
                    >
                      {reason}
                    </Checkbox>
                  ))}
              </CheckboxWrapper>
            </FigmaBox>
          </FigmaBox>
        </Box>
      </FigmaBox>
    </>
  )
}

const CheckboxWrapper = styled(FigmaBox)`
  label {
    width: 100%;
  }
  * {
    text-align: left !important;
  }
`

const KYCEmailButtonWrapper = styled(FigmaBox)`
  margin-top: 35px;
  margin-right: ${Spacings.large};
`

const OtherLevelModal: React.FC<{
  show: boolean
  onSubmit: (reason: string) => void
  onClose: () => void
}> = ({ show, onSubmit, onClose }) => {
  const handleSubmit = ({ reason }: { reason: string }) => {
    if (!reason) return

    onSubmit(reason)
    onClose()
  }

  const form = useForm({
    defaultValues: {
      reason: ''
    }
  })

  return (
    <CoreMyntModal
      open={show}
      title="Add other reason"
      onClose={onClose}
      actions={(Wrapper) => (
        <Wrapper>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="primary" onClick={form.handleSubmit(handleSubmit)}>
            Submit
          </Button>
        </Wrapper>
      )}
    >
      <Box sx={{ paddingTop: '16px' }}>
        <TextFieldController control={form.control} name="reason" labelText="Reason" />
      </Box>
    </CoreMyntModal>
  )
}

export default CustomerPepRiskExperimental
