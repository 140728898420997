import styled from '@emotion/styled';
import ButtonTertiaryPressedCss from './ButtonTertiaryPressedCss';


interface ButtonTertiaryPressedProps {
  children: any;
  [propName: string]: {};
}

// Extend the below as needed
const ButtonTertiaryPressedStyled = styled.div<ButtonTertiaryPressedProps>`
  ${ButtonTertiaryPressedCss};
`;

export default ButtonTertiaryPressedStyled;