import PictureAsPdf from '@mui/icons-material/PictureAsPdf'
import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import CheckCircle from '@mui/icons-material/CheckCircle'
import NoteAdd from '@mui/icons-material/NoteAdd'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useRef, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Divider from '@mui/material/Divider'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { colors as themeColors } from 'themes'

export interface RollingCreditType {
  creditId: string
  creditNo: string | number
  OCR: string
  customer: string
  receiver: string
  type: string
  status: string
  invoiceDate: string
  dueDate: string
  minimumPayment: number
  paid: number
  hasPaidEnough: boolean
  currency: string
  period: {
    from: string
    to: string
  }
  pdfUrl?: string
}

interface RollingCreditTableProps {
  rollingCredits: RollingCreditType[]
  formatAmount?: (amount: number, currency: string) => string
  formatDate?: (field: string, date: string) => string
  // onRowSelect: (rowIds: (string | number)[]) => void
  onMarkAsClosed: (rowId: string) => Promise<unknown>
  onMarkAsPaidEnough: (row: RollingCreditType) => Promise<unknown>
  onCreateLatePaymentFee: (row: RollingCreditType) => void
  onCreateCreditInterestFee: (row: RollingCreditType) => void
  textSearch?: string
  isMarkAsClosedDisabled?: (row: RollingCreditType) => boolean
  isMarkAsPaidEnoughDisabled?: (row: RollingCreditType) => boolean
  isLoading?: boolean
  onRecalculateTransactions?: (creditId: string) => unknown | Promise<unknown>
}

const getColor = (mynt) => ({
  red: mynt?.product?.error?.[600] || 'red',
  green: mynt?.product?.success?.[700] || 'green'
})

const getPdfUrl = async (pdfUrl: string) =>
  fetch(pdfUrl, { credentials: 'include' })
    .then(async (response) => response.json() as Promise<{ url: string }>)
    .then(({ url }) => url)

const openPdf = async (url: string) =>
  fetch(url, { credentials: 'include' })
    .then(async (r) => r.blob())
    .then((blob) => window.open(URL.createObjectURL(blob), '_BLANK'))

const EMPTY_TABLE_HEIGHT = 200

export const RollingCreditTable = ({
  rollingCredits,
  formatAmount,
  formatDate,
  // onRowSelect,
  textSearch,
  isLoading,
  ...props
}: RollingCreditTableProps) => {
  const [isPDFLoading, setIsPDFLoading] = useState<null | string>(null)
  const [isActionLoading, setIsActionLoading] = useState<Partial<Record<Actions, string | null>>>({})

  const _formatAmount = (amount: number, currency: string) => formatAmount?.(amount, currency) ?? amount
  const _formatDate = (field: string, date: string) => formatDate?.(field, date) ?? date

  const tableHasResults = rollingCredits.length > 0

  const handleRecalculateTransactions = async (creditId: string) => {
    if (!props.onRecalculateTransactions) return Promise.resolve()

    setIsActionLoading((prev) => ({ ...prev, recalculateTransactions: creditId }))

    const promiseOrVoid = props.onRecalculateTransactions(creditId)

    if (promiseOrVoid instanceof Promise) {
      return promiseOrVoid.finally(() => setIsActionLoading((prev) => ({ ...prev, recalculateTransactions: null })))
    }

    return Promise.resolve()
  }

  const handleActionsClick = async (action: Parameters<ActionsMenuProps['onActionClick']>[0], creditId: string) => {
    switch (action) {
      case 'recalculateTransactions': {
        setIsActionLoading((prev) => ({ ...prev, recalculateTransactions: creditId }))

        return handleRecalculateTransactions(creditId).finally(() =>
          setIsActionLoading((prev) => ({ ...prev, recalculateTransactions: null }))
        )
      }

      case 'markAsClosed': {
        setIsActionLoading((prev) => ({ ...prev, markAsClosed: creditId }))

        return props.onMarkAsClosed(creditId).finally(() => setIsActionLoading((prev) => ({ ...prev, markAsClosed: null })))
      }

      case 'markAsPaidEnough': {
        const row = rollingCredits.find((row) => row.creditId === creditId)

        if (!row) throw new Error(`credit with id ${creditId} does not exist`)

        return props.onMarkAsPaidEnough(row)
      }

      case 'createCreditInterestFee': {
        const row = rollingCredits.find((row) => row.creditId === creditId)

        if (!row) throw new Error(`credit with id ${creditId} does not exist`)

        return props.onCreateCreditInterestFee(row)
      }

      case 'createLatePaymentInvoice': {
        const row = rollingCredits.find((row) => row.creditId === creditId)

        if (!row) throw new Error(`credit with id ${creditId} does not exist`)

        return props.onCreateLatePaymentFee(row)
      }

      default: {
        throw new Error(`Unknown action: ${action}`)
      }
    }
  }

  return (
    <div style={{ height: !tableHasResults ? EMPTY_TABLE_HEIGHT : 'auto', width: '100%' }}>
      <DataGridPro
        {...props}
        pagination
        loading={isLoading}
        pageSizeOptions={[10, 25, 50]}
        initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        slots={{
          noResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No rolling credits found from text search
            </Stack>
          ),
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No rolling credits found
            </Stack>
          ),
          loadingOverlay: LinearProgress
        }}
        getRowId={(row) => row.creditId}
        filterModel={{
          items: [],
          quickFilterValues: [textSearch]
        }}
        columns={[
          {
            field: 'creditNo',
            headerName: 'Credit No',
            flex: 1
          },
          {
            field: 'OCR',
            headerName: 'OCR',
            flex: 1.75
          },
          {
            field: 'customer',
            headerName: 'Customer',
            flex: 2
          },
          {
            field: 'receiver',
            headerName: 'Receiver',
            flex: 2
          },
          {
            field: 'type',
            headerName: 'Type',
            flex: 2
          },
          {
            field: 'status',
            headerName: 'Status',
            flex: 1
          },
          {
            field: 'period',
            headerName: 'Period',
            flex: 1.25,
            valueFormatter: ({ value }) => `${_formatDate('period', value.from)} - ${_formatDate('period', value.to)}`
          },
          {
            field: 'invoiceDate',
            headerName: 'Invoice Date',
            flex: 1.25,
            valueFormatter: ({ value }) => _formatDate('invoiceDate', value)
          },
          {
            field: 'dueDate',
            headerName: 'Due Date',
            flex: 1.25,
            valueFormatter: ({ value }) => _formatDate('dueDate', value)
          },
          {
            field: 'minimumPayment',
            headerName: 'Minimum Payment',
            flex: 1.75,
            renderCell: ({ row }) => _formatAmount(row.minimumPayment, row.currency)
          },
          {
            field: 'paid',
            headerName: 'Paid',
            flex: 1.5,
            renderCell: ({ row }) => {
              const colors = getColor(themeColors)
              const color = Boolean(row.hasPaidEnough) ? colors.green : colors.red
              const tooltip = Boolean(row.hasPaidEnough) ? 'Paid enough' : 'Not paid enough'

              return (
                <Tooltip title={tooltip}>
                  <Badge color={color}>{_formatAmount(row.paid, row.currency)}</Badge>
                </Tooltip>
              )
            }
          },
          {
            field: 'pdfUrl',
            headerName: 'PDF',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: ({ row, value }) => {
              if (!value) return null

              return (
                <span
                  style={{ display: 'flex', cursor: 'pointer' }}
                  onClick={async () => {
                    setIsPDFLoading(row.creditId)

                    getPdfUrl(value)
                      .then(openPdf)
                      .finally(() => {
                        setIsPDFLoading(null)
                      })
                  }}
                >
                  {isPDFLoading === row.creditId ? <CircularProgress size={16} /> : <PictureAsPdf fontSize="small" />}
                </span>
              )
            }
          },
          {
            field: 'actions',
            headerName: 'Actions',
            align: 'center',
            headerAlign: 'center',
            flex: 1.5,
            renderCell: ({ row }) => (
              <ActionsColumn
                isActionsDisabled={{
                  markAsClosed: props.isMarkAsClosedDisabled?.(row) || false,
                  markAsPaidEnough: props.isMarkAsPaidEnoughDisabled?.(row) || false
                }}
                onActionClick={handleActionsClick}
                loadingRowIdStates={isActionLoading}
                row={row}
              />
            )
          }
        ]}
        rows={rollingCredits}
      />
    </div>
  )
}

type ActionsColumnProps = Pick<ActionsMenuProps, 'loadingRowIdStates' | 'row' | 'onActionClick' | 'isActionsDisabled'>

const ActionsColumn = ({ row, loadingRowIdStates, onActionClick, isActionsDisabled }: ActionsColumnProps) => {
  const ref = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton data-testid="more-actions" onClick={() => setOpen(true)} ref={ref}>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu
        isActionsDisabled={isActionsDisabled}
        onActionClick={onActionClick}
        row={row}
        loadingRowIdStates={loadingRowIdStates}
        onClose={() => setOpen(false)}
        open={open}
        anchor={ref.current}
      />
    </>
  )
}

type Actions = 'recalculateTransactions' | 'markAsClosed' | 'markAsPaidEnough' | 'createLatePaymentInvoice' | 'createCreditInterestFee'

type ActionsMenuProps = {
  anchor: HTMLButtonElement | null
  onClose: () => void
  open?: boolean
  row: { creditId: string }
  loadingRowIdStates: Partial<Record<Actions, string | null>>
  onActionClick: (action: Actions, creditId: string) => unknown
  isActionsDisabled: Partial<Record<Actions, boolean | null>>
}

const ActionsMenu = ({ anchor, onClose, row, loadingRowIdStates, onActionClick, ...props }: ActionsMenuProps) => {
  const LoadingIcon = ({ action, children }: React.PropsWithChildren<{ action: Actions }>) => {
    if (row.creditId === loadingRowIdStates[action]) return <CircularProgress size="22px" />

    return <>{children}</>
  }

  return (
    <Menu open={Boolean(props.open)} anchorEl={anchor} onClose={onClose}>
      <Paper sx={{ width: 325, maxWidth: '100%' }}>
        <MenuList>
          <MenuItem data-testid="recalculate" onClick={() => onActionClick('recalculateTransactions', row.creditId)}>
            <ListItemIcon>
              <LoadingIcon action="recalculateTransactions">
                <RefreshIcon fontSize="small" />
              </LoadingIcon>
            </ListItemIcon>
            <ListItemText>Recalculate transactions</ListItemText>
          </MenuItem>
          <MenuItem
            data-testid="mark-as-closed"
            onClick={() => onActionClick('markAsClosed', row.creditId)}
            disabled={Boolean(props.isActionsDisabled.markAsClosed)}
          >
            <ListItemIcon>
              <LoadingIcon action="markAsClosed">
                <CheckCircle fontSize="small" />
              </LoadingIcon>
            </ListItemIcon>
            <ListItemText>Mark as closed</ListItemText>
          </MenuItem>
          <MenuItem data-testid="mask-as-paid-enough" onClick={() => onActionClick('markAsPaidEnough', row.creditId)} disabled={false}>
            <ListItemIcon>
              <LoadingIcon action="markAsPaidEnough">
                <CheckCircle fontSize="small" />
              </LoadingIcon>
            </ListItemIcon>
            <ListItemText>Mark as {props.isActionsDisabled.markAsPaidEnough ? 'not ' : ''}paid enough</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            data-testid="create-late-payment-fee"
            onClick={() => {
              onActionClick('createLatePaymentInvoice', row.creditId)
              onClose()
            }}
          >
            <ListItemIcon>
              <NoteAdd fontSize="small" />
            </ListItemIcon>
            <ListItemText>Create late payment invoice</ListItemText>
          </MenuItem>
          <MenuItem
            data-testid="create-credit-interest-fee"
            onClick={() => {
              onActionClick('createCreditInterestFee', row.creditId)
              onClose()
            }}
          >
            <ListItemIcon>
              <NoteAdd fontSize="small" />
            </ListItemIcon>
            <ListItemText>Create credit interest fee</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    </Menu>
  )
}

const Badge = styled.span<{ color: string }>`
  text-align: center;
  padding: 2px 6px;
  background-color: ${(props) => props.color};
  border-radius: 4px;
  color: white;
  font-weight: 500;
`
