/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerRole = {
    ADMIN: 'ADMIN',
    SIGNATORY: 'SIGNATORY',
    CONTACT: 'CONTACT',
    BENEFICIAL_OWNER: 'BENEFICIAL_OWNER',
    CARD_HOLDER: 'CARD_HOLDER',
    ACCOUNTANT: 'ACCOUNTANT',
    USER_ADMIN: 'USER_ADMIN'
} as const;

export type CustomerRole = typeof CustomerRole[keyof typeof CustomerRole];



