import * as React from 'react';

import ButtonTertiaryDefaultStyled from './ButtonTertiaryDefaultStyled';

type ButtonTertiaryDefaultProps = any

const ButtonTertiaryDefault: React.FC<ButtonTertiaryDefaultProps> = (props) => (
  <ButtonTertiaryDefaultStyled  {...props}>{props.children ? props.children : ""}</ButtonTertiaryDefaultStyled>
);

export default ButtonTertiaryDefault;