import styled from '@emotion/styled'
import React from 'react'
import BorderRadius from '../figma/tokens/BorderRadius'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import { AgreementStatus } from '../api/swagger/definitions/backoffice'
import FigmaBox from '../mynt-components/components/FigmaBox'
import TextContainer from './TextContainer'
import { TextKeys } from '../tiger/libs/TextRepository'
import { prettifyEnumLabel } from '../helpers/CreditOnCardHelpers'

export type StatusTagProps = {
  status?: AgreementStatus
}

const DirectDebitTabStatusTag = ({ status }: StatusTagProps) => {
  switch (status) {
    case AgreementStatus.FULLY_SIGNED: {
      return (
        <FullySignedTagContainer>
          <TextContainer textKey={TextKeys.creditAgreementAgreementIDLink} text="Fully signed" />
        </FullySignedTagContainer>
      )
    }

    case AgreementStatus.PARTIALLY_SIGNED: {
      return (
        <PartialSignedTagContainer>
          <TextContainer textKey={TextKeys.creditAgreementAgreementIDLink} text="Partially signed" />
        </PartialSignedTagContainer>
      )
    }

    case AgreementStatus.SENT: {
      return (
        <SentTagContainer>
          <TextContainer textKey={TextKeys.creditAgreementAgreementIDLink} text="Sent" />
        </SentTagContainer>
      )
    }

    case AgreementStatus.REJECTED: {
      return (
        <RejectedTagContainer>
          <TextContainer textKey={TextKeys.creditAgreementAgreementIDLink} text="Rejected" />
        </RejectedTagContainer>
      )
    }

    case AgreementStatus.DRAFT: {
      return (
        <DraftTagContainer>
          <TextContainer textKey={TextKeys.creditAgreementAgreementIDLink} text="Draft" />
        </DraftTagContainer>
      )
    }

    default: {
      return (
        <FigmaBox>
          {status && <TextContainer textKey={TextKeys.creditAgreementSentDateValue} text={prettifyEnumLabel(status as string)} />}
        </FigmaBox>
      )
    }
  }
}

const SentTagContainer = styled(FigmaBox)`
  background-color: ${ColorsDeprecated.decorativeBlue};
  padding: ${Spacings.minimum} ${Spacings.min};
  border-radius: ${BorderRadius.rounded};

  span {
    color: ${ColorsDeprecated.textOnBlue} !important;
  }
`

const RejectedTagContainer = styled(FigmaBox)`
  background-color: ${ColorsDeprecated.baseCritical};
  padding: ${Spacings.minimum} ${Spacings.min};
  border-radius: ${BorderRadius.rounded};

  span {
    color: ${ColorsDeprecated.white} !important;
  }
`

const DraftTagContainer = styled(FigmaBox)`
  background-color: ${ColorsDeprecated.baseStone};
  padding: ${Spacings.minimum} ${Spacings.min};
  border-radius: ${BorderRadius.rounded};

  span {
    color: ${ColorsDeprecated.baseGrey} !important;
  }
`

const PartialSignedTagContainer = styled(FigmaBox)`
  background-color: ${ColorsDeprecated.decorativeMint};
  padding: ${Spacings.minimum} ${Spacings.min};
  border-radius: ${BorderRadius.rounded};

  span {
    color: ${ColorsDeprecated.textOnGreen} !important;
  }
`

const FullySignedTagContainer = styled(FigmaBox)`
  background-color: ${ColorsDeprecated.baseSuccess};
  padding: ${Spacings.minimum} ${Spacings.min};
  border-radius: ${BorderRadius.rounded};

  span {
    color: ${ColorsDeprecated.baseWhite} !important;
  }
`

export default DirectDebitTabStatusTag
