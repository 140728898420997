import React from 'react'
import _Transition, { EnterHandler, ExitHandler } from 'react-transition-group/Transition'

// TODO fix this type properly, ts-expect-error breaks backoffice
const Transition = _Transition as unknown as any

type PopperTransitionProps = {
  in: boolean
  style?: React.CSSProperties
  transitionStates: { [key: string]: React.CSSProperties }
  transitionDuration?: number | string
  timeout: number | string
  children: React.ReactElement
  onEnter: EnterHandler<HTMLElement>
  onExited: ExitHandler<HTMLElement>
}

const MyntPopperCustomTransition: React.FC<React.PropsWithChildren<React.PropsWithChildren<PopperTransitionProps>>> = (props) => {
  const { in: inProp, style, transitionStates, transitionDuration, timeout, children, ...other } = props

  return (
    <Transition timeout={+timeout ?? 0} appear mountOnEnter unmountOnExit in={inProp} {...other}>
      {(state) =>
        React.cloneElement(children, {
          style: {
            transition: `all ${transitionDuration}ms ease-in-out`,
            opacity: 0,
            ...transitionStates[state],
            ...style,
            ...(React.isValidElement(children) ? children.props.style : {})
          }
        })
      }
    </Transition>
  )
}

export default MyntPopperCustomTransition
