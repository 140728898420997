import React from 'react'
import ReactJson from 'react-json-view'

export default function JSONDetailPanel({ src: obj, collapsed = false }: { src: any; collapsed?: boolean }) {
  const _obj = Object.keys(obj)
    .filter((key) => key !== 'tableData')
    .reduce((a, key) => {
      a[key] = obj[key]
      return a
    }, {})

  return <ReactJson src={_obj} collapsed={collapsed} />
}
