export const LOGIN_PATH = '/'
export const CUSTOMERS_PATH = '/customers'
export const CREDITS_PATH = '/credits'
export const INBOX_PATH = '/inbox'
export const SEARCH_PATH = '/search'
export const REPAYMENT_INVOICE_PATH = '/repayment-invoice'
export const DASHBOARD_PATH = '/dashboard'
export const ACCOUNTING_PATH = '/accounting'
export const ROLLING_CREDIT = '/rolling-credit'

export default {
  LOGIN_PATH,
  CUSTOMERS_PATH,
  CREDITS_PATH,
  REPAYMENT_INVOICE_PATH,
  INBOX_PATH,
  SEARCH_PATH,
  DASHBOARD_PATH,
  ACCOUNTING_PATH,
  ROLLING_CREDIT
}
