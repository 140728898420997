import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { signInWithGoogle } from '../api'
import LoginForm, { VPNStatus } from './LoginForm'
import { Session, isSignedIn, useSessionRole } from 'contexts/session'

export const handleSignInWithGoogle = () => {
  const url = new URL(window.location.origin + '/start')

  signInWithGoogle(url.toString())
}

export const mapLoginTypeToVpnStatus = ({ isError, isLoading, role, errorMessage }: Session): VPNStatus => {
  switch (true) {
    case isLoading:
      return 'connecting'

    case Boolean(errorMessage):
    case Boolean(role) && !isError:
      return 'connected'

    case isError:
      return 'disconnected'

    default:
      return 'connecting'
  }
}

const LoginFormContainer = () => {
  const navigate = useNavigate()
  const session = useSessionRole()

  useEffect(() => {
    if (isSignedIn(session.role)) navigate('/start')
  }, [session.role])

  return (
    <>
      <LoginForm
        loading={session.isLoading}
        vpnStatus={mapLoginTypeToVpnStatus(session)}
        handleGoogleLogin={handleSignInWithGoogle}
        errorMessage={session.errorMessage}
      />
    </>
  )
}

export default LoginFormContainer
