import React, { useState } from 'react'
import { Repayment } from '../interfaces/RepaymentInterfaces'
import ModalExperimental from 'mynt-components/components/ModalExperimental'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'

export type AttachInvoiceToRepayment = (
  repayment: Repayment,
  body: {
    invoiceNo: number
  }
) => Promise<void>

const AttachRepaymentModal: React.FC<{
  isModalOpen: boolean
  onCancel: () => void
  repayment: null | Repayment
  attachInvoiceToRepayment: AttachInvoiceToRepayment
}> = ({ isModalOpen, onCancel, repayment, attachInvoiceToRepayment }) => {
  const [attachedFortnoxInvoiceNo, setAttachedFortnoxInvoiceNo] = useState<null | string>(null)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleAttachBtnClick = async () => {
    if (!repayment || !attachedFortnoxInvoiceNo) return

    setIsSubmitting(true)

    await attachInvoiceToRepayment(repayment, {
      invoiceNo: parseInt(attachedFortnoxInvoiceNo, 10)
    })

    setIsSubmitting(false)
    onCancel()
  }

  if (!isModalOpen) return null

  return (
    <ModalExperimental backOfficeStyling onClose={onCancel}>
      <Box sx={{ padding: '32px' }}>
        <Box sx={{ marginTop: '-12px' }}>
          <h2>Attach fortnox invoice</h2>
        </Box>
        <Box sx={{ paddingTop: '8px', display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'center' }}>
          <TextField onChange={(e) => setAttachedFortnoxInvoiceNo(e.target.value)} label="Fortnox invoice no" />
          <LoadingButton loading={isSubmitting} onClick={handleAttachBtnClick} variant="primary">
            Attach
          </LoadingButton>
        </Box>
      </Box>
    </ModalExperimental>
  )
}

export default AttachRepaymentModal
