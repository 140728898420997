import { useContext } from 'react'
import Spacings from '../figma/tokens/Spacings'
import { useCardCredits } from '../api/react-query'
import { BorderContainer, BorderContainerHeader, CustomerIdContext } from '../helpers/CreditOnCardHelpers'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import MyntLoader from '../mynt-components/components/MyntLoader'
import { Line } from '../mynt-components/components/StyledComponents'
import CreditOnCardUpdateCredit from './CreditOnCardUpdateCredit'
import TextContainer from './TextContainer'

const CreditOnCardCreditSettings = () => {
  const customerId = useContext(CustomerIdContext)
  const { data: credits, isLoading } = useCardCredits(customerId)

  if (!credits && isLoading) {
    return <MyntLoader />
  }

  if (!credits) return null

  const hasMultipleCredits = credits.length > 1

  return (
    <FigmaBox spacing={Spacings.medium} bottom>
      <BorderContainer fullWidth>
        <BorderContainerHeader>
          <TextContainer textKey={TextKeys.creditOnCardCreditSettingsHeading} />
        </BorderContainerHeader>
        <FigmaBox fullWidth direction={'row'} gap={Spacings.medium}>
          {credits.map((credit, index) => (
            <>
              <FigmaBox key={credit.id} fullWidth direction={hasMultipleCredits ? 'column' : 'row'} gap={Spacings.medium}>
                <CreditOnCardUpdateCredit credit={credit} />
              </FigmaBox>
              {hasMultipleCredits && index !== credits.length - 1 && <Line vertical fullHeight />}
            </>
          ))}
        </FigmaBox>
      </BorderContainer>
    </FigmaBox>
  )
}

export default CreditOnCardCreditSettings
