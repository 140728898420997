import styled from '@emotion/styled'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import Colors from '../../figma/panda/Colors'
import Spacings from '../../figma/tokens/Spacings'
import MaterialFieldFormik, { MaterialFieldProps } from './MaterialFieldFormik'

type Props = {
  options: { value: string | number; label: string; dataCy?: string }[]
} & MaterialFieldProps

const MaterialSelectFieldFormik: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ options, ...props }) => (
  <MaterialFieldFormik {...props} select>
    {options.map((option) => (
      <Item key={option.value} value={option.value} data-cy={option.dataCy}>
        {option.label}
      </Item>
    ))}
  </MaterialFieldFormik>
)

const Item = styled(MenuItem)`
  && {
    cursor: pointer;
    color: ${Colors.base500};
    padding-left: ${Spacings.small};
    padding-right: ${Spacings.small};

    &:hover {
      color: ${Colors.baseBlack};
      background-color: ${Colors.base300};
    }
  }
`

export default MaterialSelectFieldFormik
