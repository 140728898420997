import styled from '@emotion/styled'
import { Formik } from 'formik'
import React, { useState } from 'react'
import ReactJson from 'react-json-view'
import * as Yup from 'yup'
import ButtonIconChangeStatus from '../figma/images/buttonIconChangeStatus'
import Spacings from '../figma/tokens/Spacings'
import { useCardBalanceConfig, useCreateCardBalanceConfig } from '../api/react-query'
import { BorderContainer, BorderContainerHeader } from '../helpers/CreditOnCardHelpers'
import { captureException } from '../helpers/sentryHelpers'
import useMaterialNotification from '../hooks/useMaterialNotification'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaButton from '../mynt-components/components/FigmaButton'
import MaterialTextFieldFormik from '../mynt-components/components/MaterialFieldFormik'
import MaterialSwitch from '../mynt-components/components/MaterialSwitch'
import { GenericObjectViewContainer, Line, UnstyledButton } from '../mynt-components/components/StyledComponents'
import { BO_CUSTOMER_CARD_FORM_MIN_WIDTH, BO_TWO_COLUMN_SIZE_LEFT, BO_TWO_COLUMN_SIZE_RIGHT } from '../mynt-components/WeakHardCodedSizes'
import { getText, TextKeys } from '../tiger/libs/TextRepository'
import TextContainer from './TextContainer'
import { TooltipChangeAccountStatus } from './Tooltips'

type Props = {
  customerId: string
  setIsChangeStatusModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const errorMessage = getText(TextKeys.mFautoTopUpFallsBelowError, 'figma')
const ValidationSchema = Yup.object().shape({
  threshold: Yup.number().required(errorMessage).typeError(errorMessage)
})

export default function CustomerCardTabLowBalanceAlert({ customerId, setIsChangeStatusModalOpen }: Props) {
  const notify = useMaterialNotification()

  const [isMutating, setIsMutating] = useState<boolean>(false)

  const { data: cardBalanceConfig, isLoading } = useCardBalanceConfig(customerId)

  const initialValues = {
    threshold: cardBalanceConfig?.notification?.threshold
  }

  const statusSetByCustomer = cardBalanceConfig?.notification?.active
  const mutation = useCreateCardBalanceConfig(customerId)

  const onToggleNotifications = async () => {
    try {
      setIsMutating(true)

      await mutation.mutateAsync({
        ...cardBalanceConfig,
        notification: {
          threshold: cardBalanceConfig?.topUp?.threshold,
          active: !cardBalanceConfig?.notification?.active
        }
      })
    } catch (error) {
      if (error instanceof Error) {
        captureException(error)
      } else {
        throw error
      }
    } finally {
      setIsMutating(false)
    }
  }

  const onSubmit = async ({ threshold }) => {
    try {
      await mutation.mutateAsync({
        notification: {
          ...cardBalanceConfig?.notification,
          threshold: parseInt(threshold)
        }
      })

      notify('Successfully set low balance limit', 'success')
    } catch (error) {
      if (error instanceof Error) {
        captureException(error)
      } else {
        throw error
      }
    }
  }

  if (isLoading) {
    return null
  }

  return (
    <BorderContainer>
      <BorderContainerHeader>
        <TextContainer textKey={TextKeys.lowBalanceAlertHeading} />

        <TooltipChangeAccountStatus
          content={
            <UnstyledButton onClick={() => setIsChangeStatusModalOpen && setIsChangeStatusModalOpen(true)}>
              <ButtonIconChangeStatus />
            </UnstyledButton>
          }
        />
      </BorderContainerHeader>

      <FigmaBox fullWidth direction="row" justify="space-between" align="stretch" gap={Spacings.medium}>
        <FigmaBox fullWidth direction="column">
          <FigmaBox top={Spacings.small} bottom={Spacings.medium}>
            <TextContainer textKey={TextKeys.customersCardLowBalanceAlertSetLowBalanceAlertLimitHeading} />
          </FigmaBox>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
            {(formProps) => (
              <FormContainer fullWidth direction="column">
                <MaterialTextFieldFormik
                  fullWidth
                  labelTextKey={TextKeys.mFlowBalanceAlertSetLabel}
                  name="threshold"
                  formProps={formProps}
                />
                <FigmaBox top fullWidth>
                  <FigmaButton onClick={formProps.handleSubmit} textKey={TextKeys.lowBalanceAlertSetButton} />
                </FigmaBox>
              </FormContainer>
            )}
          </Formik>
        </FigmaBox>

        <Line vertical />

        <GenericObjectViewContainer fullWidth>
          <TextContainer textKey={TextKeys.customersCardLowBalanceAlertSetLowBalanceAlertDetailsHeading} />
          <FigmaBox fullWidth direction="row" justify="space-between" align="flex-start" gap={Spacings.minimum} top={Spacings.medium}>
            <FigmaBox fullWidth flex={BO_TWO_COLUMN_SIZE_LEFT}>
              <TextContainer textKey={TextKeys.lowBalanceAlertText} />
            </FigmaBox>
            <FigmaBox fullWidth flex={BO_TWO_COLUMN_SIZE_RIGHT} align="flex-end">
              <MaterialSwitch checked={!!statusSetByCustomer} onChange={onToggleNotifications} disabled={isMutating} />
            </FigmaBox>
          </FigmaBox>
        </GenericObjectViewContainer>
      </FigmaBox>

      <FigmaBox top spacing={Spacings.huge}>
        <ReactJson src={cardBalanceConfig || {}} name="" />
      </FigmaBox>
    </BorderContainer>
  )
}

const FormContainer = styled(FigmaBox)`
  min-width: ${BO_CUSTOMER_CARD_FORM_MIN_WIDTH};
`
