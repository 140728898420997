import styled from '@emotion/styled'
import ButtonBase from '@mui/material/ButtonBase'
import React, { SVGProps } from 'react'
import Colors from '../../figma/panda/Colors'
import Spacings from '../../figma/tokens/Spacings'
import { useLanguage } from '../../hooks/useLanguage'
import { AntiloopTextType } from '../../tiger/interfaces/Antiloop'
import FigmaTextContainer from './FigmaTextContainer'
import SVGContainer from './SVGContainer'

type Props = {
  onClick?: () => void
  textKey: AntiloopTextType
  leftSvg?: React.FC<React.PropsWithChildren<React.PropsWithChildren<SVGProps<SVGSVGElement>>>>
  rightSvg?: React.FC<React.PropsWithChildren<React.PropsWithChildren<SVGProps<SVGSVGElement>>>>
  loading?: boolean
  disabled?: boolean
  active?: boolean
  text?: string
  className?: string
  spacing?: string
}

const FigmaTextButton: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  textKey,
  leftSvg,
  rightSvg,
  onClick,
  active,
  text,
  disabled,
  loading,
  className,
  spacing: _spacing,
  ...props
}) => {
  const language = useLanguage()

  if (loading) disabled = loading

  return (
    /* !!! DO NOT REMOVE {...props} !!! IT ADDS THE CYPRESS SELECTORS !!! */
    <Wrapper {...props} className={className} onClick={() => onClick?.()} disableRipple active={active} disabled={disabled}>
      {leftSvg && (
        <SVGWrapper left spacing={_spacing || Spacings.tiny}>
          <SVGContainer SVG={leftSvg} />
        </SVGWrapper>
      )}
      <FigmaTextContainer textKey={textKey} language={language} text={text} />
      {rightSvg && (
        <SVGWrapper spacing={_spacing || Spacings.tiny}>
          <SVGContainer SVG={rightSvg} />
        </SVGWrapper>
      )}
    </Wrapper>
  )
}

const SVGWrapper = styled.div<{ left?: boolean; spacing: string }>`
  display: flex;

  svg {
    display: flex;
    color: ${Colors.bluePrimary600Cta};
  }
  ${({ left, spacing }) => (left ? `padding-right: ${spacing};` : `padding-left: ${spacing};`)};
`

const Wrapper = styled(ButtonBase)<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  transition: transform ease 0.1s;

  &:active {
    transform: translateY(-1.5px);
  }

  && {
    ${(props) => props.active && `span { color: ${Colors.bluePrimary600Cta} !important; }`}
    ${(props) => props.disabled && `span { color: ${Colors.base500} !important; }`}
    &:hover {
      span,
      svg {
        color: ${Colors.bluePrimary500} !important;
      }
    }
    &:active {
      span,
      svg {
        color: ${Colors.bluePrimary600Cta} !important;
      }
    }
  }
`

export default FigmaTextButton
