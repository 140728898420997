import React from 'react'
import * as Images from '../../figma/helpers/Images'
import { AntiloopImageType } from '../../tiger/interfaces/Antiloop'
import useImageVariant from '../hooks/useImageVariant'

const FigmaImage: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      imageKey: AntiloopImageType
      imageStyle?: React.CSSProperties
      imageProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
    }>
  >
> = ({ imageKey, imageStyle, imageProps }) => {
  const variant = useImageVariant(imageKey)
  const component = Images[imageKey.name]

  if (!component || !variant) {
    return null
  }

  if (imageKey.format === 'svg') {
    return React.createElement(component, {
      style: imageStyle,
      alt: imageKey.name,
      width: variant.width,
      height: variant.height,
      ...imageProps
    })
  }

  return <img style={imageStyle} src={component} alt={imageKey.name} width={'100%'} {...imageProps} />
}

export default FigmaImage
