// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const fontSizes = {
 "body4": "0.75rem",
 "body3": "1rem",
 "button": "1.125rem",
 "h2M": "1.875rem",
 "h1M": "2.125rem",
 "link2": "1rem",
 "link1": "1.25rem",
 "h4": "1.375rem",
 "body1": "1.625rem",
 "h3": "2rem",
 "h2": "3.5rem",
 "h1": "4rem",
 "body3M": "0.875rem",
 "uitext": "0.625rem"
}

export default fontSizes;