import { UseMutationResult } from '@tanstack/react-query'
import { BackOfficePayoutDataResponseDtoV2, BackOfficePayoutPatchDtoV2, EnfucePayoutState } from '../api/swagger/definitions/backoffice'
import { Language } from '../tiger/interfaces/Antiloop'
import { TextKeys, getText } from '../tiger/libs/TextRepository'

type PayoutMutation = { payoutId: string; payout: BackOfficePayoutPatchDtoV2 }

type GetPayoutsProps = {
  mutation: (payout: PayoutMutation) => Promise<unknown>
  language: Language
  isLoading?: boolean
}

export const getPayoutsActions = ({ mutation, language, isLoading }: GetPayoutsProps) => [
  {
    icon: 'book',
    tooltip: getText(TextKeys.accountingInvoicesToolTipBook, language),
    disabled: isLoading,
    onClick: (event: any, payout: BackOfficePayoutDataResponseDtoV2) =>
      mutation({
        payoutId: payout.id,
        payout: { state: EnfucePayoutState.BOOKED }
      })
  },
  {
    icon: 'description',
    tooltip: getText(TextKeys.accountingPayoutsToolTipCredit, language),
    disabled: isLoading,
    onClick: (event: any, payout: BackOfficePayoutDataResponseDtoV2) =>
      mutation({
        payoutId: payout.id,
        payout: { state: EnfucePayoutState.SETTLED }
      })
  },
  {
    icon: 'clear',
    tooltip: getText(TextKeys.accountingPayoutsToolTipCancel, language),
    disabled: isLoading,
    onClick: (event: any, payout: BackOfficePayoutDataResponseDtoV2) =>
      window.confirm('Are you sure ?') &&
      mutation({
        payoutId: payout.id,
        payout: {
          state: EnfucePayoutState.CANCELLED
        }
      })
  }
]
