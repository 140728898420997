/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const BackOfficeOverrideField = {
    CARD_GROUP: 'CARD_GROUP',
    FEE: 'FEE',
    AUTO_RECEIPT_TRANSACTION_MATCHING: 'AUTO_RECEIPT_TRANSACTION_MATCHING',
    AUTO_RECEIPT_TRANSACTION_MATCHING_TRIAL: 'AUTO_RECEIPT_TRANSACTION_MATCHING_TRIAL',
    ERP_INTEGRATION_BUSINESS_CENTRAL: 'ERP_INTEGRATION_BUSINESS_CENTRAL',
    ERP_INTEGRATION_VISMA_NET: 'ERP_INTEGRATION_VISMA_NET',
    AUTO_RULES: 'AUTO_RULES',
    GASOLINE_INTERPRETATION: 'GASOLINE_INTERPRETATION',
    OUTLAY: 'OUTLAY',
    MILEAGE: 'MILEAGE',
    TRAVEL_ALLOWANCE: 'TRAVEL_ALLOWANCE'
} as const;

export type BackOfficeOverrideField = typeof BackOfficeOverrideField[keyof typeof BackOfficeOverrideField];



