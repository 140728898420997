import { RolePermissions } from 'contexts/permissions'
import { SessionRole } from 'contexts/session'

/**
 * The dot notation is used to define permission groups.
 * If a role has the permission customers.people, the role has access to all permissions that start with customers.people.
 * Eg. customers.people will give access to customers.people.view, customers.people.edit, customers.people.delete and customers.people.create
 */
export type Permissions =
  | '*'
  | 'customers'
  | 'customers.view'
  | 'customers.create'
  | 'customers.log'
  | 'customers.people'
  | 'customers.people.view'
  | 'customers.people.create'
  | 'customers.people.edit'
  | 'customers.people.delete'
  | 'customers.people.kyc'
  | 'customers.people.invite'
  | 'customers.subscription'
  | 'customers.activation-guide'
  | 'customers.refresh-address'
  | 'customers.card-partner'
  | 'customers.scoring'
  | 'customers.kyc'
  | 'customers.direct-debit'
  | 'customers.card'
  | 'customers.credits'
  | 'customers.sms'
  | 'customers.login'
  | 'customers.delete'
  | 'customers.bureau'
  | 'customers.notes.view'
  | 'customers.notes.create'
  | 'customers.notes.delete'
  | 'customers.notes.edit'
  | 'customers.cards.overview.view'
  | 'customers.cards.overview.table'
  | 'customers.cards.overview.enfuce'
  | 'customers.cards.overview.order'
  | 'customers.cards.overview.terminate'
  | 'customers.cards.overview.details'
  | 'customers.cards.transactions'
  | 'customers.cards.transactions.view'
  | 'customers.cards.transactions.notify'
  | 'customers.cards.payouts'
  | 'customers.cards.invoices'
  | 'customers.cards.pe'
  | 'customers.cards.autoTopUp'
  | 'customers.cards.lowBalanceAlert'
  | 'customers.cards.creditOnCard'
  | 'customers.cards.billing.view'
  | 'customers.cards.billing.edit'
  | 'customers.payment.whitelist'
  | 'inbox'
  | 'inbox.load'
  | 'inbox.view'
  | 'credits'
  | 'rolling-credit'
  | 'repayment-invoice'
  | 'search'
  | 'dashboard'
  | 'accounting'
  | 'payouts'
  | 'external'
  | 'activation-guide.status'
  | 'activation-guide.visibility'
  | 'customers.cards.credits.view'
  | 'subscription-plan'

export const permissions: RolePermissions = {
  [SessionRole.ADMIN]: ['*'],
  [SessionRole.SALES]: [
    'search',
    'customers.view',
    'customers.people.view',
    'customers.notes.view',
    'customers.cards.transactions.view',
    'customers.people.invite',
    'customers.people.kyc',
    'customers.cards.overview.view',
    'customers.cards.billing.view',
    'customers.notes.view',
    'customers.notes.create',
    'activation-guide.status',
    'customers.cards.credits.view',
    'subscription-plan'
  ],
  [SessionRole.UNAUTHORIZED]: []
}
