import AccountingPage from '../components/AccountingPage'
import SearchResultPage from '../components/SearchResultsPage'
import CreditsDetailsPage from '../components/CreditsDetailsPage'
import CreditsPage from '../components/CreditsPage'
import CustomerDetailsPage from '../components/CustomerDetailsPage'
import ExternalPage from '../components/ExternalPage'
import InboxPage from '../components/InboxPage'
import RepaymentInvoicePage from '../components/RepaymentInvoicePage'
import routes from './constants'
import RollingCreditAdapter from '../pages/RollingCredit'
import { Permissions } from 'appPermissions'

type ProtectedRoute = {
  path: string
  component: () => JSX.Element
  fullScreenWidth?: boolean
  permissions?: Permissions[]
}

export const protectedRoutes: ProtectedRoute[] = [
  {
    path: `${routes.CUSTOMERS_PATH}/:customerId`,
    component: () => <CustomerDetailsPage />,
    fullScreenWidth: true,
    permissions: ['customers.view']
  },
  {
    path: routes.CREDITS_PATH,
    component: () => <CreditsPage />,
    permissions: ['credits']
  },
  {
    path: `${routes.CREDITS_PATH}/:creditType/:creditId`,
    component: () => <CreditsDetailsPage />,
    fullScreenWidth: true,
    permissions: ['credits']
  },
  {
    path: routes.ROLLING_CREDIT,
    component: () => <RollingCreditAdapter paddingTop="40px" />,
    permissions: ['rolling-credit']
  },
  {
    path: `${routes.INBOX_PATH}`,
    component: () => <InboxPage />,
    permissions: ['inbox.view']
  },
  {
    path: `${routes.REPAYMENT_INVOICE_PATH}/:id`,
    component: () => <RepaymentInvoicePage />,
    permissions: ['repayment-invoice']
  },
  {
    path: '/search',
    component: () => <SearchResultPage />,
    permissions: ['search']
  },
  {
    path: `${routes.ACCOUNTING_PATH}`,
    component: () => <AccountingPage />,
    permissions: ['accounting']
  },
  {
    path: '/external',
    component: () => <ExternalPage />,
    permissions: ['external']
  }
]
