/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const InboxItemType = {
    COMPANY_CREDIT: 'COMPANY_CREDIT',
    CUSTOMER: 'CUSTOMER',
    COMPANY_CREDIT_REPAYMENT_INVOICE: 'COMPANY_CREDIT_REPAYMENT_INVOICE',
    CARD_CREDIT_REPAYMENT_INVOICE: 'CARD_CREDIT_REPAYMENT_INVOICE',
    AUTO_TOPUP: 'AUTO_TOPUP',
    AUTOGIRO_APPROVAL: 'AUTOGIRO_APPROVAL',
    ENFUCE_PAYOUT: 'ENFUCE_PAYOUT',
    CARD_CREDIT_REQUEST: 'CARD_CREDIT_REQUEST',
    CARD_CREDIT_REVIEW: 'CARD_CREDIT_REVIEW',
    REQUEST_FOR_PAYMENT: 'REQUEST_FOR_PAYMENT',
    PE_MONTHLY_REPORT: 'PE_MONTHLY_REPORT'
} as const;

export type InboxItemType = typeof InboxItemType[keyof typeof InboxItemType];



