import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { SelectChipController, TextFieldController } from './react-hook-components'
import { UseFormReturn, useForm } from 'react-hook-form'
import styled from '@emotion/styled'
import FeatherIcon from 'feather-icons-react'
import { colors } from 'themes'
import IconButton from '@mui/material/IconButton'
import { useCreatePerson, useCustomer, useDeletePersons, usePatchPerson, usePersons } from 'api/react-query'
import { DataGridPro } from '@mui/x-data-grid-pro'
import React, { ElementRef, useEffect, useMemo, useRef, useState } from 'react'
import { EnumToOptions, prettifyEnumLabel } from 'helpers/CreditOnCardHelpers'
import { BackOfficeCustomerDtoV2, CustomerRole, PersonDto } from 'api/swagger/definitions/backoffice'
import { PremiumSwitch } from './PremiumSwitch'
import useDebounce from 'helpers/useDebounce'
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'
import { isMacOs } from 'react-device-detect'
import useMaterialNotification from 'hooks/useMaterialNotification'
import MyntPopper from 'mynt-components/components/MyntPopper'
import FigmaBox, { FigmaBoxProps } from 'mynt-components/components/FigmaBox'
import Spacings from 'figma/tokens/Spacings'
import { Access, usePermissions } from 'contexts/permissions'
import JSONDetailPanel from './JSONDetailPanel'
import { When } from 'mynt-components/components/When'
import { isDev } from 'helpers/common'
import Tooltip from '@mui/material/Tooltip'
import SwedishSSN from 'helpers/validations/SwedishSSN'
import { invite } from 'helpers/ProcessHandler'
import { directLoginTokenUsingPOST, sendKYCEmail } from 'api'
import config from 'config'
import AddPersonModal from './AddPersonModal'
import UpdatePersonModal from './UpdatePersonModal'
import { ModalAreYouSureWithBody } from './Modals'
import CircularProgress from '@mui/material/CircularProgress'
import { SmsModal } from './modals/SmsModal'

type CustomerPeopleProps = {
  customerId: string
}

const getStandaloneLoginUrl = (loginToken: string) =>
  `${config.gazelleWebUrl}/login-experimental?type=DIRECT_LOGIN&directLoginToken=${loginToken}`

type ModalNames = 'createPerson' | 'updatePerson' | 'sendSms' | 'sendInvite' | 'sendKyc' | 'deletePerson'

export const CustomerPeople = ({ customerId }: CustomerPeopleProps) => {
  const [filter, setFilter] = useState<{ state: 'deleted' | 'active'; role: CustomerRole[] }>({ state: 'active', role: [] })
  const [tableSearch, setTableSearch] = useState('')
  const [activeModalData, setActiveModalData] = useState<Partial<Record<ModalNames, PersonDto>>>({})

  const notify = useMaterialNotification()
  const setTableSearchDebouced = useDebounce(setTableSearch, 300)

  const { data: customer } = useCustomer(customerId)
  const { data, isLoading: isPersonsLoading } = usePersons(customerId)

  const { mutateAsync: createPersonAsync, isLoading: isCreatingPerson } = useCreatePerson(customerId)
  const { mutateAsync: patchPersonAsync, isLoading: isPatchPersonLoading } = usePatchPerson(customerId)
  const { mutateAsync: deletePersonsAsync, isLoading: isDeletePersonLoading } = useDeletePersons(customerId)

  const permissions = {
    create: usePermissions('customers.people.create')
  }

  const persons = useMemo(() => filterPersons(data, filter), [data, filter])

  const form = useForm({
    defaultValues: {
      search: '',
      persons: persons
    }
  })

  useEffect(() => {
    form.reset({ persons })
  }, [persons])

  const autoCreatePerson = () =>
    createPersonAsync({
      email: `sysdev+test${Math.random().toString().slice(2, 6)}@mynt.se`,
      fullName: `Testare${Math.random().toString().slice(2, 6)} Testsson`,
      phoneNumber: '+46101980300',
      roles: [CustomerRole.ADMIN],
      ssn: SwedishSSN.generateSSNWithParameters(new Date('09/01/1980'), 'MALE')
    }).then((newPerson) => {
      notify(`Auto created new person named ${newPerson.fullName}`)
    })

  const handleRemove = async (id: string) => {
    await deletePersonsAsync({
      personId: id,
      id
    })
  }

  const handleRoleFilter = (role: CustomerRole) => () => {
    setFilter((prev) => {
      if (prev.role.includes(role)) {
        return { ...prev, role: prev.role.filter((r) => r !== role) }
      }

      return { ...prev, role: [...prev.role, role] }
    })
  }

  const handleEdit = (person: PersonDto) => {
    setActiveModalData({ updatePerson: person })
  }

  const handleOnEditPersonSuccess = () => {
    setActiveModalData({})
    // queryClient.invalidateQueries([GET_PERSONS])
  }

  const handleEmail = (person: PersonDto) => {
    window.open(`mailto:${encodeURIComponent(person.fullName)}%3C${person.email}%3E?bcc=support@mynt.se`)
  }

  const handleSMS = (person: PersonDto) => {
    setActiveModalData({ sendSms: person })
  }

  const handleSendInvite = (personId: string) => {
    invite(personId, customerId).then(() => {
      notify('Invite Email sent 👍')
    })
  }

  const handleSendKyc = (personId: string) => {
    sendKYCEmail(customerId, personId).then(() => {
      notify('KYC sent 👍')
    })
  }

  const handleLogin = (person: PersonDto) => {
    directLoginTokenUsingPOST(customerId, person.id as string).then((token) =>
      window?.open(getStandaloneLoginUrl(token), '_blank')?.focus()
    )
  }

  const personChangeRoles = (personId: string, roles: CustomerRole[]) => patchPersonAsync({ personId, roles })

  return (
    <>
      <When is={Boolean(activeModalData.deletePerson)}>
        <ModalAreYouSureWithBody
          bodyText={`Are you sure you want to delete ${activeModalData.deletePerson?.fullName}?`}
          loading={isDeletePersonLoading}
          onClose={() => setActiveModalData({})}
          title="Delete person"
          confirmText="Yes, delete person"
          onContinue={() => {
            handleRemove(activeModalData.deletePerson?.id as string).then(() => {
              setActiveModalData({})
            })
          }}
        />
      </When>
      <When is={Boolean(activeModalData.sendKyc)}>
        <ModalAreYouSureWithBody
          bodyText={`Do you want to send a KYC to ${activeModalData.sendKyc?.email}?`}
          onClose={() => setActiveModalData({})}
          title="Send KYC"
          confirmText="Yes, send KYC"
          onContinue={() => {
            handleSendKyc(activeModalData.sendKyc?.id as string)
            setActiveModalData({})
          }}
        />
      </When>
      <When is={Boolean(activeModalData.sendInvite)}>
        <ModalAreYouSureWithBody
          bodyText={`Do you want to send an invite email to ${activeModalData.sendInvite?.email}?`}
          onClose={() => setActiveModalData({})}
          title="Send invite email"
          confirmText="Yes, send invite"
          onContinue={() => {
            handleSendInvite(activeModalData.sendInvite?.id as string)
            setActiveModalData({})
          }}
        />
      </When>
      <AddPersonModal
        isOpen={Boolean(activeModalData.createPerson)}
        onClose={() => setActiveModalData({})}
        roleOptions={EnumToOptions(CustomerRole, prettifyEnumLabel)}
        onSubmit={(values) => {
          const promise = createPersonAsync({
            ...values,
            roles: values.roles as CustomerRole[]
          })

          promise.then(() => {
            notify(`Added ${values.fullName}`)
            setActiveModalData({})
          })

          return promise
        }}
      />
      <UpdatePersonModal
        customerId={customerId}
        isOpen={Boolean(activeModalData.updatePerson)}
        person={activeModalData.updatePerson}
        onSuccess={handleOnEditPersonSuccess}
        onCancel={() => setActiveModalData({})}
      />
      <Access permissions="customers.sms">
        <SmsModal
          open={Boolean(activeModalData.sendSms)}
          person={activeModalData.sendSms as PersonDto}
          customer={customer as BackOfficeCustomerDtoV2}
          onClose={() => setActiveModalData({})}
        />
      </Access>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '32px',
          alignItems: 'baseline',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant="h2">People</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '12px', gap: '4px', paddingRight: '16px' }}>
              Role filter:
              <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap', maxWidth: '400px' }}>
                {Object.values(CustomerRole).map((role) => (
                  <QuickFilter key={role} data-active={filter.role.includes(role)} onClick={handleRoleFilter(role)}>
                    {prettifyEnumLabel(role)}
                  </QuickFilter>
                ))}
              </Box>
            </Box>
            <Box sx={{ paddingRight: '8px' }}>
              <PremiumSwitch value={filter.state} onChange={(state) => setFilter((prev) => ({ ...prev, state }))}>
                <PremiumSwitch.Left value="deleted">Deleted</PremiumSwitch.Left>
                <PremiumSwitch.Right value="active">Active</PremiumSwitch.Right>
              </PremiumSwitch>
            </Box>
            <Box>
              <TextFieldController
                InputProps={{
                  onChange: (e) => {
                    form.setValue('search', e.target.value)
                    form.handleSubmit(({ search }) => setTableSearchDebouced(search))(e)
                  },
                  placeholder: 'Search person...',
                  startAdornment: <FeatherIcon icon="search" />
                }}
                control={form.control}
                labelText="Search person..."
                noLabel
                name="search"
              />
            </Box>
            <When is={isDev()}>
              <Tooltip placement="top" title="Auto create person">
                <StyledIconButton disabled={!permissions.create} onClick={() => autoCreatePerson()}>
                  {isCreatingPerson ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <FeatherIcon icon="zap" />}
                </StyledIconButton>
              </Tooltip>
            </When>
            <Tooltip placement="top" title="Create person">
              <StyledIconButton
                disabled={(!permissions.create || persons.length > 0) && !isDev()}
                onClick={() => setActiveModalData({ createPerson: {} as PersonDto })}
              >
                <FeatherIcon icon="user-plus" />
              </StyledIconButton>
            </Tooltip>
          </Box>
        </Box>
        <PersonsTable
          onChangePersonRole={personChangeRoles}
          persons={persons}
          isPersonsLoading={isPersonsLoading || isPatchPersonLoading}
          form={form}
          search={tableSearch}
          filterName={filter.state}
          actionButtonProps={{
            onDelete: (person) => setActiveModalData({ deletePerson: person }),
            onEdit: (person) => handleEdit(person),
            onEmail: (person) => handleEmail(person),
            onSMS: (person) => handleSMS(person),
            onInvite: (person) => setActiveModalData({ sendInvite: person }),
            onLogin: (person) => handleLogin(person),
            onKYC: (person) => setActiveModalData({ sendKyc: person })
          }}
        />
      </Box>
    </>
  )
}

type PersonsTableProps = {
  persons: PersonDto[]
  isPersonsLoading: boolean
  form: UseFormReturn<{
    search: string
    persons: PersonDto[]
  }>
  search: string
  onChangePersonRole: (personId: string, roles: CustomerRole[]) => void
  filterName: 'deleted' | 'active'
  actionButtonProps: Omit<ActionButtonProps, '_person'>
}

const _PersonsTable = ({
  persons = [],
  isPersonsLoading,
  form,
  search,
  onChangePersonRole,
  filterName,
  actionButtonProps
}: PersonsTableProps) => {
  const [showFixedTableHeight, setshowFixedTableHeight] = useState(false)

  useEffect(() => {
    const noData = !persons || persons.length === 0

    setshowFixedTableHeight(noData || isPersonsLoading)
  }, [persons, isPersonsLoading])

  const permissions = {
    edit: usePermissions('customers.people.edit')
  }

  const notify = useMaterialNotification()

  const hiddenColumns: Partial<Record<keyof PersonDto, boolean>> = {
    email: true
  }

  return (
    <Box sx={{ height: showFixedTableHeight ? 200 : 'auto', width: '100%' }}>
      <span>Hint: if a cell is being cut off, you can still select it and press {isMacOs ? 'cmd' : 'ctrl'} + c to copy the full text</span>
      <DataGridPro
        sx={{ height: '100%' }}
        disableRowSelectionOnClick
        disableAutosize
        loading={isPersonsLoading}
        getRowHeight={() => 90}
        rows={persons}
        pagination
        onStateChange={(event) => {
          setshowFixedTableHeight(event.rowsMeta.positions.length === 0)
        }}
        columnVisibilityModel={hiddenColumns}
        pageSizeOptions={[5, 10, 25]}
        initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
        disableColumnMenu
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={({ row }) => (
          <Box sx={{ padding: '16px' }}>
            <JSONDetailPanel src={row} />
          </Box>
        )}
        slots={{
          detailPanelExpandIcon: () => <FeatherIcon icon="chevron-right" />,
          detailPanelCollapseIcon: () => <FeatherIcon icon="chevron-down" />,
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              The customer has no {filterName} people
            </Stack>
          ),
          noResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Found no people with the search term &apos;{search}&apos;
            </Stack>
          ),
          loadingOverlay: LinearProgress
        }}
        filterModel={{
          items: [],
          quickFilterValues: [search]
        }}
        columns={[
          {
            field: 'fullName',
            headerName: 'Name',
            flex: 1.5
          },
          {
            field: 'ssn',
            headerName: 'SSN',
            flex: 1.25
          },
          {
            field: 'email',
            headerName: 'Email',
            flex: 1.5
          },
          {
            field: 'phoneNumber',
            headerName: 'Phone number',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            renderCell: ({ value }) => (
              <a style={{ color: colors.product.blue[600] }} href={`tel:${value}`}>
                {value}
              </a>
            )
          },
          {
            field: 'roles',
            headerName: 'Roles',
            width: 400,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              const index = persons?.findIndex((person) => person.id === params.row.id) as number
              const name = `persons.${index}.roles` as const

              return (
                <StyledChipWrapper sx={{ padding: '8px 0', width: '100%' }}>
                  <SelectChipController
                    chipProps={{ sx: { fontSize: '10px', fontWeight: 'normal' } }}
                    noLabel
                    disabled={!permissions.edit}
                    options={EnumToOptions(CustomerRole)}
                    control={form.control}
                    name={name}
                    onChange={(roles) => {
                      const customerRoles = roles as CustomerRole[]

                      if (!params.row.id) return notify('Person has no id', 'error')

                      onChangePersonRole(params.row.id, customerRoles)
                      form.setValue(name, customerRoles)
                    }}
                  />
                </StyledChipWrapper>
              )
            }
          },
          {
            field: '',
            headerName: 'Actions',
            headerAlign: 'center',
            width: 75,
            sortable: false,
            disableColumnMenu: true,
            align: 'center',
            renderCell: ({ row }) => <ActionsButton {...actionButtonProps} _person={row as Required<PersonDto>} />
          }
        ]}
      />
    </Box>
  )
}

// Make the chips smaller so the row height
const StyledChipWrapper = styled(Box)`
  .MuiChip-root {
    border-radius: 8px;
    display: flex;
    align-items: center;
    height: 18px;
  }

  .MuiChip-deleteIcon,
  .MuiSvgIcon-root {
    margin: 0 !important;
    padding-right: 4px !important;
    font-size: 18px !important;
  }

  .MuiChip-label {
    font-size: 10px;
    font-weight: normal;
    color: ${colors.product.grey[600]};
    padding: 0;
    padding-left: 6px;
    padding-right: 4px;
    line-height: 1;
  }
`

const PersonsTable = React.memo(_PersonsTable)

type ActionButtonProps = {
  _person: Required<PersonDto>
  onEdit?: (person: Required<PersonDto>) => void
  onDelete?: (person: Required<PersonDto>) => void
  onEmail?: (person: Required<PersonDto>) => void
  onSMS?: (person: Required<PersonDto>) => void
  onInvite?: (person: Required<PersonDto>) => void
  onLogin?: (person: Required<PersonDto>) => void
  onKYC?: (person: Required<PersonDto>) => void
}

const ActionsButton = (props: ActionButtonProps) => {
  const permissions = {
    sms: usePermissions('customers.sms'),
    login: usePermissions('customers.login'),
    edit: usePermissions('customers.people.edit'),
    delete: usePermissions('customers.people.delete'),
    kyc: usePermissions('customers.people.kyc'),
    invite: usePermissions('customers.people.invite')
  }

  const anchor = useRef<ElementRef<'button'>>(null)
  const [showActions, setShowActions] = useState(false)

  const handleClick = (callbackName: keyof typeof props) => {
    const callback = props[callbackName]

    if (typeof callback === 'function') callback(props._person)

    setShowActions(false)
  }

  return (
    <>
      <MyntPopper
        visible={showActions}
        noPadding
        marginTop={Spacings.small}
        position="bottom"
        anchorRef={anchor.current}
        content={
          <FigmaBox style={{ color: colors.product.grey[500] }} justify="flex-start">
            {permissions.edit && (
              <IconWrapper onClick={() => handleClick('onEdit')}>
                <FeatherIcon icon="edit-2" />
                <FigmaBox spacing={Spacings.min} left>
                  <Typography color="inherit" variant="body2" fontWeight="medium">
                    Edit person
                  </Typography>
                </FigmaBox>
              </IconWrapper>
            )}
            {permissions.delete && (
              <IconWrapper onClick={() => handleClick('onDelete')}>
                <FeatherIcon icon="trash-2" />
                <FigmaBox spacing={Spacings.min} left>
                  <Typography color="inherit" variant="body2" fontWeight="medium">
                    Remove user
                  </Typography>
                </FigmaBox>
              </IconWrapper>
            )}
            {permissions.sms && (
              <IconWrapper onClick={() => handleClick('onSMS')}>
                <FeatherIcon icon="message-circle" />
                <FigmaBox spacing={Spacings.min} left>
                  <Typography color="inherit" variant="body2" fontWeight="medium">
                    Send SMS
                  </Typography>
                </FigmaBox>
              </IconWrapper>
            )}
            <IconWrapper onClick={() => handleClick('onEmail')}>
              <FeatherIcon icon="mail" />
              <FigmaBox spacing={Spacings.min} left>
                <Typography color="inherit" variant="body2" fontWeight="medium">
                  Send email
                </Typography>
              </FigmaBox>
            </IconWrapper>
            {permissions.invite && (
              <IconWrapper onClick={() => handleClick('onInvite')}>
                <FeatherIcon icon="user-plus" />
                <FigmaBox spacing={Spacings.min} left>
                  <Typography color="inherit" variant="body2" fontWeight="medium">
                    Send invite email
                  </Typography>
                </FigmaBox>
              </IconWrapper>
            )}
            {permissions.kyc && (
              <IconWrapper onClick={() => handleClick('onKYC')}>
                <FeatherIcon icon="file-text" />
                <FigmaBox spacing={Spacings.min} left>
                  <Typography color="inherit" variant="body2" fontWeight="medium">
                    Send KYC
                  </Typography>
                </FigmaBox>
              </IconWrapper>
            )}
            {permissions.login && (
              <IconWrapper onClick={() => handleClick('onLogin')}>
                <FeatherIcon icon="log-in" />
                <FigmaBox spacing={Spacings.min} left>
                  <Typography color="inherit" variant="body2" fontWeight="medium">
                    Log in as user
                  </Typography>
                </FigmaBox>
              </IconWrapper>
            )}
          </FigmaBox>
        }
        handleClose={() => setShowActions(false)}
      />
      <IconButton onClick={() => setShowActions(true)} ref={anchor}>
        <FeatherIcon icon="more-horizontal" />
      </IconButton>
    </>
  )
}

const LinkHoverFigmaBox = styled(FigmaBox)`
  &:hover {
    background-color: ${colors.product.blue[100]};
  }
`

const IconWrapper: React.FC<FigmaBoxProps> = ({ children, ...props }) => (
  <LinkHoverFigmaBox
    fullWidth
    style={{ cursor: 'pointer' }}
    justify="flex-start"
    align="center"
    direction="row"
    spacing={Spacings.min}
    bottom
    top
    left={Spacings.small}
    right={Spacings.small}
    {...props}
  >
    {children}
  </LinkHoverFigmaBox>
)

const filterPersons = (persons: PersonDto[] = [], filter: { state: 'deleted' | 'active'; role: CustomerRole[] }) => {
  let filteredPersons = persons.filter((person) => (filter.state === 'deleted' ? person.deleted : !person.deleted))

  if (filter.role.length > 0) {
    filteredPersons = filteredPersons.filter((person) => filter.role.every((role) => person.roles.includes(role)))
  }

  return filteredPersons
}

const StyledIconButton = styled(IconButton)`
  color: white;
  border-radius: 50%;
  background-color: ${colors.product.blue[700]};
  padding: 12px;

  &:disabled {
    background-color: ${colors.product.grey[300]};
  }

  &:hover {
    background-color: ${colors.product.blue[600]};
  }
`
const QuickFilter = styled.span`
  white-space: nowrap;
  font-size: 14px;
  transition: all 0.3s;
  padding: 4px 10px;
  border-radius: 16px;
  background-color: ${colors.product.grey[300]};
  color: ${colors.product.grey[800]};
  cursor: pointer;

  &:hover {
    background-color: ${colors.product.blue[100]};
  }

  &[data-active='true'] {
    color: white;
    background-color: ${colors.product.blue[700]};
  }
`
