import { createContext, useContext, useState } from 'react'

const NotesContext = createContext<
  [
    {
      open: boolean
    },
    React.Dispatch<
      React.SetStateAction<{
        open: boolean
      }>
    >
  ]
>([
  {
    open: true
  },
  () => null
])

export const useNotesContext = () => useContext(NotesContext)

export const NotesContextProvider = ({ children }) => {
  const [state, setState] = useState({
    open: window.innerWidth > 1100
  })

  return <NotesContext.Provider value={[state, setState]}>{children}</NotesContext.Provider>
}
