import styled from '@emotion/styled'
import React, { CSSProperties, ReactNode } from 'react'
import TextContainer from '../../components/TextContainer'
import Colors from '../../figma/panda/Colors'
import { AntiloopTextType } from '../../tiger/interfaces/Antiloop'
import FigmaBox from './FigmaBox'
import MyntLoader from './MyntLoader'

const StyledButton = styled.button<{ fullWidth?: boolean; square?: boolean }>`
  border: 1px solid transparent;
  background-color: ${Colors.baseWhite};
  padding: 10px 24px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  position: relative;
  white-space: nowrap;
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}

  &:focus-visible {
    outline: 2px solid #458fff;
    outline-offset: 2px;
  }

  &[data-size='medium'] {
    padding: 15px 24px;
  }

  &[data-size='large'] {
    padding: 20px 32px;
  }

  &[data-variant='primary'] {
    background-color: ${Colors.bluePrimary600Cta} !important;
    span {
      color: ${Colors.baseWhite} !important;
    }

    @media (hover: hover) {
      &:hover {
        background-color: ${Colors.bluePrimary700} !important;
      }
    }

    &:active {
      background-color: ${Colors.bluePrimary500} !important;
    }

    &:disabled {
      background-color: ${Colors.base400} !important;
      span {
        color: ${Colors.base500} !important;
      }
    }
  }

  &[data-variant='secondary'] {
    border: 1px solid ${Colors.brandBiegeBlack};
    span {
      color: ${Colors.baseBlack} !important;
    }
    @media (hover: hover) {
      &:hover {
        border-color: ${Colors.brandBiegeBlack};
        background-color: ${Colors.brandBiegeLightBlack};
        span {
          color: ${Colors.baseBlack} !important;
        }
      }
    }

    &:active {
      background-color: ${Colors.brandBiegeGray100};
      border-color: ${Colors.base500};
      span {
        color: ${Colors.baseBlack} !important;
      }
    }

    &:disabled {
      background-color: ${Colors.brandBiegeGray100};
      border-color: ${Colors.base400};
      span {
        color: ${Colors.base400} !important;
      }
    }
  }

  &[data-variant='filter'] {
    border: 1px solid ${Colors.base300};
    span {
      color: ${Colors.base500} !important;
    }
    @media (hover: hover) {
      &:hover {
        border-color: ${Colors.base400};
        span {
          color: ${Colors.brandBiegeGray400} !important;
        }
      }
    }

    &:active {
      background-color: ${Colors.brandBiegeGray100};
      border-color: ${Colors.base500};
      span {
        color: ${Colors.baseBlack} !important;
      }
    }

    &:disabled {
      background-color: ${Colors.brandBiegeGray100};
      border-color: ${Colors.base400};
      span {
        color: ${Colors.base400} !important;
      }
    }
  }

  &[data-square='true'] {
    padding: 8px;
  }

  &[data-loading='true'] {
    span:not([role='progressbar']),
    div[role='icon'] {
      visibility: hidden;
    }
  }

  &:disabled {
    cursor: default;

    span:not([role='progressbar']),
    div[role='icon'] {
      opacity: 0.5;
    }
  }
`

export type ButtonProps = {
  text?: string
  textKey?: AntiloopTextType
  size?: 'small' | 'medium' | 'large'
  variant?: 'primary' | 'secondary' | 'filter'
  disabled?: boolean
  startIcon?: JSX.Element
  endIcon?: JSX.Element
  loading?: boolean
  style?: CSSProperties
  fullWidth?: boolean
  tabIndex?: number
  square?: boolean
  className?: string
  children?: ReactNode
}

// We pick the most common props to not bloat the intellisense, just add more if they are needed
export type ReactButtonProps = Pick<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'onClick' | 'type'
>

const CenteredSpinner = styled(MyntLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Button = (props: ButtonProps & ReactButtonProps) => {
  const {
    text,
    style,
    square,
    endIcon,
    loading,
    textKey,
    disabled,
    startIcon,
    fullWidth,
    size = 'small',
    variant = 'primary',
    children,
    ...rest
  } = props

  // We want to be fullWidth by default, but only if we are not square
  const isDefaultFullWidth = fullWidth === undefined
  const isFullWidth = isDefaultFullWidth ? !square : fullWidth

  const hasIcon = startIcon || endIcon

  return (
    <StyledButton
      style={style}
      fullWidth={isFullWidth}
      disabled={disabled || loading}
      data-size={size}
      data-variant={variant}
      data-loading={loading}
      data-square={square}
      {...rest}
    >
      {loading && <CenteredSpinner variant="small" />}
      <FigmaBox fullWidth justify="center" align="center" direction="row" gap={hasIcon ? '10px' : '0px'}>
        {startIcon && <FigmaBox role="icon">{startIcon}</FigmaBox>}
        {textKey && <TextContainer textKey={textKey} text={text} />}
        {endIcon && <FigmaBox role="icon">{endIcon}</FigmaBox>}
        {children}
      </FigmaBox>
    </StyledButton>
  )
}

export default Button
