import * as React from 'react';

import ButtonSecondaryDisabledStyled from './ButtonSecondaryDisabledStyled';

type ButtonSecondaryDisabledProps = any

const ButtonSecondaryDisabled: React.FC<ButtonSecondaryDisabledProps> = (props) => (
  <ButtonSecondaryDisabledStyled  {...props}>{props.children ? props.children : "Kom igång"}</ButtonSecondaryDisabledStyled>
);

export default ButtonSecondaryDisabled;