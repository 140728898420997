import { useCallback, useState } from 'react'

const useDebounce = <T extends unknown[]>(fn: (...args: T) => void, timeout: number): ((...args: T) => void) => {
  const [, setTimeoutId] = useState<NodeJS.Timeout>()

  const restart = useCallback(
    (...args: T) => {
      const id = setTimeout(() => fn(...args), timeout)

      setTimeoutId((current) => {
        current && clearTimeout(current)
        return id
      })
    },
    [timeout, fn]
  )

  return restart
}

export default useDebounce
