import * as React from 'react';

import ButtonSecondaryDefaultStyled from './ButtonSecondaryDefaultStyled';

type ButtonSecondaryDefaultProps = any

const ButtonSecondaryDefault: React.FC<ButtonSecondaryDefaultProps> = (props) => (
  <ButtonSecondaryDefaultStyled  {...props}>{props.children ? props.children : "Kom igång"}</ButtonSecondaryDefaultStyled>
);

export default ButtonSecondaryDefault;