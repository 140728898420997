import * as React from 'react';

import ButtonTertiaryDisabledStyled from './ButtonTertiaryDisabledStyled';

type ButtonTertiaryDisabledProps = any

const ButtonTertiaryDisabled: React.FC<ButtonTertiaryDisabledProps> = (props) => (
  <ButtonTertiaryDisabledStyled  {...props}>{props.children ? props.children : ""}</ButtonTertiaryDisabledStyled>
);

export default ButtonTertiaryDisabled;