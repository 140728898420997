import Box from '@mui/material/Box'
import { useForm } from 'react-hook-form'
import { SelectController, TextFieldController } from './react-hook-components'
import { useEffect, useState } from 'react'
import { useAssignBureau, useBureauClients, useBureaus, useConfigureBureau, useResetBureau, useSearch } from 'api/react-query'
import useMaterialNotification from 'hooks/useMaterialNotification'
import Typography from '@mui/material/Typography'
import FeatherIcon from 'feather-icons-react'
import IconButton from '@mui/material/IconButton'
import useDebounce from 'helpers/useDebounce'
import CircularProgress from '@mui/material/CircularProgress'
import { When } from 'mynt-components/components/When'
import { BureauType } from 'api/swagger/definitions/backoffice'
import { EnumToOptions, prettifyEnumLabel } from 'helpers/CreditOnCardHelpers'
import Colors from 'figma/panda/Colors'
import styled from '@emotion/styled'

const ExtendedBureauType = {
  ...BureauType,
  NONE: 'none' as const
}

const FilteredBureauType = {
  ACCOUNTING_AGENCY: ExtendedBureauType.ACCOUNTING_AGENCY,
  NONE: 'none' as const
}

type ExtendedBureauTypeEnum = (typeof ExtendedBureauType)[keyof typeof ExtendedBureauType]

type FormValues = {
  bureauType: ExtendedBureauTypeEnum
  query: string
}

export const CustomerBureauPanel = ({ customerId }: { customerId: string }) => {
  const { mutateAsync: configureBureau } = useConfigureBureau()
  const { mutateAsync: resetBureau } = useResetBureau()
  const { mutateAsync: assingClientToBureau } = useAssignBureau()

  const { data: bureaus, isLoading } = useBureaus()
  const { data: bureauClients } = useBureauClients(customerId)

  const [query, setQuery] = useState<string>('')
  const [loadingClientId, setLoadingClientId] = useState<string | null>(null)

  const notify = useMaterialNotification()

  const form = useForm<FormValues>({
    defaultValues: {
      bureauType: ExtendedBureauType.NONE,
      query: ''
    }
  })

  const formQuery = form.watch('query')

  useEffect(() => {
    setQueryDebounced(formQuery)
  }, [formQuery])

  const setQueryDebounced = useDebounce(setQuery, 200)

  const search = useSearch(query, 200)

  useEffect(() => {
    const bureau = bureaus?.find((bureau) => bureau.bureauId === customerId)
    const bureauType = bureau?.type ?? ExtendedBureauType.NONE

    form.reset({ bureauType })
  }, [isLoading, bureaus])

  const handleIsBureauChange = ({ bureauType }: FormValues) => {
    if (!bureauType) return

    if (bureauType === ExtendedBureauType.NONE) {
      return resetBureau({ bureauId: customerId }).then(() => {
        notify('Customer is no longer a bureau')
      })
    }

    configureBureau({ bureauId: customerId, type: bureauType })
      .then(() => {
        notify('Customer is now a bureau')
      })
      .catch(() => {
        form.setValue('bureauType', ExtendedBureauType.NONE)
      })
  }

  const handleAddBureauClient = ({ bureauId, clientId }: { bureauId: string; clientId: string }) => {
    setLoadingClientId(clientId)

    assingClientToBureau({ bureauId, bureauClientId: clientId })
      .then(() => {
        notify('Client added to bureau')
      })
      .finally(() => {
        setLoadingClientId(null)
      })
  }

  return (
    <Box>
      <Box sx={{ width: '100%', paddingBottom: '24px' }}>
        <Typography variant="h2">Bureau</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '32px' }}>
        <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', minWidth: '300px', width: 'fit-content' }}>
          <Box>
            <SelectController
              control={form.control}
              name="bureauType"
              labelText="Bureau type"
              onChange={form.handleSubmit(handleIsBureauChange)}
              options={[...EnumToOptions(FilteredBureauType, prettifyEnumLabel)]}
            />
          </Box>
          <When is={bureauClients && bureauClients.length > 0}>
            <Box>
              Clients:
              <Box sx={{ display: 'flex', gap: '4px', flexDirection: 'column' }}>
                {bureauClients
                  ?.sort((a, b) => {
                    const nameA = a.bureauClientName as string
                    const nameB = b.bureauClientName as string

                    return nameA?.localeCompare(nameB)
                  })
                  .map((bureau) => (
                    <Typography key={bureau.bureauClientId}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
                        <a
                          style={{ color: Colors.brandBlueCTA }}
                          target="_blank"
                          href={`/customers/${bureau.bureauClientId}`}
                          rel="noreferrer"
                        >
                          {bureau.bureauClientName}
                        </a>
                        {/* <IconButton onClick={() => alert('Remove client not implemented')} size="small">
                        <ColoredFeatherIcon color={Colors.notificationErrors500} size={16} strokeWidth={4} icon="minus" />
                      </IconButton> */}
                      </Box>
                    </Typography>
                  ))}
              </Box>
            </Box>
          </When>
        </Box>
        <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column', minWidth: '500px' }}>
          <Box>
            <TextFieldController
              disabled={form.watch('bureauType') === ExtendedBureauType.NONE}
              control={form.control}
              name="query"
              labelText="Search customers to add as bureau client"
            />
          </Box>
          <Box sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {search.data?.items
              .filter((item) => item.type === 'CUSTOMER' && !item.body.includes('Partner external id'))
              .map((item) => {
                const isAlreadyClient = bureauClients?.some((client) => client.bureauClientId === item.customerId)
                const isSelf = customerId === item.customerId

                return (
                  <Box
                    key={item.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '8px',
                      height: '36px'
                    }}
                  >
                    <Typography>{item.title}</Typography>
                    <When is={isAlreadyClient}>
                      <ColoredFeatherIcon color={Colors.success700} icon="check" />
                    </When>
                    <When is={isSelf}>🫵</When>
                    <When is={!isAlreadyClient}>
                      <When is={customerId !== item.customerId}>
                        <IconButton onClick={() => handleAddBureauClient({ bureauId: customerId, clientId: item.customerId })} size="small">
                          {loadingClientId === item.customerId ? <CircularProgress size="24px" /> : <FeatherIcon size={24} icon="plus" />}
                        </IconButton>
                      </When>
                    </When>
                  </Box>
                )
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const ColoredFeatherIcon = styled(FeatherIcon)<{ color: string }>`
  stroke: ${(props) => props.color};
`
