import { IInboxDeprecated } from 'interfaces/InboxInterface'
import React, { useState } from 'react'
import { snoozeInboxItem, unSnoozeInboxItem } from '../api'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import LoadingButton from '@mui/lab/LoadingButton'
import { CoreMyntModal } from './modals/CoreMyntModal'
import { DatePickerControllerV2 } from './react-hook-components'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

interface IProps {
  item: IInboxDeprecated
  updateInboxItem: (item: IInboxDeprecated) => void
}

const InboxSnooze: React.FC<IProps> = ({ item, updateInboxItem }) => {
  const [snoozeSubmitting, setSnoozeSubmitting] = useState(false)
  const [unsnoozeSubmitting, setUnsnoozeSubmitting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const form = useForm({
    defaultValues: {
      date: ''
    }
  })

  const snoozeInboxItemRequest = async (until: string) => {
    try {
      setSnoozeSubmitting(true)
      const data: Record<string, any> = await snoozeInboxItem(item.id, until)
      setSnoozeSubmitting(false)
      updateInboxItem({ ...item, snoozeUntil: data.until })
    } catch (err) {
      console.warn(err)
      setSnoozeSubmitting(false)
    }
  }

  const handleSnooze = (time: 1 | 3 | 5 | 7) => {
    const date = new Date()
    date.setDate(date.getDate() + time)
    const until = date.toISOString().split('T')[0]
    snoozeInboxItemRequest(until)
  }

  const handleUnsnooze = async () => {
    try {
      setUnsnoozeSubmitting(true)
      await unSnoozeInboxItem(item.id)
      setUnsnoozeSubmitting(false)
      updateInboxItem({ ...item, snoozeUntil: undefined })
    } catch (err) {
      console.warn(err)
      setUnsnoozeSubmitting(false)
    }
  }

  const handleSubmit = async ({ date }: { date: string }) => {
    if (isNaN(Date.parse(date))) return

    await snoozeInboxItemRequest(date)
    form.reset({ date: '' })
    setIsModalOpen(false)
  }

  return (
    <>
      <Menu
        open={showMenu}
        onClose={() => setShowMenu(false)}
        MenuListProps={{ onMouseLeave: () => setShowMenu(false) }}
        onMouseLeave={() => setShowMenu(false)}
        anchorEl={buttonRef.current}
      >
        <MenuItem onClick={() => handleSnooze(1)}>1 day</MenuItem>
        <MenuItem onClick={() => handleSnooze(3)}>3 days</MenuItem>
        <MenuItem onClick={() => handleSnooze(5)}>5 days</MenuItem>
        <MenuItem onClick={() => handleSnooze(7)}>7 days</MenuItem>
        <MenuItem onClick={() => setIsModalOpen(true)}>Custom</MenuItem>
      </Menu>

      {!item.snoozeUntil ? (
        <Button onMouseOver={() => setShowMenu(true)} ref={buttonRef} size="small">
          Snooze
        </Button>
      ) : (
        <>
          <LoadingButton variant="contained" size="small" style={{ marginLeft: 5 }} onClick={handleUnsnooze} loading={unsnoozeSubmitting}>
            Unsnooze
          </LoadingButton>
          <p style={{ marginBottom: 0, marginTop: 5, fontFamily: 'Inter' }}>
            Snooze until: <b>{item.snoozeUntil}</b>
          </p>
        </>
      )}

      <CoreMyntModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Snooze date"
        actions={(Wrapper) => (
          <Wrapper>
            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
            <LoadingButton variant="primary" loading={snoozeSubmitting} onClick={form.handleSubmit(handleSubmit)}>
              Set snooze
            </LoadingButton>
          </Wrapper>
        )}
      >
        <Box sx={{ paddingTop: '16px' }}>
          <DatePickerControllerV2 control={form.control} name="date" labelText="Date" />
        </Box>
      </CoreMyntModal>
    </>
  )
}

export default InboxSnooze
