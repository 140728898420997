import { Button } from '../components/Button'
import InboxSnooze from '../components/InboxSnooze'
import { RepaymentInvoiceRemindBtn } from '../components/RepaymentInvoiceRemind'
import React from 'react'
import { Link } from 'react-router-dom'
import { EnfucePayoutState, InboxItemDto, InboxItemType } from '../api/swagger/definitions/backoffice'
import { useUpdateEnfucePayoutV2 } from '../api/react-query'
import useMaterialNotification from '../hooks/useMaterialNotification'
import { SearchResultContent, SearchResultHeader, SearchResultRow } from './SearchResults'
import { isDev } from 'helpers/common'
import Box from '@mui/material/Box'
import FeatherIcon from 'feather-icons-react'

interface InboxRowProps {
  item: InboxItemDto
  debtBtnSubmittingId: string
  onDebtCollectionClick: (item: InboxItemDto) => Promise<void>
  removeInboxItem: (id: string) => void
  updateInboxItem: (item: InboxItemDto) => void
}

const InboxRow: React.FC<InboxRowProps> = ({ item, debtBtnSubmittingId, onDebtCollectionClick, removeInboxItem, updateInboxItem }) => {
  const updateEnfucePayouMutation = useUpdateEnfucePayoutV2()
  const notify = useMaterialNotification()

  const onClickBookEnfucePayout = async () =>
    updateEnfucePayouMutation
      .mutateAsync({
        payoutId: item.typeId,
        payout: { state: EnfucePayoutState.BOOKED }
      })
      .then(() => {
        notify('Success 👍')
        removeInboxItem(item.id)
      })
  return (
    <SearchResultRow
      style={
        item.snoozeUntil
          ? {
              border: '1px solid #076b5f',
              padding: '10px 0',
              borderRadius: 5
            }
          : {}
      }
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
        <Box sx={{ flex: 0.5 }}>
          <FeatherIcon icon="star" fill={item.starred ? 'rgb(255, 172, 39)' : undefined} strokeWidth={item.starred ? 0 : undefined} />
        </Box>
        <Box sx={{ flex: 1.5 }}>
          <Link to={item.localPath}>
            <SearchResultHeader title={item.title} subTitle={item.subTitle} />
          </Link>
        </Box>
        <Box sx={{ flex: 1 }}>
          <SearchResultContent>{item.action}</SearchResultContent>
        </Box>
        <Box sx={{ flex: 1 }}>
          <SearchResultContent>{item.text}</SearchResultContent>
        </Box>
        <Box style={{ textAlign: 'right', flex: 1.75 }}>
          <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
            {item.type === InboxItemType.COMPANY_CREDIT_REPAYMENT_INVOICE && item.state === 'OVER_DUE' && (
              <>
                <RepaymentInvoiceRemindBtn id={item.typeId} onSuccess={() => removeInboxItem(item.id)}>
                  Remind
                </RepaymentInvoiceRemindBtn>

                <Button
                  size="small"
                  loading={debtBtnSubmittingId === item.id}
                  onClick={() => {
                    if (window.confirm('Are you sure?')) {
                      onDebtCollectionClick(item)
                    }
                  }}
                >
                  Debt collection
                </Button>
              </>
            )}
            {item.type === InboxItemType.ENFUCE_PAYOUT && (
              <Button size="small" loading={updateEnfucePayouMutation.isLoading} onClick={onClickBookEnfucePayout}>
                Book
              </Button>
            )}
            <InboxSnooze item={item} updateInboxItem={updateInboxItem} />
          </Box>
        </Box>
      </Box>
    </SearchResultRow>
  )
}

export default InboxRow
