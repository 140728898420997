
import accountingIconImg from '../images/accountingIcon'
import activateCardIconImg from '../images/activateCardIcon'
import bookImg from '../images/book'
import bookIconImg from '../images/bookIcon'
import cancelImg from '../images/cancel'
import cancelIconImg from '../images/cancelIcon'
import creditImg from '../images/credit'
import creditIconImg from '../images/creditIcon'
import debtCollectionImg from '../images/debtCollection'
import debtCollectionIconImg from '../images/debtCollectionIcon'
import deleteCustomerIconImg from '../images/deleteCustomerIcon'
import editIconDeletePersonImg from '../images/editIconDeletePerson'
import errorCircleImg from '../images/errorCircle'
import figma24Img from '../images/figma24'
import fileGeneralImg from '../images/fileGeneral'
import fileGeneralHoverImg from '../images/fileGeneralHover'
import fileJpgImg from '../images/fileJpg'
import fileJpgHoverImg from '../images/fileJpgHover'
import filePdfImg from '../images/filePdf'
import filePdfHoverImg from '../images/filePdfHover'
import filePngImg from '../images/filePng'
import filePngHoverImg from '../images/filePngHover'
import finland24Img from '../images/finland24'
import iconCalendar24Img from '../images/iconCalendar24'
import iconExport24LinkGreenImg from '../images/iconExport24LinkGreen'
import iconFileAddition24Img from '../images/iconFileAddition24'
import iconInfoErrorImg from '../images/iconInfoError.png'
import image2Img from '../images/image2.png'
import image3Img from '../images/image3.png'
import inviteMailIconDeletePersonImg from '../images/inviteMailIconDeletePerson'
import logInAsUserIconDeletePersonImg from '../images/logInAsUserIconDeletePerson'
import mailIconDeletePersonImg from '../images/mailIconDeletePerson'
import paginationPageNextIconImg from '../images/paginationPageNextIcon'
import pdfInvoiceImg from '../images/pdfInvoice'
import refreshIcon24Img from '../images/refreshIcon24'
import remindImg from '../images/remind'
import remindIconImg from '../images/remindIcon'
import removeIconDeletePersonImg from '../images/removeIconDeletePerson'
import smallScreenInfoImageImg from '../images/smallScreenInfoImage'
import smsIconDeletePersonImg from '../images/smsIconDeletePerson'
import successCircleImg from '../images/successCircle'
import warningCircleImg from '../images/warningCircle'
export const iconFileAddition24 = iconFileAddition24Img 
export const image2 = image2Img 
export const image3 = image3Img 
export const pdfInvoice = pdfInvoiceImg 
export const book = bookImg 
export const cancel = cancelImg 
export const credit = creditImg 
export const remind = remindImg 
export const debtCollection = debtCollectionImg 
export const accountingIcon = accountingIconImg 
export const bookIcon = bookIconImg 
export const cancelIcon = cancelIconImg 
export const creditIcon = creditIconImg 
export const remindIcon = remindIconImg 
export const debtCollectionIcon = debtCollectionIconImg 
export const activateCardIcon = activateCardIconImg 
export const editIconDeletePerson = editIconDeletePersonImg 
export const mailIconDeletePerson = mailIconDeletePersonImg 
export const smsIconDeletePerson = smsIconDeletePersonImg 
export const removeIconDeletePerson = removeIconDeletePersonImg 
export const inviteMailIconDeletePerson = inviteMailIconDeletePersonImg 
export const logInAsUserIconDeletePerson = logInAsUserIconDeletePersonImg 
export const iconInfoError = iconInfoErrorImg 
export const deleteCustomerIcon = deleteCustomerIconImg 
export const iconCalendar24 = iconCalendar24Img 
export const refreshIcon24 = refreshIcon24Img 
export const iconExport24LinkGreen = iconExport24LinkGreenImg 
export const paginationPageNextIcon = paginationPageNextIconImg 
export const figma24 = figma24Img 
export const finland24 = finland24Img 
export const warningCircle = warningCircleImg 
export const successCircle = successCircleImg 
export const errorCircle = errorCircleImg 
export const filePdf = filePdfImg 
export const filePdfHover = filePdfHoverImg 
export const fileJpg = fileJpgImg 
export const fileJpgHover = fileJpgHoverImg 
export const filePng = filePngImg 
export const filePngHover = filePngHoverImg 
export const fileGeneral = fileGeneralImg 
export const fileGeneralHover = fileGeneralHoverImg 
export const smallScreenInfoImage = smallScreenInfoImageImg 