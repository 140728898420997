import { OptionsObject, useSnackbar, VariantType, WithSnackbarProps } from 'notistack'
import React from 'react'
import styled from '@emotion/styled'
import Cancel from '@mui/icons-material/Cancel'
import Close from '@mui/icons-material/Close'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { SnackbarContent, SnackbarKey, SnackbarMessage } from 'notistack'
import { Ref, forwardRef, useCallback, useState } from 'react'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { captureAxiosError } from '../helpers/sentryHelpers'
import { colors } from 'themes'
import InfoIcon from '@mui/icons-material/Info'
import Box from '@mui/material/Box'
import { LOGIN_PATH } from 'routes/constants'

type ErrorWithDetails = { response?: { data?: { details?: string } } }

type NotificationProps = {
  message: SnackbarMessage
  snackbarKey?: SnackbarKey
  error?: ErrorWithDetails
  variant?: string
}

const getVariantColor = (variant: string | undefined) => {
  switch (variant) {
    case 'error':
      return {
        text: 'white',
        background: colors.product.error[600]
      }
    case 'success':
      return {
        text: 'white',
        background: colors.product.success[600]
      }
    case 'warning':
      return {
        text: 'white',
        background: colors.product.alert[600]
      }
    default:
      return {
        text: 'black',
        background: 'white'
      }
  }
}

export const Notification = forwardRef(function Notification(
  { error, snackbarKey, message, variant }: NotificationProps,
  ref: Ref<HTMLDivElement>
) {
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded)
  }, [])

  const dismissMaterialNotification = () => {
    useSnackbarRef.closeSnackbar(snackbarKey)
  }

  const details = error?.response?.data?.details
  const variantColors = getVariantColor(variant)

  const Icon = variant === 'error' ? Cancel : InfoIcon

  return (
    <SnackbarContent ref={ref}>
      <FullWidthCardWrapper color={variantColors.background}>
        <CardActionsWrapper>
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon fontSize="small" style={{ marginRight: 8, color: variantColors.text }} />
              <Typography color={variantColors.text} fontSize={14} fontFamily="Inter" fontWeight={500}>
                {message}
              </Typography>
            </Box>
            <FigmaBox style={{ marginLeft: 'auto', marginRight: -8, alignItems: 'center' }} direction="row">
              {details && (
                <IconButtonRotate size="small" rotate={expanded} onClick={handleExpandClick}>
                  <ExpandMore />
                </IconButtonRotate>
              )}
              <IconButton size="small" onClick={dismissMaterialNotification}>
                <Close fontSize="small" />
              </IconButton>
            </FigmaBox>
          </Box>
        </CardActionsWrapper>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <FullscreenPaper>{details}</FullscreenPaper>
        </Collapse>
      </FullWidthCardWrapper>
    </SnackbarContent>
  )
})

const IconButtonRotate = styled(IconButton)<{ rotate?: boolean }>`
  transition: transform 200ms;
  padding: 2px;
  transform: rotate(${({ rotate }) => (rotate ? '180' : '0')}deg);
`

const FullscreenPaper = styled(Paper)`
  padding: ${Spacings.small};
  overflow-y: scroll;
  max-height: 80vh;
`

const CardActionsWrapper = styled(CardActions)`
  padding: 6px 16px 6px 20px;
`

const FullWidthCardWrapper = styled(Card)<{ color?: string }>`
  width: 100%;
  background-color: ${({ color }) => color || ColorsDeprecated.baseWhite};
  color: white;
  font-family: Inter;
`

const isSignedOutError = (code: number, messages: string[]) => {
  if (code !== 401) return false

  const matchingStrings = ['not authorized', 'support@mynt.se']

  return messages.some((message) => matchingStrings.some((matchingString) => message.toLowerCase().includes(matchingString)))
}

export const MaterialNotificationAxios = async (error: any) => {
  const errorCode = error?.response?.status
  const message = error?.response?.data?.message
  const details = error?.response?.data?.details

  if (error?.response?.config?.disableHandler || error.config?.disableCustomErrorHandler) {
    return Promise.reject(error)
  }

  // if (isSignedOutError(errorCode, [message, details])) {
  //   window.location.href = LOGIN_PATH
  // }

  if (errorCode === 400 || errorCode >= 404 || errorCode >= 500) captureAxiosError(error)

  materialNotifyOutsideComponent(message || 'Something Went Wrong', 'error', {
    content: (key, message) => <Notification variant="error" message={message} snackbarKey={key} error={error} />,
    autoHideDuration: 60 * 1000
  })

  return Promise.reject(error)
}

export let useSnackbarRef: WithSnackbarProps
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar()
  return null
}

export const materialNotifyOutsideComponent = (message: string, variant: VariantType = 'success', options?: OptionsObject) => {
  useSnackbarRef.enqueueSnackbar(message, {
    variant: variant,
    anchorOrigin: { horizontal: 'right', vertical: 'top' },
    autoHideDuration: 5000,
    ...options
  })
}

const useMaterialNotification = () => {
  const { enqueueSnackbar } = useSnackbar()

  return (message: string, variant: VariantType = 'success', options?: OptionsObject) =>
    enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: { horizontal: 'right', vertical: 'top' },
      content: (key, message) => <Notification variant={variant} message={message} snackbarKey={key} />,
      autoHideDuration: 5000,
      ...options
    })
}

export default useMaterialNotification
