import styled from '@emotion/styled'
import BorderRadius from 'figma/tokens/BorderRadius'
import Spacings from 'figma/tokens/Spacings'
import React from 'react'
import { LinkButton } from '../helpers/customerGeneralFormHelper'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaButton from '../mynt-components/components/FigmaButton'
import ModalExperimental, { ModalProps } from '../mynt-components/components/ModalExperimental'
import { MODAL_SIZE_WIDTH_PX } from '../mynt-components/StrongHardCodedSizes'
import TextContainer from './TextContainer'
import ModalExperimentalHeader from '../mynt-components/components/ModalExperimentalHeader'
import ModalExperimentalFooter from '../mynt-components/components/ModalExperimentalFooter'

export type ModalDirectDebitWithoutAgreementProps = ModalProps & {
  visible: boolean
  loading: boolean
  onSubmit: () => void
  onClose: () => void
}

export const _ModalDirectDebitWithoutAgreement: React.FC<ModalDirectDebitWithoutAgreementProps> = (props) => {
  const { onSubmit, loading, onClose, visible } = props

  const handleActivate = () => onSubmit()

  if (!visible) return null

  return (
    <ModalExperimental
      hideCloseButton
      backOfficeStyling
      onClose={onClose}
      heading={() => (
        <ModalExperimentalHeader>
          <TextContainer textKey={TextKeys.areYourSureHeading} />
        </ModalExperimentalHeader>
      )}
    >
      <Container fullWidth>
        <FigmaBox fullWidth fullPadding left={Spacings.medium} right={Spacings.medium}>
          <TextContainer textKey={TextKeys.areYourSureSendMandateAlertText} />
        </FigmaBox>

        <ModalExperimentalFooter>
          <FigmaBox direction="row" fullWidth align="center" justify="space-between">
            <LinkButton onClick={onClose} fullWidth={false} textKey={TextKeys.areYourSureSendMandateAlertButtonCancel} />
            <FigmaButton
              fullWidth={false}
              loading={loading}
              onClick={handleActivate}
              textKey={TextKeys.areYourSureSendMandateAlertButtonYes}
            />
          </FigmaBox>
        </ModalExperimentalFooter>
      </Container>
    </ModalExperimental>
  )
}

const Container = styled(FigmaBox)`
  width: ${MODAL_SIZE_WIDTH_PX};
  border-radius: ${BorderRadius.soft};
`
