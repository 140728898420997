/* eslint-disable prettier/prettier */
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useRef, useState } from 'react'
import ReactJson from 'react-json-view'
import {
  AutogiroApprovalProvider,
  AutogiroApprovalType,
  DirectDebitMandateResponseDtoV4,
  BackOfficeAutogiroMandateResponseDtoV4,
  BackOfficeSepaDirectDebitMandateResponseDtoV4,
  BackOfficeOverforselsServiceMandateResponseDtoV4
} from '../api/swagger/definitions/backoffice'
import resendIcon24 from '../figma/svgs/IconActivity24'
import iconTrash24 from '../figma/svgs/IconTrash24'
import refreshIcon24 from '../figma/svgs/RefreshIcon24'
import BorderRadius from '../figma/tokens/BorderRadius'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import { useCancelDirectDebitMandateV4, useResendDirectDebitMandateV4, useSyncDirectDebitMandateV4 } from '../api/react-query'
import { BorderContainer } from '../helpers/CreditOnCardHelpers'
import useMaterialNotification from '../hooks/useMaterialNotification'
import TextKeys from '../libs/TextKeys'
import Button from '../mynt-components/components/Button'
import FigmaBox from '../mynt-components/components/FigmaBox'
import SVGContainer from '../mynt-components/components/SVGContainer'
import { When } from '../mynt-components/components/When'
import { YYYY_MM_DD_hh_mm_ss } from '../tiger/Constants'
import DirectDebitTabStatusBox from './DirectDebitTabStatusBox'
import { ModalAreYouSure, ModalMandateLog } from './Modals'
import TextContainer from './TextContainer'
import { AUTOGIRO } from 'api/react-query/keys'

export const DirectDebitTabAutoGiroApproval = ({
  directDebitMandate,
  customerId
}: {
  directDebitMandate: 
  BackOfficeAutogiroMandateResponseDtoV4 | BackOfficeSepaDirectDebitMandateResponseDtoV4 | BackOfficeOverforselsServiceMandateResponseDtoV4
  customerId: string
}) => {
  const syncMutation = useSyncDirectDebitMandateV4(customerId)
  const cancelMutation = useCancelDirectDebitMandateV4(customerId)
  const resendMutation = useResendDirectDebitMandateV4(customerId)
  const notify = useMaterialNotification()

  const statusAnchorRef = useRef<any>()
  const refreshAnchorRef = useRef<any>()
  const cancelAnchorRef = useRef<any>()
  const resendAnchorRef = useRef<any>()
  const [showModal, setShowModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showResendModal, setShowResendModal] = useState(false)

  const bankAccountValue = (() => {
    switch(directDebitMandate.type) {
      case 'AUTOGIRO':
        return directDebitMandate.bankAccount
      case 'SEPA_DIRECT_DEBIT':
        return directDebitMandate.iban
      case 'OVERFORSELSSERVICE':
        return directDebitMandate.bankAccount
    }
  })()

  const directDebitMandateId = directDebitMandate.id!

  const handleMandateCancel = () => {
    cancelMutation.mutateAsync(directDebitMandateId).then(() => {
      notify(`Cancelled mandate with account ${bankAccountValue}`)
    })
    setShowCancelModal(false)
  }

  const handleMandateResend = () => {
    resendMutation.mutateAsync(directDebitMandateId).then(() => {
      notify(`Resend mandate with account ${bankAccountValue}`)
    })
    setShowResendModal(false)
  }

  return (
    <FigmaBox fullWidth onMouseLeave={() => setShowModal(false)}>
      <BorderContainer fullWidth smallSpacing>
        <FigmaBox fullWidth direction="row" justify={'space-between'} bottom={Spacings.medium}>
          <TextContainer textKey={TextKeys.DirectDebitDetailsHeading} />

          <FigmaBox direction={'row'} gap={Spacings.minimum}>
            <FigmaBox fullWidth ref={cancelAnchorRef}>
              <StyledButton
                size="small"
                variant="secondary"
                loading={cancelMutation.isLoading}
                onClick={() => setShowCancelModal(true)}
                startIcon={<SVGContainer SVG={iconTrash24} svgStyles={{ width: Spacings.small, height: Spacings.small }} />}
              />
            </FigmaBox>
            <FigmaBox fullWidth ref={refreshAnchorRef}>
              <StyledButton
                size="small"
                variant="secondary"
                loading={syncMutation.isLoading}
                onClick={async () => syncMutation.mutateAsync(directDebitMandateId)}
                startIcon={<SVGContainer SVG={refreshIcon24} svgStyles={{ width: Spacings.small, height: Spacings.small }} />}
              />
            </FigmaBox>
            <FigmaBox fullWidth ref={resendAnchorRef}>
              <StyledButton
                size="small"
                variant="secondary"
                loading={resendMutation.isLoading}
                onClick={() => setShowResendModal(true)}
                startIcon={<SVGContainer SVG={resendIcon24} svgStyles={{ width: Spacings.small, height: Spacings.small }} />}
              />
            </FigmaBox>
          </FigmaBox>
        </FigmaBox>

        <FigmaBox direction="column" gap={Spacings.small}>
          <FigmaBox direction="column" gap={Spacings.minimum}>
            <TextContainer textKey={TextKeys.DirectDebitDetailsState} />
            <FigmaBox onMouseEnter={() => setShowModal(true)} ref={statusAnchorRef}>
              <DirectDebitTabStatusBox status={directDebitMandate.state!} />
            </FigmaBox>
          </FigmaBox>

          <FigmaBox direction="column" gap={Spacings.minimum}>
            <TextContainer textKey={TextKeys.DirectDebitDetailsModifiedAt} />
            <TextContainer
              textKey={TextKeys.DirectDebitDetailsModifiedAtValue}
              text={dayjs(+directDebitMandate.modifiedAt! * 1000).format(YYYY_MM_DD_hh_mm_ss)}
            />
          </FigmaBox>

          <FigmaBox direction="column" gap={Spacings.minimum}>
            <TextContainer textKey={TextKeys.DirectDebitDetailsBankAccount} text="Provider" />
            <TextContainer textKey={TextKeys.DirectDebitDetailsBankAccountValue} text={directDebitMandate?.provider} />
          </FigmaBox>

          <FigmaBox direction="column" gap={Spacings.minimum}>
            <TextContainer textKey={TextKeys.DirectDebitDetailsBankAccount} text="Type" />
            <TextContainer textKey={TextKeys.DirectDebitDetailsBankAccountValue} text={directDebitMandate?.type} />
          </FigmaBox>

          <FigmaBox direction="column" gap={Spacings.minimum}>
            <TextContainer textKey={TextKeys.DirectDebitDetailsBankAccount} text="Bank account" />
            <TextContainer textKey={TextKeys.DirectDebitDetailsBankAccountValue} text={bankAccountValue} />
          </FigmaBox>
        </FigmaBox>

        <FigmaBox top={Spacings.small}>
          <ReactJson src={directDebitMandate || {}} collapsed />
        </FigmaBox>
      </BorderContainer>

      <ModalMandateLog
        showAltarLink={directDebitMandate.provider === AutogiroApprovalProvider.ATLAR}
        visible={showModal}
        onClose={() => setShowModal(false)}
        anchor={statusAnchorRef.current}
        log={directDebitMandate.log!}
      />

      <When is={showCancelModal}>
        <ModalAreYouSure
          headingText="Are you sure you want to CANCEL the mandate?"
          anchor={cancelAnchorRef.current}
          onClose={() => setShowCancelModal(false)}
          onContinue={handleMandateCancel}
        />
      </When>
      <When is={showResendModal}>
        <ModalAreYouSure
          headingText="Are you sure you want to RESEND the mandate?"
          anchor={resendAnchorRef.current}
          onClose={() => setShowResendModal(false)}
          onContinue={handleMandateResend}
        />
      </When>
    </FigmaBox>
  )
}

const StyledButton = styled(Button)`
  width: ${Spacings.medium};
  height: ${Spacings.medium};
  padding: 0;
  border: 1px solid ${ColorsDeprecated.stone};
  border-radius: ${BorderRadius.rounded};
`
