import React, { useState } from 'react'
import ReactJson from 'react-json-view'
import { useAllKycRoaring, usePatchPerson } from '../api/react-query'
import { KYCPerson } from '../interfaces/PepKYCInterfaces'
import CustomerDetailsKYCBenificialOwnersTable from './CustomerDetailsKYCBenificialOwnersTable'
import CustomerDetailsKYCBoardMembersTable from './CustomerDetailsKYCPeopleTable'

interface CustomerDetailsKYCProps {
  customerId: string
}

const CustomerDetailsKYC: React.FC<CustomerDetailsKYCProps> = ({ customerId }) => {
  const [submitting, setSubmitting] = useState(false)
  const { data: fullKYCData } = useAllKycRoaring(customerId)

  const personMutation = usePatchPerson(customerId)

  const handleOnBeneficialCheckboxClick = async ({ myntRoles, personId }: KYCPerson) => {
    if (!personId) return
    let roles: string[] = []

    if (myntRoles.includes('BENEFICIAL_OWNER')) {
      roles = myntRoles.filter((r) => r !== 'BENEFICIAL_OWNER')
    } else {
      roles = [...myntRoles, 'BENEFICIAL_OWNER']
    }

    setSubmitting(true)
    await personMutation.mutateAsync({
      personId,
      roles: roles as any
    })
    // await updatePersonsRoles(customerId, personId, roles)
    // await fetchFullKYCData()
    // await fetchKYCAnalysis()
    setSubmitting(false)
  }

  return (
    <div style={{ marginBottom: 40 }}>
      <CustomerDetailsKYCBenificialOwnersTable customerId={customerId} />
      <CustomerDetailsKYCBoardMembersTable
        submitting={submitting}
        handleOnBeneficialCheckboxClick={handleOnBeneficialCheckboxClick}
        customerId={customerId}
      />
      <ReactJson src={fullKYCData || {}} name="" collapsed={true} />
    </div>
  )
}

export default CustomerDetailsKYC
