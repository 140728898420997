import { Button } from '../components/Button'
import Popconfirm from '../components/Popconfirm'
import { StatusColors } from 'enums/statusColors'
import { formatCurrency } from 'helpers/utils'
import get from 'lodash/get'
import React, { ChangeEvent, useState } from 'react'
import { RepaymentInvoiceDto } from '../api/swagger/definitions/backoffice'
import Spacings from '../figma/tokens/Spacings'
import { Repayment, RepaymentInvoice, RepaymentPlan } from '../interfaces/RepaymentInterfaces'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaTextButton from '../mynt-components/components/FigmaTextButton'
import AttachRepaymentModal, { AttachInvoiceToRepayment } from './RepaymentAttachmentModal'
import RepaymentInvoices from './RepaymentInvoices'
import TextField from '@mui/material/TextField'
import DatePicker from '@mui/lab/DatePicker'
import styled from '@emotion/styled'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import { YYYY_MM_DD } from 'tiger/Constants'
import FeatherIcon from 'feather-icons-react'

interface RepaymentPlanProps {
  repaymentPlan: RepaymentPlan | null
  updateRepayment: (index: number) => Promise<void>
  createRepayment: (index: number) => Promise<void>
  dropInvoiceFromRepayment: (index: number) => void
  removeRepaymentField: (index: number) => void
  invoices: RepaymentInvoice[] | null
  setInvoiceById: (id: string, data: RepaymentInvoiceDto) => void
  creditAmount: number
  createRepaymentField: () => void
  updateRepaymentField: (
    name: 'amortization' | 'fee' | 'dueDate' | 'adminFee' | 'penalty',
    index: number
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void
  attachInvoiceToRepayment: AttachInvoiceToRepayment
  recreateRepaymentInvoice: (repayment: Repayment) => Promise<void>
  submitting: {
    type: '' | 'update' | 'drop' | 'recreate'
    index: number
  }
}

const StyledTextField = styled(TextField)`
  .MuiInputAdornment-root {
    padding-right: 16px;

    svg {
      font-size: 24px;
    }
  }
`

const RepaymentPlanTable: React.FC<RepaymentPlanProps> = ({
  repaymentPlan,
  updateRepayment,
  createRepayment,
  dropInvoiceFromRepayment,
  updateRepaymentField,
  createRepaymentField,
  removeRepaymentField,
  invoices,
  setInvoiceById,
  creditAmount,
  attachInvoiceToRepayment,
  recreateRepaymentInvoice,
  submitting
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [attachedRepayment, setAttachedRepayment] = useState<null | Repayment>(null)

  const onInputKeyPress = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updateRepayment(index)
    }
  }

  const createColumnInputRender = (
    placeholder: string,
    type: 'number' | 'date',
    name: 'amortization' | 'fee' | 'dueDate' | 'adminFee' | 'penalty',
    valuePath: string
  ) =>
    function input(record: Repayment, _: any, index: number) {
      if (type === 'date') {
        return (
          <DatePicker
            label={placeholder}
            renderInput={(props) => <StyledTextField {...props} sx={{ minWidth: 200 }} />}
            value={get(record, valuePath, null)}
            onChange={(value) => {
              updateRepaymentField(name, index)({ target: { value: dayjs(value).format(YYYY_MM_DD) } } as any)
            }}
          />
        )
      }

      const handleTextfieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') return

        updateRepaymentField(name, index)(event)
      }

      return (
        <TextField
          size="small"
          label={placeholder}
          defaultValue={get(record, valuePath, null)}
          onChange={handleTextfieldChange}
          onKeyPress={onInputKeyPress(index)}
          required
        />
      )
    }
  const getDataSource = () => {
    if (!repaymentPlan?.repayments) return []

    return repaymentPlan.repayments.map((repayment) => {
      if (!!invoices) {
        const invoice = invoices.find((invoice) => invoice.id === repayment.repaymentInvoiceId)

        if (invoice) {
          return {
            ...repayment,
            isCancelled: invoice.isCancelled,
            isCredited: invoice.isCredited
          }
        }
        return repayment
      }
      return repayment
    })
  }

  const createAmountSum = (name: 'amortization' | 'fee' | 'adminFee' | 'penalty') =>
    getDataSource().reduce((sum: number, repayment: Repayment) => {
      const isCancelled = get(repayment, 'isCancelled', false)
      const isCredited = get(repayment, 'isCredited', false)

      if (isCancelled || isCredited) {
        return sum
      }
      return sum + repayment[name].value || 0
    }, 0)

  const columns = [
    {
      title: '',
      render: (_, __, ___, open, setIsOpen) => (
        <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    },
    {
      title: 'No',
      dataIndex: 'no',
      width: 50,
      align: 'center' as const,
      render: (record: Repayment) => record.no
    },
    {
      title: () => {
        const amortizationSum = createAmountSum('amortization')
        const amortizationCreditAmountDiff = Math.abs(amortizationSum - creditAmount)

        const isAmortizationSumCorrect = amortizationCreditAmountDiff >= -1 && amortizationCreditAmountDiff <= 1

        const style = isAmortizationSumCorrect ? {} : { color: StatusColors.red }

        return (
          <span style={style}>
            Amortization (
            {formatCurrency(
              {
                value: amortizationSum,
                currency: 'SEK'
              },
              2
            )}
            )
          </span>
        )
      },
      key: 'amortization',
      width: 175,
      render: createColumnInputRender('Amortization', 'number', 'amortization', 'amortization.value')
    },
    {
      title: () => (
        <span>
          Fee (
          {formatCurrency(
            {
              value: createAmountSum('fee'),
              currency: 'SEK'
            },
            2
          )}
          )
        </span>
      ),
      key: 'fee',
      width: 125,
      render: createColumnInputRender('Fee', 'number', 'fee', 'fee.value')
    },
    {
      title: 'Due date',
      key: 'dueDate',
      width: 125,
      render: createColumnInputRender('Due date', 'date', 'dueDate', 'dueDate')
    },
    {
      title: 'Invoice no',
      dataIndex: 'repaymentInvoiceId',
      width: 150,
      align: 'center' as const,
      render: (record: Repayment) => {
        const invoice = (invoices || []).find((invoice) => invoice.id === record.repaymentInvoiceId)
        return invoice ? invoice.no : '-'
      }
    },
    {
      title: 'State',
      dataIndex: 'repaymentInvoiceId',
      key: 'repaymentInvoiceKey',
      width: 100,
      align: 'center' as const,
      render: (record: Repayment) => {
        const invoice = (invoices || []).find((invoice) => invoice.id === record.repaymentInvoiceId)
        return invoice ? invoice.state : '-'
      }
    },
    {
      title: '',
      width: 50,
      render: (record: Repayment, _: any, index: number) => {
        const btnServices = record.new ? createRepayment : updateRepayment

        return (
          <div style={{ position: 'relative' }}>
            <Button
              size="small"
              className="updateBtn"
              onClick={async () => btnServices(index)}
              loading={submitting.type === 'update' && submitting.index === index}
              htmlType="button"
              disabled={
                !record.dueDate ||
                isNaN(record.amortization.value) ||
                isNaN(record.fee.value) ||
                isNaN(record.adminFee.value) ||
                isNaN(record.penalty.value)
              }
            >
              {record.new ? 'Create' : 'Update'}
            </Button>

            {record.new && (
              <IconButton onClick={() => removeRepaymentField(index)}>
                <FeatherIcon icon="x-circle" />
              </IconButton>
            )}
          </div>
        )
      }
    },
    {
      title: '',
      width: 50,
      render: (record: Repayment, _: any, index: number) => {
        if (record.new) return null

        return (
          <>
            <Popconfirm title="Are you sure you want to drop this invoice?" onConfirm={() => dropInvoiceFromRepayment(index)}>
              <Button
                size="small"
                className="updateBtn"
                htmlType="button"
                disabled={!record.repaymentInvoiceId}
                loading={submitting.type === 'drop' && submitting.index === index}
              >
                Drop
              </Button>
            </Popconfirm>
          </>
        )
      }
    },
    {
      title: '',
      width: 50,
      render: (record: Repayment) => {
        if (record.new) return null

        const handleAttachClick = () => {
          setIsModalOpen(true)
          setAttachedRepayment(record)
        }

        return (
          <>
            <Button size="small" className="updateBtn" htmlType="button" disabled={!!record.repaymentInvoiceId} onClick={handleAttachClick}>
              Attach
            </Button>
          </>
        )
      }
    },
    {
      title: '',
      width: 50,
      render: (record: Repayment, _: any, index: number) => {
        if (record.new) return null

        return (
          <>
            <Button
              size="small"
              className="updateBtn"
              htmlType="button"
              disabled={!!record.repaymentInvoiceId}
              onClick={async () => recreateRepaymentInvoice(record)}
              loading={submitting.type === 'recreate' && submitting.index === index}
            >
              Recreate
            </Button>
          </>
        )
      }
    }
  ]

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              const _title = typeof column.title === 'function' ? column.title() : column.title

              return <TableCell key={column.key}>{_title}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {getDataSource().map((record: Repayment, index: number) => {
            const isCancelled = get(record, 'isCancelled', false)
            const isCredited = get(record, 'isCredited', false)

            const style = isCancelled || isCredited ? { backgroundColor: StatusColors.gray } : {}

            return (
              <Collapsable
                style={style}
                key={record.no}
                columns={columns}
                index={index}
                invoices={invoices}
                record={record}
                setInvoiceById={setInvoiceById}
              />
            )
          })}
        </TableBody>
      </Table>
      <FigmaTextButton
        onClick={() => createRepaymentField()}
        leftSvg={() => (
          <FigmaBox spacing={Spacings.minimum} right>
            <FeatherIcon icon="plus" />
          </FigmaBox>
        )}
        textKey={TextKeys.CardCreditProductToolTip}
        text="Add new repayment"
      />

      <AttachRepaymentModal
        isModalOpen={isModalOpen}
        repayment={attachedRepayment}
        attachInvoiceToRepayment={attachInvoiceToRepayment}
        onCancel={() => {
          setIsModalOpen(false)
          setAttachedRepayment(null)
        }}
      />
    </div>
  )
}

const createExpandedRepaymentPlanView = (
  invoices: RepaymentInvoice[] | null,
  setInvoiceById: (id: string, data: RepaymentInvoiceDto) => void
) =>
  function view({ repaymentInvoiceId }: { repaymentInvoiceId: string }) {
    if (!invoices) return null
    const invoice = invoices.find((invoice) => invoice.id === repaymentInvoiceId)

    if (!invoice) {
      return 'No invoice found'
    }

    return <RepaymentInvoices invoices={[invoice]} setInvoiceById={setInvoiceById} defaultExpandAllRows={true} />
  }

const Collapsable = ({ columns, record, style, index, invoices, setInvoiceById }) => {
  const [open, setIsOpen] = useState(false)

  return (
    <>
      <TableRow key={record.no} style={style}>
        {columns.map((column) => {
          const _render = typeof column.render === 'function' ? column.render : () => column.render

          return (
            <TableCell sx={{ minWidth: column.width }} key={column.key}>
              {_render(record as any, null, index, open, setIsOpen)}
            </TableCell>
          )
        })}
      </TableRow>
      <TableRow>
        <TableCell sx={{ border: 'none', padding: 0 }} colSpan={columns.length}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>{createExpandedRepaymentPlanView(invoices, setInvoiceById)({ repaymentInvoiceId: record.repaymentInvoiceId })}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default RepaymentPlanTable
