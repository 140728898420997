import { MutationFunction, MutationKey, useMutation as _useMutation, useQueryClient } from '@tanstack/react-query'
import { getQueriesData } from './helpers'

type HookOptions = {
  queryKey: MutationKey
}

export const useCreateListItemMutation = <Dto, PayloadDto>(
  { queryKey }: HookOptions,
  fn: MutationFunction<Dto, PayloadDto>,
  invalidateCacheKeyPrefix?: MutationKey
) => {
  const queryClient = useQueryClient()

  return _useMutation(queryKey, fn, {
    onSuccess: (item) => {
      const previous = getQueriesData<Dto[]>(queryClient, queryKey) || []
      const data = [...previous, item]

      queryClient.setQueriesData(queryKey, data)

      return { previous, data }
    },
    onSettled: () => {
      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach((key) => queryClient.invalidateQueries([key]))
      }
    }
  })
}

type OppertunisticHookOptions<Dto> = {
  queryKey: MutationKey
  getId: (item: Dto) => string // Method used to find an items identifier to match and replace old data oppertunistically, i.e. before the mutation has completed
}

export const useCreateListItemMutationOppertunistic = <Dto, PayloadDto>(
  { queryKey, getId }: OppertunisticHookOptions<Dto>,
  fn: MutationFunction<Dto, PayloadDto>,
  invalidateCacheKeyPrefix?: MutationKey
) => {
  const queryClient = useQueryClient()

  return _useMutation(queryKey, fn, {
    onMutate: async (item) => {
      await queryClient.cancelQueries(queryKey)

      const previous = getQueriesData<Dto[]>(queryClient, queryKey) || []
      const data = [...previous, item]

      queryClient.setQueriesData(queryKey, data)

      return { previous, data }
    },
    onSuccess: (item) => {
      const previous = getQueriesData<Dto[]>(queryClient, queryKey) || []

      const data = previous.map((prev) => {
        const prevId = getId(prev)
        const updatedId = getId(item)

        if (prevId === updatedId) return item

        return prev
      })

      queryClient.setQueriesData(queryKey, data)

      return { previous, data }
    },
    onError: (error, newItem, context) => {
      if (context?.previous) {
        queryClient.setQueriesData(queryKey, context.previous)
      }
    },
    onSettled: () => {
      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach((key) => queryClient.invalidateQueries([key]))
      }
    }
  })
}
