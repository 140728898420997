enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  RANDOM = 'RANDOM'
}

export default class SwedishSSN {
  /**
   * Validates parameter given SSN. Returns true if SSN is valid, otherwise false.
   * @param ssn - {String}
   * @returns {boolean}
   */
  static validate(ssn: string | null | undefined): boolean {
    if (ssn === undefined || ssn === null) {
      return false
    }

    const ssnAsArray = parse(ssn)

    if (ssnAsArray.length === 10) {
      const checkDigit = ssnAsArray.pop()

      switch (getChecksum(ssnAsArray)) {
        case checkDigit: {
          return true
        }
        default: {
          return false
        }
      }
    }

    return false
  }

  /**
   * Creates a valid SSN using random numbers.
   * @returns {String} - valid ssn.
   */
  static generateRandomSSN(): string {
    const birthdate = getRandomDate()

    const secondPart = getRandomNumber(1000, 9998, GenderEnum.RANDOM)

    const randomSsn = yymmdd(birthdate) + secondPart

    const ssnAsArray = parse(randomSsn)

    const checksum = getChecksum(ssnAsArray)
    ssnAsArray.push(checksum)

    return ssnFormatter(ssnAsArray)
  }

  /**
   * Creates a valid SSN using given date and gender.
   * @returns {String} - valid ssn.
   */
  static generateSSNWithParameters(birthdate: Date, gender: keyof typeof GenderEnum): string {
    const secondPart = getRandomNumber(1000, 9999, gender)

    const randomSsn = yymmdd(birthdate) + secondPart

    const ssnAsArray = parse(randomSsn)

    const checksum = getChecksum(ssnAsArray)
    ssnAsArray.push(checksum)

    return ssnFormatter(ssnAsArray)
  }
}

function yymmdd(birthdate: Date) {
  const formatted = birthdate.toLocaleDateString('sv-SE', { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\D/g, '')
  return formatted
}

/**
 * returns a random number as string for second part of ssn
 */
function getRandomNumber(min: number, max: number, gender: keyof typeof GenderEnum) {
  let number = Math.floor(Math.random() * (max - min + 1) + min)

  switch (gender) {
    case GenderEnum.MALE: {
      if (isEven(number)) {
        number = number + 1
      }

      break
    }
    case GenderEnum.FEMALE: {
      if (isOdd(number)) {
        number = number + 1
      }

      break
    }
    default: {
      break
    }
  }

  return number.toString().substring(1)
}

function isEven(x: number) {
  return x % 2 === 0
}

function isOdd(x: number) {
  return !isEven(x)
}

function getRandomDate() {
  return new Date(new Date(1900, 1, 1).getTime() + Math.random() * (new Date(2099, 1, 1).getTime() - new Date(1900, 1, 1).getTime()))
}

function getChecksum(ssn: number[]) {
  const luhn = [2, 1, 2, 1, 2, 1, 2, 1, 2]

  const multiplied = ssn.map((num, index) => num * luhn[index])

  const digits = multiplied.flatMap((num) => (num >= 10 ? [sumOfNum(num)] : [num]))

  const sumOfDigits = digits.reduce((prev, current) => prev + current, 0)

  const checksum = Math.floor((sumOfDigits * 9) % 10)

  return checksum
}

function sumOfNum(num: number) {
  return Math.floor(num % 10) + Math.floor(num / 10)
}

/**
 * returns a formatted SSN 'yymmdd-fjkm'
 */
function ssnFormatter(ssnAsArray: number[]) {
  const formattedSSN = ssnAsArray.slice(0, 6).join('').toString() + '-' + ssnAsArray.slice(6, 10).join('').toString()
  return formattedSSN
}

/**
 * Parse parameter given SSN string. Remove all characters except digits.
 * @param ssn - {String} SSN to parse
 * @returns Int[]
 */
function parse(ssn: string) {
  const cleaned = ssn.replace(/\D/g, '').split('')

  if (cleaned.length === 12) {
    return cleaned.slice(2, 12).map(Number)
  }

  return cleaned.map(Number)
}
