import styled from '@emotion/styled'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { AccountXYear } from '../api/swagger/definitions/backoffice'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import useLanguage from '../flamingo/hooks/useLanguage'
import { AccountingFilters } from '../hooks/useAccountingFilters'
import TextKeys from '../libs/TextKeys'
import { YYYY_MM_DD } from '../mynt-components/Constants'
import { VOUCHERS_FILTERS_SELECT_INPUT_MIN_HEIGHT } from '../mynt-components/WeakHardCodedSizes'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaButton from '../mynt-components/components/FigmaButton'
import { getText } from '../tiger/libs/TextRepository'

interface AccountingVoucherFiltersProps {
  years: AccountXYear[]
  accountingFilters: AccountingFilters
  currencies: string[]
}

const VouchersFilters: React.FC<AccountingVoucherFiltersProps> = ({ years = [], accountingFilters, currencies = [] }) => {
  const language = useLanguage()

  const { fromDate, setFromDate, toDate, setToDate } = accountingFilters

  const [updatedFromDate, setUpdatedFromDate] = useState<string | undefined>(fromDate)
  const [updatedToDate, setUpdatedToDate] = useState<string | undefined>(toDate)

  // useEffect(() => {
  //   setUpdatedYearIds(yearIds)
  // }, [yearIds])

  useEffect(() => {
    setUpdatedFromDate(fromDate)
  }, [fromDate])

  useEffect(() => {
    setUpdatedToDate(toDate)
  }, [toDate])

  const handleOnFromDateSelect = (date) => {
    setUpdatedFromDate(date ? dayjs(date).format(YYYY_MM_DD) : '')
  }

  const handleOnToDateSelect = (date) => {
    setUpdatedToDate(date ? dayjs(date).format(YYYY_MM_DD) : '')
  }

  const handleSearchSubmit = () => {
    setFromDate(updatedFromDate)
    setToDate(updatedToDate)
  }

  // const currencyOptions = useMemo(() => currencies.map((currency) => ({ value: currency, label: currency })), [currencies])

  return (
    <Wrapper fullWidth direction="row" justify="center" align="flex-start" gap={Spacings.medium}>
      {/* <FigmaBox fullWidth>
        <FormControl fullWidth>
          <InputLabel id="vouchersYearLabel">{getText(TextKeys.vouchersYearLabel, language)}</InputLabel>
          <Select
            id="vouchersYear"
            labelId="vouchersYearLabel"
            label={getText(TextKeys.vouchersYearLabel, language)}
            value={updatedYearIds || []}
            onChange={(e) => handleOnYearSelect([e.target.value])}
            style={{ height: 'auto' }}
            renderValue={(selected) => (
              <FigmaBox direction="row" flexWrap="wrap" gap={Spacings.minimum}>
                {years
                  ?.filter((year: any) => selected.includes(year.id))
                  .map((item: any) => (
                    <Chip key={item.id} label={item.name} />
                  ))}
              </FigmaBox>
            )}
          >
            {years?.map((option) => (
              <Item key={option.id} value={option.id || '-'}>
                {option.name}
              </Item>
            ))}
          </Select>
        </FormControl>
      </FigmaBox> */}
      <FigmaBox fullWidth>
        <DesktopDatePicker
          label={getText(TextKeys.vouchersFromDateLabel, language)}
          value={updatedFromDate || null}
          inputFormat={YYYY_MM_DD}
          onChange={handleOnFromDateSelect}
          renderInput={(props) => <FixedDesktopDatePickerInput {...props} />}
        />
      </FigmaBox>
      <FigmaBox fullWidth>
        <DesktopDatePicker
          label={getText(TextKeys.vouchersToDateLabel, language)}
          value={updatedToDate || null}
          inputFormat={YYYY_MM_DD}
          onChange={handleOnToDateSelect}
          renderInput={(props) => <FixedDesktopDatePickerInput {...props} />}
        />
      </FigmaBox>
      {/* <FigmaBox fullWidth>
        <FormControl fullWidth>
          <InputLabel id="voucherCurrencyLabel">{getText(TextKeys.vouchersCurrencyLabel, language)}</InputLabel>
          <Select
            id="voucherCurrency"
            labelId="voucherCurrencyLabel"
            label={getText(TextKeys.vouchersAccountLabel, language)}
            value={updatedAccountNos || []}
            onChange={(e) => handleOnAccountSelect(e.target.value)}
            multiple
            style={{ height: 'auto' }}
            renderValue={(selected) => (
              <FigmaBox direction="row" flexWrap="wrap" gap={Spacings.minimum}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </FigmaBox>
            )}
          >
            {currencyOptions?.map((option) => (
              <Item key={option.value} value={option.value}>
                {option.label}
              </Item>
            ))}
          </Select>
        </FormControl>
      </FigmaBox> */}
      <FigmaButton
        fullWidth={false}
        textKey={TextKeys.accountingVoucherCreateVoucher}
        text={'Search'}
        variant={'secondary'}
        onClick={handleSearchSubmit}
      />
    </Wrapper>
  )
}

const Wrapper = styled(FigmaBox)`
  .MuiSelect-select {
    min-height: ${VOUCHERS_FILTERS_SELECT_INPUT_MIN_HEIGHT} !important;
    padding-top: ${Spacings.min};
    padding-bottom: ${Spacings.min};
  }
`

const FixedDesktopDatePickerInput = styled(TextField)`
  button {
    margin-right: ${Spacings.minimum};
  }
`

const Item = styled(MenuItem)`
  && {
    cursor: pointer;
    color: ${ColorsDeprecated.baseGrey};
    padding-left: ${Spacings.small};
    padding-right: ${Spacings.small};

    &:hover {
      color: ${ColorsDeprecated.baseBlack};
      background-color: ${ColorsDeprecated.baseStone};
    }
  }
`

export default VouchersFilters
