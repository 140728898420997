import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CREDITS_PATH } from 'routes/constants'
import { useAllCredits } from '../api/react-query'
import { generateCSVReport } from '../api'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import { CreditDto, CreditDtoType } from 'api/swagger/definitions/backoffice'
import dayjs, { Dayjs } from 'dayjs'
import { YYYY_MM_DD } from 'tiger/Constants'
import CreditsTable from './CreditsList'
import { When } from 'mynt-components/components/When'
import { useNotesContext } from 'contexts/Notes'

const getCreditType = (type?: CreditDtoType) => {
  if (type === CreditDtoType.COMPANYCREDIT) {
    return 'company-credits'
  }

  return 'invoice-credits'
}

interface IProps {
  customerId?: string
  pageSize?: number
  defaultCheckboxValue?: string[]
  type?: 'customer'
}

const CreditsListContainer: React.FC<IProps> = ({ customerId = '', defaultCheckboxValue = ['OPENED', 'PROSPECT', 'CLOSED'], type }) => {
  const navigate = useNavigate()
  const [level, setLevel] = useState(defaultCheckboxValue)
  const { data: credits = [], isLoading: creditsIsLoading } = useAllCredits({ customerId, level: level.join(',') })

  const [reportDate, setReportDate] = useState(dayjs())
  const [notesContext] = useNotesContext()

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setLevel((levels) => {
      if (checked && !levels.includes(name)) {
        return [...levels, name]
      }

      return levels.filter((level) => level !== name)
    })
  }

  const onRowClick = (row: CreditDto) => {
    const type = getCreditType(row.type)
    navigate(`${CREDITS_PATH}/${type}/${row.id}`)
  }

  const handleDownloadReport = async () => {
    try {
      const onDate: unknown = new Date(reportDate.format(YYYY_MM_DD))
      const isOnDateValid = !isNaN(onDate as number)

      const query = isOnDateValid ? (onDate as Date).toISOString().split('T')[0] : ''

      const data: any = await generateCSVReport(query)
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      const currentDate = new Date().toISOString().split('T')[0]

      link.href = url
      link.setAttribute('download', `credit_report_${currentDate}.csv`)
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err)
    }
  }

  const isNotesOpen = notesContext.open && Boolean(customerId)
  const creditsMaxWidth = Boolean(customerId) ? '93vw' : '100vw'

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', color: 'black', alignItems: 'center' }}>
        <FormGroup
          sx={{ display: 'flex', flexDirection: 'row', '*:not(svg)': { fontSize: '16px' } }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(e.target.name, e.target.checked)}
        >
          <FormControlLabel name="OPENED" control={<Checkbox checked={level.includes('OPENED')} size="small" />} label="Open loans" />
          <FormControlLabel name="PROSPECT" control={<Checkbox checked={level.includes('PROSPECT')} size="small" />} label="Prospect" />
          <FormControlLabel name="CLOSED" control={<Checkbox checked={level.includes('CLOSED')} size="small" />} label="Repaid / Default" />
          <FormControlLabel
            name="CANCELLED"
            control={<Checkbox checked={level.includes('CANCELLED')} size="small" />}
            label="Cancelled / Closed"
          />
        </FormGroup>
        <When is={type !== 'customer'}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledDatePicker
                format={YYYY_MM_DD}
                value={reportDate}
                onChange={(date) => setReportDate(date as Dayjs)}
                // The header font size is too big, was unable to find the source of where its getting increased from
                slotProps={{
                  calendarHeader: {
                    sx: { '.MuiPickersCalendarHeader-label': { fontSize: '1rem' } }
                  }
                }}
                label="On date"
              />
            </LocalizationProvider>
            <Button onClick={handleDownloadReport} variant="secondary">
              Download Report
            </Button>
          </Box>
        </When>
      </Box>
      <Box sx={{ minHeight: '200px', width: '100%' }}>
        <Box sx={{ paddingBottom: '8px' }}>
          <span>Hint: Double click to go to credit, single click to mark cell to copy</span>
        </Box>
        <Box sx={{ maxWidth: isNotesOpen ? '75vw' : creditsMaxWidth }}>
          <CreditsTable onRowDoubleClick={({ row }) => onRowClick(row)} credits={credits} loading={creditsIsLoading} view={type} />
        </Box>
      </Box>
    </Box>
  )
}

const StyledDatePicker = styled(DatePicker)`
  .MuiInputAdornment-root {
    height: unset;
    min-height: unset;

    .MuiButtonBase-root {
      margin-right: unset;
    }
  }
`

export default CreditsListContainer
