/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentState = {
    INCOMING: 'INCOMING',
    MAPPED: 'MAPPED',
    BOOKED: 'BOOKED',
    CANCELLED: 'CANCELLED',
    MANUAL_HANDLED: 'MANUAL_HANDLED',
    PENDING_MAPPED: 'PENDING_MAPPED',
    PENDING_INCOMING: 'PENDING_INCOMING',
    SENDER_UNKNOWN: 'SENDER_UNKNOWN',
    TO_BE_RETURNED: 'TO_BE_RETURNED',
    RETURNED: 'RETURNED'
} as const;

export type PaymentState = typeof PaymentState[keyof typeof PaymentState];



