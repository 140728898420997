import styled from '@emotion/styled'
import ColorsDeprecated from 'figma/tokens/ColorsDeprecated'
import BorderRadius from 'figma/tokens/BorderRadius'
import Shadows from 'figma/tokens/Shadows'
import Spacings from 'figma/tokens/Spacings'
import ZIndices from 'figma/tokens/ZIndices'
import MyntPopper from 'mynt-components/components/MyntPopper'
import React from 'react'
import { Triangle } from '../helpers/customerGeneralFormHelper'
import useMaterialNotification from '../hooks/useMaterialNotification'
import TextKeys from '../libs/TextKeys'
import Button from '../mynt-components/components/Button'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaTextArray from '../mynt-components/components/FigmaTextArray'
import FigmaTextLink from '../mynt-components/components/FigmaTextLink'
import { ModalProps } from '../mynt-components/components/ModalExperimental'
import { When } from '../mynt-components/components/When'
import TextContainer from './TextContainer'

export type ModalActivateCardProps = ModalProps & {
  anchor: any
  log: string
  visible?: boolean
  showAltarLink?: boolean
}

export const _ModalMandateLog: React.FC<ModalActivateCardProps> = ({ anchor, visible = false, onClose, log, showAltarLink }) => {
  const notify = useMaterialNotification()

  // Reversed so that the latest logs are displayed at the top
  const logArray = log.split(/\n+/).reverse()

  const handleAltarClick = () => {
    window.open('https://docs.atlar.com/#section/Payment-Details/Payment-return-reason-codes', '_BLANK')
  }

  const handleCopyClick = () => {
    window.navigator.clipboard.writeText(log)
    notify('Copied Altar log to clipboard')
  }

  return (
    <MyntPopper
      visible={visible}
      noPadding
      marginTop={Spacings.min}
      marginBottom={Spacings.medium}
      position="top"
      anchorRef={anchor}
      zIndex={ZIndices.heaven}
      handleClose={onClose}
      content={
        <Container gap={Spacings.medium} fullWidth fullPadding>
          <FigmaBox fullWidth align="center">
            <TextContainer textKey={TextKeys.directDebitStatusLogHeading} />
          </FigmaBox>
          <LogContainer fullWidth>
            <FigmaTextArray propSets={logArray.map((text) => ({ textKey: TextKeys.directDebitLog, text }))} />
          </LogContainer>
          <FigmaBox direction="row" fullWidth justify="space-between" align="center">
            <FigmaBox onClick={handleCopyClick}>
              <FigmaTextLink textKey={TextKeys.directDebitCopyLogButton} />
            </FigmaBox>
            <When is={showAltarLink}>
              <FigmaBox>
                <StyledButton onClick={handleAltarClick} size="small" textKey={TextKeys.directDebitOpenAltarButton} />
              </FigmaBox>
            </When>
          </FigmaBox>
          <Triangle />
        </Container>
      }
    />
  )
}

const StyledButton = styled(Button)`
  padding: ${Spacings.minimum} ${Spacings.tiny};
`

const Container = styled(FigmaBox)`
  width: 345px;
  background-color: ${ColorsDeprecated.baseWhite};
  border-radius: ${BorderRadius.soft};
  box-shadow: ${Shadows.baseHeavy};
`

const LogContainer = styled(FigmaBox)`
  * {
    display: block !important;
  }

  max-height: 6rem;
  padding: ${Spacings.tiny};
  background-color: ${ColorsDeprecated.surfaceOnWhite};
  border: 1px solid ${ColorsDeprecated.baseStone};
  border-radius: ${BorderRadius.soft};
  overflow-y: scroll;
`
