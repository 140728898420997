import styled from '@emotion/styled'
import { useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { CARD_CREDITS } from '../api/react-query/keys'
import { BackOfficeCardCreditGetV2Dto } from '../api/swagger/definitions/backoffice'
import BorderRadius from '../figma/tokens/BorderRadius'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import { invalidateAgreements, usePatchCardCredit } from '../api/react-query'
import { BorderContainer, BorderContainerHeader, CustomerIdContext, parseCreditRequest } from '../helpers/CreditOnCardHelpers'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaTextButton from '../mynt-components/components/FigmaTextButton'
import TextContainer from './TextContainer'

interface Props {
  credit: BackOfficeCardCreditGetV2Dto
}

const CreditOnCardCreditRequestView = ({ credit }: Props) => {
  const customerId = useContext(CustomerIdContext)
  const queryClient = useQueryClient()
  const mutation = usePatchCardCredit(customerId)

  const onClickClearRequest = () => {
    mutation.mutateAsync({ creditId: credit.id, request: '' }).then(() => {
      invalidateAgreements(queryClient)
      queryClient.invalidateQueries([CARD_CREDITS])
    })
  }

  return (
    <BorderContainer fullWidth>
      <BorderContainerHeader noBorder noSpacingBottom>
        <TextContainer textKey={TextKeys.creditRequestHeading} text={credit.type} />
      </BorderContainerHeader>

      <FigmaBox fullWidth direction="row" justify="space-between" top={Spacings.small} bottom={Spacings.tiny}>
        <TextContainer textKey={TextKeys.creditRequestHeading} />
        <FigmaTextButton textKey={TextKeys.creditRequestLink} onClick={onClickClearRequest} />
      </FigmaBox>

      <CreditRequestBox fullWidth>
        {credit?.request && <TextContainer textKey={TextKeys.creditRequestBody} text={parseCreditRequest(credit?.request)} />}
      </CreditRequestBox>
    </BorderContainer>
  )
}

const CreditRequestBox = styled(FigmaBox)`
  padding: ${Spacings.tiny};
  background-color: ${ColorsDeprecated.surfaceOnWhite};
  border: 1px solid ${ColorsDeprecated.baseStone};
  border-radius: ${BorderRadius.soft};
`

export default CreditOnCardCreditRequestView
