/* eslint-disable prettier/prettier */

export const FONT_FAMILY_MYNT_SANS = "'Mynt Sans Display', 'Arial', sans-serif"
export const FONT_FAMILY_INTER = "'Inter', 'Arial', sans-serif"

export const DEFAULT_ERR_MSG = 'Tyvärr är tjänsten inte tillgänglig i nuläget. Vi ber dig återkomma vid ett senare tillfälle'

export const UUID_REGEX = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g
export const SSN_REGEX =
  /((19|20)?)([0-9]{2})[- ]?(((0[0-9])|(10|11|12)))[- ]?((([0-2][0-9])|(3[0-1])|(([7-8][0-9])|(6[1-9])|(9[0-1]))))[- ]?([0-9]{4})/g
export const ORG_NO_REGEX = /(?:[[0-9]{2})(?:[2-9][0-9])(?:[0-9]{2})[- ]?(?:[0-9]{4})/g

export const sensitiveCardInfoHTMLRegex = /\s*<.*>\s*([0-9]{16})\s*<\/.*>\s*<.*>\s*([0-9]{4})\s*<\/.*>\s*<.*>\s*([0-9]{3})\s*<\/.*>\s*/
export const phoneValidatorRegex =
  /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i
export const emailValidatorRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const validInputRegex = [
  /(^[0-9]{4}-[0-9]{7}$)/,
  /(^[0-9]{4}-[0-9]{9}$)/,
  /(^[0-9]{4}-[0-9]{10}$)/,
  /(^[0-9]{5}-[0-9]{8}$)/,
  /(^[0-9]{5}-[0-9]{9}$)/,
  /(^[0-9]{5}-[0-9]{10}$)/
]

export const ALL_NON_DIGIT_REGEX = /[^0-9]+/g
export const PERCENT_REGEX = /^[1-9][0-9]?$|^100$/
export const ZERO_TO_HUNDRED_REGEX = /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/
export const NAME_REGEX = /(\w.+\s).+/i
export const NAME_IMPROVE_REGEX = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/

export const PHONE_REGEX = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/

export const PHONE_NO_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
export const EXPORTED = 'EXPORTED'

export const DATE_REGEX = /[0-9]{4}-[0-9]{2}-[0-9]{2}/g
export const OFFSET_REGEX = /offset=[0-9]+/
export const LIMIT_REGEX = /limit=[0-9]+/
export const MIN_REGEX = /min=[0-9]+/
export const MAX_REGEX = /max=[0-9]+/
export const STATUS_REGEX = /status=[a-zA-Z]+/g
export const FORCE_REFRESH_REGEX = /force-refresh=[a-zA-Z]{4,5}/
export const ALL_ACCOUNT_REGEX = /all-account=[a-zA-Z]{4,5}/
export const CODE_REGEX = /code=[a-z0-9]+/
export const START_SERIAL_NUMBER_REGEX = /startSerialNumber=[0-9]+/
export const CARD_CARDS_ID_REGEX = /\/card\/cards\/[0-9]+/

export const ENFUCE_URL_REGEX = /(https:\/\/)(.+)(cia\.enfuce\.com)((\/pinweb\/)|(\/panweb\/))(.+)/g

export const NEW_NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/

export const EUR = 'EUR'
export const SEK = 'SEK'
export const USD = 'USD'
export const GBP = 'GBP'
export const currencySEK = 'kr'
export const currencyEUR = '€'
export const currencyUSD = '$'
export const currencyGBP = '£'

export const DD_MMM_YYYY = 'DD MMM YYYY'
export const D_MMM = 'D MMM'
export const MMM_DD = 'MMM_DD'
export const YYYY_MM_DD = 'YYYY-MM-DD'
export const DD_MM_YYYY = 'DD-MM-YYYY'
export const YYYY_MM_DD_hh_mm_ss = 'YYYY-MM-DD hh:mm:ss'
export const LT = 'LT'
export const HH_mm = 'HH:mm'
export const HH_mm_ss = 'HH:mm:ss'

export const ECB = 'ECB'
export const SETLY = 'setly'
export const SETLYPATH_HARDCODED_IN_BACKEND = '/setly'
export const SETLY_CONFIG_NAME = 'setly' // hardcoded in backend
export const DENIED = 'DENIED'
export const BANKID = 'BANKID'
export const STATE = 'STATE'
export const HTTP_STATUS_NO_CONTENT = 204
export const DIRECT = 'DIRECT'
export const MYNT_WEB = 'MYNT_WEB'
export const SSN = '8408110016'
export const INVITATION_TOKEN = 'INVITATION_TOKEN'

// Languages
export const sv = 'sv' // its motivated to have small caps here as this is iso standard
export const en = 'en' // its motivated to have small caps here as this is iso standard
export const fi = 'fi' // its motivated to have small caps here as this is iso standard
export const figma = 'figma'

export const MAIL_TO = 'mailto:info@mynt.se'
export const TEL_TO = 'tel: 010 198 0300'

export const ONE_SECOND = 1000

export const IGNORED_TEXT_KEYS = ['hidden', 'label_', 'monthlySpendInfoOverview']
export const IGNORED_IMAGE_KEYS = ['hidden', 'group9077', 'mobile', 'vector']

export const PENDING = 'PENDING'

export const POST = 'POST'

export const SETTINGS = 'SETTINGS'
export const TITLE_USER = 'TITLE_USER'

export const FACEBOOK_DOMAIN_VERIFICATION_TOKEN = 'zzyyl0qu95oj165185xavx8dix86py'
export const INVALID_EMAIL_ADDRESS_DOMAINS = ['gmail', 'hotmail']

// Used by our own if - EXACT MATCH
export const IGNORED_NETWORK_ERRORS = [
  'BankID-appen verkar inte finnas i din dator eller telefon. Installera den och hämta ett BankID hos din internetbank. Installera appen från install.bankid.com.',
  'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.'
]

// Used by Sentry - PARTIAL MATCH
export const IGNORED_SENTRY_ERRORS = [
  // Non-error notifications
  'Invalid status url: https://scrive.com',
  'Invalid refresh token',
  'Missing account number',
  'Null name',
  'No contact email found',
  'Can not send an invite to person without email address',
  'You must patch either tax amount or tax rate, not both',
  'Customer could not be found',
  'Customer do not have a active auto giro approval',
  'User is not a signatory, admin or card holder for this customer',
  'Person is missing phone number',
  'Transaction type not supported',
  'someone has changed the transaction, will not export now',
  'no repayment plan id',
  'The transaction is already settled',
  'Person have non terminated cards',
  'obtain Visma authorization tokens',
  'unable to parse fortnox error',
  'fortnox_access_token_refresh',
  'Fortnox access token',
  'token is not longer valid',
  'transaction is already exported',
  'Customer does not have setup accounting',
  'Request for payment does not support pdfs',
  'Customer is missing address',
  'could not purchase basic report for',
  'Can not find employee id for personId',
  'not a valid ip address',
  'Trying to alter non latest version',
  'The page is temporarily unavailable',
  'card product must be enabled',
  'No enfuce account connected to customer',
  'User cancelled image selection',
  'is not a mobile number',
  'customer must have a bank account before manually approving',
  "Credit doesn't match customerId",
  'Team do not belong to customer',
  'invoice is already cancelled',
  'Account can not be updated in this status',
  'Day must be in',
  'Can not credit invoice that has payments',
  'cannot update invoice that is booked',
  'Can not create a signatory without phoneNumber',
  'You must use Billecta web GUI. Then press refresh in BO.',
  'Person is not approver for team',
  'Can not delete customer with connected card.',
  'Can not find peToken',
  'BankAccount expected',

  // User input errors
  'Email must be unique per customer',
  'SSN must be unique per customer',
  'Not a valid SSN',
  'Check Digit Is Not Valid',
  'No plastic with QR-code',
  'invalid ssn',
  'invalid email',
  'invalid orgno',
  'not a valid org no',
  'invalid phone number',
  'not a valid phone number',
  'Check Digit Is Not Valid',
  'bank account: ',
  'Either DebitAmount or CreditAmount must be greater than 0',
  'both first and last name required',

  // False positives
  'Invalid file format', // affects some pdfs, we investigated and monitored, most probably a BE error with some encrypted files

  // Not allowed actions
  'Credit locked for change',
  'Can not remove person with this role',
  'Card can not be updated when account is closed',
  'card can not be disabled when card credit is enabled',
  'Can only settle enfuce payout in state booked',
  'you are not authorized to see this page',

  // Fortnox [400], 2000 error
  '\\[[0-9]+\\], [0-9]+',

  // Various
  'ResizeObserver loop' // multiple ResizeObserver issues caused by dependencies
]

export const GOOGLE_TAG_MANAGER_ID = 'GTM-PZC4SQZ'
export const TEST_ACCOUNT_NUMBER = '5266-0048320'

export const INTERCOM_APP_ID = 'sosfww2i'
export const GDPR_COOKIE = 'gdpr_cookie_new'

export const ONE = 1
export const ONE_CLOSE = 0.9
export const ONE_MILLION = 1000000
export const ONE_MILLION_CLOSE = 999999
export const TWO_THOUSAND = 2000
export const FIVE_THOUSAND = 5000
export const TWENTY_FIVE_HUNDRED = 2500
export const TWENTY_THOUSAND = 20000
export const TWENTY_FIVE_THOUSAND = 25000
export const FIFTY_THOUSAND = 50000
export const TWO_MILLION = 2000000
export const THREE_MILLION = 3000000
export const FIVE_MILLION = 5000000
export const TEN_MILLION = 10000000
export const THREE_MILLION_CLOSE = 2999999
export const FIVE_SECONDS = 5000
export const ONE_MINUTE = 60 * 1000
export const THREE_MINUTES = 3 * 60 * 1000
export const TEN_MINUTES = 10 * 60 * 1000
export const MIN_TOPUP_VALUE = 5000

export const ADMIN = 'ADMIN'
export const MY_CARDS = 'MY_CARDS'

export const ENTER_KEYCODE = 13
export const ENTER_KEYCODE_STRING = '13'

export const MONTHLY = 'MONTHLY'
export const WEEKLY = 'WEEKLY'

export const VAT_AMOUNTS = ['0', '6', '12', '25']

const TEN_THOUSAND = 10000
export const LIMIT = TEN_THOUSAND

export const CONTENT_TYPE_IMG = {
  'image/png': 'image/png',
  'image/jpg': 'image/jpg',
  'image/jpeg': 'image/jpeg'
}

export const CONTENT_TYPE_PDF = 'application/pdf'

export const CUSTOM = 'Custom'

export const DEBOUNCE_200 = 200
export const DEBOUNCE_300 = 300
export const DEBOUNCE_400 = 400
export const DEBOUNCE_800 = 800

export const SESSION_ENDPOINT = '/standalone/v4/session'
export const BACKDOOR_ENDPOINT = 'developer/login/backdoor'

export const featherIconNames = [
  'archive',
  'briefcase',
  'book',
  'clock',
  'camera',
  'box',
  'briefcase',
  'heart',
  'compass',
  'credit-card',
  'database',
  'file',
  'gift',
  'globe',
  'home',
  'inbox',
  'battery',
  'list',
  'more-horizontal',
  'coffee',
  'tool',
  'edit-2',
  'mail',
  'lock',
  'package',
  'phone',
  'printer',
  'radio',
  'send',
  'search',
  'settings',
  'shopping-cart',
  'shopping-bag',
  'scissors',
  'smartphone',
  'smile',
  'tablet',
  'truck',
  'user',
  'users',
  'zap',
  'tv',
  'music',
  'mail',
  'map',
  'inbox',
  'folder'
]

export const customIcons = [
  'othericon',
  'gifticon',
  'usersicon',
  'coffeeicon',
  'travelicon',
  'smileicon',
  'toolicon',
  'computericon',
  'shoppingicon',
  'smartphoneicon',
  'bookicon',
  'homeicon',
  'hearticon',
  'cutleryicon',
  'usericon',
  'presentationicon',
  'mappinicon',
  'penicon',
  'charticon',
  'packageicon'
]

export const THREE_QUARTERS_OPACITY = 0.75
export const FORTY_SEVEN_POINT_FIVE = 47.5
export const FORTY_FIVE = 45

export const DEV_CARD_ACTIVATION_CODE = 'topgun'
export const DEV_SSN = '19791130-0338'
export const TIGER_ONBOARDING_TIMEOUT_INTERVAL = 20000

/* LINKS - START */

export const MYNT_WEBSITE_LINKS = {
  [sv]: 'https://www.mynt.com/',
  [en]: 'https://en.mynt.com/',
  [fi]: 'https://fi.mynt.com/'
}

export const MYNT_TERMS_LINK = 'terms'

export const TPML_SCHEDULE_LINK = '/20210430/TPML_Schedule.pdf'

export const MYNT_GAZELLE_GITHUB_COMMITS_LINK = 'https://github.com/moneaOrg/gazelle/commit/'

export const LOGIN_LINK = 'https://app.mynt.se/'

export enum TARGET {
  BLANK = '_blank',
  SELF = '_self'
}

export const ACTIVATION_GUIDE_URLS = {
  ADD_MONEY: {
    sv: 'https://help.mynt.com/sv/articles/5689568-lagg-till-pengar-pa-kontot',
    en: 'https://help.mynt.com/en/articles/5689568-add-money-to-your-account',
    fi: 'https://help.mynt.com/fi/articles/5689568-lisaa-rahaa-tilillesi'
  },
  SUBMIT_BANK_DATA: {
    sv: 'https://help.mynt.com/sv/articles/5810738-hur-du-anvander-kredit-for-ditt-konto',
    en: 'https://help.mynt.com/en/articles/5810738-how-to-use-credit-on-your-account',
    fi: 'https://help.mynt.com/fi/articles/5810738-kortin-luotto'
  },
  CONNECT_ACCOUNTING_SYSTEM: {
    sv: 'https://help.mynt.com/sv/articles/5689565-koppla-ditt-bokforingssystem',
    en: 'https://help.mynt.com/en/articles/5689565-connect-your-accounting-system',
    fi: 'https://help.mynt.com/fi/articles/5689565-connect-your-accounting-system'
  },
  ADD_MEMBERS_AND_ORDER_CARD: {
    en: 'https://help.mynt.com/en/articles/5335620-add-members-and-order-cards',
    sv: 'https://help.mynt.com/sv/articles/5335620-lagg-till-anvandare-och-bestall-kort',
    fi: 'https://help.mynt.com/fi/articles/5335620-add-members-and-order-cards'
  }
}

export const LINK_HELP_CENTER = {
  url: {
    sv: 'https://help.mynt.com/sv/',
    en: 'https://help.mynt.com/en/',
    fi: 'https://help.mynt.com/fi/'
  },
  target: TARGET.BLANK
}

export const LINK_LOGIN = {
  url: {
    sv: 'https://app.mynt.se/',
    en: 'https://app.mynt.se/',
    fi: 'https://app.mynt.se/'
  },
  target: TARGET.BLANK
}

export const LIGHT_CARD_PARTNER_URLS = {
  Setly: 'https://www.setly.com/',
  eTasku: 'https://www.etasku.fi/en',
  Wint: 'https://www.wint.se/'
} as const

export const LINK_SIGN_UP = {
  url: {
    sv: 'https://mynt.se/signup',
    en: 'https://en.mynt.com/signup',
    fi: 'https://fi.mynt.com/signup'
  },
  target: TARGET.BLANK
}

export const LINK_CONTACT_US = {
  url: {
    sv: 'https://mynt.se/contact',
    en: 'https://en.mynt.com/contact',
    fi: 'https://fi.mynt.com/contact'
  },
  target: TARGET.BLANK
}

export const LINK_PRICING = {
  url: {
    sv: 'https://www.mynt.com/pricing',
    en: 'https://en.mynt.com/pricing',
    fi: 'https://fi.mynt.com/pricing'
  },
  target: TARGET.BLANK
}

export const INTERCOM_ATTEST_LINK = {
  sv: 'https://help.mynt.com/sv/articles/5880297-attestering',
  en: 'https://help.mynt.com/en/articles/5880297-expense-review',
  fi: 'https://help.mynt.com/en/articles/5880297-expense-review'
}

export const BULK_EXPORT_HELP_LINK = {
  en: 'https://help.mynt.com/en/articles/6209234-bulk-export',
  sv: 'https://help.mynt.com/sv/articles/6209234-bulk-export',
  fi: 'https://help.mynt.com/en/articles/6209234-bulk-export'
}

//Dashboard articles links

export const DASHBOARD_OVERVIEW_ARTICLE_STEP_BY_STEP = {
  en: 'https://help.mynt.com/en/articles/6240593-step-by-step-visual-guide',
  sv: 'https://help.mynt.com/sv/articles/6240593-mynt-step-by-step-visuell-guide',
  fi: 'https://help.mynt.com/fi/articles/6240593-nain-paaset-alkuun'
}

export const DASHBOARD_OVERVIEW_ARTICLE_FAQ = {
  en: 'https://help.mynt.com/en/',
  sv: 'https://help.mynt.com/sv/',
  fi: 'https://help.mynt.com/fi/'
}

export const DASHBOARD_OVERVIEW_ARTICLE_FIRST_PURCHASE = {
  en: 'https://help.mynt.com/en/articles/5689569-make-your-first-purchase',
  sv: 'https://help.mynt.com/sv/articles/5689569-forsta-kop-med-kortet',
  fi: 'https://help.mynt.com/fi/articles/5689569-kortin-aktivointi'
}

export const DASHBOARD_OVERVIEW_ARTICLE_START_USING_MOBILE_APP = {
  en: 'https://help.mynt.com/en/articles/5689573-start-using-the-mobile-app',
  sv: 'https://help.mynt.com/sv/articles/5689573-mynt-i-mobilen',
  fi: 'https://help.mynt.com/fi/articles/5689573-aloita-mynt-apin-kaytto'
}

export const DASHBOARD_OVERVIEW_ARTICLE_EXPENSE_REVIEW = {
  en: 'https://help.mynt.com/en/articles/5880297-expense-review',
  sv: 'https://help.mynt.com/sv/articles/5880297-attestering',
  fi: 'https://help.mynt.com/en/articles/5880297-expense-review'
}

export const DASHBOARD_OVERVIEW_ARTICLE_BULK_EXPORT = {
  en: 'https://help.mynt.com/en/articles/6209234-bulk-export',
  sv: 'https://help.mynt.com/sv/articles/6209234-bulk-export',
  fi: 'https://help.mynt.com/en/articles/6209234-bulk-export'
}

export const DASHBOARD_OVERVIEW_ARTICLE_CREDIT_ON_YOUR_ACCOUNT = {
  en: 'https://help.mynt.com/en/articles/5810738-credit-on-your-mynt-account',
  sv: 'https://help.mynt.com/sv/articles/5810738-kredit-pa-ert-myntkonto',
  fi: 'https://help.mynt.com/fi/articles/5810738-kortin-luotto'
}

export const DASHBOARD_OVERVIEW_ARTICLE_ADD_MEMBERS_AND_ORDER_CARDS = {
  en: 'https://help.mynt.com/en/articles/5335620-add-members-and-order-cards',
  sv: 'https://help.mynt.com/sv/articles/5335620-lagg-till-anvandare-och-bestall-kort',
  fi: 'https://help.mynt.com/en/articles/5335620-add-members-and-order-cards'
}

export const DOCUMENTS = {
  TERMS: {
    [sv]: 'https://static.mynt.com/documents/mynt-anvandarvillkor.pdf',
    [en]: 'https://static.mynt.com/documents/mynt-terms-and-conditions-of-use.pdf',
    [fi]: 'https://static.mynt.com/documents/mynt-terms-and-conditions-of-use.pdf'
  },
  PRIVACY: {
    [sv]: 'https://static.mynt.com/documents/mynt-integritetspolicy.pdf',
    [en]: 'https://static.mynt.com/documents/mynt-integrity-and-privacy-policy.pdf',
    [fi]: 'https://static.mynt.com/documents/mynt-integrity-and-privacy-policy.pdf'
  }
}

export const SEPA_DIRECT_DEBIT_INSTRUCTIONS_FI = 'https://static.mynt.com/documents/finland-sepa-dd-instructions.pdf'

/* LINKS - END */

export const HIDE_DEFAULT_TEAM_NAME = 'No team assignment'

export const FB_LANDING_HOWMYNTWORKS_VIDEO = 'https://www.youtube.com/embed/GyJioxjX1us?rel=0'
export const ANTILOOP_FORETAGSKORT_VIDEO = 'https://player.vimeo.com/video/644301337'
export const PEACCOUNTING_TOP_VIDEO = 'https://player.vimeo.com/video/650239600?h=139ec475eb'
export const PEACCOUNTING_BOTTOM_VIDEO = 'https://player.vimeo.com/video/643840056?h=92aac3badf'

export const EAGLE_TODO_TRANSACTIONS_FROMDATE = '2022-01-01'

export const MAX_VIRTUAL_CARDS = 20

export const CARDS_CAROUSEL_HEIGHT = 270
export const CARD_CAROUSEL_HEIGHT = 230

// used for antillop conversation
export const SCRAMBLE_KEY = 'SS_'

export const BANK_DATA_CONTENT_TYPES_ACCEPTED = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf']
export const BANK_DATA_LIMIT_FILE_SIZE = 52428800
export const BANK_DATA_LIMIT_FILES_TOTAL = 10

export const TRANSACTION_RECEIPT_CONTENT_TYPES_ACCEPTED = BANK_DATA_CONTENT_TYPES_ACCEPTED

export const MYNT_FAILED_AUTO_TOPUP = 'https://help.mynt.com/en/articles/6988459-failed-auto-top-up'

export const MYNT_LOW_BALANCE_NOTIFICATIONS = {
  en: 'https://help.mynt.com/en/articles/6704361-low-balance-notification-with-card-credit',
  sv: 'https://help.mynt.com/sv/articles/6704361-lagt-saldo-notiser-med-kortkredit',
  fi: 'https://help.mynt.com/fi/articles/6704361'
}

export const VAT_FRACTIONS = {
  6: 0.06,
  12: 0.12,
  25: 0.25
}

export const BO_DEFAULT_SUBSCRIPTION_FEE = 499
export const BO_DEFAULT_SUBSCRIPTION_FEE_VAT_VALUE = 125
export const BO_DEFAULT_SUBSCRIPTION_FEE_VAT_FRACTION = VAT_FRACTIONS[25]

// Used in EasterEggHelpers.tsx
export const EASTER_START_DATE = '2023-04-07'
export const EASTER_END_DATE = '2023-04-10'

// user for midsommar strawberry icon
export const MIDSOMMAR_START_DATE = '2023-06-20'
export const MIDSOMMAR_END_DATE = '2023-06-25'

export const HALLOWEEN_START_DATE = '2023-10-25'
export const HALLOWEEN_END_DATE = '2023-11-05'

export const GAZELLE_BORDER_YELLOW = 'rgba(255, 196, 83, 0.33)'
