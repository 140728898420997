import { useAppState } from '../../contexts/AppStateContext'
import { Languages } from '../config/ConfigLanguages'
import { Language } from '../../tiger/interfaces/Antiloop'

export function getInitialLanguage(defaultLanguage: Languages | Language, deviceLanguage?: string) {
  // Set default language
  let initialLanguage: Languages | Language = defaultLanguage

  // Allow use of device language if supported
  if (deviceLanguage) {
    const languagePreference = deviceLanguage.substring(0, 2)
    if (Object.values(Languages).includes(languagePreference as Languages)) {
      initialLanguage = languagePreference as Languages | Language
    }
  }

  return initialLanguage as Language
}

export default function useLanguage() {
  return useAppState().state.language
}
