import { useQueryClient } from '@tanstack/react-query'
import { FormValues } from '../components/ModalManuallyOrderCard'
import { ModalEnablePlatinumAccount, ModalManuallyOrderCard } from '../components/Modals'
import Spacings from 'figma/tokens/Spacings'
import FigmaBox from 'mynt-components/components/FigmaBox'
import React, { useMemo, useState } from 'react'
import { TextKeys } from 'tiger/libs/TextRepository'
import { CARDS, CARDS_V2, CORPORATE_CARDS, PRIVATE_CARDS } from '../api/react-query/keys'
import {
  BackOfficeAccountGetDto,
  BackOfficeCustomerDtoV2,
  BackOfficeOrderCardRequestDto,
  CardStatus,
  CardStatusPatch,
  EnfuceAccountType
} from '../api/swagger/definitions/backoffice'
import { useCardsV2, useCreateCardMutation, usePatchCardIdInPayload, usePatchCustomer, usePrivateCorporateCards } from '../api/react-query'
import useMaterialNotification from '../hooks/useMaterialNotification'
import MyntLoader from '../mynt-components/components/MyntLoader'
import { When } from '../mynt-components/components/When'
import CustomerCardTabAccountType from './CustomerCardTabAccountType'
import CustomerCardTabOverviewAccount from './CustomerCardTabOverviewAccount'
import CustomerCardTabOverviewCorporateCustomer from './CustomerCardTabOverviewCorporateCustomer'
import CustomerCardTabOverviewSingleCardDetails from './CustomerCardTabOverviewSingleCardDetails'
import TextContainer from './TextContainer'
import { Card, CardOverviewTable } from '../pages/CardsOverview'
import { DeleteCardsModal } from '../pages/CardsOverview/DeleteCardsModal'
import { Access, useIsLoadingWithPermission, usePermissions } from 'contexts/permissions'
import Button from '@mui/material/Button'
import { CoreMyntModal } from './modals/CoreMyntModal'
import Box from '@mui/material/Box'
import { useNotesContext } from 'contexts/Notes'

interface CustomerCardTabOverviewProps {
  customer: BackOfficeCustomerDtoV2
  currentCardAccount?: BackOfficeAccountGetDto
  cardAccounts?: BackOfficeAccountGetDto[]
  setCurrentCardAccount: React.Dispatch<React.SetStateAction<BackOfficeAccountGetDto | undefined>>
  setIsChangeStatusModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomerCardTabOverview: React.FC<CustomerCardTabOverviewProps> = ({
  customer,
  currentCardAccount,
  cardAccounts,
  setCurrentCardAccount,
  setIsChangeStatusModalOpen
}) => {
  const notify = useMaterialNotification()
  const queryClient = useQueryClient()

  const [showEnablePlatinumModal, setShowEnablePlatinumModal] = useState(false)
  const [enablePlatinumLoading, setEnablePlatinumLoading] = useState(false)
  const [showOrderCardModal, setShowOrderCardModal] = useState(false)
  const [orderCardLoading, setOrderCardLoading] = useState(false)
  const [selectedCardId, setSelectedCardId] = useState<string>()
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [showDeleteCardsModal, setShowDeleteCardsModal] = useState(false)
  const [notesContext] = useNotesContext()

  const permissions = {
    overview: usePermissions('customers.cards.overview.view'),
    table: usePermissions('customers.cards.overview.table'),
    enfuce: usePermissions('customers.cards.overview.enfuce')
  }

  const mutation = useCreateCardMutation(customer.id)
  const mutationEnablePlatinumAccount = usePatchCustomer(customer.id)

  const { data: corporateCards } = usePrivateCorporateCards(customer.id, {
    retryOnMount: false,
    enabled: permissions.enfuce
  })

  const { data: cards, isLoading: isLoadingCards } = useCardsV2(customer.id, {
    retryOnMount: false,
    enabled: permissions.overview
  })

  const selectedCard = useMemo(() => {
    if (!cards) return undefined

    return cards.find((card) => card.id === selectedCardId)
  }, [cards, selectedCardId])

  const cardMutation = usePatchCardIdInPayload(customer.id)

  const isLoading = useIsLoadingWithPermission(isLoadingCards, 'customers.cards.overview.view')

  const handleRowOpen = (card: Card) => {
    setSelectedCardId(card.id)
  }

  const handleCardSelect = (selectedCardIds: string[]) => {
    setSelectedRows(selectedCardIds)
  }

  const mappedCards = useMemo<Card[]>(() => {
    if (!cards) return []

    return cards.map((card) => ({
      id: card.id,
      externalId: card.externalId ?? '',
      number: card.lastFourDigits,
      status: card.status,
      type: card.type,
      personId: card.personId,
      address: card.address?.address1 || '',
      owner: card.personName === 'NON-EXISTING PERSON' ? '' : card.personName,
      singleAmountLimit: card.singleAmountLimit
    }))
  }, [cards])

  const internalIdToExternalIdMap = useMemo<Record<string, string>>(() => {
    if (!cards) return {}

    return cards?.reduce((acc, card) => ({ ...acc, [card.id]: card.externalId as string }), {})
  }, [cards])

  const handleOrderCard = (data: FormValues) => {
    const CardRequestData: BackOfficeOrderCardRequestDto = {
      address: {
        country: data.country,
        address1: data.address1,
        address2: data.address2,
        areaCode: data.areaCode,
        city: data.city
      },
      companyName: data.companyName,
      personId: data.personId
    }

    setOrderCardLoading(true)

    const onSuccess = () => {
      queryClient.invalidateQueries([CARDS, customer.id])
      queryClient.invalidateQueries([PRIVATE_CARDS, customer.id])
      queryClient.invalidateQueries([CORPORATE_CARDS, customer.id])
      notify(`Ordered card for ${data.fullName} at ${data.companyName}`)
    }

    const onComplete = () => {
      setOrderCardLoading(false)
      setShowOrderCardModal(false)
    }

    mutation.mutateAsync(CardRequestData).then(onSuccess).finally(onComplete)
  }

  const handleEnablePremium = () => {
    const EnablePlatinumRequestData: Partial<BackOfficeCustomerDtoV2> = {
      accountType: EnfuceAccountType.PLATINUM
    }
    setEnablePlatinumLoading(true)

    const onSuccess = () => notify(`Enable Platinum migration`)

    const onComplete = () => {
      setEnablePlatinumLoading(false)
      setShowEnablePlatinumModal(false)
    }

    mutationEnablePlatinumAccount.mutateAsync(EnablePlatinumRequestData).then(onSuccess).finally(onComplete)
  }

  const handleCopyCardUserInfo = (rows: Card[]) => {
    const formatted = rows.reduce((acc, row) => acc + `${row.owner} ${row.number}\n`, '')

    window.navigator.clipboard.writeText(formatted)
    notify(`Copied ${rows.length} users with card number to clipboard`)
  }

  if (isLoading) {
    return <MyntLoader fullWidth />
  }

  if (!currentCardAccount && permissions.enfuce) {
    return <h1>The customer does not have any card/prepaid/corporate accounts</h1>
  }

  return (
    <>
      <FigmaBox fullWidth gap={Spacings.medium} bottom={Spacings.medium}>
        <FigmaBox fullWidth direction="row" justify="flex-start">
          <When is={cardAccounts}>
            <FigmaBox fullWidth direction="row" align={'center'} gap={Spacings.min}>
              <TextContainer textKey={TextKeys.customersCardOverviewAccountTypeDropdownLabel} />
              <CustomerCardTabAccountType
                currentCardAccount={currentCardAccount as BackOfficeAccountGetDto}
                cardAccounts={cardAccounts as BackOfficeAccountGetDto[]}
                setCurrentCardAccount={setCurrentCardAccount}
              />
            </FigmaBox>
          </When>
        </FigmaBox>
        <Access permissions="customers.cards.overview.enfuce">
          <FigmaBox fullWidth direction="row" align="stretch" gap={Spacings.medium}>
            {corporateCards ? <CustomerCardTabOverviewCorporateCustomer corporateCustomer={corporateCards} /> : <MyntLoader />}
            <CustomerCardTabOverviewAccount
              cardAccount={currentCardAccount as BackOfficeAccountGetDto}
              customerId={customer.id}
              setIsChangeStatusModalOpen={setIsChangeStatusModalOpen}
            />
          </FigmaBox>
        </Access>
        <DeleteCardsModal
          endpoint={async (id) => cardMutation.mutateAsync({ id, status: CardStatusPatch.CARD_CLOSED })}
          cards={selectedRows.map((id) => internalIdToExternalIdMap[id])}
          open={showDeleteCardsModal}
          onClose={() => setShowDeleteCardsModal(false)}
          onDeleted={() => {
            setTimeout(() => {
              queryClient.invalidateQueries([CARDS_V2, customer.id])
              queryClient.invalidateQueries([PRIVATE_CARDS, customer.id])
            }, 1000)

            setShowDeleteCardsModal(false)
            setSelectedRows([])

            notify(`Terminated ${selectedRows.length} cards`)
          }}
        />
        <FigmaBox fullWidth>
          <FigmaBox spacing={Spacings.tiny} bottom direction="row" align="baseline" justify="space-between" fullWidth>
            <h4>Cards</h4>
            <Access permissions="customers.cards.overview.order">
              <Button onClick={() => setShowOrderCardModal(true)} variant="primary">
                Order new card
              </Button>
            </Access>
          </FigmaBox>
        </FigmaBox>
        <Box sx={{ maxWidth: notesContext.open ? '75vw' : '93vw' }}>
          <CardOverviewTable
            cards={mappedCards}
            onRowOpen={handleRowOpen}
            onUserInfoCopy={handleCopyCardUserInfo}
            onRowSelect={handleCardSelect}
            onDelete={() => {
              setShowDeleteCardsModal(true)
            }}
            selectedRows={selectedRows}
            initialFilters={[{ field: 'status', value: [CardStatus.OK] }]}
          />
        </Box>
      </FigmaBox>

      <ModalManuallyOrderCard
        loading={orderCardLoading}
        customerId={customer.id}
        visible={showOrderCardModal}
        onClose={() => setShowOrderCardModal(false)}
        onSubmit={handleOrderCard}
      />

      <ModalEnablePlatinumAccount
        visible={showEnablePlatinumModal}
        loading={enablePlatinumLoading}
        customerId={customer.id}
        onClose={() => setShowEnablePlatinumModal(false)}
        onSubmit={handleEnablePremium}
      />

      <CoreMyntModal
        title={`${selectedCard?.personName} - ${selectedCard?.lastFourDigits}`}
        open={!!selectedCardId}
        onClose={() => setSelectedCardId(undefined)}
        actions={() => null}
        wrapperProps={{ sx: { maxWidth: '1200px', width: '80vw' } }}
      >
        <>
          {selectedCard && (
            <CustomerCardTabOverviewSingleCardDetails
              card={{
                id: selectedCard.externalId as string,
                internalId: selectedCard.id,
                singleAmountLimit: selectedCard.singleAmountLimit
              }}
              customerId={customer.id}
            />
          )}
        </>
      </CoreMyntModal>
    </>
  )
}

export default CustomerCardTabOverview
