import styled from '@emotion/styled'
import Header from '../components/Header'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import Spacings from '../figma/tokens/Spacings'
import { SentryInit } from '../helpers/sentryHelpers'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { BO_LAYOUT_SIZE } from '../mynt-components/WeakHardCodedSizes'
import { loginRoutes } from './loginRoutes'
import { protectedRoutes } from './protectedRoutes'
import { hasPermission, usePermissions, useRolePermissionsContext } from 'contexts/permissions'
import { useSessionRole } from 'contexts/session'
import useMaterialNotification from 'hooks/useMaterialNotification'
import { INBOX_PATH } from './constants'
import { useEffect } from 'react'

const RedirectToStart = () => {
  const notify = useMaterialNotification()
  const navigate = useNavigate()

  useEffect(() => {
    notify('You do not have permission to access this page', 'error')
    navigate('/start')
  }, [])

  return null
}

const Router = () => {
  const rolePermissions = useRolePermissionsContext()
  const { role, isLoading } = useSessionRole()

  return (
    <BrowserRouter>
      <SentryInit />
      <Routes>
        {loginRoutes.map(({ component: Component, path }) => (
          <Route
            path={path}
            key={path}
            element={
              <>
                <Header />
                <Component />
              </>
            }
          />
        ))}
        {protectedRoutes.map(({ component: Component, path, fullScreenWidth = false, permissions = [] }) => {
          // console.log(path, rolePermissions, role, permissions, hasPermission(rolePermissions, role, permissions))
          if (!hasPermission(rolePermissions, role, permissions)) {
            if (isLoading) return null

            return <Route path={path} key={path} element={<RedirectToStart />} />
          }

          return (
            <Route
              path={path}
              key={path}
              element={
                <>
                  <Header />
                  <Layout fullScreenWidth={fullScreenWidth}>
                    <Component />
                  </Layout>
                </>
              }
            />
          )
        })}
      </Routes>
    </BrowserRouter>
  )
}

const Layout = styled(FigmaBox)<{ fullScreenWidth?: boolean }>`
  width: 100%;
  ${({ fullScreenWidth }) => (fullScreenWidth ? `` : `max-width: ${BO_LAYOUT_SIZE}px;`)}
  ${({ fullScreenWidth }) => (fullScreenWidth ? `` : `padding: 0 ${Spacings.small};`)}
  ${({ fullScreenWidth }) => (fullScreenWidth ? `` : `margin: 0 auto;`)}
`

export default Router
