import { useAppState } from '../../contexts/AppStateContext'
import { Currencies } from '../config/ConfigCurrencies'

export function getInitialCurrency(defaultCurrency: Currencies) {
  // Allow setting of preferred currency for every app
  const initialCurrency: Currencies = defaultCurrency

  return initialCurrency
}

export default function useCurrency() {
  return useAppState().state.currency
}
