/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Partner = {
    PE_ACCOUNTING: 'PE_ACCOUNTING',
    MYNT_STANDALONE: 'MYNT_STANDALONE',
    BROKER: 'BROKER'
} as const;

export type Partner = typeof Partner[keyof typeof Partner];



