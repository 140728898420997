/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const FileContext = {
    KYC: 'KYC',
    KYC_REPORT: 'KYC_REPORT',
    SCORING: 'SCORING',
    AGREEMENT: 'AGREEMENT',
    MANUAL_UPLOAD_INVOICE: 'MANUAL_UPLOAD_INVOICE',
    PAY_FINAL_INVOICE: 'PAY_FINAL_INVOICE'
} as const;

export type FileContext = typeof FileContext[keyof typeof FileContext];



