import MaterialTable, { MTableToolbar } from '@material-table/core'
import FigmaBox from 'mynt-components/components/FigmaBox'
import React, { useRef, useState } from 'react'
import { AccountDto } from '../api/swagger/definitions/backoffice'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import useLanguage from '../flamingo/hooks/useLanguage'
import { getAccountsTableActions } from '../helpers/AccountingTablesHelper'
import { useAccountingAccounts, useAccountingYears, useDeleteAccountingAccount } from '../api/react-query'
import { GetTableOptions } from '../helpers/getTableOptions'
import { captureException } from '../helpers/sentryHelpers'
import MyntPopper from '../mynt-components/components/MyntPopper'
import { TextKeys, getText } from '../tiger/libs/TextRepository'
import { AccountFormTypes } from './ModalAccountingAccount'
import { ModalAccountingAccount, ModalConfirmDialog } from './Modals'
import TableWrapper from './TableWrapper'

const AccountsTable: React.FC = () => {
  const language = useLanguage()
  const [accountValues, setAccountValues] = useState<AccountDto>()
  const [isAccountModalOpen, setIsAccountModalOpen] = useState<boolean>(false)
  const [isAccountDeleteModalOpen, setIsAccountDeleteModalOpen] = useState<boolean>(false)
  const divRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(divRef.current)

  const { data: years = [] } = useAccountingYears({
    refetchOnWindowFocus: false
  })
  const { data: accounts = [], isLoading: accountsLoading } = useAccountingAccounts(undefined, {
    refetchOnWindowFocus: false
  })

  const deleteAccountingAccountMutation = useDeleteAccountingAccount()

  const handleDeleteClick = (account: AccountDto) => {
    setIsAccountDeleteModalOpen(true)
    setAccountValues(account)
  }

  const handleEditClick = (account: AccountDto) => {
    setIsAccountModalOpen(true)
    setAccountValues(account)
  }

  const handleDeleteAccept = async () => {
    handleClearModal()

    deleteAccountingAccountMutation.mutateAsync({ id: accountValues?.id as string }).catch((err) => captureException(err))
  }

  const handleClearModal = () => {
    setIsAccountDeleteModalOpen(false)
    setAccountValues(undefined)
  }

  return (
    <>
      {isAccountModalOpen && (
        <ModalAccountingAccount
          isOpen={isAccountModalOpen}
          type={accountValues ? AccountFormTypes.EDIT : AccountFormTypes.CREATE}
          initialValues={accountValues}
          years={years}
          onModalCancel={() => {
            setIsAccountModalOpen(false)
            setAccountValues(undefined)
          }}
        />
      )}
      <MyntPopper
        visible={isAccountDeleteModalOpen}
        noPadding
        marginBottom={Spacings.large}
        position="top"
        anchorRef={anchorEl}
        content={<ModalConfirmDialog onCancel={handleClearModal} onConfirm={handleDeleteAccept} />}
      />
      <TableWrapper>
        <MaterialTable
          components={{
            Toolbar: (props) => (
              <FigmaBox fullWidth direction={'row'} align={'center'} justify={'stretch'}>
                <FigmaBox fullWidth>
                  <MTableToolbar {...props} />
                </FigmaBox>
              </FigmaBox>
            )
          }}
          columns={[
            {
              title: getText(TextKeys.editAccountNameLabel, language),
              field: 'name'
            },
            {
              title: getText(TextKeys.editAccountNumberLabel, language),
              field: 'no',
              defaultSort: 'asc'
            }
          ]}
          data={accounts}
          isLoading={accountsLoading}
          title="Accounts"
          actions={getAccountsTableActions(handleEditClick, handleDeleteClick, setAnchorEl) as any}
          options={GetTableOptions({
            headerTextKey: TextKeys.accountingPaymentsOCR,
            extraOptions: {
              filtering: false,
              headerStyle: { backgroundColor: ColorsDeprecated.surfaceOnWhite }
            }
          })}
        />
      </TableWrapper>
    </>
  )
}

export default AccountsTable
