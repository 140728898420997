/* eslint-disable prettier/prettier */ // Prettier is messing with the curried handleDateAccept method, always gives an error after formatting

import styled from '@emotion/styled'
import { colors } from 'themes'
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'

type DateRange = {
  from: string
  to: string
}

export type Filters = {
  text: string
  state: string
  invoiceDate: DateRange
  dueDate: DateRange
  period: DateRange
  hasPaid: boolean
  currency: string
}

export type SelectOption = { value: string; label: string }

export type RollingCreditFiltersProps = {
  filterValues?: Partial<Filters>
  onFilterChange: (filers: Partial<Filters>) => void
  dateFormat?: string
  options: {
    currency: SelectOption[]
    status: SelectOption[]
  }
}

const ClearButton = ({ hidden, onClick }: { hidden?: boolean, onClick?: () => void }) => {
  if (hidden) return null

  return <IconButton onClick={onClick} sx={{ marginRight: 1 }}><ClearIcon sx={{ width: 24, height: 24 }} /></IconButton>
}

const parseHasPaidValue = (hasPaid?: boolean) => {
  if (hasPaid === undefined) return
  if (hasPaid) return 1

  return 0
}

type MaterialDateAccept = [dayjs.Dayjs | null, dayjs.Dayjs | null]

const parseDates = (date: string | undefined) => (date ? dayjs(date) : null)

export const RollingCreditFilters = ({ filterValues, options, onFilterChange, dateFormat }: RollingCreditFiltersProps) => {
  const [filters, setFilters] = useState(filterValues ?? {})

  const handleChange = (newState: Partial<Filters>) => setFilters((currentFilters) => ({ ...currentFilters, ...newState }))

  const handleDateAccept = (key: keyof Filters) => ([from, to]: MaterialDateAccept) => {
    if (from && to) handleChange({ [key]: { from: from.format('YYYY-MM-DD'), to: to.format('YYYY-MM-DD') } })
  }

  const handleDateClear = (key: keyof Filters) => (dates: MaterialDateAccept) => {
    if (dates.every(date => date === null)) handleChange({ [key]: undefined })
  } 

  useEffect(() => {
    onFilterChange(filters)
  }, [filters])

  useEffect(() => {
    if (filterValues) setFilters(filterValues)
  }, [filterValues])

  return (
    <BorderedContainer>
      <Header>
        <ColumnContainer>
          <Typography>Rolling credit filters</Typography>
        </ColumnContainer>
        <ColumnContainer>
        </ColumnContainer>
      </Header>
      <RowContainer>
        <TextField fullWidth data-testid="text-search" onChange={event => handleChange({ text: event.target.value })} label='Search on filtered results...' />
      </RowContainer>
      <RowContainer>
        <ColumnContainer>
          <FormControl>
            <InputLabel id="Status">Status</InputLabel>
            <Select
              data-testid="status"
              value={filters?.state ?? ''}
              onChange={(e) => handleChange({ state: e.target.value })}
              style={{ minWidth: 150 }}
              labelId="Status"
              label="Status"
              IconComponent={() => <ClearButton onClick={() => handleChange({ state: undefined })} hidden={!Boolean(filters?.state)} />}
            >
              {options.status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ColumnContainer>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='sv'>
          <ColumnContainer>
            <DateRangePicker
              format={dateFormat}
              value={[parseDates(filters?.invoiceDate?.from), parseDates(filters?.invoiceDate?.to)]}
              onAccept={handleDateAccept('invoiceDate')}
              onChange={handleDateClear('invoiceDate')}
              label="Invoice date"
              slotProps={{ actionBar: { actions: ['clear'] }, textField: { style: { minWidth: 275 } }, field: { 'data-testid': 'invoice-date' } as any }}
              slots={{ field: SingleInputDateRangeField }}
            />
          </ColumnContainer>
          <ColumnContainer>
            <DateRangePicker
              format={dateFormat}
              value={[parseDates(filters?.dueDate?.from), parseDates(filters?.dueDate?.to)]}
              onAccept={handleDateAccept('dueDate')}
              onChange={handleDateClear('dueDate')}
              label="Due date"
              slotProps={{ actionBar: { actions: ['clear'] }, textField: { style: { minWidth: 275 } }, field: { 'data-testid': 'due-date' } as any }}
              slots={{ field: SingleInputDateRangeField }}
            />
          </ColumnContainer>
          <ColumnContainer>
            <DateRangePicker
              format={dateFormat}
              value={[parseDates(filters?.period?.from), parseDates(filters?.period?.to)]}
              onAccept={handleDateAccept('period')}
              onChange={handleDateClear('period')}
              label="Period"
              slotProps={{ actionBar: { actions: ['clear'] }, textField: { style: { minWidth: 275 } }, field: { 'data-testid': 'period' } as any }}
              slots={{ field: SingleInputDateRangeField }}
            />
          </ColumnContainer>
        </LocalizationProvider>
        <ColumnContainer>
          <FormControl>
            <InputLabel id="currency">Currency</InputLabel>
            <Select
              data-testid="currency"
              value={filters?.currency ?? ''}
              onChange={(e) => handleChange({ currency: e.target.value })}
              style={{ minWidth: 150 }}
              labelId="currency"
              label="Currency"
              IconComponent={() =>
                <ClearButton
                  onClick={() => handleChange({ currency: undefined })}
                  hidden={!Boolean(filters?.currency)}
                />
              }
            >
              {options.currency.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ColumnContainer>
        <ColumnContainer>
        <FormControl>
            <InputLabel id="has-paid">Has paid</InputLabel>
            <Select
              data-testid="has-paid"
              value={parseHasPaidValue(filters.hasPaid)}
              onChange={(e) => handleChange({ hasPaid: Boolean(e.target.value) })}
              style={{ minWidth: 150 }}
              labelId="has-paid"
              label="has-paid"
              IconComponent={() => 
                <ClearButton
                  onClick={() => handleChange({ hasPaid: undefined })}
                  hidden={filters?.hasPaid === undefined}
                />
              }
            >
              <MenuItem value={1}>true</MenuItem>
              <MenuItem value={0}>false</MenuItem>
            </Select>
          </FormControl>
        </ColumnContainer>
      </RowContainer>
    </BorderedContainer>
  )
}

const BorderedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 24px;
  border: 1px solid ${colors.product.grey[400]};
  border-radius: 8px;
`

const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
