import styled from '@emotion/styled'
import React from 'react'
import { AntiloopImageType } from '../../tiger/interfaces/Antiloop'
import useImageVariant from '../hooks/useImageVariant'
import FigmaImage from './FigmaImage'

const ImageContainer: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      containerStyle?: React.CSSProperties
      imageStyle?: React.CSSProperties
      imageKey: AntiloopImageType
      fullWidth?: boolean
      minHeight?: boolean
      className?: string
      imageProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
    }>
  >
> = ({ containerStyle, imageKey, fullWidth, imageStyle, minHeight, className, imageProps }) => {
  const variantStyles = useImageVariant(imageKey)

  if (!variantStyles) {
    return null
  }

  return (
    <Wrapper
      className={className}
      style={{
        ...(!!containerStyle ? containerStyle : {}),
        maxWidth: fullWidth ? '100%' : variantStyles.width,
        maxHeight: !minHeight ? variantStyles.height : '100%',
        minHeight: minHeight ? variantStyles.height : '100%'
      }}
    >
      {imageKey && <FigmaImage imageKey={imageKey} imageStyle={imageStyle} imageProps={imageProps} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
`

export default ImageContainer
