import Switch, { SwitchProps } from '@mui/material/Switch'
import React from 'react'
import Colors from '../../figma/panda/Colors'
import Durations from '../../figma/tokens/Durations'
import { MUI_SWITCH_BASE_PX } from '../StrongHardCodedSizes'

const MaterialSwitchExperimental: React.FC<React.PropsWithChildren<React.PropsWithChildren<SwitchProps>>> = (props) => (
  <Switch
    sx={(theme) => ({
      width: MUI_SWITCH_BASE_PX + 20,
      height: MUI_SWITCH_BASE_PX + 4,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: `${Durations.medium}ms`,
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: `${Colors.baseWhite} !important`,
          opacity: 1,
          '& + .MuiSwitch-track': {
            backgroundColor: `${Colors.bluePrimary600Cta} !important`,
            opacity: 1,
            border: 0
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: `${Colors.base300} !important`,
            opacity: 1
          }
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: `${Colors.base300} !important`,
          opacity: 1
        }
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: MUI_SWITCH_BASE_PX,
        height: MUI_SWITCH_BASE_PX
      },
      '& .MuiSwitch-track': {
        border: `1px solid ${Colors.base300} !important`,
        borderRadius: (MUI_SWITCH_BASE_PX + 4) / 2,
        backgroundColor: `${Colors.baseWhite} !important`,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500
        })
      }
    })}
    type="checkbox"
    size="small"
    disableRipple
    value={props.checked}
    checked={props.checked}
    {...props}
  />
)

export default MaterialSwitchExperimental
