import Person from 'interfaces/person'
import React from 'react'
import { colors } from 'themes'
import Box from '@mui/material/Box'

interface IProps {
  showQuotes: boolean
  signatories: Person[]
  handleOnDocumentClick: () => void
  handleOnQuoteUrlClick: (ssn: string, personId: string) => ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined
}

const CreditAgreements: React.FC<IProps> = ({ signatories, handleOnDocumentClick, handleOnQuoteUrlClick, showQuotes }) => (
  <div style={{ fontSize: 16, fontFamily: 'Inter', fontWeight: 500 }}>
    <button
      style={{
        border: 0,
        background: 'transparent',
        color: colors.product.blue[700],
        cursor: 'pointer',
        marginBottom: 20,
        paddingLeft: 0,
        fontWeight: 500
      }}
      type="button"
      onClick={handleOnDocumentClick}
    >
      Get document (new tab)
    </button>

    {showQuotes && (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {signatories.map(({ fullName, ssn, id }) => (
          <Box key={id} sx={{ width: '100%' }}>
            {fullName}, {ssn}{' '}
            <button
              style={{
                border: 0,
                background: 'transparent',
                color: colors.product.blue[700],
                cursor: 'pointer',
                fontWeight: 500
              }}
              type="button"
              onClick={handleOnQuoteUrlClick(ssn, id)}
            >
              Copy quote URL
            </button>
          </Box>
        ))}
      </Box>
    )}
  </div>
)

export default CreditAgreements
