// used in BO Credit On Card
export const CREDIT_REQUEST_WIDTH = 390

// this is the size Jakob uses in Figma for his layoyt max-size
// STRONG
export const PAGE_CONTENT_SIZE = '908px'

// FIGMA BUTTON HEIGHT, STRONG MUI THEME
export const BUTTON_HEIGHT_PX = '48px'

// FIGMA BUTTON MOBILE, STRONG
export const BUTTON_HEIGHT_MOBILE = '40px'

// USED IN CardAccount & CardTeam
export const TAB_PANEL_ITEM_HEIGHT_PX = '3px'

// USED IN cardTEAM & cardDetails
export const SIDE_PANEL_MAX_WIDTH_PX = '415px'

// USED IN MaterialGenericSlider
export const SLIDER_BASE_PX = 9

// USED IN MaterialSwitch
export const MUI_SWITCH_BASE_PX = 22

// USED in StyledComponents
export const DEFAULT_LINE_COMPONENT_SIZE = '1px'

// BO MODAL SIZE
export const MODAL_SIZE_WIDTH_PX = '345px'
