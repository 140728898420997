import { useEffect, useState } from 'react'
import useIsBrowser from './useIsBrowser'

export const useActiveTabKey = (tabSetName: string, defaultTabKey: string): [string, (activeTab: string) => void] => {
  let storedTabsSetValue
  const isBrowser = useIsBrowser()

  if (isBrowser) storedTabsSetValue = window.localStorage.getItem(tabSetName)

  const [tabKey, setTabKey] = useState<string>(storedTabsSetValue || defaultTabKey)

  useEffect(() => {
    if (isBrowser) window.localStorage.setItem(tabSetName.toString(), tabKey)
  }, [tabKey])

  return [tabKey, setTabKey]
}

export default useActiveTabKey
