import { IInboxDeprecated } from '../interfaces/InboxInterface'

export const isNotSnoozed = (item: IInboxDeprecated) => {
  const { snoozeUntil } = item
  const today = new Date().toISOString().split('T')[0]

  if (today === snoozeUntil) {
    return true
  }

  return !snoozeUntil
}
