/* eslint-disable no-console */
import { ModalManuallyOrderCard } from '../components/Modals'

const SandBox = () => {
  const id = '9f27cda1-493b-43fc-a8df-ec79321404d5'

  console.log('yes')

  return (
    <ModalManuallyOrderCard
      customerId={id}
      onClose={() => console.log('we closed')}
      onSubmit={(...params) => console.log('submit', ...params)}
      visible={true}
    />
  )
}

export default SandBox
