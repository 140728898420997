import styled from '@emotion/styled'
import React from 'react'
import Spacings from '../../figma/tokens/Spacings'
import { useLanguage } from '../../hooks/useLanguage'
import { TextContainerProps } from '../../tiger/interfaces/Antiloop'
import { LinkStyles } from './FigmaBox'
import FigmaText from './FigmaText'

export type FigmaTextArrayProps = TextContainerProps & { link?: boolean; onClick?: (unknown) => void }

type Props = {
  propSets: FigmaTextArrayProps[]
  spacing?: string
}

const FigmaTextArray: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ propSets, spacing }: Props) => {
  const language = useLanguage()
  return (
    <div>
      {propSets.map((props, key) => (
        <span key={key} style={{ paddingRight: spacing || Spacings.minimum }}>
          {!props.link && <FigmaText {...props} element={Span} language={language} />}
          {props.link && <FigmaText {...props} element={(_props) => <Link onClick={props.onClick} {..._props} />} language={language} />}
        </span>
      ))}
    </div>
  )
}

const Span = styled.span``

const Link = styled.span`
  ${LinkStyles}
`

export default FigmaTextArray
