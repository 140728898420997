/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ApprovalStatus = {
    MANUALLY_APPROVED: 'MANUALLY_APPROVED',
    MANUALLY_DENIED: 'MANUALLY_DENIED',
    AUTO_APPROVED: 'AUTO_APPROVED',
    AUTO_DENIED: 'AUTO_DENIED',
    PENDING: 'PENDING'
} as const;

export type ApprovalStatus = typeof ApprovalStatus[keyof typeof ApprovalStatus];



