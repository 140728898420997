import { Options } from '@material-table/core'
import useTextVariant from '../mynt-components/hooks/useTextVariant'
import { AntiloopTextType } from '../tiger/interfaces/Antiloop'
import { TextKeys } from '../tiger/libs/TextRepository'

type TableOptionsProps = {
  headerTextKey: AntiloopTextType
  extraOptions?: Options<any>
}

export const GetTableOptions = ({ headerTextKey, extraOptions }: TableOptionsProps): Options<any> => {
  const headerStyles = useTextVariant(headerTextKey).style

  const rowStyles = useTextVariant(TextKeys.tableValue).style

  const DEFAULT_OPTIONS = {
    pageSize: 50,
    thirdSortClick: false,
    pageSizeOptions: [20, 50, 100, 250, 500],
    actionsColumnIndex: -1,
    emptyRowsWhenPaging: false,
    headerStyle: {
      ...headerStyles
    },
    rowStyle: {
      ...rowStyles
    }
  }

  return { ...DEFAULT_OPTIONS, ...(!!extraOptions && extraOptions) }
}
