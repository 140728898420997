import MaterialTable from '@material-table/core'
import React, { useMemo, useReducer } from 'react'
import { VoucherDto } from '../api/swagger/definitions/backoffice'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import useLanguage from '../flamingo/hooks/useLanguage'
import { TabSets, VoucherTabs } from '../helpers/TabsHelpers'
import { GetTableOptions } from '../helpers/getTableOptions'
import { useAccounting } from '../hooks/useAccounting'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import Tabs from '../mynt-components/components/Tabs'
import { getText } from '../tiger/libs/TextRepository'
import TableWrapper from './TableWrapper'
import TextContainer from './TextContainer'
import VouchersFilters from './VouchersFilters'
import VouchersSummaryTable from './VouchersSummaryTable'

const VouchersTable: React.FC = () => {
  const language = useLanguage()

  const { years = [], vouchers = [], summary, vouchersLoading, summaryLoading, accountingFilters } = useAccounting()

  const [, forceRender] = useReducer((x) => x + 1, 0)
  const onTabChange = () => {
    forceRender()
  }

  const currencies = useMemo(() => {
    if (!summary) return []

    const currencySet = summary.reduce((acc, next) => {
      next.periods?.forEach((period) => {
        acc.add(period.currency)
      })

      return acc
    }, new Set<string>())

    return Array.from(currencySet)
  }, [summary])

  return (
    <>
      <FigmaBox fullWidth>
        <FigmaBox fullWidth direction="row" justify="space-between" align="flex-start" gap={Spacings.medium} bottom>
          <VouchersFilters currencies={currencies} years={years} accountingFilters={accountingFilters} />
        </FigmaBox>
        <Tabs
          fullPage
          enableNestedTabs
          alwaysShowNavHeadings
          onTabChange={onTabChange}
          tabKeyNameSpace={TabSets.ACCOUNTING_VOUCHERS}
          tabs={[
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={VoucherTabs.LIST} />,
              contentElement: (
                <TableWrapper>
                  <MaterialTable
                    columns={[
                      {
                        title: getText(TextKeys.voucherListAccountingYearLabel, language),
                        field: 'accountingYearId',
                        render: ({ accountingYearId }: VoucherDto) => {
                          const year = years?.find((y) => y.id === accountingYearId)
                          return year ? year.name : '-'
                        }
                      },
                      {
                        title: getText(TextKeys.voucherListAccountingDateLabel, language),
                        field: 'accountingDate',
                        defaultSort: 'desc'
                      },
                      {
                        title: getText(TextKeys.voucherListDescriptionLabel, language),
                        field: 'description'
                      }
                    ]}
                    data={vouchers}
                    isLoading={vouchersLoading}
                    title={getText(TextKeys.vouchersHeading, language)}
                    options={GetTableOptions({
                      headerTextKey: TextKeys.accountingPaymentsOCR,
                      extraOptions: {
                        filtering: false,
                        headerStyle: { backgroundColor: ColorsDeprecated.surfaceOnWhite }
                      }
                    })}
                  />
                </TableWrapper>
              ),
              enable: true
            },
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={VoucherTabs.SUMMARY} />,
              contentElement: <VouchersSummaryTable currencies={currencies} summary={summary || []} loading={summaryLoading} />,
              enable: true
            }
          ]}
        />
      </FigmaBox>
    </>
  )
}

export default VouchersTable
