// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const colors = {
  "baseGrey": "rgba(120, 120, 122, 1)",
  "baseBlack": "rgba(27, 28, 31, 1)",
  "stone": "rgba(216, 216, 218, 1)",
  "cloud": "rgba(251, 251, 252, 1)",
  "baseGarden": "rgba(42, 204, 136, 1)",
  "baseGreen": "rgba(105, 224, 174, 1)",
  "baseWhite": "rgba(255, 255, 255, 1)",
  "baseCritical": "rgba(216, 44, 13, 1)",
  "baseWarning": "rgba(255, 196, 83, 1)",
  "baseSuccess": "rgba(0, 128, 96, 1)",
  "backgroundGazelle": "rgba(248, 248, 247, 1)",
  "backgroundAntiloop": "rgba(251, 251, 252, 1)",
  "backgroundBlurred": "rgba(37, 37, 37, 0.34)",
  "surfaceOnWhite": "rgba(246, 246, 247, 1)",
  "textDefault": "rgba(32, 34, 35, 1)",
  "textSubdued": "rgba(120, 120, 122, 1)",
  "textDisabled": "rgba(216, 216, 218, 1)",
  "textLink": "rgba(13, 130, 81, 1)",
  "textLinkHover": "rgba(17, 167, 104, 1)",
  "iconDefault": "rgba(111, 111, 113, 1)",
  "gray": "rgba(120, 120, 122, 1)",
  "iconCritical": "rgba(215, 44, 13, 1)",
  "iconWarning": "rgba(185, 137, 0, 1)",
  "iconSuccess": "rgba(0, 127, 95, 1)",
  "decorativePowder": "rgba(255, 242, 238, 1)",
  "decorativeLilac": "rgba(241, 234, 254, 1)",
  "decorativeMint": "rgba(223, 248, 237, 1)",
  "decorativeViolett": "rgba(90, 52, 161, 1)",
  "decorativeTiger": "rgba(255, 128, 87, 1)",
  "focusedDefault": "rgba(69, 143, 255, 1)",
  "white": "rgba(255, 255, 255, 1)",
  "decorativeSurfaceFive": "rgba(253, 201, 208, 1)",
  "decorativeSurfaceFour": "rgba(145, 224, 214, 1)",
  "decorativeSurfaceThree": "rgba(146, 230, 181, 1)",
  "decorativeSurfaceTwo": "rgba(255, 196, 176, 1)",
  "decorativeSurfaceOne": "rgba(255, 201, 107, 1)",
  "baseStone": "rgba(216, 216, 218, 1)",
  "baseCloud": "rgba(251, 251, 252, 1)",
  "baseBlue": "rgba(126, 218, 237, 1)",
  "surfaceOnWhiteTransparent": "rgba(246, 246, 247, 0.7)",
  "surfaceOnHover": "rgba(245, 251, 247, 1)",
  "surfaceWarning": "rgba(255, 245, 234, 1)",
  "surfaceCritical": "rgba(255, 244, 244, 1)",
  "textOnBlue": "rgba(0, 107, 143, 1)",
  "textOnGreen": "rgba(9, 93, 58, 1)",
  "textGreenSubdued": "rgba(147, 232, 194, 1)",
  "iconSubdued": "rgba(120, 120, 122, 1)",
  "iconDisabled": "rgba(216, 216, 218, 1)",
  "decorativeBlue": "rgba(223, 242, 248, 1)",
  "linearGreenToGarden": "linear-gradient(135deg, rgba(105, 224, 174, 1) 0%, rgba(42, 204, 136, 1) 100%)",
  "linearBlue": "linear-gradient(135deg, rgba(173, 232, 244, 1) 0%, rgba(128, 211, 226, 1) 100%)",
  "linearMint": "linear-gradient(135deg, rgba(216, 255, 239, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
  "linearGardenToGreen": "linear-gradient(135deg, rgba(42, 204, 136, 1) 0%, rgba(105, 224, 174, 1) 100%)",
  "overlayWhite": "rgba(255, 255, 255, 0.5)",
  "overlaySoftWhite": "rgba(255, 255, 255, 0.2)",
  "overlayHeaderGradient": "linear-gradient(135deg, rgba(105, 224, 174, 0.6) 0%, rgba(223, 248, 237, 0.49) 100%)",
  "actionPrimaryPressed": "rgba(23, 183, 116, 1)",
  "categoryGrey": "rgba(219, 219, 221, 1)",
  "categoryGreen": "rgba(146, 210, 164, 1)",
  "categoryPurple": "rgba(218, 137, 179, 1)",
  "categoryAppleGreen": "rgba(192, 227, 164, 1)",
  "categoryViolet": "rgba(191, 155, 228, 1)",
  "categoryBlue": "rgba(156, 189, 244, 1)",
  "categorySand": "rgba(236, 190, 162, 1)",
  "categoryMoss": "rgba(189, 210, 146, 1)",
  "categoryYellow": "rgba(248, 230, 127, 1)",
  "categoryPink": "rgba(237, 176, 185, 1)",
  "mint": "rgba(216, 255, 239, 1)",
  "mintLinear": "linear-gradient(135deg, rgba(216, 255, 239, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
  "mintLight": "rgba(236, 255, 247, 1)",
  "greenHover": "rgba(82, 131, 111, 1)",
  "greenLink": "rgba(13, 130, 81, 1)",
  "onHover": "rgba(247, 250, 248, 1)",
  "green": "rgba(105, 224, 174, 1)",
  "garden": "rgba(42, 204, 136, 1)",
  "red": "rgba(244, 67, 54, 1)",
  "yellow": "rgba(242, 201, 76, 1)",
  "lilac": "rgba(241, 234, 254, 1)",
  "violett": "rgba(90, 52, 161, 1)",
  "powder": "rgba(255, 242, 238, 1)",
  "tiger": "rgba(255, 128, 87, 1)",
  "gazelleBackground": "rgba(246, 246, 247, 1)",
  "blurredBackground": "rgba(162, 162, 162, 0.65)",
  "eagleGrey": "rgba(246, 247, 247, 1)",
  "eagleBackgroundBlur": "rgba(37, 37, 37, 0.15)",
  "stoneTransparent": "rgba(216, 216, 218, 0.7)",
  "black": "rgba(27, 28, 31, 1)"
}

export default colors;