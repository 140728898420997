import React, { useState } from 'react'

import Box from '@mui/material/Box'
import ModalExperimental from 'mynt-components/components/ModalExperimental'
import { useForm } from 'react-hook-form'
import { SelectChipController, TextFieldController } from './react-hook-components'
import ZIndices from 'figma/tokens/ZIndices'
import LoadingButton from '@mui/lab/LoadingButton'

type FormValues = {
  fullName: string
  ssn: string
  email: string
  phoneNumber: string
  roles: string[]
}

interface Props {
  isOpen: boolean
  roleOptions: { label: string; value: string }[]
  onClose: () => void
  onSubmit: (values: FormValues) => void | Promise<unknown>
}

const AddPersonModal: React.FC<Props> = ({ isOpen, onClose, onSubmit, roleOptions }) => {
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm({
    defaultValues: {
      fullName: '',
      ssn: '',
      email: '',
      phoneNumber: '',
      roles: []
    }
  })

  const handleSubmit = (formValues: FormValues) => {
    setIsLoading(true)

    const promiseOrVoid = onSubmit(formValues)

    if (promiseOrVoid instanceof Promise) {
      return promiseOrVoid.finally(() => setIsLoading(false))
    }

    setIsLoading(false)
  }

  const handleClose = () => {
    form.reset()
    onClose()
  }

  if (!isOpen) return null

  return (
    <ModalExperimental
      zIndexModal={ZIndices.modal}
      heading={
        <Box sx={{ justifyContent: 'center', padding: '18px', display: 'flex', alignItems: 'center', paddingTop: '0' }}>
          <h2 style={{ margin: 0 }}>Create person</h2>
        </Box>
      }
      onClose={handleClose}
    >
      <Box sx={{ display: 'flex', gap: '16px', width: 400, padding: '24px', flexDirection: 'column' }}>
        <TextFieldController control={form.control} name="fullName" labelText="Full name" />
        <TextFieldController control={form.control} name="ssn" labelText="SSN" />
        <TextFieldController control={form.control} name="email" labelText="Email" />
        <TextFieldController control={form.control} name="phoneNumber" labelText="Phone number" />
        <SelectChipController control={form.control} name="roles" options={roleOptions} labelText="Roles" />
        <LoadingButton loading={isLoading} sx={{ marginTop: '16px' }} variant="contained" onClick={form.handleSubmit(handleSubmit)}>
          Add person
        </LoadingButton>
      </Box>
    </ModalExperimental>
  )
}

export default AddPersonModal
