import { Global } from '@emotion/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import TextContainer from '../../../components/TextContainer'
import dayjs from 'dayjs'
import FigmaBox from 'mynt-components/components/FigmaBox'
import React, { useEffect, useState } from 'react'
import { Control, Path, useForm } from 'react-hook-form'
import { TextKeys } from 'tiger/libs/TextRepository'
import * as yup from 'yup'
import { ObjectWithId } from 'api/react-query/interfaces/mynt'
import {
  BackOfficeDefaultPenaltyFeeDto2,
  BackOfficeInvoicePatchDto2,
  BackOfficeInvoiceResponseDtoV2,
  InvoiceState
} from 'api/swagger/definitions/backoffice'
import Spacings from 'figma/tokens/Spacings'
import { Currencies } from 'flamingo/config/ConfigCurrencies'
import useConfigByCountry from 'flamingo/hooks/useConfigByCountry'
import { useCustomer, useDefaultPenaltyFeeV2 } from 'api/react-query'
import useMaterialNotification from 'hooks/useMaterialNotification'
import { BO_SUBSCRIPTION_FEE_MODAL_WIDTH } from 'mynt-components/WeakHardCodedSizes'
import Button from 'mynt-components/components/Button'
import ModalForked from 'mynt-components/components/ModalForked'
import { Line } from 'mynt-components/components/StyledComponents'
import { When } from 'mynt-components/components/When'
import { YYYY_MM_DD } from 'tiger/Constants'
import { AntiloopTextType } from 'tiger/interfaces/Antiloop'
import {
  CheckboxController,
  DatePickerControllerV2,
  SelectController,
  TextFieldController
} from '../../../components/react-hook-components'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (values: BackOfficeInvoicePatchDto2 & ObjectWithId) => void | Promise<any>
  invoice?: BackOfficeInvoiceResponseDtoV2
}

type FormData = {
  payload: BackOfficeInvoicePatchDto2
  dueDate: string
}

const createInitialValues = (invoice?: BackOfficeInvoiceResponseDtoV2, defaultPenaltyFees?: BackOfficeDefaultPenaltyFeeDto2): FormData => ({
  payload: {
    state: InvoiceState.REMINDED,
    latePaymentFee: {
      value: defaultPenaltyFees?.latePaymentFee.value ?? 0,
      currency: defaultPenaltyFees?.latePaymentFee.currency ?? Currencies.SEK
    },
    reminderDueDate: invoice ? dayjs(invoice.dueDate).add(7, 'days').format(YYYY_MM_DD) : '',
    addLatePaymentInterest: true
  },
  dueDate: invoice ? dayjs(invoice.dueDate).format(YYYY_MM_DD) : ''
})

const validationSchema = yup.object().shape({
  payload: yup.object().shape({
    latePaymentFee: yup.object().shape({
      value: yup.number().required('fee is required').typeError('fee is required'),
      currency: yup.string().required('currency is required')
    }),
    reminderDueDate: yup.string().required('due date is required')
  })
})

const leoptions = [
  { value: 'true', label: 'true' },
  { value: 'false', label: 'false' }
]

export const PenaltyFee: React.FC<Props> = ({ isOpen, onClose: onModalCancel, onSubmit, invoice }) => {
  const customerId = invoice?.customerId as string
  const { formatAmount } = useConfigByCountry()
  const { data: defaultPenaltyFees } = useDefaultPenaltyFeeV2(customerId, { enabled: Boolean(customerId) })

  const { data: customer } = useCustomer(customerId, { enabled: Boolean(customerId) })
  const [isLoading, setIsLoading] = useState(false)
  const notify = useMaterialNotification()

  const form = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: createInitialValues(invoice, defaultPenaltyFees)
  })

  const onFormSubmit = async (data: ReturnType<typeof createInitialValues>) => {
    if (!invoice?.id) return notify('Invoice does not have an ID', 'error')

    setIsLoading(true)

    const payload: BackOfficeInvoicePatchDto2 & ObjectWithId = {
      id: invoice.id,
      ...data.payload
    }

    const promiseOrVoid = onSubmit(payload)

    if (promiseOrVoid instanceof Promise) {
      return promiseOrVoid.finally(() => setIsLoading(false))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    form.reset(createInitialValues(invoice, defaultPenaltyFees))
  }, [invoice, defaultPenaltyFees])

  if (!isOpen) return null

  return (
    <ModalForked
      fullWidth
      alignHeadingWithCloseButton
      wrapperStyle={{ width: BO_SUBSCRIPTION_FEE_MODAL_WIDTH }} // Form adjusts size when you select different options in the selects without fixed width. Value from figma
      onClose={onModalCancel}
      heading={() => (
        <FigmaBox spacing={Spacings.large} left>
          <TextContainer textKey={TextKeys.invoicesCreateInvoiceHeading} text="Reminder invoice" />
        </FigmaBox>
      )}
    >
      <MuiSelectFontFix />
      <FigmaBox
        fullWidth
        direction="row"
        justify="space-between"
        spacing={Spacings.large}
        left
        right
        bottom={Spacings.tiny}
        top={Spacings.medium}
      >
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceSubHeadingCompanyName} text={customer?.companyName} />
        <TextContainer textKey={TextKeys.invoicesCreateInvoiceSubHeadingCompanyName} text={`Invoice ${invoice?.no}`} />
      </FigmaBox>
      <form onSubmit={form.handleSubmit(onFormSubmit)}>
        <FigmaBox fullWidth spacing={Spacings.large} fullPadding top={Spacings.small} gap={Spacings.min}>
          <Row
            control={form.control}
            name="dueDate"
            labelTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameCurrency}
            labelText="Invoice due date"
            type="text"
            disabled
          />
          <Row
            control={form.control}
            name="payload.reminderDueDate"
            labelTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameCurrency}
            labelText="Reminder due date"
            type="date"
          />
          <FigmaBox fullWidth top={Spacings.tiny} bottom={Spacings.tiny}>
            <Line fullWidth color="#ccc" />
          </FigmaBox>
          <Row
            control={form.control}
            name="payload.latePaymentFee.value"
            labelTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameCurrency}
            labelText="Late payment fee"
            type="number"
            adornmentTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameSubFeeCurrencyXXX}
            adornmentText={form.watch('payload.latePaymentFee.currency')}
          />
          <Row
            control={form.control}
            name="payload.addLatePaymentInterest"
            labelTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameCurrency}
            labelText="Add interest rate"
            type="checkbox"
            options={leoptions}
            adornmentTextKey={TextKeys.invoicesCreateInvoiceDropdownLabelNameSubFeeCurrencyXXX}
            adornmentText=""
          />
          <FigmaBox top={Spacings.medium} fullWidth direction="row" justify="space-between">
            <TextContainer textKey={TextKeys.invoicesCreateInvoiceLabelNameTotal} text="Total invoice amount" />
            <TextContainer
              textKey={TextKeys.invoicesCreateInvoiceLabelNameTotalValue}
              text={invoice?.grossTotal ? formatAmount(invoice.grossTotal) : '-'}
            />
          </FigmaBox>
          <FigmaBox top={Spacings.tiny} fullWidth>
            <Button
              disabled={!form.formState.isValid}
              loading={isLoading}
              type="submit"
              textKey={TextKeys.invoicesCreateInvoiceButtonCreateInvoice}
              text="Send reminder"
            />
          </FigmaBox>
        </FigmaBox>
      </form>
    </ModalForked>
  )
}

type Options = { value: string; label: string; disabled?: boolean }[]

type RowProps<T extends Record<string, any>> = {
  control: Control<T>
  labelTextKey: AntiloopTextType
  labelText?: string
  name: Path<T>
  type?: 'text' | 'select' | 'number' | 'date' | 'checkbox'
  options?: Options
  adornmentTextKey?: AntiloopTextType
  adornmentText?: string
  onChange?: (event: any) => void
  disabled?: boolean
}

const Row = <T extends Record<string, any>>({
  control,
  labelTextKey,
  labelText,
  name,
  type = 'number',
  options,
  adornmentTextKey,
  adornmentText,
  onChange,
  disabled
}: RowProps<T>) => (
  <FigmaBox fullWidth justify="space-between" direction="row">
    <FigmaBox fullWidth flex={2} style={{ whiteSpace: 'pre' }} justify="center">
      <TextContainer textKey={labelTextKey} text={labelText} />
    </FigmaBox>
    <StyledTextFieldControllerContainer fullWidth flex={3}>
      <When is={['text', 'number'].includes(type)}>
        <TextFieldController
          disabled={disabled}
          type={type}
          noLabel
          name={name}
          control={control}
          adornment={adornmentTextKey && <TextContainer text={adornmentText} textKey={adornmentTextKey} />}
        />
      </When>
      <When is={type === 'select'}>
        <SelectController
          noLabel
          control={control}
          name={name}
          options={options as Options}
          adormentTextKey={adornmentTextKey}
          adormentText={adornmentText}
          onChange={onChange}
        />
      </When>
      <When is={type === 'date'}>
        <DatePickerControllerV2 control={control} name={name} onChange={onChange} />
      </When>
      <When is={type === 'checkbox'}>
        <CheckboxController style={{ justifyContent: 'flex-end' }} control={control} name={name} onChange={onChange} />
      </When>
    </StyledTextFieldControllerContainer>
  </FigmaBox>
)

// These fixes some issues that should not be applied everywhere that comes from UX. Would be improved once panda components are created
const MuiSelectFontFix = () => <Global styles={{ '.MuiPaper-root li': { fontSize: '14px !important' } }} />

const StyledTextFieldControllerContainer = styled(FigmaBox)`
  *:not(svg) {
    font-family: Inter !important;
    font-size: 14px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
