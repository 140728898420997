import styled from '@emotion/styled'
import CircularProgress from '@mui/material/CircularProgress'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import ButtonPrimaryDefault from '../../figma/elements/Button/Primary/Default/ButtonPrimaryDefault'
import ButtonPrimaryHover from '../../figma/elements/Button/Primary/Hover/ButtonPrimaryHover'
import ButtonSecondaryDefault from '../../figma/elements/Button/Secondary/Default/ButtonSecondaryDefault'
import ButtonSecondaryDisabled from '../../figma/elements/Button/Secondary/Disabled/ButtonSecondaryDisabled'
import ButtonSecondaryHover from '../../figma/elements/Button/Secondary/Hover/ButtonSecondaryHover'
import ButtonTertiaryClicked from '../../figma/elements/Button/Tertiary/Clicked/ButtonTertiaryClicked'
import ButtonTertiaryDefault from '../../figma/elements/Button/Tertiary/Default/ButtonTertiaryDefault'
import ButtonTertiaryDisabled from '../../figma/elements/Button/Tertiary/Disabled/ButtonTertiaryDisabled'
import ButtonTertiaryHover from '../../figma/elements/Button/Tertiary/Hover/ButtonTertiaryHover'
import ButtonTertiaryPressed from '../../figma/elements/Button/Tertiary/Pressed/ButtonTertiaryPressed'
import Colors from '../../figma/panda/Colors'
import BorderRadius from '../../figma/tokens/BorderRadius'
import Spacings from '../../figma/tokens/Spacings'
import Translates from '../../figma/tokens/Translates'
import FigmaBox from './FigmaBox'

type Variant = 'primary' | 'secondary' | 'tertiary'

type Props = {
  variant?: Variant
  disabled?: boolean
  onClick?: () => any
  style?: CSSProperties
  className?: any
  pressed?: boolean
  loading?: boolean
  focus?: boolean
}

const SubmitButton: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
  const { variant, children, onClick, style, className, pressed, loading, focus, ...rest } = props

  let { disabled } = props
  if (loading) disabled = loading

  const parentRef = useRef<HTMLButtonElement | null>(null)

  const [hover, setHover] = useState(false)
  const [size, setSize] = useState(0)

  const element = getButtonElement({ variant, disabled, hover, pressed, focus })
  const classNames = (className ? className : '') + `${disabled ? ' disabled' : ''}`

  useEffect(() => {
    const clientWidth = parentRef.current?.clientWidth || 0
    const clientHeight = parentRef.current?.clientHeight || 0

    setSize(Math.min(clientHeight, clientWidth))
  }, [])

  return (
    <HTMLButton
      {...rest} // this is needed for the cypress tests, the data-cy tags
      ref={parentRef}
      className={classNames}
      disabled={disabled}
      onClick={onClick}
      type="submit"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {loading && (
        <LoaderWrapper fullWidth justify="center" align="center" top bottom spacing={Spacings.tiny}>
          <CircularProgress size={size ? 0.8 * size : undefined} />
        </LoaderWrapper>
      )}
      {React.createElement(element, { style }, children)}
    </HTMLButton>
  )
}

export const LoaderWrapper = styled(FigmaBox)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .MuiCircularProgress-circle {
    color: ${Colors.bluePrimary600Cta};
  }
`

const HTMLButton = styled.button`
  position: relative;

  padding: 0 0;
  border: none;

  background-color: transparent;

  cursor: pointer;
  white-space: nowrap;

  border-radius: ${BorderRadius.soft};

  &.disabled {
    cursor: default;
    background-color: ${Colors.base300};

    div:last-of-type {
      & > span {
        opacity: 0.7;
      }
    }

    &:active {
      transform: unset;
    }
  }

  transition: transform ease 0.1s;

  &:not(:disabled):active > div {
    transform: ${Translates.translateYSmall};
  }

  &:focus {
    outline: 0;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const getButtonElement = ({
  variant = 'primary',
  disabled,
  hover,
  pressed,
  focus
}: {
  variant?: Variant
  disabled?: boolean
  hover?: boolean
  pressed?: boolean
  focus?: boolean
}) => {
  if (variant === 'primary' && !disabled && !hover) return ButtonPrimaryDefault
  if (variant === 'primary' && disabled) return ButtonSecondaryDisabled // wrong
  if (variant === 'primary' && hover) return ButtonPrimaryHover
  if (variant === 'secondary' && !disabled && !hover) return ButtonSecondaryDefault
  if (variant === 'secondary' && disabled) return ButtonSecondaryDisabled
  if (variant === 'secondary' && hover) return ButtonSecondaryHover
  if (variant === 'tertiary' && !disabled && !hover) return ButtonTertiaryDefault
  if (variant === 'tertiary' && disabled) return ButtonTertiaryDisabled
  if (variant === 'tertiary' && hover && !pressed) return ButtonTertiaryHover
  if (variant === 'tertiary' && pressed) return ButtonTertiaryPressed
  if (variant === 'tertiary' && focus) return ButtonTertiaryClicked

  return ButtonPrimaryDefault
}

export default SubmitButton
