import MaterialButton, { ButtonProps } from '@mui/material/Button'
import React from 'react'

interface IProps {
  loading?: boolean
  type?: string
  htmlType?: string
}
/** @deprecated use material button instead */
export const Button = React.forwardRef(({ children, type, loading, htmlType, ...props }: Omit<ButtonProps, 'type'> & IProps, ref) => {
  let variant: string | undefined = undefined

  // Try catch old lib filled in buttons to material
  if ((type as any) === 'primary') {
    variant = 'primary'
  }

  return (
    <MaterialButton ref={ref as any} {...props} disabled={props.disabled || loading} type={htmlType as any} variant={variant as any}>
      {children}
    </MaterialButton>
  )
})

Button.displayName = 'Button'
