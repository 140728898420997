/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentSource = {
    BILLECTA_INVOICE: 'BILLECTA_INVOICE',
    BILLECTA_UNMATCHED: 'BILLECTA_UNMATCHED',
    MANUAL: 'MANUAL',
    CARD_ACCOUNT: 'CARD_ACCOUNT',
    ATLAR: 'ATLAR',
    ATLAR_BANK_TRANSFER: 'ATLAR_BANK_TRANSFER',
    ATLAR_DIRECT_DEBIT: 'ATLAR_DIRECT_DEBIT',
    MYNT: 'MYNT',
    MYNT_NETTING: 'MYNT_NETTING',
    CASHBACK: 'CASHBACK'
} as const;

export type PaymentSource = typeof PaymentSource[keyof typeof PaymentSource];



