import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Modal from '@mui/material/Modal'
import FigmaBox from '../../mynt-components/components/FigmaBox'
import styled from '@emotion/styled'
import Spacings from '../../figma/tokens/Spacings'
import { useEffect, useRef, useState } from 'react'

type DeleteCardsModalProps = {
  open: boolean
  cards: string[]
  onClose: () => void
  endpoint: (card: string) => Promise<unknown>
  onDeleted?: () => void
}

const chainPromises = async <T,>(promises: (() => Promise<T>)[]) => {
  const [firstPromise, ...rest] = promises

  return rest.reduce(async (chain, promise) => chain.then(promise), firstPromise())
}

const getProgress = (total: number, current: number) => (current / total) * 100

export const DeleteCardsModal = ({ open, onClose, cards: allCards, endpoint, onDeleted }: DeleteCardsModalProps) => {
  const [progress, setProgress] = useState<number | null>(null)
  const openRef = useRef(open)

  const handleDelete = async () => {
    setProgress(0)

    // Create all of our request promises and wrap them in a function so that they are not called immediately
    const promises = allCards.map((card, index) => async () => {
      if (!openRef.current) return Promise.reject()
      setProgress(index)

      return endpoint(card)
    })

    try {
      // Chain each promise so they called after one another
      await chainPromises(promises)

      setProgress(allCards.length)

      // UX, Have a small timeout to show all cards did complete
      setTimeout(() => {
        setProgress(null)
        onDeleted?.()
      }, 500)
    } catch {
      setProgress(null)
    }
  }

  useEffect(() => {
    openRef.current = open
  }, [open])

  return (
    <Modal data-testid="card-overview-modal" onClose={onClose} open={open}>
      <Container>
        {progress === null && (
          <>
            <Heading>
              Are you sure you want to terminate {allCards.length} card{allCards.length > 1 ? 's' : ''}?
            </Heading>
            <FigmaBox top={Spacings.medium} direction="row" justify="center" gap={Spacings.small} fullWidth>
              <FigmaBox>
                <Button onClick={onClose} variant="outlined">
                  Cancel
                </Button>
              </FigmaBox>
              <FigmaBox>
                <Button onClick={handleDelete} variant="contained" color="error">
                  Terminate
                </Button>
              </FigmaBox>
            </FigmaBox>
          </>
        )}
        {typeof progress === 'number' && (
          <>
            <Heading data-testid="card-overview-progress-title">
              Terminated {progress}/{allCards.length}
            </Heading>
            <FigmaBox fullWidth top={'50px'}>
              <LinearProgress
                data-testid="card-overview-progress"
                sx={{ height: 10, borderRadius: 8 }}
                variant="determinate"
                value={getProgress(allCards.length, progress)}
              />
            </FigmaBox>
          </>
        )}
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  padding: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  transform: all 1s;
  border-radius: 8px;
  text-align: center;
  background-color: white;
`

const Heading = styled.h1`
  font-weight: 500;
  line-height: 120%;
  font-size: 20px;
  color: #1c1b1f;
`
