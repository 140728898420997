import { _ModalAccountingAccount } from './ModalAccountingAccount'
import { _ModalAccountingYear } from './ModalAccountingYear'
import { _ModalActivateCard } from './ModalActivateCard'
import { _ModalAreYouSure } from './ModalAreYouSure'
import { _ModalAreYouSureWithBody } from './ModalAreYouSureWithBody'
import { _ModalConfirmDialog } from './ModalConfirmDialog'
import { _ModalEnablePlatinumAccount } from './ModalEnablePlatinumAccount'
import _ModalInvoicePenaltyFee from './ModalInvoicePenaltyFee'
import { _ModalMandateLog } from './ModalMandateLog'
import { _ModalManuallyOrderCard } from './ModalManuallyOrderCard'
import { _ModalDirectDebitWithoutAgreement } from './ModalDirectDebitWithoutAgreement'

export const ModalActivateCard = _ModalActivateCard
export const ModalMandateLog = _ModalMandateLog
export const ModalManuallyOrderCard = _ModalManuallyOrderCard
export const ModalAreYouSure = _ModalAreYouSure
export const ModalAreYouSureWithBody = _ModalAreYouSureWithBody
export const ModalConfirmDialog = _ModalConfirmDialog
export const ModalAccountingAccount = _ModalAccountingAccount
export const ModalAccountingYear = _ModalAccountingYear
export const ModalInvoicePenaltyFee = _ModalInvoicePenaltyFee
export const ModalEnablePlatinumAccount = _ModalEnablePlatinumAccount
export const ModalDirectDebitWithoutAgreement = _ModalDirectDebitWithoutAgreement
