import styled from '@emotion/styled'
import React from 'react'
import Colors from '../../figma/panda/Colors'
import BorderRadius from '../../figma/tokens/BorderRadius'
import Spacings from '../../figma/tokens/Spacings'
import FigmaBox from './FigmaBox'

type Props = {
  children: React.ReactNode
}

const ModalExperimentalHeader: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ children }) => (
  <Wrapper fullWidth>{children}</Wrapper>
)

const Wrapper = styled(FigmaBox)`
  padding: ${Spacings.medium};
  background: ${Colors.backgroundColorsBackground};
  border-radius: ${BorderRadius.soft} ${BorderRadius.soft} 0 0;
  border-bottom: 1px solid ${Colors.base300};
`

export default ModalExperimentalHeader
