import { Permissions } from 'appPermissions'
import routes from '../routes/constants'

interface Props {
  text: string
  path: string
  permissions: Permissions[]
}

export const HeaderMenuLinks: Props[] = [
  {
    text: 'Inbox',
    path: routes.INBOX_PATH,
    permissions: ['inbox.view']
  },
  {
    text: 'Credits',
    path: routes.CREDITS_PATH,
    permissions: ['credits']
  },
  {
    text: 'Rolling credit',
    path: routes.ROLLING_CREDIT,
    permissions: ['rolling-credit']
  },
  {
    text: 'Accounting',
    path: routes.ACCOUNTING_PATH,
    permissions: ['accounting']
  }
]
