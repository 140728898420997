import styled from '@emotion/styled'
import BorderRadius from '../figma/tokens/BorderRadius'
import Spacings from '../figma/tokens/Spacings'
import FigmaBox from '../mynt-components/components/FigmaBox'

type CardPartnerBadgeProps = {
  logo: React.ReactNode
  color: string
  svgStyles?: {
    fill?: string
    stroke?: string
  }
}

export const CardPartnerBadge = ({ logo, color, svgStyles }: CardPartnerBadgeProps) => (
  <Badge color={color} {...svgStyles}>
    <FigmaBox align="center" direction="row" gap={Spacings.tiny}>
      {logo}
    </FigmaBox>
  </Badge>
)

const Badge = styled.div<{ color: string; fill?: string; stroke?: string }>`
  padding: ${Spacings.minimum} ${Spacings.tiny};
  border-radius: ${BorderRadius.soft};
  background-color: ${(props) => props.color};
  color: #1c1b1f;
  border: 1px solid rgba(0, 0, 0, 0.25);
  cursor: default;

  path {
    fill: ${(props) => props.fill};
    stroke: ${(props) => props.stroke};
  }
`
