import { useEffect as getEffect, useState as getState } from 'react'
import useDeviceDetect from './useDeviceDetect'

const MOBILE_DEVICE_WIDTH = 768

export default function useIsMobile() {
  const { isMobile } = useDeviceDetect()

  if (typeof window === `undefined`) return isMobile

  const [hasRunInitially, setHasRunInitially] = getState(false)

  const [width, setWidth] = getState(window.innerWidth)

  const onResize = () => {
    setWidth(window.innerWidth)
  }

  getEffect(() => {
    if (!hasRunInitially) {
      setHasRunInitially(true)
      onResize()
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [width])

  return width <= MOBILE_DEVICE_WIDTH
}
