import styled from '@emotion/styled'
import { useNavigate } from 'react-router'
import Spacings from '../figma/tokens/Spacings'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { UnstyledButton } from '../mynt-components/components/StyledComponents'
import TextContainer from './TextContainer'
import { LOGIN_PATH } from 'routes/constants'
import { signOut } from 'api'
import { useSignOut } from 'contexts/session'

interface Props {
  isMobileHeader?: boolean
}

const HeaderButtonLogout: React.FC<Props> = ({ isMobileHeader = false }) => {
  const navigate = useNavigate()
  const logout = useSignOut()

  const handleSignOut = async () => {
    logout()
    await signOut()
    navigate(LOGIN_PATH)
  }

  return (
    <FigmaBox fullWidth={isMobileHeader} direction="row" align="center" justify={'space-between'} gap={Spacings.min}>
      <StyledButton tabIndex={-1} onClick={handleSignOut}>
        <TextContainer textKey={TextKeys.headerSignOut} />
      </StyledButton>
    </FigmaBox>
  )
}

const StyledButton = styled(UnstyledButton)`
  white-space: nowrap;
`

export default HeaderButtonLogout
