import { Button } from '../components/Button'
import qs from 'query-string'
import { useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FastSearchResultItem } from '../api/swagger/definitions/backoffice'
import { useSearch } from '../api/react-query'
import { createRoutePath } from '../helpers/SearchResultsHelpers'
import { When } from '../mynt-components/components/When'
import { SearchResultContent, SearchResultHeader, SearchResultRow } from './SearchResults'
import styled from '@emotion/styled'
import { colors } from 'themes'
import Box from '@mui/material/Box'

const SearchResults = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const itemsPerClick = 10

  const [{ query, size }, setQuery] = useState<{ query: string; size: number }>(qs.parse(location.search) as any)
  const { data, isLoading: searchIsLoading } = useSearch(query, size)
  const [meta, setMeta] = useState({ hits: data?.totalNoHits || 0, exact: data?.totalIsExact, seconds: (data?.timeMs || 0) / 1000 })

  useEffect(() => {
    setQuery(qs.parse(location.search) as any)
  }, [location.search])

  const [searchItems, setSearchItems] = useState<FastSearchResultItem[]>(data?.items || [])

  useEffect(() => {
    if (data?.items) {
      setSearchItems(data.items)
      setMeta({ hits: data?.totalNoHits || 0, exact: data.totalIsExact, seconds: (data.timeMs || 0) / 1000 })
    }
  }, [data])

  const emptyHeaderStyles = {
    marginTop: 30,
    fontSize: 20,
    fontWeight: 300,
    fontFamily: 'Inter'
  }

  const handleMoreResultsClick = () => {
    navigate(`${location.pathname}?query=${query}&size=${(Number(size) || itemsPerClick) + itemsPerClick}`, { replace: true })
  }

  if (!searchIsLoading && searchItems.length === 0 && !query) {
    return <h3 style={emptyHeaderStyles}>Search input value is empty, please provide a query to search for.</h3>
  }

  return (
    <>
      <When is={searchItems.length === 0 && !searchIsLoading}>
        <h3 style={emptyHeaderStyles}>
          Your search - <span style={{ fontWeight: 500 }}>{query}</span> - did not match any documents.
        </h3>
      </When>
      <When is={searchItems.length > 0}>
        <div style={{ fontFamily: 'Inter' }}>
          <p style={{ fontSize: 15, marginTop: 30 }}>
            {meta.exact
              ? `${meta.hits} ${meta.hits === 1 ? 'result' : 'results'} (${meta.seconds}s)`
              : `more than ${meta.hits} results (${meta.seconds}s)`}
          </p>
          {searchItems.map((item: FastSearchResultItem) => (
            <StyledLink to={createRoutePath(item)} key={item.id}>
              <SearchResultRow style={{ display: 'flex' }}>
                <Box sx={{ display: 'block', flex: 1 }}>
                  <SearchResultHeader title={item.title} subTitle={item.subTitle} />
                </Box>
                <Box sx={{ display: 'block', flex: 2 }}>
                  <SearchResultContent>
                    <Highlighter
                      autoEscape={true}
                      textToHighlight={item.body}
                      searchWords={query ? query.split(' ').filter((q: string) => !!q) : []}
                      highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0
                      }}
                    />
                  </SearchResultContent>
                </Box>
              </SearchResultRow>
            </StyledLink>
          ))}
          {meta.hits > searchItems.length && (
            <Button style={{ display: 'block', margin: '30px auto' }} onClick={handleMoreResultsClick}>
              Show me more results!
            </Button>
          )}
        </div>
      </When>
    </>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus-visible > div {
    background-color: ${colors.product.blue[100]} !important;
  }
`

export default SearchResults
