import { AccountResultDto } from '../api/swagger/definitions/backoffice'
import { useAccountingAccounts, useAccountingYears, useAllVouchers, useVoucherResult } from '../api/react-query'
import { TabSets } from '../helpers/TabsHelpers'
import { useAccountingFilters } from './useAccountingFilters'

export const useAccounting = () => {
  const { data: accounts, isLoading: accountsLoading } = useAccountingAccounts(undefined, { refetchOnWindowFocus: false })

  const { data: fetchedYears = [], isLoading: yearsLoading } = useAccountingYears({ refetchOnWindowFocus: false })

  const years = typeof fetchedYears === 'string' ? [fetchedYears] : fetchedYears

  const activeTab = window.localStorage.getItem(TabSets.ACCOUNTING_VOUCHERS)

  const accountingFilters = useAccountingFilters()

  const shouldFetchVouchers = Object.keys(accountingFilters.queryParam).length > 0

  let letVouchers, letVouchersLoading

  if (!activeTab || activeTab === '0') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data, isLoading } = useAllVouchers(accountingFilters.queryParam, {
      enabled: shouldFetchVouchers,
      refetchOnWindowFocus: false
    })
    letVouchers = data
    letVouchersLoading = isLoading
  }

  const vouchers = letVouchers
  const vouchersLoading = letVouchersLoading

  let letSummary, letSummaryLoading

  if (activeTab === '1') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data, isLoading } = useVoucherResult(accountingFilters.queryParam, {
      enabled: shouldFetchVouchers,
      refetchOnWindowFocus: false
    })

    letSummary = data
    letSummaryLoading = isLoading
  }

  const summary: AccountResultDto[] = letSummary
  const summaryLoading = letSummaryLoading

  return {
    years,
    accounts,
    vouchers,
    summary,
    yearsLoading,
    accountsLoading,
    vouchersLoading,
    summaryLoading,
    accountingFilters
  }
}
