import styled from '@emotion/styled'
import Tab from '@mui/material/Tab'
import MaterialTabs from '@mui/material/Tabs'
import React, { useEffect } from 'react'
import Colors from '../../figma/panda/Colors'
import MediaQueries from '../../figma/tokens/MediaQueries'
import Spacings from '../../figma/tokens/Spacings'
import { PAGE_CONTENT_SIZE, TAB_PANEL_ITEM_HEIGHT_PX } from '../StrongHardCodedSizes'
import FigmaBox from '../components/FigmaBox'
import useActiveTabKey from '../hooks/useActiveTabKey'
import useIsBrowser from '../hooks/useIsBrowser'
import useUrlParam from '../hooks/useUrlParam'
import { colors } from 'themes'

type TabType = {
  labelElement: React.ReactElement
  contentElement: React.ReactElement
  enable?: boolean
  onClick?: () => unknown
}

type Props = {
  fullPage?: boolean
  enableNestedTabs?: boolean
  alwaysShowNavHeadings?: boolean
  tabKeyNameSpace: string
  tabs: TabType[]
  changeTabExternally?: string
  onTabChange?: (any?) => void
  handleNavigate?: (path: string) => void
}

/**
 * Mui Tabs with support for nested tabs and optional url param navigation
 *
 * @remarks
 *
 * @param fullPage - disable Gazelle max-width limit and use the whole page
 * @param enableNestedTabs - disable tabUrl param to make nested tabs work
 * @param alwaysShowNavHeadings - display tab controls even if we have just one tab
 * @param tabKeyNameSpace - namespace used for storing active tab in localStorage
 * @param tabs - array with label, content, and enable flag for each tab
 * @param changeTabExternally - change tab from another component when not using url params
 * @param onTabChange - callback to be executed after tab change
 * @param handleNavigate - pass react router navigate to enable back/forward buttons
 *
 */

export default function Tabs({
  fullPage,
  enableNestedTabs,
  alwaysShowNavHeadings,
  tabKeyNameSpace,
  tabs: _tabs,
  changeTabExternally,
  onTabChange,
  handleNavigate
}: Props) {
  const isBrowser = useIsBrowser()
  const tabUrl = useUrlParam('tabUrl')

  const [activeTab, setActiveTab] = useActiveTabKey(tabKeyNameSpace, '0')
  const activeTabIndex = parseInt(activeTab)

  const tabs = _tabs.filter((t) => t.enable !== false)

  // Change tab
  const handleChange = (index: number) => {
    const onClick = _tabs[index].onClick

    if (onClick) return onClick()

    if (isBrowser && !enableNestedTabs && handleNavigate) handleNavigate(`?tabUrl=${index}`)
    setActiveTab(index.toString())
  }

  // Change tab externally without using tabUrl
  useEffect(() => {
    if (changeTabExternally) handleChange(Number(changeTabExternally))
  }, [changeTabExternally])

  // Handle navigate and back/forward buttons
  useEffect(() => {
    // console.log(tabUrl, activeTab)
    if (tabUrl && tabUrl !== activeTab) {
      setActiveTab(tabUrl)
    }
  }, [tabUrl])

  // On change tab callback
  useEffect(() => {
    if (onTabChange) onTabChange()
  }, [activeTab])

  // Show only tab content (without tab heading) if there's only one tab and alwaysShowNavHeadings is not enforced
  if (!alwaysShowNavHeadings && tabs.length === 1) return tabs[0].contentElement

  return (
    <Wrapper fullWidth fullPage={fullPage}>
      <MaterialTabs
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        value={activeTabIndex}
        onChange={(event, newTab: number) => handleChange(newTab)}
        TabIndicatorProps={{ style: { backgroundColor: colors.brand.blue.CTA, height: 2 } }}
      >
        {tabs.map(({ labelElement }, index) => (
          <Tab key={index} label={labelElement} />
        ))}
      </MaterialTabs>
      <Container fullWidth fullPage={fullPage}>
        {tabs.map(({ contentElement, onClick }, index) => {
          if (activeTabIndex === index) onClick?.()

          return (
            <TabPanel activeTabIndex={activeTabIndex} index={index} key={index}>
              {contentElement}
            </TabPanel>
          )
        })}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled(FigmaBox)<{ fullPage?: boolean }>`
  ${({ fullPage }) => !fullPage && `max-width: ${PAGE_CONTENT_SIZE};`}

  .MuiTabs-indicator {
    height: ${TAB_PANEL_ITEM_HEIGHT_PX};
    background-color: ${Colors.baseBlack};
  }

  .MuiTab-textColorInherit.Mui-selected {
    .MuiTab-wrapper {
      color: ${Colors.bluePrimary600Cta};
    }
  }

  .MuiTab-root {
    align-items: center;
    min-width: 0;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    white-space: nowrap;

    :not(:last-child) {
      margin-right: ${Spacings.large};
    }
  }

  .Mui-selected span {
    opacity: 1;
    font-weight: 600 !important;
    color: ${Colors.baseBlack} !important;
  }

  .MuiTabs-indicator {
    min-width: 0;
  }

  .MuiTabs-scroller {
    @media (max-width: ${MediaQueries.md}px) {
      margin-left: ${Spacings.small};
      margin-right: ${Spacings.small};
    }
  }

  .MuiTabs-scrollButtons {
    opacity: 1 !important;
  }
`

const Container = styled(FigmaBox)<{ fullPage?: boolean }>`
  position: relative;
  ${({ fullPage }) => !fullPage && `max-width: ${PAGE_CONTENT_SIZE};`}
  margin-top: ${Spacings.medium};
`

function TabPanel({ index, activeTabIndex, children }: { activeTabIndex: number; index: number; children: React.ReactNode }) {
  return (
    <FigmaBox role="tabpanel" fullWidth hidden={activeTabIndex !== index}>
      {activeTabIndex === index && children}
    </FigmaBox>
  )
}
