import { Button } from '../components/Button'
import Popconfirm from '../components/Popconfirm'
import { formatCurrency } from 'helpers/utils'
import React, { useState } from 'react'
import { RepaymentInvoiceDto } from '../api/swagger/definitions/backoffice'
import { useRepaymentInvoiceSummary } from '../api/react-query'
import { getAgreementDocument } from '../api'
import { useRepaymentInvoiceAction } from '../helpers/RepaymentHelpers'
import { RepaymentInvoice } from '../interfaces/RepaymentInterfaces'
import { RepaymentInvoiceRemindBtn } from './RepaymentInvoiceRemind'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

interface RepaymentInvoicesProps {
  invoices: RepaymentInvoice[] | null
  setInvoiceById: (id: string, data: RepaymentInvoiceDto) => void
  defaultExpandAllRows?: boolean
}

const RepaymentInvoices: React.FC<RepaymentInvoicesProps> = ({ invoices, setInvoiceById, defaultExpandAllRows = false }) => {
  const [open, setOpen] = useState('')

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      width: 75
    },
    {
      title: 'Invoice date',
      dataIndex: 'invoiceDate',
      width: 150
    },
    {
      title: 'Due date',
      dataIndex: 'dueDate',
      width: 150
    },
    {
      title: 'Reminder due date',
      dataIndex: 'reminderDueDate',
      width: 150
    },
    {
      title: 'State',
      dataIndex: 'state',
      width: 120
    },
    {
      title: 'No reminders',
      dataIndex: 'noReminders',
      align: 'right' as const,
      width: 150
    },
    {
      title: 'Total',
      key: 'total',
      align: 'right' as const,
      width: 150,
      render: (record: RepaymentInvoice) => formatCurrency(record.total, 2)
    },
    {
      title: 'Balance',
      key: 'balance',
      align: 'right' as const,
      width: 150,
      render: (record: RepaymentInvoice) => formatCurrency(record.balance, 2)
    },
    {
      title: 'Booked, Cancelled, Credited',
      key: 'booked_cancelled_sent',
      align: 'right' as const,
      render: (record: { isBooked: boolean; isCancelled: boolean; isCredited: boolean }) => {
        const { isBooked, isCancelled, isCredited } = record

        const map = {
          isCancelled: 'Cancelled',
          isBooked: 'Booked',
          isCredited: 'Credited'
        }

        return (
          <div>
            {Object.entries({ isBooked, isCancelled, isCredited }) // Becomes [['Booked', boolean], ...]
              .filter((item) => item[1]) // Leave only truthy values
              .map((item) => map[item[0] as keyof typeof map]) // Leave only labels
              .toString() || '-'}
          </div>
        )
      }
    },
    {
      title: '',
      render: () => <div />
    } // needed for extra space on the right
  ]

  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <TableCell />
          {columns.map((column) => (
            <TableCell key={column.key}>{column.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {invoices?.map((invoice) => (
          <>
            <TableRow key={invoice.id}>
              <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(open ? '' : invoice.id)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.key}>{column.render ? column.render(invoice as any) : invoice[column.dataIndex]}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 'none', padding: 0 }} colSpan={columns.length}>
                <Collapse in={open === invoice.id} timeout="auto" unmountOnExit>
                  <Box>{createExpandedRepaymentInvoiceView(setInvoiceById)(invoice)}</Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ))}
      </TableBody>
    </StyledTable>
  )
}

const StyledTable = styled(Table)`
  *:not(svg) {
    font-size: 14px;
  }
`

const Key = styled.span`
  color: black;
  font-weight: 500;
`

const Value = styled.span`
  color: black;
`

const createExpandedRepaymentInvoiceView = (setInvoiceById: (id: string, data: RepaymentInvoiceDto) => void) =>
  function view(invoice: RepaymentInvoice) {
    const { id, no, orgNo } = invoice
    const btnStyles = { marginLeft: 5 }

    const onDocumentClick = (id: string) => async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        const data = await getAgreementDocument({
          id,
          orgNo,
          creditNo: no
        })
        window.open(data, '_blank')
      } catch (e) {
        console.log(e)
      }
    }

    return (
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '18px', padding: '16px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Key>Ocr</Key>
            <Value>{invoice.ocr || '-'}</Value>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Key>Reminder PDF</Key>
            {invoice.reminderPdf ? (
              <button className="simple" type="button" onClick={onDocumentClick(invoice.reminderPdf)}>
                Open
              </button>
            ) : (
              '-'
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Key>Amortization</Key>
            <Value>{formatCurrency(invoice.amortization, 2)}</Value>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Key>Fee</Key>
            <Value>{formatCurrency(invoice.fee, 2)}</Value>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Key>Admin fee</Key>
            <Value>{formatCurrency(invoice.adminFee, 2)}</Value>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Key>Penalty</Key>
            <Value>{formatCurrency(invoice.penalty, 2)}</Value>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Key>Payments</Key>
            <Value>{formatCurrency(invoice.payments, 2)}</Value>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', padding: '0 16px' }}>
            <ActionButton type="sync" id={id} setInvoiceById={setInvoiceById}>
              Sync
            </ActionButton>
            <ActionButton type="credited" id={id} setInvoiceById={setInvoiceById} style={btnStyles}>
              Credit
            </ActionButton>
            <ActionButton type="cancelled" id={id} setInvoiceById={setInvoiceById} style={btnStyles}>
              Cancel
            </ActionButton>
            <RepaymentInvoiceRemindBtn id={id} onSuccess={(data) => setInvoiceById(id, data)}>
              Remind
            </RepaymentInvoiceRemindBtn>
            <ActionButton type="booked" id={id} setInvoiceById={setInvoiceById} style={btnStyles}>
              Book
            </ActionButton>
            <ActionButton type="debt-collection" id={id} setInvoiceById={setInvoiceById} style={btnStyles} withConfirm={true}>
              Debt collection
            </ActionButton>
          </Box>
        </Box>
        <Summary id={id} />
      </Box>
    )
  }

const ActionButton: React.FC<{
  type: 'cancelled' | 'credited' | 'debt-collection' | 'sync' | 'booked' | 'draft'
  id: string
  setInvoiceById: (id: string, data: RepaymentInvoiceDto) => void
  onSubmit?: () => void
  style?: any
  withConfirm?: boolean
}> = ({ children, style, type, id, setInvoiceById, onSubmit, withConfirm = false }) => {
  const { submitting, handleBtnClick } = useRepaymentInvoiceAction(type, id, setInvoiceById)

  const handlePopClick = async () => {
    await handleBtnClick()
    onSubmit && onSubmit()
  }

  if (withConfirm) {
    return (
      <Popconfirm title="Are you sure?" onConfirm={handlePopClick}>
        <Button style={style} loading={submitting} size="small">
          {children}
        </Button>
      </Popconfirm>
    )
  }

  return (
    <Button style={style} onClick={handlePopClick} loading={submitting} size="small">
      {children}
    </Button>
  )
}

const Summary: React.FC<{ id: string }> = ({ id }) => {
  const { data: summary } = useRepaymentInvoiceSummary(id)

  return <p style={{ fontSize: 16, marginTop: 30, fontWeight: 500 }}>{summary || 'Loading summary...'}</p>
}

export default RepaymentInvoices
