import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import Box, { BoxProps } from '@mui/material/Box'
import Modal, { ModalProps } from '@mui/material/Modal'
import FeatherIcon from 'feather-icons-react'
import React, { useEffect } from 'react'

export type CoreMyntModalProps = {
  title: React.ReactNode
  actions: (Wrapper: React.FunctionComponent) => React.ReactNode
  wrapperProps?: BoxProps
  onClose?: () => void
}

const toggleScrollLock = (enabled: boolean) => {
  document.documentElement.style.overflow = enabled ? 'hidden' : ''
}

export const CoreMyntModal = (props: CoreMyntModalProps & ModalProps) => {
  useEffect(() => {
    toggleScrollLock(props.open)

    return () => toggleScrollLock(false)
  }, [props.open])

  return (
    <StyledModal disableScrollLock {...props}>
      <ModalWrapper {...props.wrapperProps}>
        <TitleContainer>
          <Title>{props.title}</Title>
        </TitleContainer>
        <SquareIconButton onClick={props.onClose}>
          <FeatherIcon icon="x" />
        </SquareIconButton>
        <Content>
          <BodyText>{props.children}</BodyText>
        </Content>
        <Actions>{props.actions(ActionsWrapper)}</Actions>
      </ModalWrapper>
    </StyledModal>
  )
}

const ActionsWrapper = styled(Box)`
  display: flex;
  gap: 8px;
`

const StyledModal = styled(Modal)`
  &:focus-visible {
    outline: none;
    display: none;
  }
`

const TitleContainer = styled(Box)`
  padding: 16px;
`

const Content = styled(Box)`
  grid-column: 1 / span 2;
  padding: 0 16px;
`

const Actions = styled(Box)`
  grid-column: 1 / span 2;
  display: flex;
  padding-top: 32px;
  justify-content: flex-end;
`

const SquareIconButton = styled(IconButton)`
  padding: 7px;
  border-radius: 8px;
  border: 1px solid #eeeeee;
`

const ModalWrapper = styled(Box)`
  align-items: baseline;
  outline: none;
  display: grid;
  grid-template-columns: 1fr 40px;
  min-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: -4px 20px 20px 0px rgba(0, 0, 0, 0.1);

  @keyframes enter {
    from {
      opacity: 0;
      top: 52.5%;
    }
    to {
      opacity: 1;
      transform: 50%;
    }
  }

  animation: enter 600ms cubic-bezier(0.16, 1, 0.3, 1);
`

const BodyText = styled.p`
  color: #68676a;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  margin: 0;
`

// Replace with mui typography
const Title = styled.h1`
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin: 0;
`
