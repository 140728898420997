import { useEffect, useState } from 'react'
import { CompanyCreditDto } from '../api/swagger/definitions/backoffice'
import { getCompanyCredits } from '../api'
import { CreditTypes } from '../helpers/utils'

const creditAPI = (creditType: CreditTypes) => {
  switch (creditType) {
    case CreditTypes.COMPANYCREDIT: {
      return getCompanyCredits
    }

    default: {
      throw new Error(`${creditType} api does not exist`)
    }
  }
}

interface ICreditDetails {
  details: any
  loading: boolean
  fetchDetails: () => void
}

export const useCreditDetails = (
  creditId: string,
  creditType: CreditTypes,
  onSuccess?: (data: CompanyCreditDto) => void
): ICreditDetails => {
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState<CompanyCreditDto>()

  const fetchDetails = async () => {
    try {
      const data = await creditAPI(creditType)(creditId)
      setDetails(data)
      setLoading(false)

      onSuccess && onSuccess(data)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchDetails()
  }, [creditId])

  return {
    details,
    loading,
    fetchDetails
  }
}
