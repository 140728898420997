import MaterialTable from '@material-table/core'
import { Button } from '../components/Button'
import { Checkbox } from '../components/Checkbox'
import React, { useMemo } from 'react'
import { CustomerRole, KycPerson } from '../api/swagger/definitions/backoffice'
import IconMail24 from '../figma/svgs/IconMail24'
import { useCreateKycForPerson, useKycPeople } from '../api/react-query'
import { sendKYCEmail } from '../api'
import useMaterialNotification from '../hooks/useMaterialNotification'
import Popconfirm from './Popconfirm'

interface CustomerDetailsKYCProps {
  submitting: boolean
  handleOnBeneficialCheckboxClick: any
  customerId: string
}

const isSSN = (ssn?: string) => {
  if (typeof ssn !== 'string') return false

  const filteredSSN = ssn.replaceAll(/[^0-9]/g, '')

  return filteredSSN.length >= 10 && filteredSSN.length <= 12
}

const renderBoolean = (property: boolean | undefined | null) => {
  if (property === null || property === undefined) {
    return '-'
  }

  return property ? <span style={{ color: 'red', fontSize: 18, fontWeight: 500 }}>True</span> : <span>False</span>
}

const CustomerDetailsKYC: React.FC<CustomerDetailsKYCProps> = ({ submitting, handleOnBeneficialCheckboxClick, customerId }) => {
  // const { analysis, handlePersonRefresh } = kycData
  const notify = useMaterialNotification()

  const { data: analysis } = useKycPeople(customerId)

  const personKycMutation = useCreateKycForPerson(customerId)

  const sorted = useMemo(
    () =>
      analysis?.rows?.sort((a, b) => {
        const nameA = a.name?.toLowerCase()
        const nameB = b.name?.toLowerCase()

        if (!nameA || !nameB) return 0

        if (nameA < nameB) return -1
        if (nameA > nameB) return 1

        return 0
      }),
    [analysis]
  )

  return (
    <div>
      <h2 style={{ marginBottom: 0 }}>People</h2>
      {!!analysis?.oldestData && <p>{analysis.beneficialOwnerText}</p>}
      <MaterialTable
        title=""
        columns={[
          {
            title: 'SSN',
            field: 'ssn'
          },
          {
            title: 'Name',
            field: 'name'
          },
          {
            title: 'Roles',
            field: 'roles',
            render: ({ roles }: any) => (roles.length > 0 ? roles.map((a) => a.name).join(', ') : '-')
          },
          {
            title: 'Mynt roles',
            field: 'myntRoles',
            render: ({ myntRoles: roles }: any) => (roles.length > 0 ? roles.join(', ') : '-')
          },
          {
            title: 'PEP',
            field: 'pepFlag',
            align: 'center' as const,
            sorting: false,
            render: ({ pepFlag }) => renderBoolean(pepFlag)
          },
          {
            title: 'Sanctioned',
            field: 'sanctionFlag',
            align: 'center' as const,
            sorting: false,
            render: ({ sanctionFlag }) => renderBoolean(sanctionFlag)
          },
          {
            title: '',
            align: 'center' as const,
            sorting: false,
            render: (person: KycPerson) => (
              <Button
                disabled={!isSSN(person.ssn)}
                size="small"
                type="primary"
                onClick={() => {
                  isSSN(person.ssn) && personKycMutation.mutateAsync(person.ssn as string)
                }}
              >
                Refresh
              </Button>
            )
          },
          {
            title: 'Beneficial owner',
            align: 'center' as const,
            sorting: false,
            render: (person: KycPerson) => (
              <Checkbox
                checked={Array.from(person.myntRoles || []).includes(CustomerRole.BENEFICIAL_OWNER)}
                onClick={() => handleOnBeneficialCheckboxClick(person)}
                disabled={submitting}
              />
            )
          },
          {
            title: 'Send KYC Mail',
            align: 'center' as const,
            sorting: false,
            render: (person: KycPerson) => {
              const personId = person.personId || ''
              return (
                <Popconfirm
                  title="This will send an kyc email. Are you sure?"
                  onConfirm={async () => sendKYCEmail(customerId, personId).then(() => notify('KYC Email sent: 👍'))}
                >
                  <IconMail24 />
                </Popconfirm>
              )
            }
          }
        ]}
        data={!!analysis?.oldestData ? sorted || [] : []}
      />
    </div>
  )
}

export default CustomerDetailsKYC
