import * as React from 'react';

import ButtonTertiaryClickedStyled from './ButtonTertiaryClickedStyled';

type ButtonTertiaryClickedProps = any

const ButtonTertiaryClicked: React.FC<ButtonTertiaryClickedProps> = (props) => (
  <ButtonTertiaryClickedStyled  {...props}>{props.children ? props.children : ""}</ButtonTertiaryClickedStyled>
);

export default ButtonTertiaryClicked;