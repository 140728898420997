import styled from '@emotion/styled';
import ButtonSecondaryHoverCss from './ButtonSecondaryHoverCss';


interface ButtonSecondaryHoverProps {
  children: any;
  [propName: string]: {};
}

// Extend the below as needed
const ButtonSecondaryHoverStyled = styled.div<ButtonSecondaryHoverProps>`
  ${ButtonSecondaryHoverCss};
`;

export default ButtonSecondaryHoverStyled;