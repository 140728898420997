export const GET_KYC_RESPONSES = 'GET_KYC_RESPONSES'
export const KYC_PEOPLE = 'KYC_PEOPLE'
export const UPDATE_KYC_PEOPLE = 'UPDATE_KYC_PEOPLE'
export const CREATE_KYC_PERSON = 'CREATE_KYC_PERSON'
export const GET_ALL_KYC_ROARING = 'GET_ALL_KYC_ROARING'
export const GET_PERSONS = 'GET_PERSONS'
export const AUTOGIRO = 'AUTOGIRO'
export const PE_REPORTS = 'PE_REPORTS'
export const CUSTOMER_LOG = 'CUSTOMER_LOG'
export const CUSTOMER_NOTES = 'CUSTOMER_NOTES'
export const BUREAU_LIST = 'BUREAU_LIST'
export const BUREAU = 'BUREAU'
export const BUREAU_RESET = 'BUREAU_RESET'
export const BUREAU_ASSIGN = 'BUREAU_ASSIGN'
export const BUREAU_CLIENTS = 'BUREAU_CLIENTS'
export const GET_ENFUCE_PAYOUTS = 'GET_ENFUCE_PAYOUTS'
export const UPDATE_ENFUCE_PAYOUTS = 'UPDATE_ENFUCE_PAYOUTS'
export const GET_PAYMENTS = 'GET_PAYMENTS'
export const GET_AUTO_GIRO_APPROVAL = 'GET_AUTO_GIRO_APPROVAL'
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const CARD_CREDITS = 'CARD_CREDITS'
export const CARD_ACCOUNT = 'CARD_ACCOUNT'
export const CARD_ACCOUNTS = 'CARD_ACCOUNTS'
export const GET_DELIVERY_ADDRESS = 'GET_DELIVERY_ADDRESS'
export const ALL_CUSTOMERS_V2 = 'ALL_CUSTOMERS_V2'
export const CREDIT_OVERRIDES = 'CREDIT_OVERRIDES'
export const REPAYMENT_MODELS = 'REPAYMENT_MODELS'
export const INVOICE_SUMMARY = 'INVOICE_SUMMARY'
export const INVOICE_CONFIG = 'INVOICE_CONFIG'
export const ACCOUNTING_YEARS = 'ACCOUNTING_YEARS'
export const ACCOUNTING_ACCOUNTS = 'ACCOUNTING_ACCOUNTS'
export const VOUCHER_RESULT = 'VOUCHER_RESULT'
export const All_VOUCHERS = 'All_VOUCHERS'
export const ALL_CREDITS = 'ALL_CREDITS'
export const CUSTOMER_FILES = 'CUSTOMER_FILES'
export const SEARCH = 'SEARCH'
export const SMS_TEMPLATES = 'SMS_TEMPLATES'
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'

export const AGREEMENT = 'AGREEMENT'
export const ALL_AGREEMENTS = 'ALL_AGREEMENTS'

export const INVOICES = 'INVOICES'
export const CUSTOMER_V2 = 'CUSTOMER_V2'
export const GET_PAYMENT = 'GET_PAYMENT'
export const GET_PAYMENT_RECEIVER = 'GET_PAYMENT_RECEIVER'
export const PAYMENT_WHITELIST = 'PAYMENT_WHITELIST'
export const ADD_PAYMENT_WHITELIST = 'ADD_PAYMENT_WHITELIST'
export const CARDS = 'CARDS'
export const CARDS_V2 = 'CARDS_V2'
export const CORPORATE_CARDS = 'CORPORATE_CARDS'
export const PRIVATE_CARDS = 'PRIVATE_CARDS'
export const PRIVATE_CARDS_ID_IN_PAYLOAD = 'PRIVATE_CARDS_ID_IN_PAYLOAD'
export const PLASTICS = 'PLASTICS'
export const CARD_CONFIG = 'CARD_CONFIG'
export const RISK_CONFIG = 'RISK_CONFIG'
export const ACTIVATION_GUIDE = 'ACTIVATION_GUIDE'
export const PAYMENT_REQUESTS = 'PAYMENT_REQUESTS'
export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST'
export const PATCH_PAYMENT_REQUESTS = 'PATCH_PAYMENT_REQUESTS'
export const ACTIVATION_GUIDE_VISIBILITY = 'ACTIVATION_GUIDE_VISIBILITY'
export const PATCH_CARD_V1 = 'PATCH_CARD_V1'

export const UPDATE_AUTOGIRO_APPROVAL = 'UPDATE_AUTOGIRO_APPROVAL'

export const GET_TRANSACTIONS_ACTIVATION_STATUS = 'GET_TRANSACTIONS_ACTIVATION_STATUS'
export const GET_MANDATES_APPROVAL = 'GET_MANDATES_APPROVAL'
export const UPDATE_MANDATES_APPROVAL = 'UPDATE_MANDATES_APPROVAL'
export const REFRESH_ADDRESS = 'REFRESH_ADDRESS'
export const GET_SUBSCRIPTION_PLAN_TYPE = 'GET_SUBSCRIPTION_PLAN_TYPE'

export const SEND_WELCOME_EMAIL = 'SEND_WELCOME_EMAIL'

export const PENALTY_FEE = 'PENALTY_FEE'
