import { AntiloopImageType } from '../../tiger/interfaces/Antiloop'
import AntiloopVariant from '../../tiger/interfaces/AntiloopVariant'
import useIsMobile from './useIsMobile'

export default function useImageVariant(imageKey: AntiloopImageType): { width: any; height: any } {
  const isMobile = useIsMobile()
  let variantStyle = isMobile ? imageKey.variants[AntiloopVariant.MOBILE] : imageKey.variants[AntiloopVariant.DESKTOP]
  if (!variantStyle) variantStyle = imageKey.variants[AntiloopVariant.DESKTOP] || imageKey.variants[AntiloopVariant.MOBILE]
  return variantStyle as { width: any; height: any }
}
