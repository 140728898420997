import styled from '@emotion/styled'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Spacings from '../figma/tokens/Spacings'
import { colors } from 'themes'

interface Props {
  text: string
  path: string
  onDrawerClose?: () => void
}

const HeaderLink: React.FC<Props> = ({ text, path, onDrawerClose }) => {
  const location = useLocation()

  const isActivePage = (key: string) => location.pathname.includes(key)

  return (
    <StyledLink to={path} onClick={onDrawerClose}>
      <Header active={isActivePage(path)}>{text}</Header>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  padding: ${Spacings.small} 0;
  white-space: nowrap;
`

const Header = styled.span<{ active: boolean }>`
  margin: 0;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 19.2px;
  color: ${(props) => (props.active ? colors.brand.blue.CTA : 'rgba(27, 28, 31, 1)')};
  transition: all 100ms;

  &:hover {
    color: ${colors.brand.blue.CTA} !important;
  }
`

export default HeaderLink
