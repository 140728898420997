import { MutationFunction, MutationKey, useMutation, useQueryClient } from '@tanstack/react-query'

const useMutationWithInvalidation = <Dto, DtoPayload>(
  { queryKey }: { queryKey: MutationKey },
  fn: MutationFunction<Dto, DtoPayload>,
  invalidateCacheKeyPrefix?: MutationKey
) => {
  const queryClient = useQueryClient()

  return useMutation(queryKey, fn, {
    onSuccess: () => {
      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach((key) => queryClient.invalidateQueries([key]))
      }
    }
  })
}

export default useMutationWithInvalidation
