import React from 'react'
import { CardPayoutsTable } from './CardPayoutsTable'

type Props = {
  customerId: string
}

export default function CardPayouts({ customerId }: Props) {
  return <CardPayoutsTable customerId={customerId} />
}
