import styled from '@emotion/styled'
import React from 'react'
import Colors from '../../figma/panda/Colors'
import Durations from '../../figma/tokens/Durations'
import Spacings from '../../figma/tokens/Spacings'
import Translates from '../../figma/tokens/Translates'

export type FlexDirection = 'row' | 'column' | 'column-reverse' | 'row-reverse'
export type FlexWrap = 'wrap' | 'nowrap' | 'wrap-reverse'
export type FlexJustify = 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly' | 'stretch'
export type FlexAlign = 'center' | 'flex-start' | 'flex-end' | 'normal' | 'stretch' | 'baseline'
export type FlexAlignSelf = 'center' | 'flex-start' | 'flex-end' | 'normal'

export type FigmaBoxProps = {
  left?: boolean | string
  right?: boolean | string
  top?: boolean | string
  bottom?: boolean | string
  spacing?: string
  fullWidth?: boolean
  fullPadding?: boolean
  link?: boolean
  flex?: number | string
  gap?: string
  direction?: FlexDirection
  flexWrap?: FlexWrap
  justify?: FlexJustify
  align?: FlexAlign
  alignSelf?: FlexAlignSelf
  alignText?: 'center' | 'left' | 'right'
  position?: 'relative' | 'absolute' | 'static'
  onClick?: (event?) => void
}

const FlexboxStyles = `
  display: flex;
  flex-direction: column;
  width: fit-content;
`

type DeterminePaddingPropsOverloads = {
  (padding?: string | boolean, spacing?: string, fullPadding?: boolean, fallback?: string): string
}

// If a string value is given to the top|right|bottom|left values, use that value instead of the padding
// This solves an issue where you want a different padding in one of those directions
// Without this you need to use two FigmaBoxes to achieve different paddings in different directions
const determinePadding: DeterminePaddingPropsOverloads = (padding, spacing, fullPadding, fallback) => {
  const defaultValue = '0'

  if (typeof padding === 'string') {
    return padding || defaultValue
  }

  if (padding || fullPadding) {
    return spacing || fallback || defaultValue
  }

  return defaultValue
}

export default React.memo(styled.div<FigmaBoxProps>`
  ${FlexboxStyles};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  flex-direction: ${({ direction }) => direction || `column`};
  text-align: ${({ alignText }) => !!alignText && `${alignText}`};
  justify-content: ${({ justify }) => !!justify && `${justify}`};
  align-items: ${({ align }) => !!align && `${align}`};
  padding-left: ${({ left, spacing, fullPadding }) => determinePadding(left, spacing, fullPadding, Spacings.small)};
  padding-right: ${({ right, spacing, fullPadding }) => determinePadding(right, spacing, fullPadding, Spacings.small)};
  padding-top: ${({ top, spacing, fullPadding }) => determinePadding(top, spacing, fullPadding, Spacings.small)};
  padding-bottom: ${({ bottom, spacing, fullPadding }) => determinePadding(bottom, spacing, fullPadding, Spacings.small)};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  ${({ link }) => (link ? LinkStyles : '')};
  align-self: ${({ alignSelf }) => !!alignSelf && `${alignSelf}`};
  position: ${({ position }) => !!position && `${position}`};
  flex: ${({ flex }) => !!flex && `${flex}`};
  gap: ${({ gap }) => !!gap && `${gap}`};
`)

export const LinkStyles = `
  cursor: pointer;

  :hover {
    div span {
      color:${Colors.bluePrimary700} !important;
    }
  }

  transition: transform ease ${Durations.short}ms;

  &:active {
    transform: ${Translates.translateYSmall};
  }

  &:focus {
    outline: 0;
  }
`
