/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CardCreditType = {
    REPAYMENT_INVOICE: 'REPAYMENT_INVOICE',
    PAYMENT_REQUEST: 'PAYMENT_REQUEST'
} as const;

export type CardCreditType = typeof CardCreditType[keyof typeof CardCreditType];



