import { getFeatureFlagsFromUrl } from './helpers/utils'

const { REACT_APP_CONFIG } = process.env

const getEnv = (hostname?: string) => {
  if (!hostname && typeof window !== 'undefined') hostname = window.location.hostname

  if (!!REACT_APP_CONFIG) return REACT_APP_CONFIG
  else if (hostname === 'bo.mynt.se') return 'production'
  else if (hostname === 'bo.dev.mynt.se') return 'development'
  else if (hostname?.includes('bo.proxy')) return 'developmentOnBranch'
  else if (hostname === 'localhost') return 'local'
  else if (hostname === 'bo.local.dev.mynt.se') return 'localinfra'
  else if (hostname && hostname.endsWith('dev.mynt.se')) return 'development'
  return 'production'
}

const env = getEnv()

const defaultConfiguration = {
  name: 'default',
  enableSentry: true,
  enableSentryTracing: true,
  autoLoginCustomerId: '9f27cda1-493b-43fc-a8df-ec79321404d5', // Funky AB
  autoLoginPersonId: 'ea1b39d9-f595-4e66-865b-ea805b65d792', // Anders Bäck
  autoLoginUsername: process.env.REACT_APP_USERNAME,
  autoLoginPassword: process.env.REACT_APP_PASSWORD,
  appBaseUrl: 'anAwesomeUrl',
  boBaseUrl: process.env.REACT_APP_BO_API_URL ?? 'https://api-bo.dev.mynt.se',
  gazelleWebUrl: 'https://app.dev.mynt.se',
  enableRemoveSvgInPath: true,
  enableNativeSVGR: false,
  enableEmuTokensNative: false,
  enableLoggingOfConfig: false,
  enableAutoLogin: false,
  enableSpacingForFigmaLineBreak: false,
  enableAlwaysShowActivateCardButton: false,
  enableVouchersInfoInInvoiceInformation: false,
  enableInvoiceHistory: false,
  enableInvoiceFiltering: true,
  enableCardPayoutFiltering: true,
  enablePaymentsFiltering: true,
  enableNotificationErrorMaterial: true,
  enableLokaliseTranslations: false,
  enableNewChartComponents: false,
  enableLogging: true,
  enableLoggingMissedTexts: true,
  enableLoggingMissedColors: true,
  enableSendWelcomeEmail: true,
  enableSendCreditAgreementButton: false
}

const development = {
  ...defaultConfiguration,
  name: 'development',
  enableNewChartComponents: true
}

const local = {
  ...development,
  name: 'local',
  gazelleWebUrl: 'http://localhost:3001',
  enableAutoLogin: true,
  enableSentryTracing: false
}

const developmentOnBranch = {
  ...development,
  name: 'developmentOnBranch'
}

const localDev = {
  ...defaultConfiguration,
  name: 'localDev',
  boBaseUrl: process.env.REACT_APP_BO_API_URL || defaultConfiguration.boBaseUrl,
  enableNewChartComponents: true
}

const localinfra = {
  ...local,
  name: 'localinfra',
  boBaseUrl: process.env.OVERRIDE_REACT_APP_BO_DEV_API_URL || 'http://local.dev.mynt.se:8080'
}

export const production = {
  ...defaultConfiguration,
  name: 'production',
  boBaseUrl: 'https://api-bo.mynt.se',
  gazelleWebUrl: 'https://app.mynt.se',
  enableSentry: true,
  enableNewChartComponents: true,
  enableSendWelcomeEmail: true
}

const configurations = {
  development,
  local,
  developmentOnBranch,
  production,
  localDev,
  localinfra
}

let config = configurations[env] || configurations.production

config = { ...config, ...getFeatureFlagsFromUrl() }

// eslint-disable-next-line no-console
if (config.enableLoggingOfConfig) console.log('Running with config', config.name, config)

export default config as typeof defaultConfiguration
