import { MutationFunction, MutationKey, useMutation as _useMutation, useQueryClient } from '@tanstack/react-query'
import { getQueriesData } from './helpers'

type HookOptions<Dto> = {
  queryKey: MutationKey
  getId: (item: Dto) => string // Method used to find an items identifier to match and replace old data
  merge?: (item: Dto, prev: Dto) => Dto // Optional custom merge function to merge the new item with the previous one
}

export const usePatchListItemMutation = <Dto, PayloadDto>(
  { queryKey, getId, merge }: HookOptions<Dto>,
  fn: MutationFunction<Dto, PayloadDto>,
  invalidateCacheKeyPrefix?: MutationKey
) => {
  const queryClient = useQueryClient()

  // Allow to pass a custom merge function to merge the new item with the previous one
  const _merge = (newItem: Dto, prevItem: Dto) => merge?.(newItem, prevItem) ?? { ...prevItem, ...newItem }

  const mapUpdatedItem = (item: Dto) => (prev: Dto) => {
    const prevId = getId(prev)
    const updatedId = getId(item)

    if (prevId === updatedId) return _merge(item, prev)

    return prev
  }

  return _useMutation(queryKey, fn, {
    onSuccess: (item) => {
      const previous = getQueriesData<Dto[]>(queryClient, queryKey) || []
      const data = previous.map(mapUpdatedItem(item))

      queryClient.setQueriesData(queryKey, data)

      return { previous, data }
    },
    onSettled: () => {
      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach((key) => queryClient.invalidateQueries([key]))
      }
    }
  })
}

type OpportunisticHookOptions<Dto, DtoPayload> = {
  queryKey: MutationKey
  getId: (item: Dto) => string // Method used to find an items identifier to match and replace old data
  getIdOppertunistic: (item: DtoPayload) => string // Method used to find an items identifier to match and replace old data oppertunistically, i.e. before the mutation has completed
  merge?: (item: Dto, prev: Dto) => Dto // Optional custom merge function to merge the new item with the previous one
  oppertunisticMerge?: (item: DtoPayload, prev: Dto) => Dto // Optional custom merge function to merge the new item with the previous one oppertunistically with correct payload types
}

export const usePatchListItemMutationOpportunistic = <Dto, PayloadDto>(
  { queryKey, getId, getIdOppertunistic, merge, oppertunisticMerge }: OpportunisticHookOptions<Dto, PayloadDto>,
  fn: MutationFunction<Dto, PayloadDto>,
  invalidateCacheKeyPrefix?: MutationKey
) => {
  const queryClient = useQueryClient()

  // Allow to pass a custom merge function to merge the new item with the previous one
  const _merge = (newItem: Dto, prevItem: Dto) => merge?.(newItem, prevItem) ?? { ...prevItem, ...newItem }
  const _mergeOppertunistic = (newItem: PayloadDto, prevItem: Dto) => oppertunisticMerge?.(newItem, prevItem) ?? { ...prevItem, ...newItem }

  const mapUpdatedItem = (item: Dto) => (prev: Dto) => {
    const prevId = getId(prev)
    const updatedId = getId(item)

    if (prevId === updatedId) return _merge(item, prev)

    return prev
  }

  const mapUpdatedItemOppertunistic = (item: PayloadDto) => (prev: Dto) => {
    const idKeyOppertunistic = getIdOppertunistic(item)
    const prevId = getId(prev)

    if (idKeyOppertunistic === prevId) return _mergeOppertunistic(item, prev)

    return prev
  }

  return _useMutation(queryKey, fn, {
    onMutate: async (item) => {
      await queryClient.cancelQueries(queryKey)

      const previous = getQueriesData<Dto[]>(queryClient, queryKey) || []
      const data = previous.map(mapUpdatedItemOppertunistic(item))

      queryClient.setQueriesData(queryKey, data)

      return { previous, data }
    },
    onError: (error, newItem, context) => {
      if (context?.previous) {
        queryClient.setQueriesData(queryKey, context.previous)
      }
    },
    onSuccess: (item) => {
      const previous = getQueriesData<Dto[]>(queryClient, queryKey) || []
      const data = previous.map(mapUpdatedItem(item))

      queryClient.setQueriesData(queryKey, data)

      return { previous, data }
    },
    onSettled: () => {
      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach((key) => queryClient.invalidateQueries([key]))
      }
    }
  })
}
