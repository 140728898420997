import MaterialTable, { Filter, MTableToolbar } from '@material-table/core'
import qs from 'query-string'
import React from 'react'
import { BackOfficePayoutDataResponseDtoV2, EnfucePayoutState } from '../api/swagger/definitions/backoffice'
import config from '../config'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import useLanguage from '../flamingo/hooks/useLanguage'
import { useEnfucePayoutsV2, useUpdateEnfucePayoutV2 } from '../api/react-query'
import { getPayoutsActions } from '../helpers/PayoutsActionsHelper'
import { GetTableOptions } from '../helpers/getTableOptions'
import { formatCurrency } from '../helpers/utils'
import FigmaBox from '../mynt-components/components/FigmaBox'
import JSONDetailPanel from './JSONDetailPanel'
import TableWrapper from './TableWrapper'
import { getText } from '../helpers/getText'
import TextKeys from '../libs/TextKeys'

const STATE = 'state'

export function CardPayoutsTable({ customerId }: { customerId?: string }) {
  const language = useLanguage()
  const mutationUpdate = useUpdateEnfucePayoutV2()
  const { id } = qs.parse(window.location.search) as Record<string, string>

  const [stateFilter, setStateFilter] = React.useState([EnfucePayoutState.CREATE_PAYOUT, EnfucePayoutState.DRAFT, EnfucePayoutState.BOOKED])

  const { data: payouts = [] } = useEnfucePayoutsV2(customerId, stateFilter)

  const onFilterChange = (filters: Filter<any>[]) => {
    const state = filters.find((f) => f.column.field === STATE)
    if (state) setStateFilter(state.value)
  }

  return (
    <TableWrapper>
      <MaterialTable
        onFilterChange={onFilterChange}
        components={{
          Toolbar: (props) => (
            <FigmaBox fullWidth direction={'row'} align={'center'} justify={'stretch'} bottom={Spacings.medium}>
              <FigmaBox fullWidth>
                <MTableToolbar {...props} />
              </FigmaBox>
            </FigmaBox>
          )
        }}
        columns={[
          {
            title: 'id',
            field: 'id',
            hidden: true,
            searchable: true
          },
          {
            title: getText(TextKeys.customersCardPayoutsTableBookedLabel, language),
            field: 'bookDate',
            defaultSort: 'desc'
          },
          {
            title: getText(TextKeys.customersCardPayoutsTableLableState, language),
            field: STATE,
            lookup: EnfucePayoutState,
            defaultFilter: stateFilter,
            filterCellStyle: {
              overflow: 'hidden',
              maxWidth: `${Spacings.max}`
            }
          },
          {
            title: getText(TextKeys.customersCardPayoutsTableLableCustomer, language),
            field: 'companyName'
          },
          {
            title: getText(TextKeys.customersCardPayoutsTableLablePayoutType, language),
            field: 'payoutType'
          },
          {
            title: getText(TextKeys.customersCardPayoutsTableLableSubtype, language),
            field: 'enfucePayoutType'
          },
          {
            title: 'Amount',
            field: 'amount.value',
            render: (payout: BackOfficePayoutDataResponseDtoV2) => formatCurrency(payout.amount)
          },
          {
            title: 'Currency',
            field: 'amount.currency'
          }
        ]}
        data={payouts}
        title={getText(TextKeys.customersCardPayoutsPayoutsHeading, language)}
        detailPanel={({ rowData }: { rowData: BackOfficePayoutDataResponseDtoV2 }) => <JSONDetailPanel src={rowData} />}
        options={GetTableOptions({
          headerTextKey: TextKeys.accountingPayoutsDate,
          extraOptions: {
            filtering: config.enableCardPayoutFiltering,
            searchText: id,
            headerStyle: { backgroundColor: ColorsDeprecated.surfaceOnWhite }
          }
        })}
        actions={
          getPayoutsActions({
            mutation: (payout) => mutationUpdate.mutateAsync(payout),
            isLoading: mutationUpdate.isLoading,
            language
          }) as any
        }
      />
    </TableWrapper>
  )
}
