import { MutationFunction, MutationKey, useMutation as _useMutation, useQueryClient } from '@tanstack/react-query'
import merge from 'deepmerge'
import { getQueriesData } from './helpers'

export const usePatchMutation = <Dto, DtoPayload>(
  { queryKey }: { queryKey: MutationKey },
  fn: MutationFunction<Dto, DtoPayload>,
  invalidateCacheKeyPrefix?: MutationKey
) => {
  const queryClient = useQueryClient()

  return _useMutation(queryKey, fn, {
    onMutate: async (item) => {
      const previous = getQueriesData<Dto>(queryClient, queryKey)

      if ([previous, item].some(Array.isArray)) {
        console.error({ previous, item })
        return Promise.reject('usePatchMutation: previous and item must be objects, not arrays')
      }
    },
    onSuccess: (item) => {
      const previous = getQueriesData<Dto>(queryClient, queryKey)
      const data = previous ? merge<Dto, Dto>(previous, item) : item

      queryClient.setQueriesData(queryKey, data)

      return { previous, data }
    },
    onSettled: () => {
      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach(async (key) => queryClient.invalidateQueries([key]))
      }
    }
  })
}

export const usePatchMutationOppertunistic = <Dto, DtoPayload>(
  { queryKey }: { queryKey: MutationKey },
  fn: MutationFunction<Dto, DtoPayload>,
  invalidateCacheKeyPrefix?: MutationKey
) => {
  const queryClient = useQueryClient()

  return _useMutation(queryKey, fn, {
    onMutate: async (item) => {
      await queryClient.cancelQueries(queryKey)

      const previous = getQueriesData<Dto>(queryClient, queryKey)

      if ([previous, item].some(Array.isArray)) {
        console.error({ previous, item })
        return Promise.reject('usePatchMutationOppertunistic: previous and item must be objects, not arrays')
      }

      const data = previous ? merge<Dto, DtoPayload>(previous, item) : item

      queryClient.setQueriesData(queryKey, data)

      return { previous, data }
    },
    onSuccess: (item) => {
      const previous = getQueriesData<Dto>(queryClient, queryKey)
      const data = previous ? merge<Dto, Dto>(previous, item) : item

      queryClient.setQueriesData(queryKey, data)

      return { previous, data }
    },
    onError: (error, item, context) => {
      if (context?.previous) {
        queryClient.setQueriesData(queryKey, context.previous)
      }
    },
    onSettled: () => {
      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach(async (key) => queryClient.invalidateQueries([key]))
      }
    }
  })
}
