import React, { CSSProperties, useEffect } from 'react'
import config from '../../config'
import { getText } from '../../helpers/getText'
import { AntiloopTextType, Language } from '../../tiger/interfaces/Antiloop'
import useTextVariant from '../hooks/useTextVariant'

export type FigmaTextProps = {
  textKey: AntiloopTextType
  element: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ style: any }>>>
  style?: CSSProperties
  language: Language
  text?: string
  enableSpacingForFigmaLineBreak?: boolean
  ignoreNewLine?: boolean
}

const textKeyWarnings = new Set()

const FigmaText: React.FC<React.PropsWithChildren<React.PropsWithChildren<FigmaTextProps>>> = ({
  textKey,
  style,
  text,
  language,
  element,
  enableSpacingForFigmaLineBreak = false,
  ignoreNewLine = false,
  ...props
}) => {
  const variantStyle = useTextVariant(textKey)
  text = typeof text === 'string' ? text : getText(textKey, language)
  const texts = ignoreNewLine ? [text] : text.split('\n')
  const lineHeight = getLineHeight(variantStyle?.style)

  // This shows the textkey name when we hover a text element
  const textKeyTitle = {
    title: textKey.name
  }

  const isProd = config.name === 'production'

  // Log Manrope usage in Sentry so we can fix it
  useEffect(() => {
    if (!['Inter', 'Mynt Sans Display'].includes(variantStyle?.style?.fontFamily ?? '') || !textKeyWarnings.has(textKey.name)) {
      textKeyWarnings.add(textKey.name)

      config.enableLogging &&
        config.enableLoggingMissedTexts &&
        // eslint-disable-next-line no-console
        console.warn(`Manrope font detected in the following textkeys`, Array.from(textKeyWarnings))
    }
  }, [])

  return (
    <>
      {texts.map((text, key, arr) => {
        if (enableSpacingForFigmaLineBreak && lineHeight && !!arr[key + 1]) {
          style = { ...style, paddingBottom: lineHeight }
        }

        const prodProps = { ...props, key, style: { ...variantStyle?.style, ...style } }
        const devProps = {
          ...prodProps,
          ...textKeyTitle,
          onClick: (e) => {
            if (e.altKey) {
              window.navigator.clipboard.writeText(textKey.name)
              e.stopPropagation()
              e.preventDefault()
            }
          }
        }

        return React.createElement(element, isProd ? prodProps : devProps, text)
      })}
    </>
  )
}

const getLineHeight = (style?: React.CSSProperties): number | undefined => {
  if (!style) return undefined
  const { lineHeight } = style
  if (!lineHeight) return
  if (typeof lineHeight === 'number') return lineHeight as number
  return parseInt((lineHeight as string).replace('px', ''))
}

export default FigmaText
