export const colors = {
  brand: {
    black: '#1C1B1F',
    white: '#FFFFFF',
    gray: {
      '100': '#F9F9F9',
      '200': '#F2F2F2',
      '300': '#8D8D8F',
      '400': '#555457'
    },
    beige: {
      '100': '#F8F3EC',
      '200': '#F1EDEB',
      '300': '#DFD8D4',
      '400': '#CEC4BE',
      '500': '#BEB1A9',
      '600': '#AFA29A'
    },
    blue: {
      '100': '#EAEEF5',
      '200': '#C1CDE4',
      '300': '#5E77A9',
      '400': '#304F8E',
      '500': '#163062',
      '600': '#191F3C',
      CTA: '#2A73FF'
    }
  },
  product: {
    alert: {
      '600': '#78530D',
      '500': '#AB8131',
      '400': '#FFC85F',
      '300': '#FFD680',
      '200': '#FFECC5',
      '100': '#FFF5E2'
    },
    blue: {
      '100': '#EAF1FF',
      '200': '#CCDDFF',
      '300': '#AFCBFF',
      '400': '#80ABFF',
      '500': '#558FFF',
      '600': '#2A73FF',
      '700': '#225CCC'
    },
    error: {
      '100': '#FDF2F2',
      '200': '#FDE8E8',
      '300': '#FBD5D5',
      '400': '#F8B4B4',
      '500': '#F98080',
      '600': '#FF4752'
    },
    success: {
      '100': '#DFF8ED',
      '200': '#B8EED7',
      '300': '#94E5C3',
      '400': '#71DDB0',
      '500': '#4DD49C',
      '600': '#2ACC88',
      '700': '#0D8251'
    },
    reminder: {
      '100': '#FFDDF5',
      '200': '#EFB6DE',
      '300': '#D284BB',
      '400': '#AF699B',
      '500': '#944F80',
      '600': '#7E3F6C'
    },
    // AKA base
    grey: {
      '100': '#FCFCFC',
      '200': '#F5F5F5',
      '300': '#EEEEEE',
      '350': '#C7C7C7',
      '400': '#B3B3B4',
      '500': '#68676A',
      '600': '#585759'
    },
    beige: {
      '50': '#F9F8F5',
      '100': '#F8F3EC'
    },
    white: '#FFFFFF',
    black: {
      '50': '#1c1b1f80',
      '100': '#1C1B1F'
    },
    card: '#101828'
  },
  category: {
    gray: '#C7C7C7',
    sand: '#ECBEA2',
    pink: '#EDB0B9',
    purple: '#DA89B3',
    violet: '#BF9BE4',
    blue: '#9CBDF4',
    green: '#92D2A4',
    moss: '#BDD292',
    apple: '#C0E3A4',
    yellow: '#F8E67F'
  },
  decorative: {
    tiger: {
      '100': '#FF8057',
      '10': '#ECBEA2'
    },
    violet: {
      '10': '#EEEBF6',
      '100': '#5A34A1'
    },
    virtual: '#107696'
  },
  background: {
    blurred: '#25252554',
    cloud: '#FBFBFC',
    gazelle: '#F8F8F7',
    beige: {
      '50': '#F9F8F5',
      '100': '#F8F3EC'
    }
  }
}

export type ColorTheme = typeof colors
