import styled from '@emotion/styled'
import React from 'react'
import Colors from '../../figma/panda/Colors'
import BorderRadius from '../../figma/tokens/BorderRadius'
import Shadows from '../../figma/tokens/Shadows'
import Spacings from '../../figma/tokens/Spacings'
import { DEFAULT_LINE_COMPONENT_SIZE } from '../StrongHardCodedSizes'
import FigmaBox from './FigmaBox'

type LineProps = {
  vertical?: boolean
  fullHeight?: boolean
  marginTop?: string
  marginBottom?: string
  marginLeft?: string
  marginRight?: string
  color?: string
}

export const Line = React.memo(styled(FigmaBox)<LineProps>`
  ${({ vertical }) => (vertical ? `width: ${DEFAULT_LINE_COMPONENT_SIZE} !important;` : '')}
  ${({ vertical }) => (vertical ? '' : `height: ${DEFAULT_LINE_COMPONENT_SIZE};`)}
  ${({ fullHeight }) => (fullHeight ? `height: 100%;` : '')}
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom};` : '')}
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft};` : '')}
  ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight};` : '')}
  background: ${({ color }) => (color ? color : Colors.base300)};
`)

export const UnstyledButton = styled.button<{ textAlign?: 'left' | 'right' | 'center'; disabledColor?: string }>`
  min-height: 0 !important;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none !important;
  outline: none !important;
  cursor: pointer;
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  &:hover,
  &:focus-visible {
    opacity: 0.8;
  }

  &:disabled {
    filter: grayscale(100%);
    cursor: not-allowed;
    opacity: 1 !important;

    ${({ disabledColor }) => disabledColor && `&, span { color: ${disabledColor} !important; };`}
  }
`

export const GenericObjectViewContainer = styled(FigmaBox)`
  padding: ${Spacings.small};
  background: ${Colors.base100};
  box-shadow: ${Shadows.baseSoft};
  border-radius: ${BorderRadius.soft};
`
