import { Button } from '../components/Button'
import React, { useState } from 'react'
import { FileContext } from '../api/swagger/definitions/backoffice'
import { generateKycReport } from '../api'
import CustomerFiles from './CustomerFiles'

interface CustomerPepFilesProps {
  customerId: string
}

const CustomerPepFiles: React.FC<CustomerPepFilesProps> = (props) => {
  const [generating, setGenerating] = useState(false)

  const createGenerateReportHandler = () => async () => {
    try {
      setGenerating(true)
      await generateKycReport(props.customerId)
      setGenerating(false)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err)
      setGenerating(false)
    }
  }

  return (
    <CustomerFiles
      customerId={props.customerId}
      contexts={[FileContext.KYC, FileContext.KYC_REPORT]}
      fileUploadContext={FileContext.KYC}
      renderButtonChildren={() => (
        <Button style={{ marginRight: 10 }} loading={generating} size="small" onClick={createGenerateReportHandler()}>
          Generate Report
        </Button>
      )}
    />
  )
}

export default CustomerPepFiles
