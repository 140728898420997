import React from 'react'
import Spacings from '../figma/tokens/Spacings'
import { Divider, LinkButton, Triangle } from '../helpers/customerGeneralFormHelper'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaButton from '../mynt-components/components/FigmaButton'
import { AntiloopTextType } from '../tiger/interfaces/Antiloop'
import { TextKeys } from '../tiger/libs/TextRepository'
import TextContainer from './TextContainer'

export const _ModalConfirmDialog: React.FC<{
  onConfirm: () => void
  onCancel: () => void
  description?: AntiloopTextType
}> = ({ onConfirm, onCancel, description }) => (
  <FigmaBox style={{ maxWidth: '235px', position: 'relative' }} spacing={Spacings.small} fullPadding>
    <FigmaBox spacing={Spacings.min} bottom>
      <TextContainer textKey={TextKeys.toolTipAreYouSureHeading} />
    </FigmaBox>
    {description && (
      <FigmaBox spacing={Spacings.min} bottom>
        <TextContainer textKey={description} />
      </FigmaBox>
    )}
    <Divider spacing={Spacings.min} />
    <FigmaBox fullWidth direction="row" gap={Spacings.min}>
      <FigmaBox justify="center" align="center" fullWidth>
        <LinkButton onClick={onCancel} textKey={TextKeys.toolTipAreYouSureYesDiscard} />
      </FigmaBox>
      <FigmaBox justify="center" align="center" fullWidth>
        <FigmaButton onClick={onConfirm} textKey={TextKeys.toolTipAreYouSureYes} variant="primary" />
      </FigmaBox>
    </FigmaBox>
    <Triangle />
  </FigmaBox>
)
