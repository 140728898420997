import { useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash/isNil'
import React, { useEffect, useState } from 'react'
import { CARD_ACCOUNTS } from '../api/react-query/keys'
import { BackOfficeAccountGetDto, BackOfficeCustomerDtoV2 } from '../api/swagger/definitions/backoffice'
import { useCards, useCustomerCardAccounts } from '../api/react-query'
import { getCustomerCardPrimaryAccount } from '../helpers/CustomerCardAccountHelper'
import { TabSets } from '../helpers/TabsHelpers'
import useConfig from '../hooks/useConfig'
import FigmaBox from '../mynt-components/components/FigmaBox'
import MyntLoader from '../mynt-components/components/MyntLoader'
import Tabs from '../mynt-components/components/Tabs'
import { Invoices } from '../pages/invoices'
import { TextKeys } from '../tiger/libs/TextRepository'
import CardPETab from './CardPETab'
import CardPayouts from './CardPayouts'
import CustomerCardBillingSetting from './CustomerCardBillingSetting'
import CustomerCardTabAutoTopup from './CustomerCardTabAutoTopup'
import CustomerCardTabChangeStatusModal from './CustomerCardTabChangeStatusModal'
import CustomerCardTabCreditOnCard from './CustomerCardTabCreditOnCard'
import CustomerCardTabLowBalanceAlert from './CustomerCardTabLowBalanceAlert'
import CustomerCardTabOverview from './CustomerCardTabOverview'
import TextContainer from './TextContainer'
import { CustomerTransactionsTable } from '../features/customer-transactions-table'
import { useIsLoadingWithPermission, usePermissions } from 'contexts/permissions'

interface CustomerCardTabProps {
  customerId: string
  customer: BackOfficeCustomerDtoV2
  patchCustomerDetails: (data: any) => Promise<any>
}

const CustomerCardTab: React.FC<CustomerCardTabProps> = ({ customerId, customer }) => {
  const queryClient = useQueryClient()

  const permissions = {
    overview: usePermissions('customers.cards.overview.view'),
    enfuce: usePermissions('customers.cards.overview.enfuce'),
    transactions: usePermissions('customers.cards.transactions.view'),
    payouts: usePermissions('customers.cards.payouts'),
    invoices: usePermissions('customers.cards.invoices'),
    pe: usePermissions('customers.cards.pe'),
    autoTopUp: usePermissions('customers.cards.autoTopUp'),
    lowBalanceAlert: usePermissions('customers.cards.lowBalanceAlert'),
    creditOnCard: usePermissions('customers.cards.creditOnCard'),
    billingSettings: usePermissions('customers.cards.billing.view')
  }

  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false)
  const [currentCardAccount, setCurrentCardAccount] = useState<BackOfficeAccountGetDto>()

  const {
    data: cardAccounts,
    isLoading: _isLoadingCardAccounts,
    isFetching: _isFetchingCardAccounts
  } = useCustomerCardAccounts(customerId, { retry: false, retryOnMount: false, enabled: permissions.enfuce })

  useEffect(() => {
    if (!isNil(cardAccounts)) {
      setCurrentCardAccount(getCustomerCardPrimaryAccount(cardAccounts))
    }
  }, [cardAccounts])

  const isLoadingCardAccounts = useIsLoadingWithPermission(_isLoadingCardAccounts, 'customers.cards.overview.enfuce')
  const isFetchingCardAccounts = useIsLoadingWithPermission(_isFetchingCardAccounts, 'customers.cards.overview.enfuce')

  if (isLoadingCardAccounts || isFetchingCardAccounts) {
    return <MyntLoader fullWidth />
  }

  return (
    <FigmaBox fullWidth>
      {/* !!! KEEP THIS FOR FUTURE REFERENCE !!! */}
      {/* Anna said we hide this field - Ilian, 11.10.2022 */}
      {/*<FigmaBox fullWidth direction={'row'} justify={'space-between'} align={'center'}>*/}
      {/*  <CustomerCardLimit limit={customer.companyCreditLimit} patchCustomerDetails={patchCustomerDetails} />*/}
      {/*</FigmaBox>*/}
      <Tabs
        fullPage
        enableNestedTabs
        alwaysShowNavHeadings
        tabKeyNameSpace={TabSets.CUSTOMER_CARD}
        tabs={[
          {
            labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Overview'} />,
            contentElement: (
              <CustomerCardTabOverview
                customer={customer}
                currentCardAccount={currentCardAccount}
                cardAccounts={cardAccounts}
                setCurrentCardAccount={setCurrentCardAccount}
                setIsChangeStatusModalOpen={setIsChangeStatusModalOpen}
              />
            ),
            enable: permissions.overview
          },
          {
            labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Transactions'} />,
            contentElement: <CustomerTransactionsTable customerId={customerId} />,
            enable: permissions.transactions
          },
          {
            labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Payouts'} />,
            contentElement: <CardPayouts customerId={customerId} />,
            enable: permissions.payouts
          },
          {
            labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Invoices'} />,
            contentElement: <Invoices customerId={customerId} />,
            enable: permissions.invoices
          },
          {
            labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'PE'} />,
            contentElement: <CardPETab customerId={customerId} />,
            enable: permissions.pe
          },
          {
            labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={TextKeys.autoTopUpHeading.characters} />,
            contentElement: <CustomerCardTabAutoTopup customerId={customerId} setIsChangeStatusModalOpen={setIsChangeStatusModalOpen} />,
            enable: permissions.autoTopUp
          },
          {
            labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={TextKeys.lowBalanceAlertHeading.characters} />,
            contentElement: (
              <CustomerCardTabLowBalanceAlert customerId={customerId} setIsChangeStatusModalOpen={setIsChangeStatusModalOpen} />
            ),
            enable: permissions.lowBalanceAlert
          },
          {
            labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Credit on card'} />,
            contentElement: <CustomerCardTabCreditOnCard customerId={customerId} />,
            enable: permissions.creditOnCard
          },
          {
            labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Billing settings'} />,
            contentElement: <CustomerCardBillingSetting customerId={customerId} />,
            enable: permissions.billingSettings
          }
        ]}
      />
      {!!currentCardAccount && (
        <CustomerCardTabChangeStatusModal
          isOpen={isChangeStatusModalOpen}
          setIsModalOpen={setIsChangeStatusModalOpen}
          customerId={customerId}
          cardAccount={currentCardAccount}
          onSuccess={async () => queryClient.invalidateQueries([CARD_ACCOUNTS, customerId])}
        />
      )}
    </FigmaBox>
  )
}

/* !!! KEEP THIS FOR FUTURE REFERENCE !!! */
/*const CustomerCardLimit: React.FC<{
  patchCustomerDetails: (data: any) => Promise<any>
  limit: AmountDto
}> = ({ limit, patchCustomerDetails }) => {
  const handleSubmit = async (amount: AmountDto) => {
    try {
      await patchCustomerDetails({ companyCreditLimit: amount })
    } catch (err) {
      console.warn(err)
    }
  }
  return <Limit limit={limit} onSubmit={handleSubmit} />
}*/

export default CustomerCardTab
