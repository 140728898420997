/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerProduct = {
    COMPANY_CREDIT: 'COMPANY_CREDIT',
    MULTI_INVOICE_CREDIT: 'MULTI_INVOICE_CREDIT',
    CARD: 'CARD',
    CARD_CREDIT: 'CARD_CREDIT'
} as const;

export type CustomerProduct = typeof CustomerProduct[keyof typeof CustomerProduct];



