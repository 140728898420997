import styled from '@emotion/styled'
import { ApprovalStatus, KycAccessLevel } from '../api/swagger/definitions/backoffice'
import { TooltipAccountStatusCompanyCredit, TooltipAccountStatusGenericCircle } from '../components/Tooltips'
import BorderRadius from '../figma/tokens/BorderRadius'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import Spacings from '../figma/tokens/Spacings'
import TextKeys from '../libs/TextKeys'
import FigmaBox, { FigmaBoxProps } from '../mynt-components/components/FigmaBox'
import { colors } from 'themes'

export enum AccountStatusTypes {
  PRODUCTS = 'products',
  KYC = 'kyc',
  CARD_FEATURES = 'cardFeatures',
  CARD_ACTIVATIO_STATUS = 'cardActivationStatus'
}

export type AccountStatuses = {
  products: KycAccessLevel[]
  kyc: KycAccessLevel[]
  cardFeatures: KycAccessLevel[]
  cardActivationStatus: KycAccessLevel[]
}

const { AUTO_APPROVED, AUTO_DENIED, MANUALLY_APPROVED, MANUALLY_DENIED, PENDING } = ApprovalStatus

export const accountStatusToColor = {
  GREEN: colors.product.success[600],
  YELLOW: colors.product.alert[400],
  RED: colors.product.error[600],
  GREY: colors.product.grey[350]
}

const statusToTextKey = {
  [MANUALLY_APPROVED]: TextKeys.cardProductToolTipStatusManuallyApproved,
  [AUTO_APPROVED]: TextKeys.cardProductToolTipStatusAutoApproved,
  [MANUALLY_DENIED]: TextKeys.cardProductToolTipStatusManuallyDenied,
  [AUTO_DENIED]: TextKeys.cardProductToolTipStatusAutoDenied,
  [PENDING]: TextKeys.cardProductToolTipStatusPending
}

const kycToTextKey = {
  [KycAccessLevel.GREY]: TextKeys.KYCToolTipGrey,
  [KycAccessLevel.YELLOW]: TextKeys.KYCToolTipYellow,
  [KycAccessLevel.GREEN]: TextKeys.KYCToolTipGreen,
  [KycAccessLevel.RED]: TextKeys.KYCToolTipRed
}

const tagStatusColors = {
  [ApprovalStatus.MANUALLY_APPROVED]: ColorsDeprecated.baseGreen,
  [ApprovalStatus.AUTO_APPROVED]: ColorsDeprecated.baseGreen,
  [ApprovalStatus.MANUALLY_DENIED]: ColorsDeprecated.iconCritical,
  [ApprovalStatus.AUTO_DENIED]: ColorsDeprecated.iconCritical,
  [ApprovalStatus.PENDING]: ColorsDeprecated.baseWarning
}

export const accountStatusTooltipsComponents = {
  products: [
    ({ color }) => <TooltipAccountStatusGenericCircle color={color} textKey={TextKeys.cardProductToolTip} />,
    ({ customer, color }) => (
      <TooltipAccountStatusCompanyCredit
        tagColor={tagStatusColors[customer.companyCreditApprovalStatus]}
        statusTextkey={statusToTextKey[customer.companyCreditApprovalStatus]}
        color={color}
      />
    )
  ],
  kyc: [
    ({ color }) => <TooltipAccountStatusGenericCircle color={color} textKey={TextKeys.KYCToolTipAnswered} />,
    ({ customer, color }) => <TooltipAccountStatusGenericCircle color={color} textKey={kycToTextKey[customer.kycAccessLevel]} />
  ],
  cardFeatures: [
    ({ color }) => <TooltipAccountStatusGenericCircle color={color} textKey={TextKeys.orderCardToolTip} />,
    ({ color }) => <TooltipAccountStatusGenericCircle color={color} textKey={TextKeys.makeTopUpsToolTip} />,
    ({ color }) => <TooltipAccountStatusGenericCircle color={color} textKey={TextKeys.CardCreditProductToolTip} />
  ],
  cardActivationStatus: [
    ({ color }) => <TooltipAccountStatusGenericCircle color={color} textKey={TextKeys.orderedCardToolTip} />,
    ({ color }) => <TooltipAccountStatusGenericCircle color={color} textKey={TextKeys.connectedAccountingToolTip} />,
    ({ color }) => <TooltipAccountStatusGenericCircle color={color} textKey={TextKeys.cardCreditToolTip} />,
    ({ color }) => <TooltipAccountStatusGenericCircle color={color} textKey={TextKeys.addedMoneyToolTip} />,
    ({ color }) => <TooltipAccountStatusGenericCircle color={color} textKey={TextKeys.madePurchaseToolTip} />
  ]
}

export const Tag = styled(FigmaBox)<{ color?: string }>`
  background-color: ${({ color }) => (color ? color : ColorsDeprecated.baseStone)};
  border-radius: ${BorderRadius.rounded};
`

type CircleProps = {
  color: string
  size?: number
}

export const Circle: React.FC<CircleProps & FigmaBoxProps> = ({ color, size = 16, ...rest }) => (
  <FigmaBox spacing={Spacings.min} {...rest}>
    <svg width={size} height={size}>
      <circle cy={size / 2} cx={size / 2} r={size / 2} fill={color} />
    </svg>
  </FigmaBox>
)
