/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import React from 'react'
import Colors from '../../figma/panda/Colors'
import X1 from '../../figma/svgs/X1'
import BorderRadius from '../../figma/tokens/BorderRadius'
import MediaQueries from '../../figma/tokens/MediaQueries'
import Shadows from '../../figma/tokens/Shadows'
import Spacings from '../../figma/tokens/Spacings'
import ZIndices from '../../figma/tokens/ZIndices'
import { SIDE_PANEL_MAX_WIDTH_PX } from '../StrongHardCodedSizes'
import useIsMobile from '../hooks/useIsMobile'
import Button from './Button'
import FigmaBox from './FigmaBox'
import SVGContainer from './SVGContainer'

export type ModalProps = {
  onClose: (value?: any, value1?: any) => void
  hideCloseButton?: boolean
  paddingTop?: string
  topClose?: boolean
  contentPadding?: boolean
  fullWidth?: boolean
  zIndexModal?: number
  backOfficeStyling?: boolean
}

type Props = {
  children: React.ReactNode
  heading?: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> | JSX.Element
} & ModalProps

/**
 * @experimetal
 */
export default function ModalExperimental({
  children,
  onClose,
  heading: Heading,
  hideCloseButton,
  paddingTop,
  contentPadding = true,
  topClose = false,
  fullWidth = true,
  zIndexModal,
  backOfficeStyling = false
}: Props) {
  const isMobile = useIsMobile()

  return (
    <StyledModal
      className="tink-flow-modal"
      open={true}
      onClose={onClose}
      BackdropComponent={StyledBackDrop}
      style={{ zIndex: zIndexModal }}
    >
      <Container fullWidth={fullWidth} align="center" top={paddingTop} className={fullWidth ? '' : 'tink-flow-modal'} isMobile={isMobile}>
        <StyledContainer isMobile={isMobile}>
          <ContentContainer fullWidth top spacing={!backOfficeStyling && contentPadding ? Spacings.medium : '0px'}>
            {!!Heading && typeof Heading === 'function' && <Heading />}
            {!!Heading && typeof Heading === 'object' && Heading}
            {!hideCloseButton && (
              <CloseButtonContainer topClose={topClose} backOfficeStyling={backOfficeStyling}>
                <SmallCloseButton
                  className="smallButtonSquare"
                  variant="filter"
                  onClick={onClose}
                  square
                  backOfficeStyling={backOfficeStyling}
                >
                  <SVGContainer SVG={X1} />
                </SmallCloseButton>
              </CloseButtonContainer>
            )}
            {children}
          </ContentContainer>
        </StyledContainer>
      </Container>
    </StyledModal>
  )
}

const StyledBackDrop = styled(Backdrop)``

const StyledModal = styled(Modal)`
  z-index: ${ZIndices.heaven};

  &:focus {
    outline: none;
  }

  .MuiBackdrop-root {
    background: ${Colors.backgroundColorsBlurred};
    opacity: 0.34;
  }
`

const Container = styled(FigmaBox)<{ isMobile: boolean; top?: string }>`
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-height: 100%;
  padding-top: ${Spacings.tiny};
  padding-bottom: ${Spacings.tiny};
  outline: none !important;

  ${({ top }) => (!!top ? `padding-top: ${top}` : `transform: translate(0, 50%);`)}
  // ${(props) => props.isMobile && 'align-items: flex-end;'} // WHY do we need this? It breaks mobile AFAICS

  &.tink-flow-modal {
    overflow: auto;
  }

  @media (max-width: ${MediaQueries.sm}px) {
    margin-top: 0;
    ${({ top }) => (!!top ? `padding-top: ${top}` : `transform: translate(0, 45%);`)};
    padding-left: ${Spacings.tiny};
    padding-right: ${Spacings.tiny};
  }
  z-index: ${ZIndices.heaven};
`

const StyledContainer = styled(FigmaBox)<{ isMobile: boolean }>`
  position: relative;
  background: ${Colors.baseWhite};
  border-radius: ${BorderRadius.soft};
  box-shadow: ${Shadows.baseHeavy};
  overflow-y: auto;
  overscroll-behavior: contain;

  ${(props) => props.isMobile && `max-width: ${SIDE_PANEL_MAX_WIDTH_PX};`}

  @media (max-width: ${MediaQueries.sm}px) {
    width: 100%;
  }
`

const ContentContainer = styled(FigmaBox)``

const CloseButtonContainer = styled(FigmaBox)<{ topClose: boolean; backOfficeStyling?: boolean }>`
  position: absolute;
  top: ${(props) => (props.topClose ? 0 : Spacings.small)};
  ${(props) => (!props.topClose && props.backOfficeStyling ? `right: ${Spacings.tiny};` : `right: ${Spacings.small};`)};
  ${(props) => (!props.topClose ? `right: ${Spacings.tiny};` : `right: ${Spacings.small};`)};
`

const SmallCloseButton = styled(Button)<{ backOfficeStyling?: boolean }>`
  &.smallButtonSquare {
    > div {
      color: ${Colors.baseBlack};
      background: transparent;
      border: none;
    }
  }
`
