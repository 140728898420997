import {
  createRepaymentInvoiceBooked,
  createRepaymentInvoiceCancelled,
  createRepaymentInvoiceCredited,
  createRepaymentInvoiceDebtCollection,
  createRepaymentInvoiceDraft,
  createRepaymentInvoiceReminder
} from '../api'

type InvoiceApiType = 'cancelled' | 'credited' | 'debt-collection' | 'booked' | 'draft' | 'reminder'

export const getRepaymentInvoiceAPI = (type: InvoiceApiType) => {
  switch (type) {
    case 'booked': {
      return createRepaymentInvoiceBooked
    }

    case 'cancelled': {
      return createRepaymentInvoiceCancelled
    }

    case 'draft': {
      return createRepaymentInvoiceDraft
    }

    case 'credited': {
      return createRepaymentInvoiceCredited
    }

    case 'debt-collection': {
      return createRepaymentInvoiceDebtCollection
    }

    case 'reminder': {
      return createRepaymentInvoiceReminder
    }

    default: {
      throw new Error(`${type} repayment api doesnt exist`)
    }
  }
}
