import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { Control, Path, useForm } from 'react-hook-form'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import { TextFieldController, SelectController, DatePickerControllerV2 } from 'components/react-hook-components'
import Spacings from 'figma/tokens/Spacings'
import { Currencies } from 'flamingo/config/ConfigCurrencies'
import { BO_SUBSCRIPTION_FEE_MODAL_WIDTH } from 'mynt-components/WeakHardCodedSizes'
import FigmaBox from 'mynt-components/components/FigmaBox'
import ModalForked from 'mynt-components/components/ModalForked'
import { When } from 'mynt-components/components/When'

type Amount = {
  value: number
  currency: string
}

export type PayloadValues = {
  interest: Amount
  vat: Amount
}

export type DefaultPayloadValues = Partial<PayloadValues>

type Props = {
  isOpen: boolean
  onModalCancel: () => void
  onSubmit: (values: PayloadValues) => unknown | Promise<unknown>
  companyName: string
  onNotification?: (message: string, type: string) => void
  defaultValues?: DefaultPayloadValues
}

type FormData = {
  payload: PayloadValues
}

const createInitialValues = (defaultValues?: DefaultPayloadValues): FormData => ({
  payload: {
    interest: {
      currency: defaultValues?.interest?.currency ?? Currencies.SEK,
      value: defaultValues?.interest?.value ?? 0
    },
    vat: {
      currency: defaultValues?.vat?.currency ?? Currencies.SEK,
      value: defaultValues?.vat?.value ?? 0
    }
  }
})

export const CardCreditInterestFee: React.FC<Props> = ({ isOpen, onModalCancel, onSubmit, companyName, onNotification, defaultValues }) => {
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm<FormData>({
    mode: 'onChange',
    defaultValues: createInitialValues(defaultValues)
  })

  const onFormSubmit = async (data: ReturnType<typeof createInitialValues>) => {
    setIsLoading(true)

    const promiseOrVoid = onSubmit(data.payload)

    if (promiseOrVoid instanceof Promise) {
      return promiseOrVoid.finally(() => setIsLoading(false))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (isLoading) return

    form.reset(createInitialValues(defaultValues))
  }, [defaultValues, isLoading])

  if (!isOpen) return null

  return (
    <ModalForked
      fullWidth
      alignHeadingWithCloseButton
      wrapperStyle={{ width: BO_SUBSCRIPTION_FEE_MODAL_WIDTH }} // Form adjusts size when you select different options in the selects without fixed width. Value from figma
      onClose={onModalCancel}
      heading={() => (
        <FigmaBox spacing={Spacings.large} left>
          <Typography variant="h6">Credit interest fee</Typography>
        </FigmaBox>
      )}
    >
      <FigmaBox
        fullWidth
        direction="row"
        justify="space-between"
        spacing={Spacings.large}
        left
        right
        bottom={Spacings.tiny}
        top={Spacings.medium}
      >
        <Typography>{companyName}</Typography>
      </FigmaBox>
      <form onSubmit={form.handleSubmit(onFormSubmit)}>
        <FigmaBox fullWidth spacing={Spacings.large} fullPadding top={Spacings.small} gap={Spacings.min}>
          <div data-testid="interest">
            <Row
              control={form.control}
              name="payload.interest.value"
              labelText="Interest"
              type="number"
              adornmentText={form.watch('payload.interest.currency')}
            />
          </div>
          <div data-testid="vat">
            <Row
              control={form.control}
              name="payload.vat.value"
              labelText="VAT"
              type="number"
              adornmentText={form.watch('payload.vat.currency')}
            />
          </div>
          <FigmaBox top={Spacings.medium} fullWidth>
            <LoadingButton role="submit" variant="primary" disabled={!form.formState.isValid} loading={isLoading} type="submit">
              Create interest fee
            </LoadingButton>
          </FigmaBox>
        </FigmaBox>
      </form>
    </ModalForked>
  )
}

type Options = { value: string; label: string; disabled?: boolean }[]

type RowProps<T extends Record<string, any>> = {
  control: Control<T>
  labelText?: string
  name: Path<T>
  type?: 'text' | 'select' | 'number' | 'date'
  options?: Options
  adornmentText?: string
  onChange?: (event: any) => void
  disabled?: boolean
}

const Row = <T extends Record<string, any>>({
  control,
  labelText,
  name,
  type = 'number',
  options,
  adornmentText,
  onChange,
  disabled
}: RowProps<T>) => (
  <FigmaBox fullWidth justify="space-between" direction="row">
    <FigmaBox fullWidth flex={2} style={{ whiteSpace: 'pre' }} justify="center">
      <Typography variant="subtitle1">{labelText}</Typography>
    </FigmaBox>
    <StyledTextFieldControllerContainer fullWidth flex={3}>
      <When is={['text', 'number'].includes(type)}>
        <TextFieldController
          disabled={disabled}
          type={type}
          noLabel
          name={name}
          control={control}
          adornment={adornmentText ? <Typography>{adornmentText}</Typography> : undefined}
        />
      </When>
      <When is={type === 'select'}>
        <SelectController
          noLabel
          control={control}
          name={name}
          options={options as Options}
          adormentText={adornmentText}
          onChange={onChange}
        />
      </When>
      <When is={type === 'date'}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePickerControllerV2 control={control} name={name} onChange={onChange} />
        </LocalizationProvider>
      </When>
    </StyledTextFieldControllerContainer>
  </FigmaBox>
)

// Hide the number increment buttons
const StyledTextFieldControllerContainer = styled(FigmaBox)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
