import React from 'react'

const copy = async (text: string) => window.navigator.clipboard.writeText(text)
interface IProps {
  name: string
  orgno?: string
  headerClassName?: string
}

const CustomerTitle: React.FC<IProps> = ({ name, orgno, headerClassName = '', children }) => (
  <>
    <h1 style={{ fontSize: 30, marginBottom: '16px', cursor: 'pointer' }} onClick={async () => copy(name)} className={headerClassName}>
      {name}
    </h1>
    <span
      style={{
        display: 'block',
        fontSize: 15,
        fontFamily: 'Inter',
        color: '#87888b',
        marginTop: -20
      }}
    >
      {orgno && (
        <span style={{ cursor: 'pointer' }} onClick={async () => copy(orgno)}>
          {orgno}
        </span>
      )}
      {children}
    </span>
  </>
)

export default CustomerTitle
