import { ConfigCurrency, ConfigCurrencies, Currencies } from '../config/ConfigCurrencies'
import useCurrency from './useCurrency'

const useConfigByCurrency = (preferredCurrency?: Currencies): ConfigCurrency => {
  const currency = useCurrency()

  return ConfigCurrencies[preferredCurrency || currency || Currencies.SEK]
}

export default useConfigByCurrency
