import styled from '@emotion/styled';
import ButtonPrimaryHoverCss from './ButtonPrimaryHoverCss';


interface ButtonPrimaryHoverProps {
  children: any;
  [propName: string]: {};
}

// Extend the below as needed
const ButtonPrimaryHoverStyled = styled.div<ButtonPrimaryHoverProps>`
  ${ButtonPrimaryHoverCss};
`;

export default ButtonPrimaryHoverStyled;