import styled from '@emotion/styled'
// eslint-disable-next-line no-restricted-imports
import { TooltipProps } from '@mui/material'
import React from 'react'
import useIsMobile from '../hooks/useIsMobile'
import FigmaBox from './FigmaBox'
import { DefaultTooltipContent, DefaultTooltipWrapperDesktop, DefaultTooltipWrapperMobile } from './MyntTooltipViews'

export enum TooltipThemeVariants {
  LIGHT = 'light',
  DARK = 'dark'
}

/**
 * svgIcon, svgColor, noMargin && noPadding are being used only for displaying the empty tooltip content
 * @param svgIcon - used to change empty tooltip content icon.
 * @param svgColor - used to change empty tooltip content icon color.
 * @param noMargin - when set to true, the empty tooltip icon wrapper will have no margin.
 * @param noPadding - when set to true, the empty tooltip icon wrapper will have no padding.
 * @param fullWidth - passed to make the tooltip content wrapper full width.
 * @param variant - we support LIGHT | DARK variant, and depending on the variant, the text color is changing as well.
 * @param title - This is the actual tooltip content after hovering on the content.
 * @param content - This is the content which will have the tooltip shown on hover.
 * @param onClick - what event to be triggered when clicking on the tooltip content.
 * @param arrow - it shows arrow pointing at the tooltip target, default is TRUE.
 * @param followCursor - if set to True, then tooltip follows the user mouse.
 *
 * There are many other which we can support and extend on, they can be found on MUI documentation:
 * https://mui.com/material-ui/api/tooltip/
 */

export interface IDefaultTooltip extends Partial<Omit<TooltipProps, 'content'>> {
  svgIcon?: React.FC<React.PropsWithChildren<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>>
  svgColor?: string
  noMargin?: boolean
  noPadding?: boolean
  fullWidth?: boolean
  variant?: TooltipThemeVariants
  title: NonNullable<React.ReactNode>
  content?: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> | JSX.Element | React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
  arrow?: boolean
  followCursor?: boolean
}

const DefaultTooltip: React.FC<React.PropsWithChildren<React.PropsWithChildren<IDefaultTooltip>>> = ({
  svgIcon,
  onClick,
  svgColor,
  noMargin,
  fullWidth,
  noPadding,
  title,
  content: Content,
  variant,
  placement,
  arrow,
  followCursor
}) => {
  const isMobile = useIsMobile()

  return (
    <React.Fragment>
      {isMobile && (
        <DefaultTooltipWrapperMobile
          onClick={onClick}
          fullWidth={fullWidth}
          title={title}
          variant={variant}
          placement={placement}
          arrow={arrow}
          followCursor={followCursor}
        >
          <DefaultTooltipContent noPadding={noPadding} noMargin={noMargin} svgIcon={svgIcon} svgColor={svgColor} content={Content} />
        </DefaultTooltipWrapperMobile>
      )}
      {!isMobile && (
        <DefaultTooltipWrapperDesktop
          onClick={onClick}
          fullWidth={fullWidth}
          title={title}
          variant={variant}
          placement={placement}
          arrow={arrow}
          followCursor={followCursor}
        >
          <DefaultTooltipContent noPadding={noPadding} noMargin={noMargin} svgIcon={svgIcon} svgColor={svgColor} content={Content} />
        </DefaultTooltipWrapperDesktop>
      )}
    </React.Fragment>
  )
}

const _LightTooltip: React.FC<React.PropsWithChildren<React.PropsWithChildren<IDefaultTooltip>>> = ({
  placement,
  svgIcon,
  onClick,
  svgColor,
  noMargin,
  fullWidth,
  noPadding,
  title: Title,
  content,
  arrow = true,
  followCursor = false
}) => (
  <DefaultTooltip
    fullWidth={fullWidth}
    svgIcon={svgIcon}
    onClick={onClick}
    svgColor={svgColor}
    noMargin={noMargin}
    noPadding={noPadding}
    variant={TooltipThemeVariants.LIGHT}
    content={content}
    placement={placement}
    arrow={arrow}
    followCursor={followCursor}
    title={<StyledToolTipContent>{Title}</StyledToolTipContent>}
  />
)

const _DarkTooltip: React.FC<React.PropsWithChildren<React.PropsWithChildren<IDefaultTooltip>>> = ({
  placement,
  svgIcon,
  onClick,
  svgColor,
  noMargin,
  fullWidth,
  noPadding,
  title: Title,
  content,
  arrow = true,
  followCursor = false
}) => (
  <DefaultTooltip
    fullWidth={fullWidth}
    svgIcon={svgIcon}
    onClick={onClick}
    svgColor={svgColor}
    noMargin={noMargin}
    noPadding={noPadding}
    variant={TooltipThemeVariants.DARK}
    content={content}
    placement={placement}
    arrow={arrow}
    followCursor={followCursor}
    title={<StyledToolTipContent>{Title}</StyledToolTipContent>}
  />
)

const StyledToolTipContent = styled(FigmaBox)`
  max-width: 362px;
`

export const LightTooltip = React.memo(_LightTooltip)
export const DarkTooltip = React.memo(_DarkTooltip)
