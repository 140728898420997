/* eslint-disable no-console */
import { Button } from '../components/Button'
import LoaderRenderProp from '../components/LoaderRenderProp'
import React from 'react'
import { StatusColors } from '../enums/statusColors'
import Spacings from '../figma/tokens/Spacings'
import { getLoanInvoice } from '../api'
import { useInvoicesFromFortnox, useRepaymentInvoices, useRepaymentPlan } from '../helpers/RepaymentHelpers'
import { Repayment } from '../interfaces/RepaymentInterfaces'
import FigmaBox from '../mynt-components/components/FigmaBox'
import RepaymentInvoices from './RepaymentInvoices'
import RepaymentPlan from './RepaymentPlan'
import Box from '@mui/material/Box'

interface IProps {
  creditId: string | number
  creditAmount: number
  repaymentModel?: string
}

const CreditRepayment: React.FC<IProps> = ({ creditId, creditAmount, repaymentModel }) => {
  const {
    details: repaymentPlan,
    loading: repaymentPlanLoading,
    createRepayment: createRepaymentService,
    updateRepayment: updateRepaymentService,
    attachInvoiceToRepayment: attachInvoiceToRepaymentService,
    recreateRepaymentInvoice: recreateRepaymentInvoiceService,
    dropInvoiceFromRepayment,
    updateRepaymentField,
    createRepaymentField,
    removeRepaymentField,
    fetchRepaymentPlan,
    submitting: repaymentPlanSubmitting
  } = useRepaymentPlan(creditId, repaymentModel)

  const { details: invoices, loading: invoicesLoading, setInvoiceById, fetchInvoices, pushInvoice } = useRepaymentInvoices(creditId)

  const { submitting, setupIvoicesFromFortnox } = useInvoicesFromFortnox(creditId, repaymentPlan === null ? '' : repaymentPlan.id, () => {
    fetchRepaymentPlan()
    fetchInvoices()
  })

  const spareInvoices = (invoices || []).filter((invoice) => {
    if (repaymentPlan === null) return false

    return !repaymentPlan.repayments.find((repayment) => repayment.repaymentInvoiceId === invoice.id)
  })

  const createRepayment = async (index: number) => {
    try {
      const newRepayment = await createRepaymentService(index)
      const data = await getLoanInvoice((newRepayment as any)?.repaymentInvoiceId)
      pushInvoice(data)
    } catch (err) {
      console.warn(err)
    }
  }

  const updateRepayment = async (index: number) => {
    try {
      const newRepayment = await updateRepaymentService(index)
      const invoiceId = (newRepayment as any)?.repaymentInvoiceId
      if (!invoiceId) return

      const data = await getLoanInvoice(invoiceId)
      setInvoiceById((data as any).id, data)
    } catch (err) {
      console.warn(err)
    }
  }

  const attachInvoiceToRepayment = async (repayment: Repayment, body: { invoiceNo: number }) => {
    await attachInvoiceToRepaymentService(repayment, body)
    fetchInvoices()
  }

  const recreateRepaymentInvoice = async (repayment: Repayment) => {
    await recreateRepaymentInvoiceService(repayment)
    fetchInvoices()
  }

  return (
    <LoaderRenderProp
      showLoader={repaymentPlanLoading || invoicesLoading}
      render={() => (
        <FigmaBox fullWidth top bottom spacing={Spacings.big}>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <h4>Repayment plan</h4>
            <Button style={{ marginBottom: 20 }} onClick={setupIvoicesFromFortnox} loading={submitting}>
              Bootstrap invoices
            </Button>
          </Box>
          <RepaymentPlan
            repaymentPlan={repaymentPlan}
            updateRepayment={updateRepayment}
            createRepayment={createRepayment}
            dropInvoiceFromRepayment={dropInvoiceFromRepayment}
            submitting={repaymentPlanSubmitting}
            updateRepaymentField={updateRepaymentField}
            removeRepaymentField={removeRepaymentField}
            createRepaymentField={createRepaymentField}
            invoices={invoices}
            setInvoiceById={setInvoiceById}
            creditAmount={creditAmount}
            attachInvoiceToRepayment={attachInvoiceToRepayment}
            recreateRepaymentInvoice={recreateRepaymentInvoice}
          />
          {spareInvoices.length > 0 && (
            <>
              <h4
                style={{
                  marginTop: 30,
                  color: StatusColors.red
                }}
              >
                Non-mapped invoices
              </h4>

              <RepaymentInvoices invoices={spareInvoices} setInvoiceById={setInvoiceById} />
            </>
          )}
        </FigmaBox>
      )}
    />
  )
}

export default CreditRepayment
