import Axios from 'axios'
import config from '../../config'

export const axios = Axios.create({
  withCredentials: true,
  baseURL: config.boBaseUrl,
  headers: { 'Content-Type': 'application/json' }
})

export default axios
