import { formatCurrency } from 'helpers/utils'
import { BackOfficeCustomerDtoV2, PersonDto } from '../api/swagger/definitions/backoffice'

export enum SmsBodyVariables {
  firstName = '#FirstName',
  lastName = '#LastName',
  fullName = '#FullName',
  companyName = '#CompanyName',
  limit = '#Limit'
}

export interface SmsTemplate {
  createdAt: number
  deleted: boolean
  id: string
  modifiedAt: number
  rev: number
  text: string
  title: string
}

export const getFirstNameForSMS = (person: PersonDto | null | undefined) => {
  if (!!person) {
    return person.fullName.split(' ')[0]
  }
  return '-'
}

export const getLastNameForSMS = (person: PersonDto | null | undefined) => {
  if (!!person) {
    try {
      const splittedName = person.fullName.split(' ')
      splittedName.shift()
      return splittedName.join(' ')
    } catch (err) {
      return '-'
    }
  } else {
    return '-'
  }
}

export const getFullNameForSMS = (person: PersonDto | null | undefined) => {
  if (!!person) {
    return person.fullName
  }
  return '-'
}

export const getCompanyNameForSMS = (customer?: BackOfficeCustomerDtoV2) => {
  if (!!customer) {
    return customer.companyName
  }
  return '-'
}

export const getLimitForSMS = (customer?: BackOfficeCustomerDtoV2) => {
  if (!!customer) {
    return formatCurrency(customer.companyCreditLimit)
  }
  return '-'
}

export const formatSmsText = (body: string, person: PersonDto | null | undefined, customer?: BackOfficeCustomerDtoV2) =>
  body
    .replace(SmsBodyVariables.firstName, getFirstNameForSMS(person))
    .replace(SmsBodyVariables.lastName, getLastNameForSMS(person))
    .replace(SmsBodyVariables.fullName, getFullNameForSMS(person))
    .replace(SmsBodyVariables.companyName, getCompanyNameForSMS(customer))
    .replace(SmsBodyVariables.limit, getLimitForSMS(customer))

const smsInterpolationHelpers = {
  getFirstNameForSMS,
  getLastNameForSMS,
  getFullNameForSMS,
  getCompanyNameForSMS,
  getLimitForSMS,
  formatSmsText
}

export default smsInterpolationHelpers
