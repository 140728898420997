import styled from '@emotion/styled';
import ButtonPrimaryDefaultCss from './ButtonPrimaryDefaultCss';


interface ButtonPrimaryDefaultProps {
  children: any;
  [propName: string]: {};
}

// Extend the below as needed
const ButtonPrimaryDefaultStyled = styled.div<ButtonPrimaryDefaultProps>`
  ${ButtonPrimaryDefaultCss};
`;

export default ButtonPrimaryDefaultStyled;