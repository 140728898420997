import styled from '@emotion/styled'
import FigmaBox from 'mynt-components/components/FigmaBox'
import ColorsDeprecated from '../figma/tokens/ColorsDeprecated'
import BorderRadius from '../figma/tokens/BorderRadius'
import Shadows from '../figma/tokens/Shadows'
import Spacings from '../figma/tokens/Spacings'
import TextKeys from '../libs/TextKeys'
import useTextVariant from '../mynt-components/hooks/useTextVariant'

/**
 * @summary TableWrapper for MUI tables
 * @remarks
 * This table wrapper overrides the default behavior of material-table
 * Details panel is also styled for which we use .detailTableRow && .react-json-view
 * They both are styled, because some tables are using the react-json-view package
 */

const TableWrapper = ({ noSpacingTop = false, children }) => {
  const titleStyles = useTextVariant(TextKeys.customerInvoicesHeading).style
  return (
    <TableContainer fullWidth top={noSpacingTop ? '' : Spacings.small} titleStyle={titleStyles}>
      {children}
    </TableContainer>
  )
}

const TableContainer = styled(FigmaBox)<{ titleStyle: React.CSSProperties }>`
  td,
  th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiBox-root h6 {
    ${({ titleStyle }) => ({ ...titleStyle })}
    white-space: pre-line !important;
    overflow: hidden;
  }

  & .MuiPaper-root {
    box-shadow: none;
  }

  .MuiToolbar-root {
    padding-left: 0;

    .MuiFormControl-root {
      .MuiInput-root.MuiInput-underline {
        padding-left: ${Spacings.tiny};
        border: 1px solid ${ColorsDeprecated.baseStone};
        border-radius: ${BorderRadius.soft};

        &:hover {
          border-color: ${ColorsDeprecated.baseBlack};
        }

        &.Mui-focused {
          box-shadow: inset 0px 0px 0px 1px ${ColorsDeprecated.baseGarden};
          border-color: ${ColorsDeprecated.baseGarden};
        }

        &::before,
        &::after {
          display: none;
        }
      }
    }
  }

  .detailTableRow,
  .react-json-view {
    padding: ${Spacings.tiny} ${Spacings.small};
    border-radius: ${BorderRadius.soft};
    background-color: ${ColorsDeprecated.baseCloud} !important;
  }

  & .MuiTableCell-root {
    border: none;
    padding: ${Spacings.tiny};
  }

  .MuiIconButton-root {
    padding: 6px;
  }

  & .MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: inherit;
  }

  > div > div:not(:first-of-type) {
    border-radius: ${BorderRadius.soft} !important;
    box-shadow: ${Shadows.baseSoft};
    border: 1px solid ${ColorsDeprecated.baseStone};
    margin-top: ${Spacings.min};
  }

  .MuiTablePagination-toolbar {
    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows {
      margin-bottom: 0;
    }
  }
`

export default TableWrapper
