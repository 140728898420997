import Loader from '../components/Loader'
import React from 'react'
import { TooltipKYCBeneficialOwnersControlTypes } from './Tooltips'
// eslint-disable-next-line max-len
import Spacings from '../figma/tokens/Spacings'
import { useAllKycRoaring } from '../api/react-query'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'

type ControlType = {
  code: string
  description: string
}

type RoaringBenificialOwner = {
  person: {
    firstNames: Array<string>
    surName: string
    personalNumber: string
    birthDate: string
  }
  extentOfControl: ControlType
  controlTypes: Array<ControlType>
}

interface CustomerDetailsKYCProps {
  customerId: string
}

const CustomerDetailsKYC: React.FC<CustomerDetailsKYCProps> = ({ customerId }) => {
  const { data: fullKYCData, isLoading } = useAllKycRoaring(customerId)

  if (isLoading) return <Loader />

  const benificialOwners: RoaringBenificialOwner[] = (fullKYCData as any)?.beneficialOwner?.get?.beneficialOwners || []

  const rows = benificialOwners.map(
    ({ controlTypes, extentOfControl, person: { firstNames, surName, personalNumber: ssn, birthDate } }) => ({
      ssn: ssn ?? '-',
      birthDate,
      name: `${firstNames.join(' ')} ${surName}`,
      extentOfControl,
      controlTypes
    })
  )

  const columns: GridColDef[] = [
    {
      headerName: 'SSN',
      flex: 1,
      field: 'ssn'
    },
    {
      headerName: 'Date of birth',
      flex: 1,
      field: 'birthDate'
    },
    {
      headerName: 'Name',
      flex: 1,
      field: 'name'
    },
    {
      headerName: 'Control',
      flex: 1,
      field: 'extentOfControl',
      renderCell: ({ value }) => `${value.code} - ${value.description}`
    },
    {
      headerName: 'Control Type',
      flex: 1,
      field: 'controlTypes',
      renderCell: ({ value }) => (
        <FigmaBox fullWidth direction="row" flexWrap="wrap" gap={Spacings.min}>
          {value.map(({ code, description }) => (
            <FigmaBox key={code}>
              <TooltipKYCBeneficialOwnersControlTypes code={code} description={description} />
            </FigmaBox>
          ))}
        </FigmaBox>
      )
    }
  ]

  return (
    <div style={{ paddingBottom: 30 }}>
      <h2 style={{ marginBottom: 0 }}>Benificial Owners</h2>
      {!benificialOwners ? 'Error loading benificial owners information, see JSON for raw data' : ''}
      <DataGridPro columns={columns} rows={rows} getRowId={(row) => row.ssn} />
    </div>
  )
}

export default CustomerDetailsKYC
