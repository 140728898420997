import { DefaultLoginPage } from 'pages/DefaultLoginPage/DefaultLoginPage'
import LoginPage from '../components/LoginPage'
import Sandbox from '../components/Sandbox'
import SandboxManuallyOrderCard from '../components/SandboxManuallyOrderCard'
import routes from './constants'

export const loginRoutes = [
  {
    path: '/start',
    component: () => <DefaultLoginPage />
  },
  {
    path: routes.LOGIN_PATH,
    component: () => <LoginPage />
  },
  {
    path: '/sandbox',
    component: () => <Sandbox />
  },
  {
    path: '/sandboxmanuallyordercard',
    component: () => <SandboxManuallyOrderCard />
  }
]
