const WintLogo = (props: React.SVGProps<SVGSVGElement>) => <svg width="73" height="71" viewBox="0 0 73 71" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <g clipPath="url(#clip0_357_9527)">
  <path d="M20.268 49.8947C16.3949 48.1858 14.6275 43.6329 16.3447 39.7786L29.4557 10.3532C31.1729 6.49887 35.7479 4.74008 39.621 6.44897C43.4941 8.15786 45.2615 12.7107 43.5443 16.5651L30.4333 45.9904C28.7161 49.8448 24.1411 51.6036 20.268 49.8947Z" fill="url(#paint0_linear_357_9527)"/>
  <path d="M52.732 49.8947C56.6051 48.1858 58.3724 43.6329 56.6552 39.7786L43.5443 10.3532C41.8271 6.49887 37.2521 4.74008 33.3789 6.44897C29.5058 8.15786 27.7385 12.7107 29.4557 16.5651L42.5666 45.9904C44.2838 49.8448 48.8589 51.6036 52.732 49.8947Z" fill="url(#paint1_linear_357_9527)"/>
  <path d="M26.5163 49.8945C30.3894 48.1856 32.1567 43.6327 30.4395 39.7783L14.7465 4.55899C13.0293 0.6984 8.45429 -1.05415 4.58118 0.654742C0.701798 2.36363 -1.05928 6.91651 0.657928 10.7709L16.3509 45.9902C18.0681 49.8446 22.6432 51.6034 26.5163 49.8945Z" fill="url(#paint2_linear_357_9527)"/>
  <path d="M46.4836 49.8945C42.6105 48.1856 40.8431 43.6327 42.88 39.7784L58.573 4.55903C59.9705 0.704678 64.5456 -1.05411 68.4249 0.654783C72.2981 2.36367 74.0654 6.91655 72.3482 10.7709L56.6552 45.9903C54.938 49.8446 50.363 51.6034 46.4898 49.8945H46.4836Z" fill="url(#paint3_linear_357_9527)"/>
  <path d="M24.699 61.6197L24.4733 61.5136C23.9908 61.2891 23.4079 61.5074 23.1886 61.9876L23.0006 62.4055L20.97 66.8586L18.9206 62.8421C18.9206 62.8421 18.6386 62.2808 18.2563 62.2745C17.8803 62.287 17.5795 62.8421 17.5795 62.8421L15.5552 66.8586L14.6778 64.9626V64.9501L13.3178 62.0001C13.0922 61.5199 12.5156 61.3016 12.033 61.5261L11.8074 61.6321C11.3248 61.8567 11.1055 62.4305 11.3311 62.9107L12.1145 64.6196C12.1145 64.6196 12.1207 64.632 12.127 64.6383C12.2586 64.9065 14.9159 70.4011 15.0224 70.6007C15.3107 71.1121 15.8184 71.1558 16.1067 70.6132C16.3135 70.2265 18.2563 66.1289 18.2563 66.1289C18.2563 66.1289 20.0801 70.0581 20.3934 70.6007C20.6629 71.0684 21.1517 71.0872 21.4338 70.5882C21.5779 70.3263 24.3355 64.6944 24.7303 63.8774C24.7491 63.84 24.7679 63.8026 24.7804 63.7776L25.1815 62.9107C25.4071 62.4305 25.1878 61.8504 24.7052 61.6321L24.699 61.6197Z" fill="black"/>
  <path d="M32.4389 58.782C32.84 58.782 33.1721 58.651 33.4479 58.3953C33.7299 58.1396 33.8678 57.8215 33.8678 57.4598C33.8678 57.098 33.7299 56.7862 33.4479 56.5305C33.1659 56.2748 32.8275 56.1438 32.4264 56.1438C32.0253 56.1438 31.6994 56.2748 31.4173 56.5305C31.1353 56.7862 30.9974 57.098 30.9974 57.4473C30.9974 57.8215 31.1353 58.1333 31.4173 58.3953C31.6994 58.651 32.0378 58.782 32.4389 58.782Z" fill="black"/>
  <path d="M33.5419 62.1375V62.0439V61.994C33.5419 61.4701 33.1095 61.0398 32.583 61.0398H32.2885C31.762 61.0398 31.3296 61.4701 31.3296 61.994V70.0083C31.3296 70.5322 31.762 70.9626 32.2885 70.9626H32.583C33.1095 70.9626 33.5419 70.5322 33.5419 70.0083V69.8212V62.1437V62.1375Z" fill="black"/>
  <path d="M48.2322 65.1123C48.2322 63.7714 47.9627 62.8047 47.43 62.2122C46.8095 61.5137 45.9447 61.1706 44.8291 61.1706C43.864 61.1706 42.9928 61.5511 42.222 62.312V62.1685C42.222 62.1685 42.222 62.1498 42.222 62.1373C42.222 62.1249 42.222 62.1186 42.222 62.1062V62.0438H42.2157C42.2157 62.0438 42.2157 62.0251 42.2157 62.0188V61.9939C42.2157 61.9939 42.2157 61.9752 42.2157 61.969C42.2157 61.9378 42.2032 61.9066 42.1969 61.8816C42.1342 61.4139 41.7519 61.0459 41.2631 61.0459H40.9685C40.4421 61.0459 40.0096 61.4762 40.0096 62.0001V62.05V69.8835V70.0082C40.0096 70.5321 40.4421 70.9624 40.9685 70.9624H41.2631C41.7895 70.9624 42.222 70.5321 42.222 70.0082V69.8835H42.2282V66.3597C42.2282 65.1747 42.3786 64.3452 42.6795 63.8712C42.9865 63.3909 43.5067 63.1477 44.24 63.1477C44.9106 63.1477 45.3806 63.3286 45.6313 63.6965C45.8945 64.052 46.0261 64.7007 46.0261 65.6362V69.9458H46.0324V70.002C46.0324 70.5259 46.4648 70.9562 46.9913 70.9562H47.2858C47.8123 70.9562 48.2447 70.5259 48.2447 70.002V69.9458V65.1185L48.2322 65.1123Z" fill="black"/>
  <path d="M59.7701 61.4202H57.8398V59.0502C57.8398 59.0502 57.8398 59.0377 57.8398 59.0252V58.9379H57.821C57.7646 58.4702 57.376 58.0959 56.8872 58.0959H56.5926C56.1038 58.0959 55.7152 58.4702 55.6588 58.9379H55.6337V61.4202H54.2111C53.7223 61.4202 53.3212 61.8193 53.3212 62.3058V62.5802C53.3212 63.0667 53.7223 63.4659 54.2111 63.4659H55.6337V69.9522V69.9896C55.6337 70.5135 56.0662 70.9438 56.5926 70.9438H56.8872C57.4136 70.9438 57.8461 70.5135 57.8461 69.9896V66.6092C57.8461 66.6092 57.8461 66.5967 57.8461 66.5843V63.4596H59.7763C60.2652 63.4596 60.6663 63.0605 60.6663 62.574V62.2996C60.6663 61.8131 60.2652 61.4139 59.7763 61.4139L59.7701 61.4202Z" fill="black"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_357_9527" x1="38.9128" y1="15.9913" x2="12.9996" y2="51.5426" gradientUnits="userSpaceOnUse">
  <stop/>
  <stop offset="1"/>
  </linearGradient>
  <linearGradient id="paint1_linear_357_9527" x1="29.0797" y1="21.0307" x2="58.3129" y2="36.1144" gradientUnits="userSpaceOnUse">
  <stop offset="0.22"/>
  <stop offset="0.78"/>
  </linearGradient>
  <linearGradient id="paint2_linear_357_9527" x1="3.99833" y1="20.4691" x2="39.3128" y2="35.2975" gradientUnits="userSpaceOnUse">
  <stop/>
  <stop offset="0.68"/>
  </linearGradient>
  <linearGradient id="paint3_linear_357_9527" x1="76.2088" y1="11.3634" x2="37.0442" y2="40.7715" gradientUnits="userSpaceOnUse">
  <stop/>
  <stop offset="0.92"/>
  <stop offset="1"/>
  </linearGradient>
  <clipPath id="clip0_357_9527">
  <rect width="73" height="71" fill="white"/>
  </clipPath>
  </defs>
  </svg>

export default WintLogo;