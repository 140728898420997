import LoginFormContainer from './LoginFormContainer'

const LoginPage = () => (
  <div>
    <h1 style={{ paddingTop: 50, textAlign: 'center', marginBottom: 30 }}>Sign in</h1>
    <LoginFormContainer />
  </div>
)

export default LoginPage
