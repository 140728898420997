/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CardPartner = {
    NONE: 'NONE',
    PE: 'PE',
    SETLY: 'SETLY',
    FINDITY: 'FINDITY',
    FORTNOX: 'FORTNOX',
    ETASKU: 'ETASKU',
    ACCOUNTEC: 'ACCOUNTEC',
    WINT: 'WINT',
    TRIPLETEX: 'TRIPLETEX',
    BOLAGERIET: 'BOLAGERIET',
    VISMAECONOMIC: 'VISMAECONOMIC',
    VISMAPAY: 'VISMAPAY'
} as const;

export type CardPartner = typeof CardPartner[keyof typeof CardPartner];



