import { BackOfficeAccountGetDto, EnfuceAccountType } from '../api/swagger/definitions/backoffice'

export const getCustomerCardPrimaryAccount = (accounts: BackOfficeAccountGetDto[]) =>
  accounts.find((acc) => acc.primary) as BackOfficeAccountGetDto

export const isPlatinum = (account?: BackOfficeAccountGetDto) => {
  if (!account) return false
  return account.type === EnfuceAccountType.PLATINUM
}

export const hasPlatinumAccount = (accounts: BackOfficeAccountGetDto[]) => isPlatinum(getCustomerCardPrimaryAccount(accounts))
