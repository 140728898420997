import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Popover, { PopoverProps } from '@mui/material/Popover'
import { cloneElement, useRef, useState } from 'react'

type OldPopConfirmProps = {
  title: string
  onConfirm: () => void
  children: React.ReactNode
}

const ExtraSmallButton = styled(Button)`
  font-size: 10px;
  padding: 0 4px;
`

const Popconfirm = (props: OldPopConfirmProps & Partial<PopoverProps>) => {
  const [open, setOpen] = useState(false)
  const ref = useRef()

  const handleClickOk = () => {
    props.onConfirm?.()
    setOpen(false)
  }

  return (
    <>
      <Popover
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: -75 }}
        {...props}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        open={open}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            flexDirection: 'column',
            padding: '8px 16px',
            textAlign: 'center',
            borderRadius: '8px'
          }}
        >
          {props.title}
          <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
            <ExtraSmallButton onClick={() => setOpen(false)}>Cancel</ExtraSmallButton>
            <ExtraSmallButton variant="primary" onClick={handleClickOk}>
              OK
            </ExtraSmallButton>
          </Box>
        </Box>
      </Popover>
      {cloneElement(props.children as any, { ref, onClick: () => setOpen(true) })}
    </>
  )
}

export default Popconfirm
