/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AutogiroApprovalState = {
    REQUESTED: 'REQUESTED',
    PENDING: 'PENDING',
    READY: 'READY',
    APPROVED: 'APPROVED',
    FAILED: 'FAILED',
    PENDING_CANCELLATION: 'PENDING_CANCELLATION',
    CANCELLED: 'CANCELLED'
} as const;

export type AutogiroApprovalState = typeof AutogiroApprovalState[keyof typeof AutogiroApprovalState];



