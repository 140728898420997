import Box from '@mui/material/Box'
import cardFlower from '../../assets/images/mynt-cards-flower.png'
import bannerBg from '../../assets/images/banner-bg.png'
import Typography from '@mui/material/Typography'
import { SessionRole, isSignedIn, useSessionRole } from 'contexts/session'
import Button from '@mui/material/Button'
import Google from 'figma/svgs/google'
import { SEARCH_ID } from 'components/HeaderSearch'
import { handleSignInWithGoogle } from 'components/LoginFormContainer'
import styled from '@emotion/styled'

export const DefaultLoginPage = () => {
  const { role, isLoading } = useSessionRole()

  const title = roleToTitle(role)
  const CTA = roleToCTAText(role)
  const subtitle = roleToSubtitle(role)

  const handleCTAClick = () => {
    if (!isSignedIn(role)) return handleSignInWithGoogle()

    document.getElementById(SEARCH_ID)?.focus()
  }

  const animation: React.CSSProperties = {
    animationTimingFunction: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    animationName: isLoading ? '' : 'fadein',
    animationDuration: '600ms',
    animationFillMode: 'forwards'
  }

  return (
    <Box
      sx={{
        width: '100%',
        padding: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          maxWidth: '1568px', // This value comes from the width of bannerBg
          height: '400px', // This value comes from the width of bannerBg
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundImage: `url(${bannerBg})`,
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
          backgroundSize: '100% 400px', // This value comes from the width of bannerBg
          borderRadius: '8px'
        }}
      >
        <Box sx={{ paddingLeft: '54px', maxWidth: '400px' }}>
          <AnimatedTypography sx={{ paddingBottom: '24px', animationDelay: '0s', ...animation }}>Mynt - {title}</AnimatedTypography>
          <AnimatedTypography sx={{ paddingBottom: '8px', animationDelay: '0.1s', ...animation }} variant="h1">
            BackOffice
          </AnimatedTypography>
          <AnimatedTypography sx={{ paddingBottom: '32px', animationDelay: '0.2s', ...animation }} variant="body2">
            {subtitle}
          </AnimatedTypography>
          <AnimatedButton sx={{ animationDelay: '0.3s', ...animation }} variant="primary" onClick={handleCTAClick}>
            {CTA}
          </AnimatedButton>
        </Box>
        <img style={{ maxWidth: 'calc(30vw + 100px)', placeSelf: 'flex-end', marginRight: '10%' }} src={cardFlower} />
      </Box>
    </Box>
  )
}

const AnimatedButton = styled(Button)`
  opacity: 0;
  @keyframes fadein {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

const AnimatedTypography = styled(Typography)`
  opacity: 0;
  @keyframes fadein {
    from {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

const roleToTitle = (role: SessionRole) => {
  switch (role) {
    case SessionRole.ADMIN: {
      return 'Admin'
    }

    case SessionRole.SALES: {
      return 'Sales'
    }

    default: {
      return 'Unauthorized'
    }
  }
}

const roleToCTAText = (role: SessionRole) => {
  switch (role) {
    case SessionRole.ADMIN: {
      return 'Get started'
    }

    case SessionRole.SALES: {
      return 'Search for anything'
    }

    default: {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
          <Google width="1em" height="1em" />
          <span>Sign in with google</span>
        </Box>
      )
    }
  }
}

const roleToSubtitle = (role: SessionRole) => {
  switch (role) {
    case SessionRole.ADMIN: {
      return 'Add and edit company details, users, and settings for accounting and credit.'
    }

    case SessionRole.SALES: {
      return 'View companies, users, and cards.'
    }

    default: {
      return 'You are not signed in, sign in with google again.'
    }
  }
}
