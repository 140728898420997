import Switch, { SwitchProps } from '@mui/material/Switch'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import React, { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react'

type LabelComponent = <T>(props: PremiumSwitchLabelProps<T> & React.HTMLProps<HTMLLabelElement>) => JSX.Element

type PremiumSwitchType = {
  (props: PremiumSwitchProps): JSX.Element
  Left: LabelComponent
  Right: LabelComponent
}

type ContextState = {
  leftValue: unknown
  rightValue: unknown
  currentValue: unknown
  disabled: boolean
}

type SwitchContext = [ContextState, (callback: ((prevState: ContextState) => Partial<ContextState>) | Partial<ContextState>) => void]

export const PremiumSwitchContext = createContext<SwitchContext>([
  {
    leftValue: undefined,
    rightValue: undefined,
    currentValue: undefined,
    disabled: false
  },
  () => {
    throw new Error('Cannot use PremiumSwitchContext outside of PremiumSwitch')
  }
])

type PremiumSwitchProps<T = any> = {
  children: [JSX.Element, JSX.Element]
  onChange: (value: T) => unknown
} & Omit<SwitchProps, 'onChange'>

type PremiumSwitchLabelProps<T = unknown> = {
  value: T
  children: React.ReactNode
}

export const PremiumSwitch: PremiumSwitchType = ({ value, ...props }) => {
  const [{ rightValue, leftValue, ...state }, setValues] = useState<ContextState>({
    leftValue: undefined,
    rightValue: undefined,
    currentValue: value,
    disabled: Boolean(props.disabled)
  })

  // if value is undefined, use the state value since the component is uncontrolled
  const valueToLookAt = value === undefined ? state.currentValue : value

  const setStateProxy: SwitchContext[1] = (callback) =>
    setValues((prev) => {
      const updatedState = { ...prev, ...(typeof callback === 'function' ? callback(prev) : callback) }

      if (updatedState.currentValue !== prev.currentValue) {
        props.onChange(updatedState.currentValue)
      }

      // if undefined, default the value to left
      if (updatedState.currentValue === undefined) {
        updatedState.currentValue = prev.leftValue
      }

      return updatedState
    })

  const checked = valueToLookAt === rightValue
  const [left, right] = props.children

  return (
    <PremiumSwitchContext.Provider value={[{ ...state, rightValue, leftValue, currentValue: valueToLookAt }, setStateProxy]}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'center', alignItems: 'center' }}>
        {left}
        <StyledSwitch
          {...props}
          checked={checked}
          onChange={(_, checked) => setStateProxy({ currentValue: checked ? rightValue : leftValue })}
        />
        {right}
      </Box>
    </PremiumSwitchContext.Provider>
  )
}

const Label = (side: keyof Omit<ContextState, 'currentValue'>) => {
  const SidedLabel = <T,>({ value, children, as, ...props }: { value: T } & React.HTMLProps<HTMLLabelElement>) => {
    const [{ currentValue, disabled }, setValues] = useContext(PremiumSwitchContext)

    useEffect(() => {
      setValues({ [side]: value })
    }, [value])

    return (
      <SwitchLabel
        active={currentValue === value}
        onClick={() => {
          if (!disabled) setValues((prev) => ({ ...prev, currentValue: value }))
        }}
        {...props}
      >
        {children}
      </SwitchLabel>
    )
  }

  return SidedLabel
}

PremiumSwitch.Right = Label('rightValue')
PremiumSwitch.Left = Label('leftValue')

const SwitchLabel = styled.span<{ active: boolean }>`
  color: ${(props) => (props.active ? '#1C1B1F' : '#B3B3B4')};
  transition: color 200ms ease;
  font-weight: 600;
`

const StyledSwitch = styled(Switch)`
  .MuiSwitch-track {
    background-color: #1b1c1f !important;
  }
`
