/* eslint-disable no-console */

import { useState } from 'react'

export enum LocalStorageKeys {
  // Used in Gazelle
  LAST_LOGGED_IN_SESSION = 'LAST_LOGGED_IN_SESSION',
  LAST_LOGGED_IN_SESSION_DEV = 'LAST_LOGGED_IN_SESSION_DEV',
  HAS_SEEN_NEW_LAYOUT = 'HAS_SEEN_NEW_LAYOUT',
  LANGUAGE = 'LANGUAGE',
  COUNTRY = 'COUNTRY',
  CURRENCY = 'CURRENCY',
  REDIRECT_PATH = 'REDIRECT_PATH',
  APPLE_GOOGLE_PAY_INFO_BOX = 'APPLE_GOOGLE_PAY_INFO_BOX',
  KYC_STATE = 'KYC_STATE',

  //  Used in Backoffice
  INBOX_FILTERS = 'INBOX_FILTERS',
  INBOX_FILTERS_CHECK_ALL = 'INBOX_FILTERS_CHECK_ALL'
}

export function useLocalStorageItem<T>(key: LocalStorageKeys, defaultValue: T) {
  const [item, setItem] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return defaultValue
    }

    try {
      const item = window.localStorage.getItem(key)

      return item ? (JSON.parse(item) as T) : defaultValue
    } catch (error) {
      console.log(error)

      return defaultValue
    }
  })

  const _setItem = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(item) : value

      setItem(valueToStore)

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    item,
    setItem: _setItem
  } as const
}
