import styled from '@emotion/styled'
import ColorsDeprecated from 'figma/tokens/ColorsDeprecated'
import BorderRadius from 'figma/tokens/BorderRadius'
import Shadows from 'figma/tokens/Shadows'
import Spacings from 'figma/tokens/Spacings'
import ZIndices from 'figma/tokens/ZIndices'
import { useActivatePlastic } from 'api/react-query'
import MyntPopper from 'mynt-components/components/MyntPopper'
import React, { useState } from 'react'
import { Triangle } from '../helpers/customerGeneralFormHelper'
import useMaterialNotification from '../hooks/useMaterialNotification'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaTextArray from '../mynt-components/components/FigmaTextArray'
import { ModalProps } from '../mynt-components/components/ModalExperimental'
import TextContainer from './TextContainer'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { ListPlasticResponse } from 'api/swagger/definitions/backoffice'

export type ModalActivateCardProps = ModalProps & {
  customerId: string
  plastic: ListPlasticResponse
  anchor: any
}

export const _ModalActivateCard: React.FC<ModalActivateCardProps> = (props) => {
  const { plastic, customerId, anchor } = props

  const notify = useMaterialNotification()
  const activatePlasticMutation = useActivatePlastic(customerId)
  const [loading, setLoading] = useState(false)

  const handleActivate = () => {
    const firstName = plastic.embossing?.firstName
    const lastName = plastic.embossing?.lastName
    const cardId = plastic.cardId
    const plasticId = plastic.id

    if (!firstName || !lastName || !cardId || !plasticId) return notify(`plastic is missing emobssing or id`, 'error')

    const onSuccess = () => {
      notify(`Activated ${firstName} ${lastName}'s card`, 'success')
    }

    const onFinish = () => {
      setLoading(false)
      props.onClose?.()
    }

    setLoading(true)

    const data = { cardId, plasticId }

    activatePlasticMutation.mutateAsync(data).then(onSuccess).finally(onFinish)
  }

  return (
    <MyntPopper
      visible
      noPadding
      marginTop={Spacings.min}
      marginBottom={Spacings.medium}
      position="top"
      anchorRef={anchor}
      zIndex={ZIndices.heaven}
      handleClose={props.onClose}
      content={
        <Container fullWidth fullPadding>
          <FigmaBox fullWidth bottom align="center">
            <FigmaBox bottom>
              <FigmaTextArray
                propSets={[
                  { textKey: TextKeys.activateCardTokenHeader },
                  {
                    textKey: TextKeys.activateCardTokenCode,
                    text: plastic.embossing?.additionalField1 || 'NULL'
                  }
                ]}
              />
            </FigmaBox>
            <TextContainer textKey={TextKeys.activateCardTokenText} />
          </FigmaBox>
          <FigmaBox fullWidth direction="row" justify="center">
            <FigmaBox style={{ maxWidth: '50%' }} fullWidth spacing={Spacings.tiny} right>
              <Button fullWidth onClick={props.onClose} variant="outlined">
                Cancel
              </Button>
            </FigmaBox>
            <FigmaBox style={{ maxWidth: '50%' }} fullWidth align="center">
              <LoadingButton
                variant="primary"
                disabled={!plastic.embossing?.additionalField1}
                fullWidth
                loading={loading}
                onClick={handleActivate}
              >
                Yes
              </LoadingButton>
            </FigmaBox>
          </FigmaBox>
          <Triangle />
        </Container>
      }
    />
  )
}

const Container = styled(FigmaBox)`
  width: 345px;
  background-color: ${ColorsDeprecated.baseWhite};
  border-radius: ${BorderRadius.soft};
  box-shadow: ${Shadows.baseHeavy};
`
