import dayjs from 'dayjs'

type ObjectWithAutoStartToken = {
  output: {
    bankId: {
      autoStartToken: string
    }
  }
}

type ObjectWithPersonSsnAndPhoneNumber = {
  ssn: string
  phoneNumber?: string
}

export const removeDuplicates = (arr: any[], key: string) => {
  const _arr = arr.reduce((a, b) => {
    a[b[key]] = b
    return a
  }, {})

  arr = Object.keys(_arr).map((id) => _arr[id])

  return arr
}

export const asObject = <T>(arr: Array<T>, key: keyof T): { [key: string]: T } => {
  return arr.reduce((a, b) => {
    a[b[key as string]] = b
    return a
  }, {} as { [key: string]: T })
}

export function getEnumKeyByEnumValue(myEnum: any, enumValue: number) {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue)
  return keys.length > 0 ? keys[0] : null
}

export const formatAmount = (data: { currency: string; value: number }): string => {
  const formatter = Intl.NumberFormat('SWE', {
    style: 'currency',
    currency: data.currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0
  })

  return formatter.format(data.value)
}

export const formatCurrency = (currency?: string): string => {
  return (0)
    .toLocaleString('SWE', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, '')
    .trim()
}

export const formatAmountNoCurrencyTag = (data: { currency: string; value: number }): string => {
  const formatter = Intl.NumberFormat('SWE', {
    style: 'currency',
    currency: data.currency,
    currencyDisplay: 'code',
    minimumFractionDigits: 0
  })

  return formatter.format(data.value).replace(data.currency, '').trim()
}

export const getNextMonth = (): string => {
  return dayjs().add(1, 'M').startOf('month').format('DD MMMM')
}

export const isCompletedProfile = <T extends ObjectWithPersonSsnAndPhoneNumber>(person: T): boolean => {
  return !!person?.phoneNumber && !!person.ssn
}

export const getInitials = (name: string): string => {
  if (!name) return ''

  const arr = name.trim().split(' ')
  if (arr.length === 1) {
    return name[0].toUpperCase() + name[1].toUpperCase()
  }
  return (arr[0][0] + arr[arr.length - 1][0]).toUpperCase()
}

export const maskSSN = (ssn: string): string => {
  if (!ssn) return ''
  return ssn.substring(0, ssn.length - 4) + '****'
}

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      // eslint-disable-next-line eqeqeq
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const isEmptyObj = (obj: Record<any, any>) => {
  for (const _ in obj) return false
  return true
}

export const getBankIDQRCodeValue = <T extends ObjectWithAutoStartToken>(onboardingResponse: T) => {
  let URL = `https://app.bankid.com/?autostarttoken=${onboardingResponse?.output?.bankId?.autoStartToken}`

  if (window != null) {
    URL += `&redirect=${window?.location?.href}`
  }

  return URL
}

export const getChunks = <T>(array: Array<T>, chunkSize: number): Array<Array<T>> => {
  let temparray: T[] = []
  const res: T[][] = []

  for (let i = 0, j = array.length; i < j; i += chunkSize) {
    temparray = array.slice(i, i + chunkSize)
    res.push(temparray)
  }

  return res
}

let timeout: number
export const debounce = (fn: () => unknown, time: number) => {
  window.clearTimeout(timeout)
  timeout = window.setTimeout(() => fn(), time)
}

export const dashToPascal = (string?: string): string =>
  !string
    ? ''
    : string
      .split('-')
      .map((string) => string.substring(0, 1).toUpperCase() + (string.substring(1) ?? '').toLowerCase())
      .join('')

export const parseLocaleNumber = (value = '') => parseInt(value.replace(/\W/g, ''))

export const getDropdownOptionsFromEnum = (enumValue: Record<string, string>, labelTransform?: (label: string) => string) =>
  Object.values(enumValue).map((value) => ({ value: value, label: labelTransform?.(value) ?? value }))

export const getDropdownOptionsFromArray = (arrayValue: Array<string>, labelTransform?: (label: string) => string) =>
  Object.values(arrayValue).map((value) => ({ value: value, label: labelTransform?.(value) ?? value }))
