import { AxiosError } from 'axios'
import get from 'lodash/get'
import config from '../../config'
import {
  ALL_ACCOUNT_REGEX,
  CARD_CARDS_ID_REGEX,
  CODE_REGEX,
  DATE_REGEX,
  ENFUCE_URL_REGEX,
  FORCE_REFRESH_REGEX,
  LIMIT_REGEX,
  MAX_REGEX,
  MIN_REGEX,
  OFFSET_REGEX,
  ORG_NO_REGEX,
  SSN_REGEX,
  START_SERIAL_NUMBER_REGEX,
  STATUS_REGEX,
  UUID_REGEX
} from '../../tiger/Constants'

export const clearCustomerData = (message: string) =>
  message
    .replace(`${config.appBaseUrl}/standalone`, '') // First remove API URL, including '/standalone'
    .replace(config.appBaseUrl, '') // If URL remains, then it's a DEV endpoint, so remove URL, but keep '/developer'
    .replace(UUID_REGEX, '{uuid}')
    .replace(SSN_REGEX, '{ssn}')
    .replace(ORG_NO_REGEX, '{orgNo}')
    .replace(CODE_REGEX, 'code={code}')
    .replace(START_SERIAL_NUMBER_REGEX, 'startSerialNumber={serialNumber}')
    .replace(DATE_REGEX, '{date}')
    .replace(OFFSET_REGEX, 'offset={int}')
    .replace(LIMIT_REGEX, 'limit={int}')
    .replace(MIN_REGEX, 'min={int}')
    .replace(MAX_REGEX, 'max={int}')
    .replace(STATUS_REGEX, 'status={string}')
    .replace(FORCE_REFRESH_REGEX, 'force-refresh={bool}')
    .replace(ALL_ACCOUNT_REGEX, 'all-account={bool}')
    .replace(CARD_CARDS_ID_REGEX, '/card/cards/{cardId}')
    .replace(ENFUCE_URL_REGEX, '{enfuce-sensitive-info-url}')

export const getAxiosNetworkFingerprint = (err: AxiosError): string[] => {
  const { response } = err

  const status = get(response, 'status', '-')
  const method = get(response, 'config.method', '-')

  const url = clearCustomerData(get(response, 'config.url', '-'))

  return [status as string, method.toUpperCase(), url]
}

export const getAxiosNetworkFingerprintString = (err: AxiosError): string => getAxiosNetworkFingerprint(err).join(' ')

export const getAxiosErrorMessage = (err: AxiosError): string =>
  get(err.response, 'data.message', '-').replace('No message available', '').replace(SSN_REGEX, '{ssn}')

export const getMappedAxiosError = (error: AxiosError) => {
  error.name = 'RequestError'
  error.message = getAxiosErrorMessage(error)

  return error
}
