/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AgreementStatus = {
    DRAFT: 'DRAFT',
    SENT: 'SENT',
    PARTIALLY_SIGNED: 'PARTIALLY_SIGNED',
    FULLY_SIGNED: 'FULLY_SIGNED',
    CANCELLED: 'CANCELLED',
    REJECTED: 'REJECTED',
    ERROR: 'ERROR'
} as const;

export type AgreementStatus = typeof AgreementStatus[keyof typeof AgreementStatus];



