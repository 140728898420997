import config from 'config'
import isEqual from 'lodash/isEqual'

export const isDev = () => new URL(window.location.href).searchParams.get('dev') !== null || config.name !== 'production'

export function normalizeString(string: string) {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const getObjectDifference = (A, B) =>
  Object.keys(A).reduce((acc, key) => {
    if (!isEqual(A[key], B[key])) {
      acc[key] = A[key]
    }

    return acc
  }, {})
